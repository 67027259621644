import React, { createContext, useState,  } from 'react';
import { AccountFormState, ContactFormState, CandiDateFromState, CandiDateDocumentFromState, ContactDocumentFromState, AccountDocumentFromState } from '../Modules/formState';

const ContextData = createContext();

function ContextProvider({ children }) {


  const [documents, setDocuments] = useState([]);

  const [accountDocuments, setAccountDocuments] = useState([]);

  const [formData, setFormData] = useState(ContactFormState);

  const [accountformData, setAccountFormData] = useState(AccountFormState);

  const [candiDatesFromData, setCandiDatesFromData] = useState(CandiDateFromState)

  const [candiDateDocument, setCandiDateDocument] = useState(CandiDateDocumentFromState)




  const [candiDateArrayDocument, setcandiDateArrayDocument] = useState([])

  const [accountDocumentFormData, setAccountDocumentFormData] = useState(AccountDocumentFromState);

  const [documentFormData, setDocumentFormData] = useState(ContactDocumentFromState);

  const [toastMessage, setToastMessage] = useState("");
  const [forgetPasswordEmail, setForgetPasswordEmail] = useState("");

  const [acountData, setAcountData] = useState();

  const [contactDocumnet, setContactDocument] = useState();
  const [contactSubmission, setContactSubmission] = useState();
  const [stepLine, setStepLine] = useState(1);
  const [stepLineStatus, setStepLineStatus] = useState(false);



  return (
    <ContextData.Provider value={{
      acountData,
      setAcountData,
      toastMessage,
      setToastMessage,
      forgetPasswordEmail,
      setForgetPasswordEmail,
      formData,
      setFormData,
      contactDocumnet,
      setContactDocument,
      contactSubmission,
      setContactSubmission,
      stepLine,
      setStepLine,
      stepLineStatus,
      setStepLineStatus,
      documentFormData,
      setDocumentFormData,
      documents,
      setDocuments,
      accountformData,
      setAccountFormData,
      accountDocumentFormData,
      setAccountDocumentFormData,
      accountDocuments,
      setAccountDocuments,
      candiDatesFromData,
      setCandiDatesFromData,
      candiDateDocument,
      setCandiDateDocument,
      candiDateArrayDocument, 
      setcandiDateArrayDocument
    }}>
      {children}
    </ContextData.Provider>
  );
}

export { ContextData, ContextProvider };
