import React, {
    useCallback,
    useMemo,
    useState,
    useEffect,
    useContext,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    state_DropDown,
    city_DropDown, documents_Dropdown, user_Job_DropDown,
    user_JobClientManager_DropDown
} from "../../stores/Reducers/dropDownApiReducers";
import { createJob } from "../../stores/Reducers/newJob";
import { Job_Client_DropDown, Job_Code_DropDown, Job_User_DropDown, Job_Work_DropDown, JobDegreeDropDown, JobLanguageDropDown, JobNoticeDropDown, JobDocumentDropDown, JobPriorityDropDown, JobStatusDropDown, JobTaxTermDropDown, JobTypeDropDown } from "../../stores/Reducers/jobDropDownReducer";
import CustomIcon from "../../components/CustomIcon";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomNumber from "../../components/CustomNumber";
import CustomEmail from "../../components/CustomEmail";
import CustomSocialMedia from "../../components/CustomSocialMedia";
import CutomButton from "../../components/CutomButton";
import CompanySearch from "../../components/CompanySearch";
import SearchableDropDown from "../../components/SearchableDropDown";
import MultipleSelect from "../../components/MultipleSelect";
import CustomAddSelect from "../../components/CustomAddSelect";
import computer from "../../assets/images/computer.png";
import JoditEditor from "jodit-react";
import "../../css/powerpoint.css";
import box from '../../assets/images/box.png'
import { uploadFile } from "../../Modules/Document_Upload";
import { toast } from "react-toastify";
import { currencyCodes, TimeHour, SpentTime } from "../../Modules/dropDownData";
import SingleSearchableSelect from "../../components/SingleSearchableSelect"
import { ContactDocumentFromState } from '../../Modules/formState';

const NewJob = () => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [countryName, setCountryName] = useState("");
    const [remoteJobs, setRemotejobs] = useState("");
    const [statesName, setStatesName] = useState("");
    const [cityName, setCityName] = useState("");
    const [threeStatus, setThreeStatus] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [documents, setDocuments] = useState(ContactDocumentFromState);
    const [documents1, setDocuments1] = useState([]);
    const [showData1, setShowData1] = useState(false);
    const [currency, setCurrency] = useState("USD");
    const [currencyMin, setCurrencyMin] = useState();
    const [currencyMax, setCurrencyMax] = useState();
    const [workHour, setWorkHour] = useState("");
    const [spentHour, setSpentHour] = useState("");
    const [turnAroundtimeValue, setTurnAroundtimevalue] = useState();
    const [turnAroundtimeUnit, setTurnAroundtimeUnit] = useState("");
    const [content, setContent] = useState('');
    const [imageName, setImageName] = useState("");
    const [fileUploadStatus, setFileUploadStatus] = useState(false);
    const [selectDocument, setSelectDocument] = useState("");
    const [selectInput, setSelectInput] = useState("");
    const [selectDescription, setSelectDescription] = useState("");

    const [experienceMin, setExperienceMin] = useState();

    const [experienceMax, setExperienceMax] = useState();






    const {
        jobStatusDropDown,
        jobTypeDropDown,
        jobDocumentDropDown,
        jobPriorityDropDown,
        jobWorkDropDown,
        jobNoticeDropDown,
        jobDegreeDropDown,
        jobLanguageDropDown,
        jobTaxTermDropDown,
        jobUserDropDown,
        jobClientDropDown,
        jobCodeDropDown,

    } = useSelector((data) => data.jobDropDown);

    const {
        documentDropDown
    } = useSelector((data) => data.dropDown);

    const [uiData, setUiData] = useState([
        {
            id: 1,
            label: "",
            children: [
                {
                    id: 1,
                    name: "Business Unit",
                    value: "Hyre max It",
                    error: "",
                    type: "text",
                    required: "false",
                },
            ],
        },
        {
            id: 2,
            label: "Job Details",
            children: [
                {
                    id: 1,
                    name: "Job Code",
                    value: jobCodeDropDown && jobCodeDropDown,
                    error: "",
                    type: "text",
                    required: "true",

                },
                {
                    id: 2,
                    name: "Job Title",
                    value: "",
                    error: "",
                    type: "text",
                    required: "false",
                },
                {
                    id: 3,
                    name: "Pay Rate / Salary",
                    value: "",
                    error: "",
                    type: "threeText",
                    required: "false",
                    childrenItem: [
                        { id: "1", type: "select", name: "currency", value: "", error: "", required: "false" },
                        { id: "2", type: "text", name: "min", value: "", error: "", required: "false" },
                        { id: "3", type: "text", name: "max", value: "", error: "", required: "false" },
                        { id: "4", type: "select", name: "workingHours", value: "", error: "", required: "false" },
                        { id: "5", type: "select", name: "spentworkingHours", value: "", error: "", required: "false" }
                    ]
                },
                {
                    id: 4,
                    name: "Respond By",
                    value: "",
                    error: "",
                    type: "date",
                    required: "false",
                },
                {
                    id: 5,
                    name: "Remote Job",
                    value: "No",
                    error: "",
                    type: "radio",
                    options: ["Yes", "No", "Hybrid"],
                },
                {
                    id: 6,
                    name: "Expenses Paid",
                    value: "",
                    error: "",
                    type: "radio",
                    options: ["Yes", "No"],
                },
                {
                    id: 7,
                    name: "Country",
                    value: "United States",
                    error: "",
                    type: "dropDown",
                    required: "true",
                },
                {
                    id: 8,
                    name: "States",
                    value: "Alabama",
                    error: "",
                    type: "dropDown",
                    required: "false",
                },
                {
                    id: 9,
                    name: "City",
                    value: "",
                    type: "dropDown",
                    required: "false",
                },
                {
                    id: 10,
                    name: "Address",
                    value: "",
                    type: "text",
                    required: "false",
                },
                {
                    id: 11,
                    name: "Zip Code",
                    value: "",
                    type: "text",
                    required: "false",
                },
                {
                    id: 12,
                    name: "job Status",
                    value: "",
                    error: "",
                    type: "dropDown",
                    required: "true",
                },
                {
                    id: 13,
                    name: "Job Type",
                    value: "",
                    error: "",
                    type: "dropDown",
                    required: "true",
                },
                {
                    id: 14,
                    name: "Required Hours / Week",
                    value: "",
                    error: "",
                    type: "text",
                    required: "flase",
                },
                {
                    id: 15,
                    name: "Company Name",
                    value: "",
                    error: "",
                    type: "dropDown",
                    required: "true",
                },
                {
                    id: 16,
                    name: "Contact Person",
                    value: "",
                    error: "",
                    type: "dropDown",
                    required: "true",
                },
                {
                    id: 17,
                    name: "End Client",
                    value: "",
                    error: "",
                    type: "text",
                    required: "flase",
                },
                {
                    id: 18,
                    name: "Required Documents for Submission",
                    value: "",
                    error: "",
                    type: "MultidropDown",
                    required: "flase",
                },
                {
                    id: 19,
                    name: "Turnaround Time",
                    value: "",
                    error: "",
                    type: "Twotext",
                    required: "flase",
                    childrenItem: [

                        { id: "1", type: "text", name: "value", value: "", error: "", required: "false" },
                        { id: "2", type: "dropDown", name: "Days", value: "", error: "", required: "false" },

                    ]
                },
                {
                    id: 20,
                    name: "Priority",
                    value: "",
                    error: "",
                    type: "dropDown",
                    required: "flase",
                },
                // {
                //     id: 21,
                //     name: "Client category",
                //     value: "",
                //     error: "",
                //     type: "dropDown",
                //     required: "flase",
                // },
                {
                    id: 22,
                    name: "Duration",
                    value: "",
                    error: "",
                    required: "true",
                    type: "text",
                },
                {
                    id: 23,
                    name: "Work Authorization",
                    value: "",
                    error: "",
                    type: "MultidropDown",
                    required: "flase",
                },
                {
                    id: 24,
                    name: "Interview Mode",
                    value: "",
                    error: "",
                    type: "text",
                    required: "true",
                },
                {
                    id: 25,
                    name: "Clearance",
                    value: "",
                    error: "",
                    type: "radio",
                    options: ["Yes", "No"],
                    required: "flase",
                },
                {
                    id: 26,
                    name: "placement Fee Percentage",
                    value: "",
                    error: "",
                    type: "number",
                    required: "flase",
                },
                {
                    id: 27,
                    name: "Notice Period",
                    value: "",
                    error: "",
                    type: "dropDown",
                    required: "false",
                },
            ],
        },

        {
            id: 3,
            label: "Skills",
            children: [
                {
                    id: 1,
                    name: "Industry",
                    value: "",
                    error: "",
                    type: "MultidropDown",
                    required: "false",
                },
                { id: 2, name: "Degree", value: "", error: "", type: "MultidropDown", required: "false" },
                {
                    id: 3, name: "Experience", value: "", error: "", required: "false", type: "experience",
                    childrenItem: [

                        { id: "1", type: "text", name: "min", value: "", error: "", required: "false" },
                        { id: "2", type: "text", name: "max", value: "", error: "", required: "false" },

                    ]


                },
                {
                    id: 4,
                    name: "Primary Skills",
                    value: "",
                    error: "",
                    type: "text",
                    required: "false",
                },
                { id: 5, name: "Secondary Skills", value: "", error: "", type: "text" },
                { id: 6, name: "Language", value: "", error: "", type: "MultidropDown" },
            ],
        },
        {
            id: 4,
            label: "Oraganization Information",
            children: [
                {
                    id: 1,
                    name: "Number Position",
                    value: "",
                    error: "",
                    type: "number",
                    required: "false",
                },
                {
                    id: 2,
                    name: "Maximum allowed Submissions",
                    value: "",
                    error: "",
                    type: "number",
                    required: "false",
                },
                {
                    id: 3,
                    name: "Tax Terms",
                    value: "",
                    error: "",
                    type: "MultidropDown",
                    required: "true",
                },
                {
                    id: 4,
                    name: "Recruitment Manager",
                    value: "",
                    error: "",
                    type: "dropDown",
                    required: "false",
                },
                {
                    id: 5,
                    name: "Account Manager",
                    value: "",
                    error: "",
                    type: "dropDown",
                    required: "false",
                },
                {
                    id: 6,
                    name: "Assigned To",
                    value: "",
                    error: "",
                    type: "MultidropDown",
                    required: "false",
                },
                {
                    id: 7,
                    name: "Comments",
                    value: "",
                    error: "",
                    type: "text",
                    required: "false",
                },
                {
                    id: 8,
                    name: "Additional Notification",
                    value: "",
                    error: "",
                    type: "MultidropDown",
                    required: "false",
                },
                {
                    id: 9,
                    name: "Carrer Portal Published Date",
                    value: "",
                    error: "",
                    type: "date",
                    required: "false",
                },
                {
                    id: 10,
                    name: "Job Description",
                    value: "",
                    error: "",
                    type: "LargeInputBox",
                    required: "false",
                },
            ],
        },
    ]);
    useEffect(() => {
        dispatch(Job_Client_DropDown());
        dispatch(Job_Code_DropDown());
        dispatch(Job_User_DropDown());
        dispatch(Job_Work_DropDown());
        dispatch(JobDegreeDropDown());
        dispatch(JobLanguageDropDown());
        dispatch(JobNoticeDropDown());
        dispatch(JobDocumentDropDown());
        dispatch(JobPriorityDropDown());
        dispatch(JobStatusDropDown());
        dispatch(JobTaxTermDropDown());
        dispatch(JobTypeDropDown());
        dispatch(user_Job_DropDown());

    }, []);

    useEffect(() => {
        let endpoints = "job";
        dispatch(documents_Dropdown({ endpoints }));
    }, [dispatch])

    const {
        CountryDropDown,
        stateDropDown,
        industryDropDown,
        cityDropDown,
        user_JobDropDown,
        clientManagerDropDown

    } = useSelector((data) => data.dropDown);


    useEffect(() => {
        if (companyId) {
            let clientId = companyId;
            dispatch(user_JobClientManager_DropDown({ clientId }));
        }

    }, [companyId, dispatch])

    useEffect(() => {
        if (countryName) {
            const country = CountryDropDown.find(
                (item) => item.name === countryName
            );
            if (country) {
                dispatch(state_DropDown({ countryCode: country.isoCode }));
            }
        }
        // else{
        //     dispatch(state_DropDown({countryCode:"US"}))
        // }
    }, [countryName, dispatch]);

    useEffect(() => {
        if (statesName) {
            const state = stateDropDown.find(
                (item) => item.name === statesName
            );
            const country = CountryDropDown.find(
                (item) => item.name === countryName
            );
            if (state && country) {
                dispatch(
                    city_DropDown({
                        countryCode: country.isoCode,
                        stateCode: state.isoCode,
                    })
                );
            }
            // else{
            //     dispatch(
            //         city_DropDown({
            //             countryCode: "US",
            //             stateCode: "AL",
            //         })
            //     );
            // }
        }
    }, [statesName, dispatch])



    const validateField = () => {
        let isValid = true;

        const updatedUiData = uiData.map((section) => {
            const updatedChildren = section.children.map((child) => {
                let error = "";

                if (child.required === "true" && !child.value) {
                    error = `${child.name} is required.`;
                    isValid = false;
                }

                if (child.childrenItem) {
                    child.childrenItem = child.childrenItem.map((subChild) => {
                        let subError = "";
                        if (subChild.required === "true" && !subChild.value) {
                            subError = `${subChild.name} is required.`;
                            isValid = false;
                        }
                        return { ...subChild, error: subError };
                    });
                }

                return { ...child, error };
            });

            return { ...section, children: updatedChildren };
        });

        setUiData(updatedUiData);
        return isValid;
    };



    const handleChange = (sectionId, fieldId, newValue) => {
        setUiData((prevData) =>
            prevData.map((section) => {
                if (section.id === sectionId) {
                    return {
                        ...section,
                        children: section.children.map((field) => {
                            if (field.id === fieldId) {
                                const updatedField = {
                                    ...field,
                                    value: newValue,
                                    error: field.required === "true" && !newValue.trim() ? "This field is required." : "",
                                };
                                return updatedField;
                            }
                            return field;
                        }),
                    };
                }
                return section;
            })
        );
    };

    const editorConfig = useMemo(
        () => ({
            height: 400,
            readonly: false,
            toolbarSticky: false,
        }),
        []
    );

    const handleChangeChild = (sectionId, fieldId, updatedValue, childId) => {
        setUiData((prevUiData) =>
            prevUiData.map((section) =>
                section.id === sectionId
                    ? {
                        ...section,
                        children: section.children.map((field) => {
                            if (field.id === fieldId) {
                                if (childId && field.childrenItem) {
                                    return {
                                        ...field,
                                        childrenItem: field.childrenItem.map((child) =>
                                            child.id === childId
                                                ? { ...child, value: updatedValue }
                                                : child
                                        ),
                                    };
                                }
                                return { ...field, value: updatedValue };
                            }
                            return field;
                        }),
                    }
                    : section
            )
        );
    };

    const currencyCodesData = useMemo(() => {
        return currencyCodes?.map((item) => ({
            label: item,
            code: item,
            value: item
        }));
    }, [currencyCodes]);

    const TimeHourData = useMemo(() => {
        return TimeHour?.map((item) => ({
            label: item?.name,
            code: item?.name,
        }));
    }, [TimeHour]);

    const SpentTimeData = useMemo(() => {
        return SpentTime?.map((item) => ({
            label: item?.name,
            code: item?.name,
        }));
    }, [SpentTime]);

    const countryData = useMemo(() => {
        return CountryDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [CountryDropDown]);

    const stateData = useMemo(() => {
        return stateDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [stateDropDown]);


    const cityData = useMemo(() => {
        return cityDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [cityDropDown])

    const jobStatusData = useMemo(() => {
        return jobStatusDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobStatusDropDown])

    const jobTypeData = useMemo(() => {
        return jobTypeDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobTypeDropDown])

    const jobDocumentData = useMemo(() => {
        return jobDocumentDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobDocumentDropDown])

    const jobPriorityData = useMemo(() => {
        return jobPriorityDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobPriorityDropDown])

    const jobWorkData = useMemo(() => {
        return jobWorkDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobWorkDropDown])


    const jobNoticeData = useMemo(() => {
        return jobNoticeDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobNoticeDropDown])

    const jobDegreeData = useMemo(() => {
        return jobDegreeDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobDegreeDropDown])


    const jobLanguageData = useMemo(() => {
        return jobLanguageDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobLanguageDropDown])


    const jobTaxData = useMemo(() => {
        return jobTaxTermDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [jobTaxTermDropDown])

    const jobUserData = useMemo(() => {
        return jobUserDropDown?.map((item) => ({
            label: item?.user,
            value: item?.userId,
            code: item?.user
        }));
    }, [jobUserDropDown])

    const jobClientdata = useMemo(() => {
        return jobClientDropDown?.map((item) => ({
            label: item.client_name,
            value: item.clientId,
            code: item.client_name
        }));
    }, [jobClientDropDown]);



    const industryData = useMemo(() => {
        return industryDropDown?.map((item) => ({
            label: item,
            value: item,
            code: item
        }));
    }, [industryDropDown]);

    const document_DropDwon = useMemo(() => {
        return documentDropDown?.map((item) => ({
            value: item,
            label: item,
        }));
    }, [documentDropDown]);

    const Additional_Notification = useMemo(() => {
        return jobUserDropDown?.map((item) => ({
            label: item?.user,
            value: item?.userId,
            code: item?.user
        }));
    }, [jobUserDropDown]);

    const Client_Manager = useMemo(() => {
        return clientManagerDropDown?.map((item) => ({
            value: item?.contactId,
            label: item?.contact,
        }));
    }, [clientManagerDropDown]);




    const getOptions = (fieldName) => {
        switch (fieldName) {
            case "Country":
                return countryData;
            case "States":
                return stateData;
            case "City":
                return cityData;
            case "Company Name":
                return jobClientdata;
            case "Contact Person":
                return Client_Manager;
            case "job Status":
                return jobStatusData;
            case "Job Type":
                return jobTypeData;
            case "Priority":
                return jobPriorityData;
            case "Notice Period":
                return jobNoticeData;
            case "Tax Terms":
                return jobTaxData;
            case "Recruitment Manager":
                return jobUserData;
            case "Account Manager":
                return jobUserData;

            default:
                return [];
        }
    };

    const getMultiOptions = (fieldName) => {
        switch (fieldName) {

            case "Industry":
                return industryData;
            case "Degree":
                return jobDegreeData;
            case "Required Documents for Submission":
                return jobDocumentData;
            case "Client category":
                return [];
            case "Additional Notification":
                return Additional_Notification;
            case "Work Authorization":
                return jobWorkData;
            case "Language":
                return jobLanguageData;
            case "Tax Terms":
                return jobTaxData;
            case "Assigned To":
                return jobUserData;
            default:
                return [];
        }
    };


    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        let newData1 = {};
        let neWData = [];
        if (file) {
            const fileLocation = await uploadFile(file);
            if (fileLocation.Location) {
                toast.success("file is sucessfully uploaded")
                setImageName(fileLocation?.Location);
                setSelectInput(file?.name);
                setDocuments((prevState) => {
                    const hasEmptyTitleDocument = prevState?.document && prevState?.document.some(
                        (doc) => doc.title === ""
                    );
                    const updatedDocuments = hasEmptyTitleDocument
                        ? prevState?.document && prevState?.document.map((doc) =>
                            doc.title === ""
                                ? {
                                    ...doc,
                                    title: file.name,
                                    url: fileLocation?.Location,
                                    description: "",
                                }
                                : doc
                        )
                        : [
                            ...prevState.document,
                            {
                                title: file.name,
                                url: fileLocation?.Location,
                                description: "",
                            },
                        ];

                    return {
                        ...prevState,
                        document: updatedDocuments,
                    };
                });


            }
            else {
                toast.error("file is not upload")
            }



        }
    };

    const handleRadioChange = (sectionId, fieldId, newValue) => {
        setUiData((prevUiData) =>
            prevUiData.map((section) =>
                section.id === sectionId
                    ? {
                        ...section,
                        children: section.children.map((child) =>
                            child.id === fieldId
                                ? {
                                    ...child,
                                    value: newValue,
                                    error: child.required === "true" && !newValue.trim() ? "This field is required." : "",
                                }
                                : child
                        ),
                    }
                    : section
            )
        );
    };


    const handleDriveOpen = () => {
        const googleDriveUrl = "https://drive.google.com/drive/my-drive";
        window.open(googleDriveUrl, "_blank");
    };
    const handleDropboxSignIn = () => {
        const dropboxSignInUrl = "https://www.dropbox.com/login";
        window.open(dropboxSignInUrl, "_blank");
    };

    const handleMultiChange = (sectionId, childId, newValue) => {
        setUiData((prevState) =>
            prevState.map((section) => {
                if (section.id === sectionId) {
                    return {
                        ...section,
                        children: section.children.map((child) => {
                            if (child.id === childId && child.type === "MultidropDown") {
                                return {
                                    ...child,
                                    value: newValue,
                                    error: child?.required === "true" && !newValue ? "This field is required." : "",
                                };
                            }
                            return child;
                        }),
                    };
                }
                return section;
            })
        );
    };

    const Turn_Around_DropDown = [
        { label: "In Days", value: "In Days" },
        { label: "In hour", value: "In hour" }

    ]

    const Turn_Around_DropDownData = useMemo(() => {
        return Turn_Around_DropDown?.map((item) => ({
            label: item.label,
            value: item.label,
            code: item.label
        }));
    }, []);

    const extractMainNameValuePairs = (data) => {
        const result = {
            ApiData: {}
        };

        const traverse = (items) => {
            items.forEach((item) => {
                if (item.name && item.value !== undefined && item.type !== "MultidropDown") {
                    result.ApiData[item.name] = item.value;
                }
                if (item.type === "MultidropDown" && item.value !== undefined) {
                    const Data = item.value && item.value?.map((item) => {
                        return `${item.value}`
                    })
                    result.ApiData[item.name] = Data;
                }
                if (item.children) {
                    traverse(item.children);
                }
            });
        };

        traverse(data);
        return result;
    };




    const handleSubmit = async () => {
        try {
            let DocumentData;
            if (validateField()) {
                if (showData1) {
                    DocumentData = documents1 && documents1?.map((item) => {


                        return {
                            doc_type: item.type,
                            title: item.title,
                            description: item.description,
                            url: item?.url,
                        };
                    });
                }
                else if (!showData1) {
                    DocumentData =
                        documents &&
                        documents.document?.map((item) => {
                            return {
                                doc_type: item.doc_type,
                                title: item.title,
                                description: item.description,
                                url: item?.url,
                            };
                        });
                }
                const mainNameValuePairs = extractMainNameValuePairs(uiData);

                const date = new Date();
                const newData = {
                    business_unit: mainNameValuePairs.ApiData["Business Unit"] || "Max IT Staffing",

                    // Job Details
                    job_code: jobCodeDropDown || mainNameValuePairs.ApiData["Job Code"],
                    job_title: mainNameValuePairs.ApiData["Job Title"],
                    pay_rate: {
                        currency: currency,
                        minimum: currencyMin,
                        maximum: currencyMax,
                        field1: workHour,
                        field2: spentHour
                    },
                    respond_by: "Date Option",
                    respond_by_date: date,
                    remote_job: mainNameValuePairs.ApiData["Remote Job"],
                    expenses_paid: mainNameValuePairs.ApiData["Expenses Paid"] === "Yes" ? true : false,
                    country: mainNameValuePairs.ApiData["Country"],
                    state: mainNameValuePairs.ApiData["States"],
                    city: cityName || mainNameValuePairs.ApiData["City"],
                    address: mainNameValuePairs.ApiData["Address"],
                    zipcode: mainNameValuePairs.ApiData["Zip Code"],
                    job_status: mainNameValuePairs.ApiData["job Status"],
                    job_type: mainNameValuePairs.ApiData["Job Type"],
                    required_hours: Number(mainNameValuePairs.ApiData["Required Hours / Week"]),
                    client: mainNameValuePairs.ApiData["Company Name"],
                    client_manager: mainNameValuePairs.ApiData["Contact Person"] || "Contact Person",
                    end_client: mainNameValuePairs.ApiData["End Client"],
                    required_document: mainNameValuePairs.ApiData["Required Documents for Submission"],
                    turnarount_time: {
                        value: turnAroundtimeValue,
                        unit: turnAroundtimeUnit,
                    },
                    priority: mainNameValuePairs.ApiData["Priority"],
                    client_category: mainNameValuePairs.ApiData["Client category"],
                    duration: Number(mainNameValuePairs.ApiData["Duration"]),
                    work_authorization: mainNameValuePairs.ApiData["Work Authorization"],
                    interview_mode: mainNameValuePairs.ApiData["Interview Mode"],
                    clearance: mainNameValuePairs.ApiData["Clearance"] === "Yes" ? true : false,
                    placement_fee_percentage: Number(mainNameValuePairs.ApiData["placement Fee Percentage"]),
                    notice_period: mainNameValuePairs.ApiData["Notice Period"],

                    // Skills
                    industry: mainNameValuePairs.ApiData["Industry"],
                    degree: mainNameValuePairs.ApiData["Degree"],
                    exprience: {
                        minimum: experienceMin,
                        maximum: experienceMax,
                        unit: "Years"
                    },
                    primary_skills: mainNameValuePairs.ApiData["Primary Skills"],
                    secondary_skills: mainNameValuePairs.ApiData["Secondary Skills"],
                    languages: mainNameValuePairs.ApiData["Language"],

                    // Organizational Information
                    number_of_positions: Number(mainNameValuePairs.ApiData["Number Position"]),
                    max_allowed_submissions: Number(mainNameValuePairs.ApiData["Maximum allowed Submissions"]),
                    tax_term: mainNameValuePairs.ApiData["Tax Terms"],
                    recruitment_manager: mainNameValuePairs.ApiData["Recruitment Manager"],
                    account_manager: mainNameValuePairs.ApiData["Account Manager"],
                    assigned_to: mainNameValuePairs.ApiData["Assigned To"],
                    comment: mainNameValuePairs.ApiData["Comments"],
                    additional_notification: ["Additional Notification"],
                    career_portal_published_date: date,
                    job_description: content,
                    documents: DocumentData
                }
                const response = await dispatch(createJob({ newData })).then((res) => { return res }).catch((err) => console.log(err));
                if (response?.payload.message && response?.payload.message === "Success") {
                    Navigate("/dashboard/job-list/joblistData");
                    setUiData(
                        [
                            {
                                id: 1,
                                label: "",
                                children: [
                                    {
                                        id: 1,
                                        name: "Business Unit",
                                        value: "Hyre max It",
                                        error: "",
                                        type: "text",
                                        required: "false",
                                    },
                                ],
                            },
                            {
                                id: 2,
                                label: "Job Details",
                                children: [
                                    {
                                        id: 1,
                                        name: "Job Code",
                                        value: jobCodeDropDown && jobCodeDropDown,
                                        error: "",
                                        type: "text",
                                        required: "true",

                                    },
                                    {
                                        id: 2,
                                        name: "Job Title",
                                        value: "",
                                        error: "",
                                        type: "text",
                                        required: "false",
                                    },
                                    {
                                        id: 3,
                                        name: "Pay Rate / Salary",
                                        value: "",
                                        error: "",
                                        type: "threeText",
                                        required: "false",
                                        childrenItem: [
                                            { id: "1", type: "select", name: "currency", value: "", error: "", required: "false" },
                                            { id: "2", type: "text", name: "min", value: "", error: "", required: "false" },
                                            { id: "3", type: "text", name: "max", value: "", error: "", required: "false" },
                                            { id: "4", type: "select", name: "workingHours", value: "", error: "", required: "false" },
                                            { id: "5", type: "select", name: "spentworkingHours", value: "", error: "", required: "false" }
                                        ]
                                    },
                                    {
                                        id: 4,
                                        name: "Respond By",
                                        value: "",
                                        error: "",
                                        type: "date",
                                        required: "false",
                                    },
                                    {
                                        id: 5,
                                        name: "Remote Job",
                                        value: "No",
                                        error: "",
                                        type: "radio",
                                        options: ["Yes", "No", "Hybrid"],
                                    },
                                    {
                                        id: 6,
                                        name: "Expenses Paid",
                                        value: "",
                                        error: "",
                                        type: "radio",
                                        options: ["Yes", "No"],
                                    },
                                    {
                                        id: 7,
                                        name: "Country",
                                        value: "United States",
                                        error: "",
                                        type: "dropDown",
                                        required: "true",
                                    },
                                    {
                                        id: 8,
                                        name: "States",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "false",
                                    },
                                    {
                                        id: 9,
                                        name: "City",
                                        value: "",
                                        type: "dropDown",
                                        required: "false",
                                    },
                                    {
                                        id: 10,
                                        name: "Address",
                                        value: "",
                                        type: "text",
                                        required: "false",
                                    },
                                    {
                                        id: 11,
                                        name: "Zip Code",
                                        value: "",
                                        type: "text",
                                        required: "false",
                                    },
                                    {
                                        id: 12,
                                        name: "job Status",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "true",
                                    },
                                    {
                                        id: 13,
                                        name: "Job Type",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "true",
                                    },
                                    {
                                        id: 14,
                                        name: "Required Hours / Week",
                                        value: "",
                                        error: "",
                                        type: "text",
                                        required: "flase",
                                    },
                                    {
                                        id: 15,
                                        name: "Company Name",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "flase",
                                    },
                                    {
                                        id: 16,
                                        name: "Contact Person",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "flase",
                                    },
                                    {
                                        id: 17,
                                        name: "End Client",
                                        value: "",
                                        error: "",
                                        type: "text",
                                        required: "flase",
                                    },
                                    {
                                        id: 18,
                                        name: "Required Documents for Submission",
                                        value: "",
                                        error: "",
                                        type: "MultidropDown",
                                        required: "flase",
                                    },
                                    {
                                        id: 19,
                                        name: "Turnaround Time",
                                        value: "",
                                        error: "",
                                        type: "Twotext",
                                        required: "flase",
                                        childrenItem: [

                                            { id: "1", type: "text", name: "value", value: "", error: "", required: "false" },
                                            { id: "2", type: "dropDown", name: "Days", value: "", error: "", required: "false" },

                                        ]
                                    },
                                    {
                                        id: 20,
                                        name: "Priority",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "flase",
                                    },
                                    {
                                        id: 21,
                                        name: "Client category",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "flase",
                                    },
                                    {
                                        id: 22,
                                        name: "Duration",
                                        value: "",
                                        error: "",
                                        required: "true",
                                        type: "text",
                                    },
                                    {
                                        id: 23,
                                        name: "Work Authorization",
                                        value: "",
                                        error: "",
                                        type: "MultidropDown",
                                        required: "flase",
                                    },
                                    {
                                        id: 24,
                                        name: "Interview Mode",
                                        value: "",
                                        error: "",
                                        type: "text",
                                        required: "true",
                                    },
                                    {
                                        id: 25,
                                        name: "Clearance",
                                        value: "",
                                        error: "",
                                        type: "radio",
                                        options: ["Yes", "No"],
                                        required: "flase",
                                    },
                                    {
                                        id: 26,
                                        name: "placement Fee Percentage",
                                        value: "",
                                        error: "",
                                        type: "number",
                                        required: "flase",
                                    },
                                    {
                                        id: 27,
                                        name: "Notice Period",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "true",
                                    },
                                ],
                            },

                            {
                                id: 3,
                                label: "Skills",
                                children: [
                                    {
                                        id: 1,
                                        name: "Industry",
                                        value: "",
                                        error: "",
                                        type: "MultidropDown",
                                        required: "false",
                                    },
                                    { id: 2, name: "Degree", value: "", error: "", type: "MultidropDown", required: "false" },
                                    {
                                        id: 3, name: "Experience", value: "", error: "", required: "false", type: "experience",
                                        childrenItem: [

                                            { id: "1", type: "text", name: "min", value: "", error: "", required: "false" },
                                            { id: "2", type: "text", name: "max", value: "", error: "", required: "false" },

                                        ]


                                    },
                                    {
                                        id: 4,
                                        name: "Primary Skills",
                                        value: "",
                                        error: "",
                                        type: "text",
                                        required: "false",
                                    },
                                    { id: 5, name: "Secondary Skills", value: "", error: "", type: "text" },
                                    { id: 6, name: "Language", value: "", error: "", type: "MultidropDown" },
                                ],
                            },
                            {
                                id: 4,
                                label: "Oraganization Information",
                                children: [
                                    {
                                        id: 1,
                                        name: "Number Position",
                                        value: "",
                                        error: "",
                                        type: "number",
                                        required: "false",
                                    },
                                    {
                                        id: 2,
                                        name: "Maximum allowed Submissions",
                                        value: "",
                                        error: "",
                                        type: "number",
                                        required: "false",
                                    },
                                    {
                                        id: 3,
                                        name: "Tax Terms",
                                        value: "",
                                        error: "",
                                        type: "MultidropDown",
                                        required: "true",
                                    },
                                    {
                                        id: 4,
                                        name: "Recruitment Manager",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "false",
                                    },
                                    {
                                        id: 5,
                                        name: "Account Manager",
                                        value: "",
                                        error: "",
                                        type: "dropDown",
                                        required: "false",
                                    },
                                    {
                                        id: 6,
                                        name: "Assigned To",
                                        value: "",
                                        error: "",
                                        type: "MultidropDown",
                                        required: "false",
                                    },
                                    {
                                        id: 7,
                                        name: "Comments",
                                        value: "",
                                        error: "",
                                        type: "text",
                                        required: "false",
                                    },
                                    {
                                        id: 8,
                                        name: "Additional Notification",
                                        value: "",
                                        error: "",
                                        type: "MultidropDown",
                                        required: "false",
                                    },
                                    {
                                        id: 9,
                                        name: "Carrer Portal Published Date",
                                        value: "",
                                        error: "",
                                        type: "date",
                                        required: "false",
                                    },
                                    {
                                        id: 10,
                                        name: "Job Description",
                                        value: "",
                                        error: "",
                                        type: "LargeInputBox",
                                        required: "false",
                                    },
                                ],
                            },
                        ]
                    )
                }
                // console.log(response)
            } else {
                toast.error("please add all required field");
                console.log("errorField")

                console.error("Form validation failed");
            }



        }
        catch (err) {
            console.error(err);
        }
    }
    const handleContact = () => {
        Navigate("/dashboard/job-list");
    }

    const handleChildItemDelete = (id) => {
        setDocuments1(prevDocuments => prevDocuments.filter(doc => doc.id !== id));
    };

    const handleSelectChangeDocument = useCallback(
        (index, e) => {
            const newTitle = e;
            setDocuments((prevState) => {
                const updatedDocuments = prevState.document.map((doc, i) =>
                    i === index ? { ...doc, doc_type: newTitle } : doc
                );

                return {
                    ...prevState,
                    document: updatedDocuments,
                };
            });
        },
        [documents?.document]
    );

    const handleChangeDocument = useCallback((index, e, name) => {
        const newTitle = e.target.value;
        setDocuments((prevState) => {
            const updatedDocuments = prevState.document.map((doc, i) =>
                i === index ? { ...doc, title: newTitle } : doc
            );

            return {
                ...prevState,
                document: updatedDocuments,
            };
        });
    }, []);

    const handleChangeDescription = (index, e) => {
        const newTitle = e.target.value;
        setDocuments((prevState) => {
            const updatedDocuments = prevState.document.map((doc, i) =>
                i === index ? { ...doc, description: newTitle } : doc
            );

            return {
                ...prevState,
                document: updatedDocuments,
            };
        });
    };
    const handleAdd = () => {
        if (documents?.document.length > 0) {
            const newDocuments = documents.document.map((doc, index) => ({
                id: documents.length + index + 1,
                type: doc.doc_type,
                title: doc.title,
                url: doc.url,
                description: doc.description
            }));
            setDocuments1(prevDocuments => [...prevDocuments, ...newDocuments]);
            setDocuments(ContactDocumentFromState)

            setShowData1(true);
        }
    }

    const handleInputChange = (sectionId, fieldId, value) => {
        // setUiData((prevData) =>
        //     prevData.map((section) => {
        //         if (section.id === sectionId) {
        //             return {
        //                 ...section,
        //                 children: section.children.map((field) =>
        //                     field.id === fieldId ? { ...field, value } : field
        //                 ),
        //             };
        //         }
        //         return section;
        //     })
        // );
        setUiData((prevData) =>
            prevData.map((section) => {
                if (section.id === sectionId) {
                    return {
                        ...section,
                        children: section.children.map((field) =>
                            field.id === fieldId
                                ? {
                                    ...field,
                                    value,
                                    error: field.required === "true" && !value.trim() ? "This field is required." : "", // Clear error if valid
                                }
                                : field
                        ),
                    };
                }
                return section;
            })
        );
    };



    return (
        <div className="shadow-md shadow-slate-700 ring-black ring-opacity-50 w-full h-full rounded-xl">
            <div className="bg-[#F2F3F3] rounded-xl p-6">
                <div className="flex flex-col md:flex-row items-center justify-between py-3  text-2xl text-[#004C95] font-bold rounded-t-lg">
                    <div className="flex-1 md:flex-[0.2]">
                        <p>New Jobs</p>
                    </div>
                    <div>
                        <button className=" text-[#3e5c82]" onClick={handleContact}
                            style={{
                                fontSize: '16px',
                                borderRadius: "10px",
                                border: "1px solid lightblue",
                                padding: "5px"
                            }}
                        >
                            View Account
                        </button>
                    </div>
                </div>
                <div className="text-[#0000004D] border mb-4"></div>
                {uiData.map((section) => (
                    <div key={section.id} className="mb-6">
                        <h3 className="text-xl text-[#004C95] font-bold  mb-1">
                            {section.label}
                        </h3>
                        {
                            section.label.length > 0 && <div className="text-[#0000004D] border  mb-4"></div>
                        }

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-8">
                            {section.children.map((field) => (
                                <div key={field.id}>


                                    {field.type === "text" && (
                                        <>
                                            {
                                                ((field.name === "Address" || field.name === "Zip Code") && remoteJobs === "Yes") ? (
                                                    <>
                                                        <CustomInput
                                                            name={field.name}
                                                            value={field.name === "Job Code" ? jobCodeDropDown && jobCodeDropDown : field.value}
                                                            label={field.name}
                                                            required={false}
                                                            error={field.error}
                                                            onChange={(e) => handleInputChange(section.id, field.id, e.target.value)}
                                                            type={field.type}
                                                            disabled={true}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <CustomInput
                                                            name={field.name}
                                                            value={field.name === "Job Code" ? jobCodeDropDown && jobCodeDropDown : field.value}
                                                            label={field.name}
                                                            required={field.required}
                                                            error={field.error}
                                                            onChange={(e) => handleInputChange(section.id, field.id, e.target.value)}
                                                            type={field.type}
                                                        />
                                                    </>
                                                )
                                            }

                                        </>

                                    )}

                                    {
                                        field.type === "date" && (
                                            <>
                                                <CustomInput
                                                    name={field.name}
                                                    value={field.value}
                                                    label={field.name}
                                                    required={field.required}
                                                    error={field.error}
                                                    onChange={(e) => handleInputChange(section.id, field.id, e.target.value)}
                                                    type={field.type}
                                                />
                                            </>
                                        )
                                    }

                                    {
                                        field.type === "number" && (
                                            <>
                                                <CustomInput
                                                    name={field.name}
                                                    value={field.name === "Job Code" ? jobCodeDropDown : field.value}
                                                    label={field.name}
                                                    required={field.required}
                                                    error={field.error}
                                                    onChange={(e) => handleInputChange(section.id, field.id, e.target.value)}
                                                    type={field.type}
                                                />
                                            </>
                                        )
                                    }



                                    {field.type === "threeText" && (
                                        <div className="flex flex-col">
                                            <label
                                                htmlFor={field.name}
                                                className="block text-md font-medium leading-6 text-gray-600"
                                            >
                                                {field.name}
                                                {/* <span className="text-red-500 font-medium">*</span> */}
                                            </label>
                                            <div className="flex flex-col gap-[0.2rem]">
                                                {/* First Row: One select and two input boxes */}
                                                <div className="flex flex-row gap-2">
                                                    {field.childrenItem?.slice(0, 3).map((item, index) => (
                                                        <div key={item.id} className={`flex-1 ${index === 0 ? 'basis-1/3' : 'basis-1/2'}`}>
                                                            {item.type === "select" ? (
                                                                <CustomSelect
                                                                    name={item.name}
                                                                    value={item.value || "USD"}
                                                                    onChange={(e) => {
                                                                        if (item.name === "currency") {
                                                                            setCurrency(e);
                                                                        }
                                                                        handleChangeChild(section.id, field.id, e, item.id)
                                                                    }

                                                                    }
                                                                    options={currencyCodesData}
                                                                />
                                                            ) : (
                                                                <CustomInput
                                                                    name={item.name}
                                                                    value={item.value}
                                                                    type={"number"}
                                                                    placeholder={item.name}
                                                                    error={item.error}
                                                                    onChange={(e) => {
                                                                        if (item.name === 'min') {
                                                                            setCurrencyMin(e.target.value);
                                                                        }
                                                                        if (item.name === 'max') {
                                                                            setCurrencyMax(e.target.value);
                                                                        }

                                                                        handleChangeChild(section.id, field.id, e.target.value, item.id)
                                                                    }
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Second Row: Two select boxes */}
                                                <div className="flex flex-row gap-2">
                                                    {field.childrenItem?.slice(3, 5).map((item) => (
                                                        <div key={item.id} className="flex-1">
                                                            <CustomSelect
                                                                name={item.name}
                                                                value={item.value}
                                                                onChange={(e) => {
                                                                    if (item.name === "spentworkingHours") {
                                                                        setThreeStatus(true);
                                                                        setSpentHour(e);
                                                                    }
                                                                    if (item.name === "workingHours") {
                                                                        setWorkHour(e);
                                                                    }
                                                                    handleChangeChild(section.id, field.id, e, item.id);
                                                                }}
                                                                options={item.name === "workingHours" ? TimeHourData : item.name === "spentworkingHours" ? SpentTimeData : []}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                {
                                                    threeStatus ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p className="text-red-500 text-sm">{
                                                                field.error
                                                            }</p>
                                                        </>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    )}

                                    {
                                        field.type === "experience" && (
                                            <div className="flex flex-col">
                                                <label
                                                    htmlFor={field.name}
                                                    className="block text-md font-medium leading-6 text-gray-600"
                                                >
                                                    {field.name} <span className="text-red-500 font-medium">*</span>
                                                </label>
                                                <div className="flex flex-col gap-[0.2rem]">
                                                    <div className="flex flex-row gap-2">
                                                        {field.childrenItem?.map((item, index) => (
                                                            <div key={item.id} style={{
                                                                width: "30%"
                                                            }}>
                                                                <CustomInput
                                                                    name={item.name}
                                                                    value={item.value}
                                                                    type={"number"}
                                                                    placeholder={item.name}
                                                                    error={item.error}
                                                                    onChange={(e) => {

                                                                        if (item.name === 'min') {
                                                                            setExperienceMin(e.target.value);
                                                                        }
                                                                        if (item.name === 'max') {
                                                                            setExperienceMax(e.target.value);
                                                                        }
                                                                        handleChangeChild(section.id, field.id, e.target.value, item.id)
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        ))}
                                                        <p>Years</p>
                                                    </div>


                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        field.type === "Twotext" && (
                                            <div className="flex flex-col">
                                                <label
                                                    htmlFor={field.name}
                                                    className="block text-md font-medium leading-6 text-gray-600"
                                                >
                                                    {field.name}
                                                </label>
                                                <div className="flex flex-col ">
                                                    <div className="flex flex-row gap-1">
                                                        {field.childrenItem?.map((item, index) => (
                                                            <>
                                                                <div key={item.id} style={{
                                                                    width: "60%"
                                                                }}>
                                                                    {
                                                                        item.type === "text" && <CustomInput
                                                                            name={item.name}
                                                                            value={item.value}
                                                                            type={"number"}
                                                                            placeholder={item.name}
                                                                            error={item.error}
                                                                            onChange={(e) => {

                                                                                if (item.name === "value") {
                                                                                    setTurnAroundtimevalue(e.target.value);
                                                                                }
                                                                                handleChangeChild(section.id, field.id, e.target.value, item.id)
                                                                            }}
                                                                        />
                                                                    }
                                                                    {
                                                                        item.type === "dropDown" && <CustomSelect

                                                                            name={item.name}
                                                                            value={item.value}
                                                                            onChange={(e) => {
                                                                                setTurnAroundtimeUnit(e);
                                                                                handleChangeChild(section.id, field.id, e, item.id)
                                                                            }

                                                                            }
                                                                            options={Turn_Around_DropDownData}
                                                                        />
                                                                    }

                                                                </div>


                                                            </>
                                                        ))}

                                                    </div>


                                                </div>
                                            </div>
                                        )
                                    }

                                    {field.type === "dropDown" && (

                                        <>
                                            {["Company Name", "City", "States", "Contact Person", "Country", "job Status", "Job Type", "Priority", "Client category", "Notice Period", "Recruitment Manager", "Account Manager"].includes(field.name) && (
                                                <>
                                                    {
                                                        ((field.name === "City" || field.name === "States") && remoteJobs === "Yes") ? (
                                                            <>
                                                                <CustomSelect
                                                                    label={field.name}
                                                                    name={field.name}
                                                                    value={field.value}
                                                                    onChange={(e) => {
                                                                        if (field.name === "Country") {
                                                                            setCountryName(e);

                                                                        }
                                                                        else if (field.name === "States") {
                                                                            setStatesName(e);
                                                                        }
                                                                        handleChange(section.id, field.id, e);
                                                                    }}
                                                                    disabled={true}
                                                                    required={false}
                                                                    error={field.error}
                                                                    options={getOptions(field.name)}

                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    (field.name === "City" || field.name === "States" || field.name === "Country") ? (
                                                                        <>
                                                                            <CustomSelect
                                                                                label={field.name}
                                                                                name={field.name}
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    if (field.name === "Country") {
                                                                                        setCountryName(e);

                                                                                    }
                                                                                    else if (field.name === "States") {
                                                                                        setStatesName(e);

                                                                                    }
                                                                                    else if (field.name === "City") {
                                                                                        setCityName(e)
                                                                                    }
                                                                                    handleChange(section.id, field.id, e);
                                                                                }}
                                                                                required={field.required}
                                                                                error={field.error}
                                                                                options={getOptions(field.name)}
                                                                                name1={"Job"}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <CustomAddSelect
                                                                                label={field.name}
                                                                                name={field.name}
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    if (field?.name === "Company Name") {
                                                                                        setCompanyId(e);
                                                                                    }
                                                                                    handleChange(section.id, field.id, e);
                                                                                }}
                                                                                required={field.required}
                                                                                error={field.error}
                                                                                options={getOptions(field.name)}
                                                                                disabled={(field.name === "Recruitment Manager" || field.name === "Account Manager") && true}
                                                                                name1={"Job"}
                                                                                CompanyID={companyId}
                                                                            />
                                                                        </>
                                                                    )
                                                                }

                                                            </>
                                                        )
                                                    }

                                                </>
                                            )}
                                        </>

                                    )}

                                    {field.type === "MultidropDown" && (

                                        <>
                                            {["Assigned To", "Tax Terms", "Industry", "Degree", "Required Documents for Submission", "Additional Notification", "Work Authorization", "Language",].includes(field.name) && (
                                                <SearchableDropDown
                                                    label={field.name}
                                                    name={field.name}
                                                    value={field.value && field.value}
                                                    onChange={(e) => {
                                                        handleMultiChange(section.id, field.id, Array.from(e, (e) => e));
                                                    }}
                                                    required={field.required}
                                                    error={field.error}
                                                    name1={"Job"}
                                                    options={getMultiOptions(field.name)}
                                                />
                                            )}
                                        </>

                                    )}

                                    {field.type === "radio" && (
                                        <div className="flex flex-col items-start justify-between">
                                            <label className="text-md font-medium leading-6 text-gray-600">
                                                {field.name}
                                            </label>
                                            <div className="flex items-center justify-center" style={{ marginTop: '0.1rem' }}>
                                                {field.options?.map((option) => (
                                                    <label key={option} className="mr-4 text-md font-medium text-gray-600">
                                                        <input
                                                            type="radio"
                                                            name={field.name}
                                                            value={option}
                                                            checked={field.value === option}
                                                            onChange={() => {
                                                                if (field.name === "Remote Job") {
                                                                    setRemotejobs(option);
                                                                }
                                                                handleRadioChange(section.id, field.id, option);
                                                            }}
                                                            className="text-md font-medium leading-6 text-gray-600"

                                                        />
                                                        {option}
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {
                                        field.type === "LargeInputBox" && (
                                            <div
                                                style={{
                                                    width: "50rem"
                                                }}
                                            >
                                                <label
                                                    htmlFor={field.name}
                                                    className="block text-md font-medium leading-6 text-gray-600"
                                                >
                                                    {field.name}
                                                    {/* <span className="text-red-500 font-medium">*</span> */}
                                                </label>
                                                <JoditEditor
                                                    onChange={newContent => setContent(newContent)}
                                                    config={editorConfig}
                                                />

                                            </div>
                                        )
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div>
                        {documents?.document && documents?.document.map((doc, index) => (
                            <div key={index}>
                                {doc?.status && (
                                    <CustomAddSelect
                                        label={"Select Document"}
                                        icon={"BsPersonFill"}
                                        value={doc?.doc_type || ""}
                                        onChange={(e) => {
                                            handleSelectChangeDocument(index, e);
                                            setFileUploadStatus(true);
                                        }}
                                        options={document_DropDwon}

                                        name2={"jobDocuments"}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    <div>
                        <div className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                            Upload File
                        </div>
                        <div className=" flex">
                            <div className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl text-blue-600">
                                <label>
                                    <CustomIcon name={"CgScreen"} />
                                    <input
                                        type="file"
                                        className="hidden"
                                        onChange={handleFileUpload}
                                        disabled={!fileUploadStatus}
                                    />
                                </label>
                            </div>
                            <div
                                className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                                onClick={handleDriveOpen}
                                style={{ cursor: "pointer" }}
                            >
                                <CustomIcon name={"BsCloudsFill"} />
                            </div>
                            <div
                                className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                                onClick={handleDropboxSignIn}
                                style={{ cursor: "pointer" }}
                            >
                                <CustomIcon name={"FaDropbox"} />
                            </div>
                        </div>

                    </div>

                    <div>
                        {documents?.document && documents?.document.map((doc, index) => (
                            <div key={index}>
                                {doc?.status && (
                                    <CustomInput
                                        type="text"
                                        placeholder="Please enter title"
                                        label="Document Title"
                                        icon="IoDocumentText"
                                        value={doc.title || ""}
                                        onChange={(e) => handleChangeDocument(index, e, "documentTitle")}

                                        disabled={!fileUploadStatus}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    <div>
                        <label className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                            Description
                        </label>
                        {documents?.document && documents?.document.map((doc, index) => (
                            <div key={index}>
                                {doc?.status && (
                                    <textarea
                                        id="message"
                                        rows="4"
                                        className={`block p-2.5 w-full text-sm bg-gray-50 rounded-lg border  focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                        placeholder="Write here..."
                                        value={doc.description || ""}
                                        onChange={(e) => handleChangeDescription(index, e)}
                                    ></textarea>
                                )}
                            </div>
                        ))}
                    </div>


                    <div>
                        <button
                            className="flex items-center justify-center bg-custom-blue text-white font-normal py-2 px-4 h-9 rounded-xl"
                            disabled={!fileUploadStatus}
                            onClick={handleAdd}
                        >
                            <span className="text-lg sm:text-xl mr-1 h-5">
                                <CustomIcon name={"TiPlus"} />
                            </span>
                            Add
                        </button>
                    </div>

                    <div>
                        {showData1 && documents1.length > 0 && (
                            <div>
                                <div className="border-b-2 text-gray-500 mt-4 sm:mt-6 w-full sm:w-[50%]"></div>
                                <div className="relative w-full sm:w-[50%]">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-lg sm:text-xl text-gray-600 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">S.No.</th>
                                                <th scope="col" className="px-6 py-3">Document Type</th>
                                                <th scope="col" className="px-6 py-3">Document Title</th>
                                                <th scope="col" className="px-6 py-3"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documents1 && documents1?.map((doc, index) => (
                                                <tr key={index} className="dark:bg-gray-800 mt-5">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {index + 1}
                                                    </th>
                                                    <td className="px-6 py-4">{doc?.type}</td>
                                                    <td className="px-6 py-4">{doc?.title.toUpperCase()}</td>
                                                    <td className="px-6 py-4 text-red-500 cursor-pointer">
                                                        <div onClick={() => handleChildItemDelete(doc.id)}>
                                                            <CustomIcon name={"IoCloseCircle"} />
                                                        </div>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>

                </div>


            </div>

            <div className="mt-10 pb-10 flex justify-center">
                <CutomButton
                    text={loader ? "" : "Submit"}
                    bgColor="#3D5B81"
                    width="200"
                    onClick={handleSubmit}
                    loader={loader}
                />
            </div>
        </div>


    );
};

export default NewJob;