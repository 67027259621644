import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const apiUrl = process.env.REACT_APP_API_URL ;



export const DuplicateEmail = createAsyncThunk("get/duplicateEmail", async ({pageName, email}) => {
  try {
    const tokenData = sessionStorage.getItem("auth");
    const token = JSON.parse(tokenData);
    const response = await axios.get(`${apiUrl}/api/checkDuplicate?pageName=${pageName}&Email=${email}`,  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data) {
      return response?.data;
    }
    return response?.data;
  } catch (err) {
    return err;
  }
});

const duplicate_Email = createSlice({
  name: "duplcateEmail",
  initialState: {
    loading: false,
    error:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DuplicateEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(DuplicateEmail.fulfilled, (state, action) => {
        state.loading = false;
       
      })
      .addCase(DuplicateEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default duplicate_Email.reducer;
