import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { IoListCircleOutline } from "react-icons/io5";

const DashboardLayout = ({ children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    // if (!sidebarVisible) {
    //   setTimeout(() => {
    //     setSidebarVisible(false);
    //   }, 10000); 
    // }
  };

  return (
    <div className="flex flex-col h-screen "
    >
      <Header toggleSidebar={toggleSidebar} />
      <div className="flex flex-1 flex-col md:flex-row"
       style={{
        overflow:"hidden"
       }}
      >
        {
          sidebarVisible &&
          <aside
            className={`fixed top-0 left-0 z-40 w-64 lg:w-60  text-white h-full transform transition-transform duration-300 ease-in-out ${sidebarVisible ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 md:w-64 md:relative md:block `}
          >
            <Sidebar />
          </aside>
        }
        <div className={`flex-1  md:p-6 lg:p-2 xl:p-[0.4rem] ${sidebarVisible ? 'md:ml-[0.2rem]' : 'md:ml-0'} transition-all duration-300`} 
         style={{
          overflowY:"scroll"
         }}
        >
          <button onClick={toggleSidebar}>
            <IoListCircleOutline size="30" />
          </button>
          <main className="flex-1"
          
          >
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
