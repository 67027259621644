import React from "react";
import CustomIcon from "./CustomIcon";

const ResponsiveCard = ({ imageSrc, gradientFrom, gradientTo, dataContent, textHeading }) => {
  return (
    <div className="flex justify-center items-center min-h-screen px-4 py-8">
      <div className="w-full max-w-[80%] h-[550px] bg-white rounded-xl shadow-2xl overflow-hidden flex flex-col md:flex-row">
        <div
          className="w-full md:w-1/2 h-64 md:h-full bg-cover bg-center"
          style={{
            backgroundImage: imageSrc
              ? `linear-gradient(to bottom, ${gradientFrom}, ${gradientTo}), url(${imageSrc})`
              : `linear-gradient(to bottom, ${gradientFrom}, ${gradientTo})`,
            backgroundSize: 'cover',
            backgroundBlendMode: imageSrc ? 'overlay' : 'normal',
          }}
        >
          {textHeading !== "Login" &&
            <CustomIcon
              name={"GoArrowLeft"}
              onClick={() => window.history.back()}
              className="  text-white text-2xl cursor-pointer"
              style={{ color: "white", position: 'relative', left: 5, top: 10 }}
            />
          }

        </div>
        <div className="w-full md:w-1/2 ">
          <div className=" mt-4 mb-4">
            <button
              className="flex items-center justify-center  rounded-r-full  text-white font-boldpy-2  rounded-fullw-40h-9transition duration-500  ease-in-out  w-40  h-8 "
              style={{ backgroundColor: "#3D5B81" }}
            >
              {textHeading}
            </button>
          </div>
          <div className="p-8 flex flex-col justify-center">
            {dataContent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveCard;
