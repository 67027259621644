import React from "react";
import CustomIcon from "./CustomIcon";

const CustomInput = ({
  label,
  type,
  placeholder,
  icon,
  name,
  value,
  onChange,
  error,
  required,
  disabled,
  readOnly
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-md font-medium leading-6 text-gray-600"
      >
        {label}
        {
          required === "true" ? (
            <span className="text-red-500 font-medium">
              *
              </span>
          ):(
            <>
            </>
          )

        }
      </label>
      <div className="relative border-gray-300"
        style={{
          borderRadius: "10px"
        }}
      >
        <input
          type={name === "Number Position" ? "number" : type}
          //placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          readOnly={name === "Job Code" ? true : name === "Business Unit" ? true : readOnly ? readOnly : false}
          className=" py-1.5   w-full   px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
          style={{ borderRadius: "5px", border: "1px solid lightgray", fontSize:"14px" }}
          disabled={disabled}
        />
        {error && (
          // <span className="absolute left-3 top-full mt-1 text-red-500 text-xs">
          //   {error}
          // </span>
          <p className="text-red-500 text-sm ">{error}</p>
        )}
        {/* <span
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-2xl "
          style={{ color: "#3d5b81" }}
        >
          <CustomIcon name={icon} size="text-base md:text-xl lg:text-2xl" />
        </span> */}
      </div>
    </div>
  );
};

export default CustomInput;
