'use strict';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import React, { StrictMode, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import CustomIcon from '../../components/CustomIcon';
import CreateNewgroupNext from '../../components/CreateNewgroupNext';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const gridDiv = document.querySelector('#myGrid');

const SegmentAll = () => {
    const [showMalda, setShowModal] = useState(false)
    const [rowData, setRowData] = useState([
        { Group_Name: 'Tesla', Group_Type: 'Model Y', Created_at: 64950, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Ford', Group_Type: 'F-Series', price: 33850, Created_by: true, month: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Toyota', Group_Type: 'Corolla', Created_at: 29600, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Mercedes', Group_Type: 'EQA', Created_at: 48890, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Fiat', Group_Type: '500', Created_at: 15774, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Nissan', Group_Type: 'Juke', Created_at: 20675, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Vauxhall', Group_Type: 'Corsa', Created_at: 18460, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Volvo', Group_Type: 'EX30', Created_at: 33795, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Mercedes', Group_Type: 'Maybach', Created_at: 175720, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Vauxhall', Group_Type: 'Astra', Created_at: 25795, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Fiat', Group_Type: 'Panda', Created_at: 13724, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
        { Group_Name: 'Jaguar', Group_Type: 'I-PACE', Created_at: 69425, Created_by: true, Action: ['FaEdit','IoIosMail','RiShareCircleLine','MdDelete'] },
       
    ]);

    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'Group_Name',
            checkboxSelection: true,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            
        },
        { field: 'Group_Type' },
        { field: 'Created_at'},// filter: 'agNumberColumnFilter' },
        { field: 'Created_by' },
        {
            field: 'Action',
            cellRenderer: (params) => {
                const actionIcons = Array.isArray(params.value) ? params.value : [params.value];
                const colors = ['3d5b81', '#3d5b81','#3d5b81', '#FF5733']; // Define your colors
    
                return (
                    <div className="flex space-x-2">
                        {actionIcons.map((iconName, index) => (
                            <CustomIcon
                                key={index}
                                name={iconName}
                                color={colors[index % colors.length]} // Cycle through colors
                                onClick={() => {
                                    if (iconName === 'FaEdit') {
                                        handleEditClick(params.data); // Trigger only on FaEdit click
                                    }
                                }}
                            />
                        ))}
                    </div>
                );
            },
            
        
        },
    ]);

    const handleEditClick = (rowData) => {
        console.log('Edit clicked for:', rowData);
        setShowModal(true)
        // Add your custom logic here (e.g., open a modal, edit form, etc.)
    };

    const defaultColDef = useMemo(() => {
        return {
            // filter: 'agTextColumnFilter',
            // floatingFilter: true,
        };
    }, []);

    return (
        <>
        <div
            className={
                "ag-theme-quartz"
            }
            style={{ height: 500 }}
        >
            
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 25, 50]}
            />
        </div>
        <CreateNewgroupNext isvisible={showMalda} close={()=>setShowModal(false)} />
        </>
    );
};

export default SegmentAll;