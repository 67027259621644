import React, { useState } from "react";

import  InvoiceForm  from "./InvoiceForm";
import InvoicePreview from "./InvoicePreview";

function CreateInvoice() {
    const [invoiceData, setInvoiceData] = useState({});

    return (
        <>
            <div>              
                <div className="flex lg:space-x-24">
                    <div className="text-lg font-semibold mb-2 md:mb-0">Create Invoice</div>

                    <div className="mt-2 mb-2 flex flex-col md:flex-row items-center space-y-2 md:space-y-0 lg:space-x-4">
                        <label className="text-sm font-medium text-gray-700">Select Template</label>
                        <select className="block w-full md:w-auto lg:px-24 p-1 border border-gray-300 rounded-lg">
                            <option></option>
                        </select>
                    </div>
                </div>

                <div className="flex space-x-6">
                    <InvoicePreview />
                    <InvoiceForm />
                </div>
            </div>
        </>
    );
}

export default CreateInvoice;
