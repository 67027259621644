import React, { useState } from "react";
import CustomIcon from "../../components/CustomIcon";
import { useNavigate, useLocation } from "react-router-dom";
import ColumnFilter from "./ColumnFilter";

const ContactlistHeader = ({
  handleContact,
  handleAccount,
  toggleDropdown,
  onCloseColumnFilter,
  isOpen,
  handleSelect,
  title,
  handleContactSegment,
  handleAccountSegment,
  filterColumnData,
  setFilterColumnData,
  segmentGroup,
}) => {
  const navigate = useNavigate();

  const handleNavigateAccount = () => {
    navigate("/dashboard/account", {
      state: { from: "/dashboard/accountSubmission" },
    });
  };

  const handleNavigateContact = () => {
    navigate("/dashboard/newContact", {
      state: { from: "/dashboard/accountSubmission" },
    });
  };

  const modalAccountSegment = () => {
    console.log("hello world")
  }

  const modalContactSegment = () => {
    console.log("hello world");
  }

  return (
    <>
      
      <div className="flex flex-col md:flex-row justify-between items-center">       
        <div className="custom-responsive flex-[0.5] md:flex-[0.4] lg:flex-[0.3]">
         { title === "ALL Contacts List" ? "Contacts" : title === "ALL Accounts List" ? "Account" : "Contacts"}
    </div>

        <div className="flex flex-wrap md:flex-row justify-end items-center gap-4 md:gap-6" style={{ flex: '0.8' }}>
          {/* {title === "ALL Contacts List" && (
            <div
              onClick={handleContactSegment}
              className="cursor-pointer w-full md:w-auto px-4 py-2 border border-[#6b829e] text-center text-white rounded-full bg-[#3D5B81]"
            >
              Segments or Groups
            </div>
          )} */}

          {/* {title === "ALL Accounts List" && (
            <div
              onClick={handleAccountSegment}
              className="cursor-pointer w-full md:w-auto px-4 py-2 border border-[#6b829e] text-center text-white rounded-full bg-[#3D5B81]"
            >
              Segments or Groups
            </div>
          )} */}

          {/* {title === "ALL Contacts List" ? (
            <div
              onClick={handleContact}
              className="cursor-pointer w-full md:w-auto px-4 py-2 border border-[#6b829e] text-center text-[#3e5c82] rounded-full bg-white"
            >
              Account
            </div>
          ) : (
            title === "ALL Accounts List" && (
              <div
                onClick={handleAccount}
                className="cursor-pointer w-full md:w-auto px-4 py-2 border border-[#6b829e] text-center text-[#3e5c82] rounded-full bg-white"
              >
                Contacts
              </div>
            )
          )} */}

          {title === "ALL Contacts List" && (
            <div
              className="w-full md:w-auto px-4 py-2 flex items-center border border-[#6b829e] text-center bg-[#3e5c82] rounded-full text-white cursor-pointer"
              onClick={handleNavigateContact}
            >
              <button className="mr-2">
                <CustomIcon name={"TiPlus"} />
              </button>
              <span>Add Contacts</span>
            </div>
          )}

          {title === "ALL Accounts List" && (
            <div
              className="w-full md:w-auto px-4 py-2 flex items-center border border-[#6b829e] text-center bg-[#3e5c82] rounded-full text-white cursor-pointer"
              onClick={handleNavigateAccount}
            >
              <button className="mr-2">
                <CustomIcon name={"TiPlus"} />
              </button>
              <span>Add Account</span>
            </div>
          )}

          <button className="mt-2 md:mt-0 text-[#3e5c82]" onClick={toggleDropdown}>
            <CustomIcon name={"IoFilter"} />
          </button>
          {/* <button className="text-[#3e5c82]">
            <CustomIcon name={"FaEdit"} size="text-base md:text-xl lg:text-2xl" />
          </button> */}
        </div>
      </div>

      {title === "ALL Contacts List" && (
        <>
          {isOpen && (
            <ColumnFilter
              isOpen={isOpen}
              title={title}
              onClose={onCloseColumnFilter}
              filterColumnData={filterColumnData}
              setFilterColumnData={setFilterColumnData}
            />
          )}
        </>
      )}
      {title === "ALL Accounts List" && (
        <>
          {isOpen && (
            <ColumnFilter
              isOpen={isOpen}
              title={title}
              onClose={onCloseColumnFilter}
              filterColumnData={filterColumnData}
              setFilterColumnData={setFilterColumnData}
            />
          )}
        </>
      )}
    </>
  );
};

export default ContactlistHeader;
