import React, { useState, useEffect } from 'react';
const CustomSocialMedia = ({
  value,
  onChange,
  label = "Email",
  type = "text",
  placeholder = "Enter your email",
  label4 = "LinkedIn",
  label1 = "Facebook",
  label2 = "Instagram",
  label3 = "Twitter",
  label5 = "YouTube",
}) => {
  const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);
  const [additionalFields, setAdditionalFields] = useState({ [label4]: "" });
  const [checkboxStates, setCheckboxStates] = useState({
    [label1]: false,
    [label2]: false,
    [label3]: false,
    [label4]: true,
    [label5]: false,
  });

  // useEffect(() => {
  //   setAdditionalFields(value);
  //   setCheckboxStates(prevStates => ({
  //     ...prevStates,
  //     ...Object.keys(prevStates).reduce((acc, key) => ({
  //       ...acc,
  //       [key]: key in value
  //     }), {})
  //   }));
  // }, [value]);

  const handleCheckboxChange = (label) => {
    if (label === label4) return;
    setCheckboxStates((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
    if (!checkboxStates[label]) {
      setAdditionalFields((prevState) => ({
        ...prevState,
        [label]: "",
      }));
      onChange(additionalFields);
    } else {
      setAdditionalFields((prevState) => {
        const newState = { ...prevState };
        delete newState[label];
        return newState;
      });
      onChange(additionalFields);
    }
  };

  useEffect(() => {
    if (additionalFields) {
      onChange(additionalFields)
    }
  }, [additionalFields])


  return (
    <>

      <div className=" pt-2 sm:pr-0">
        <label
          htmlFor="email"
          className="block text-md font-medium leading-6 text-gray-600"
        >
          {label}
        </label>

        {Object.keys(additionalFields).map((key, index) => (
          <div key={index} className="relative  border border-gray-300 "
            style={{
              borderRadius: "5px"
            }}
          >
            <input
              type="text"
              placeholder={`Enter ${key} URL`}
              value={additionalFields[key]}
              onChange={(e) =>
                setAdditionalFields({
                  ...additionalFields,
                  [key]: e.target.value,
                })
              }
              required
              className=" py-1.5 border-gray-400 w-full  py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
              style={{
                borderRadius: "5px",
                fontSize: "14px"
              }}
            />
          </div>
        ))}

        <div className="flex flex-wrap space-x-4 pt-4 ml-1">
          {[label4, label2, label3, label1, label5].map((labelText, idx) => (
            <div key={idx} className="mt-2 flex items-center space-x-2">
              <div>
                <input
                  type="checkbox"
                  className={`w-4 h-4 ${labelText === label4 ? 'cursor-not-allowed' : ''}`}
                  checked={checkboxStates[labelText]}
                  onChange={() => handleCheckboxChange(labelText)}
                />
              </div>
              <label className="block text-sm sm:text-md leading-6 text-gray-600">
                {labelText}
              </label>
            </div>
          ))}
        </div>
      </div>

    </>
  );
};
export default CustomSocialMedia;