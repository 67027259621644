import React, { useState, useRef } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import UserDetailsRight from "./UserDetailsRight";
import { useNavigate } from "react-router-dom";
import UserActivity from "./UserActivity";
import UserPlacement from "./UserPlacement";
import CustomIcon from "../components/CustomIcon";
import Buttonsheet from "../components/Buttonsheet";
import moment from "moment";
import CandiDateSubmission from "./GetJob/CandiDateSubmission";

const ModalUserDetails = ({ isVisible, onClose, data, title, }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [chosseDocument, setChooseDocument] = useState(false);
  const [jobId, setJobId] = useState("");

  const dropdownRef = useRef(null);

  console.log(data, "dataId");


  React.useEffect(() => {
    if (data) {
      setJobId(data._id)
    }
  }, [])

  const handleToggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const handleChooseDocument = () => {
    setIsDropdownOpen(false);
    setChooseDocument(true);
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCreateCandidate = () => {
    let data = {
      jobId : jobId,
      status:false
    }
    navigate("/dashboard/candiDate", {state:data})
  }

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const renderComponent = () => {
    switch (activeTab) {
      case 0:
        return <UserDetailsRight data={data} title={title} />;
      case 1:
        return <UserActivity />;
      case 2:
        return <UserPlacement />;
      default:
        return <UserDetailsRight data={data} title={title} />;
    }
  };

  const lastRenderUi = () => {
    if (title === "Job") {
      return (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row" style={{ gap: "10px" }}>
              {
                ["BiSolidPhone", "MdEmail", "GoPlus"]?.map((item) => (
                  <>
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: "lightgray",
                        cursor: "pointer"
                      }}
                      onClick={item === "GoPlus" ? handleToggleDropdown : null}
                    >
                      <CustomIcon name={item} color={"black"} />
                    </div>
                    {item === "GoPlus" && isDropdownOpen && (
                      <div
                        ref={dropdownRef}
                        className="absolute right-2 mt-19 w-48 bg-white text-black border border-gray-300 rounded-lg shadow-md"
                      >
                        <ul className="space-y-2 p-2">

                          <li className="cursor-pointer px-4 py-2 hover:bg-gray-100" onClick={handleChooseDocument}>Chosse Candidate from Candidate list</li>
                          <li className="cursor-pointer px-4 py-2 hover:bg-gray-100" onClick={handleCreateCandidate} >Add New</li>
                        </ul>
                      </div>
                    )}
                  </>
                ))
              }
            </div>
            <div className="flex flex-col">
              <p> <span style={{ fontSize: "14px", color: "black", fontWeight: "600" }}>
                Publish
              </span> : <span style={{ color: "lightgray" }}>
                  {moment(data?.career_portal_published_date).format("MMMM Do, YYYY")}
                </span>
              </p>
              <p> <span style={{ fontSize: "14px", color: "black", fontWeight: "600" }}>Status</span> :
                <span style={{ color: "lightgray" }}>{data?.job_status}</span>
              </p>
              <p> <span style={{ fontSize: "14px", color: "black", fontWeight: "600" }}>Number Sub</span> :
                <span style={{ color: "lightgray" }}>{data?.number_of_positions}</span>
              </p>
            </div>

          </div>
        </>
      )
    }
    else if (title === "Candidate") {
      return (
        <>
          <div className="flex flex-row" style={{ gap: "10px" }}>
            {
              ["BiSolidPhone", "MdEmail", "GoPlus"]?.map((item) => (
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "lightgray",
                    cursor: "pointer"
                  }}
                >
                  <CustomIcon name={item} color={"black"} />
                </div>
              ))
            }
          </div>
        </>
      )
    }
    else if (title === "Account") {
      return (
        <>
          {/* <div className="flex flex-row" style={{ gap: "10px" }}>
            {
              ["BiSolidPhone", "MdEmail", "GoPlus"]?.map((item) => (
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "lightgray",
                    cursor:"pointer"
                  }}
                >
                  <CustomIcon name={item} color={"black"} />
                </div>
              ))
            }
          </div> */}
        </>
      )
    }
    else if (title === "Contact") {
      return (
        <>
          <div className="flex flex-row" style={{ gap: "10px" }}>
            {
              ["BiSolidPhone", "MdEmail", "GoPlus"]?.map((item) => (
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    backgroundColor: "lightgray",
                    cursor: "pointer"
                  }}
                >
                  <CustomIcon name={item} color={"black"} />
                </div>
              ))
            }
          </div>
        </>
      )
    }
  }

  return (
    <Buttonsheet open={isVisible} onClose={onClose} header={title}>
      {
        chosseDocument ? (<>
          <CandiDateSubmission jobId={jobId} close={onClose} />
        </>
        ) : (
          <>
            <div className="flex flex-row items-center justify-between">
              <Box>

              </Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="User Details Tabs"
                  centered
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#3D5B81",
                    },
                  }}
                >
                  <Tab
                    label="Details"
                    sx={{
                      color: activeTab === 0 ? "#3D5B81" : "#8a8a8a",
                      fontWeight: activeTab === 0 ? "bold" : "normal",
                    }}
                  />
                  <Tab
                    label="Activity"
                    sx={{
                      color: activeTab === 1 ? "#3D5B81" : "#8a8a8a",
                      fontWeight: activeTab === 1 ? "bold" : "normal",
                    }}
                  />
                  <Tab
                    label="Placement"
                    sx={{
                      color: activeTab === 2 ? "#3D5B81" : "#8a8a8a",
                      fontWeight: activeTab === 2 ? "bold" : "normal",
                    }}
                  />
                </Tabs>
              </Box>
              <Box>
                {lastRenderUi()}
              </Box>
            </div>

            <Box sx={{ p: 4 }}>
              {renderComponent()}
            </Box>
          </>
        )
      }

    </Buttonsheet>
  );
};

export default ModalUserDetails;
