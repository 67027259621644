import React from "react";
import CustomCartActivity from "../components/CustomCartActivity";

const UserActivity = () => {

  return (

    <>
      <div className=" flex justify-between mt-10 ">
        <CustomCartActivity
          text1={"10"}
          text2={"Rejection or No response after "}
          text3={"Offer"}
        />
        <CustomCartActivity
          text1={"20"}
          text2={"Rejection or No Response after "}
          text3={"Submission"}
        />
        <CustomCartActivity
          text1={"30"}
          text2={"Candidate Rejection after "}
          text3={"offer"}
        />
        <CustomCartActivity
          text1={"50"}
          text2={"Time there was no feedback  "}
          text3={"after Interview"}
        />
        <CustomCartActivity
          text1={"40"}
          text2={"Time Job went on Hold after "}
          text3={"Submission"}
        />
      </div>
      <div className=" mt-14 ml-4">
        <div className=" font-bold text-[#405d83] ml-4 mb-4">
          Info Grance
        </div>
        <hr className=" mx-4" />
      </div>
      <div>
        <ol className="relative text-gray-500 border-s ml-10 border-red-500 dark:border-gray-700 dark:text-gray-400">
          <li class="mb-10">
            <span className="absolute flex items-center justify-center w-8 h-8 bg-[#EBB251] rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900"></span>
            <div className=" flex ml-10 mr-16 mt-8">
              <div className=" w-16 py-2 pl-6 bg-[#eeeeee] text-xl rounded-tl-lg rounded-bl-lg font-bold ">
                80
              </div>
              <div className=" w-full py-2 pl-4 rounded-tr-lg rounded-br-lg bg-[#EBB251] text-white">
                Total Job
              </div>
            </div>
          </li>
          <li className="mb-10">
            <span className="absolute flex items-center justify-center w-8 h-8 bg-[#4EB4DB] rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-blue-900"></span>
            <div className=" flex ml-10 mr-16 mt-8">
              <div className=" w-16 py-2 pl-6 bg-[#eeeeee] text-xl rounded-tl-lg rounded-bl-lg font-bold ">
                60
              </div>
              <div className=" w-full py-2 pl-4 rounded-tr-lg rounded-br-lg bg-[#4EB4DB] text-white">
                Total Submission
              </div>
            </div>
          </li>
          <li className="mb-10">
            <span className="absolute flex items-center justify-center w-8 h-8 bg-[#875E8A] rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-yellow-900"></span>
            <div className=" flex ml-10 mr-16 mt-8">
              <div className=" w-16 py-2 pl-6 bg-[#eeeeee] text-xl rounded-tl-lg rounded-bl-lg font-bold ">
                45
              </div>
              <div className=" w-full py-2 pl-4 rounded-tr-lg rounded-br-lg bg-[#C77ADE] text-white">
                Total Internal Rejection
              </div>
            </div>
          </li>
          <li className="mb-10 ">
            <span className="absolute flex items-center justify-center w-8 h-8 bg-[#E66161] rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-purple-900"></span>
            <div className=" flex ml-10 mr-16 mt-8">
              <div className=" w-16 py-2 pl-6 bg-[#eeeeee] text-xl rounded-tl-lg rounded-bl-lg font-bold ">
                50
              </div>
              <div className=" w-full py-2 pl-4 rounded-tr-lg rounded-br-lg bg-[#E66161] text-white">
                Total Internal Rejection
              </div>
            </div>
          </li>
          <li className="mb-10">
            <span className="absolute flex items-center justify-center w-8 h-8 bg-[#54BF93] rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-red-900"></span>
            <div className=" flex ml-10 mr-16 my-8">
              <div className=" w-16 py-2 pl-6 bg-[#eeeeee] text-xl rounded-tl-lg rounded-bl-lg font-bold ">
                40
              </div>
              <div className=" w-full py-2 pl-4 rounded-tr-lg rounded-br-lg bg-[#54BF93] text-white">
                Total Client Submission
              </div>
            </div>
          </li>
          <li className="mb-10">
            <span className="absolute flex items-center justify-center w-8 h-8 bg-[#875E8A] rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-teal-900"></span>
            <div className=" flex ml-10 mr-16 my-8">
              <div className=" w-16 py-2 pl-6 bg-[#eeeeee] text-xl rounded-tl-lg rounded-bl-lg font-bold ">
                30
              </div>
              <div className=" w-full py-2 pl-4 rounded-tr-lg rounded-br-lg bg-[#875E8A] text-white">
                Total Interviews
              </div>
            </div>
          </li>
          <li class="">
            <span className="absolute flex items-center justify-center w-8 h-8 bg-[#48C1C5] rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-pink-900"></span>
            <div className=" flex ml-10 mr-16 my-8">
              <div className=" w-16 py-2 pl-6 bg-[#eeeeee] text-xl rounded-tl-lg rounded-bl-lg font-bold ">
                20
              </div>
              <div className=" w-full py-2 pl-4 rounded-tr-lg rounded-br-lg bg-[#48C1C5] text-white">
                Total Placements
              </div>
            </div>
          </li>
        </ol>

      </div>
    </>
  );
};

export default UserActivity;
