import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "@mui/material";
import { CandiDateColumn } from "../../Modules/Table/tableColumnData";


const CandiDateFilter = ({
  isOpen,
  onClose,
  filterColumnData,
  setFilterColumnData,
  title
}) => {
  const [uiData, setUiData] = useState();


  React.useEffect(() =>{
   if(title){
    setUiData(CandiDateColumn);
   }
   
  },[title])

 

  const handleCheckboxChange = (field) => {
    const updatedColumns = uiData && uiData.map((col) =>
      col.field === field ? { ...col, visible: !col.visible } : col
    );
    setUiData(updatedColumns);
  };

  const handleApply = () =>{
    setFilterColumnData(uiData);
     onClose();
  }


  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ style: { width: "300px",top:'5rem', paddingLeft:'30px', paddingTop:'30px'  } }}
    >
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-4">Filter Columns</h3>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          { uiData && uiData.map((col) => (
            <FormControlLabel
              key={col.field}
              control={
                <Checkbox
                  checked={col.visible}
                  onChange={() => handleCheckboxChange(col.field)}
                />
              }
              label={col.headerName}
            />
          ))}
          <div className="flex justify-center pt-4 pb-20">
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            className="mt-2"
          >
            Apply
          </Button>
        </div>
        </div>

        {/* <div className="flex justify-center mt-4">
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            className="mt-2"
          >
            Apply
          </Button>
        </div> */}
      </div>
    </Drawer>
  );
};

export default CandiDateFilter;
