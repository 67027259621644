import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  state_DropDown,
  city_DropDown, client_DropDown, documents_Dropdown, user_Job_DropDown,
} from "../../stores/Reducers/dropDownApiReducers";
import CustomIcon from "../../components/CustomIcon";
import { presentationDropDwon } from "../../Modules/dropDownData";
import CustomSelecField from "../../components/CustomSelectField";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomNumber from "../../components/CustomNumber";
import CustomEmail from "../../components/CustomEmail";
import CustomSocialMedia from "../../components/CustomSocialMedia";
import { createContact } from "../../stores/Reducers/newContact";
import JoditEditor from "jodit-react";
import "../../css/powerpoint.css";
import CutomButton from "../../components/CutomButton";
import CustomAddSelect from "../../components/CustomAddSelect";
import { ContextData } from "../../Context/context";
import MultipleSelect from "../../components/MultipleSelect";
import { ContactFormState, ContactDocumentFromState } from "../../Modules/formState";
import { Job_Client_DropDown } from "../../stores/Reducers/jobDropDownReducer";
import { uploadFile } from "../../Modules/Document_Upload";
import { toast } from "react-toastify";
import { DuplicateEmail } from "../../stores/Reducers/emailCheck";
import ContactNumber from "../../components/ContactNumber";


const NewContact = () => {
  const editor = useRef(null);
  const Navigate = useNavigate();
  const {
    formData,
    setFormData,
    setDocumentFormData,
    documentFormData,
    documents,
    setDocuments,
  } = useContext(ContextData);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [documentTitle, setDocumentTitle] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");
  const [accessOwnerShip, setAccessOwnerShip] = useState(false);
  const [completeStep, setCompleteStep] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [fileshow, setFileshow] = useState({});

  const {
    CountryDropDown,
    stateDropDown,
    cityDropDown,
    industryDropDown,
    statusDropDown,
    clientDropDown,
    documentDropDown,
    clientManagerDropDown,
    user_JobDropDown
  } = useSelector((data) => data.dropDown);

  const {
    jobClientDropDown,
  } = useSelector((data) => data.jobDropDown);

  const found_Document_Title =
    documentFormData?.document &&
    documentFormData?.document.find((doc) => doc.title);

  const [firstSelectedValue, setFirstSelectedValue] = useState("");
  const [secondSelectedValue, setSecondSelectedValue] = useState("");
  const [showData, setShowData] = useState(false);
  const [showData1, setShowData1] = useState(false);
  const [deFaultUi, setDeFaultUi] = useState([
    { id: 1, name: "default", label: "Default", value: "", type: "radio" },
    { id: 2, name: "modified", label: "Modified", value: "", type: "radio" },
  ]);

  const responseData = useSelector((item) => item.contactlist);

  const [bodyValue, setBodyValue] = useState("");
  const [bodyInputValue, setBodyInputValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Default");
  const [headerInputValue, setHeaderInputValue] = useState([]);
  const [successShow, setSuccessShow] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [emailErrorStatus, setEmailErrorStatus] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);

  const [errors1, setErrors1] = useState({
    firstSelect: "",
    secondSelect: "",
    bodySelect: "",
  });

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const field = [{ name: "text1" }, { name: "text2" }, { name: "text" }];

  const handleFirstChange = (e) => {
    setBodyValue("");
    setFirstSelectedValue(e.target.value);
    setErrors1((prevErrors) => ({ ...prevErrors, firstSelect: "" }));
  };

  const handleSecondChange = (e) => {
    setBodyValue("");
    setSecondSelectedValue(e.target.value);
    setErrors1((prevErrors) => ({ ...prevErrors, secondSelect: "" }));
  };

  const handleChangeSelect = (e) => {
    setBodyValue(e.target.value);
    if (e.target.value === "header") {
      const headerData = `{${firstSelectedValue}:${secondSelectedValue}}`;
      setHeaderInputValue([...headerInputValue, headerData]);
    } else if (e.target.value === "body") {
      const bodyData = `{${firstSelectedValue}:${secondSelectedValue}}`;
      setBodyInputValue([...bodyInputValue, bodyData]);
    }
    setErrors1((prevErrors) => ({ ...prevErrors, bodySelect: "" }));
  };

  const disabledData = selectedOption === "Default";

  const editorConfig = useMemo(
    () => ({
      height: 400,
      readonly: false,
      toolbarSticky: false,
    }),
    []
  );

  const bodyData = bodyInputValue.join("");
  const headerData = headerInputValue.join("");

  const handleAddPlus = () => {
    let hasErrors = false;
    const newErrors = { firstSelect: "", secondSelect: "", bodySelect: "" };

    if (!firstSelectedValue) {
      newErrors.firstSelect = "First selection is required.";
      hasErrors = true;
    }
    if (!secondSelectedValue) {
      newErrors.secondSelect = "Second selection is required.";
      hasErrors = true;
    }
    if (!bodyValue) {
      newErrors.bodySelect = "Merge selection is required.";
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors1(newErrors);
      return;
    }

    setErrors1({ firstSelect: "", secondSelect: "", bodySelect: "" });

    if (
      firstSelectedValue &&
      secondSelectedValue &&
      (bodyInputValue.length > 0 || headerInputValue.length > 0)
    ) {
      setShowData(true);
    }
  };



  // const validateOfficialNumbers = (officialNumbers, length) => {
  //   let errors = [];
  //   const numberPattern = /^[0-9]+$/;
  //   const countryCodePattern = /^\+\d{1,4}$/;

  //   errorStatus && officialNumbers.forEach((entry, index) => {
  //     const { countryCode, number } = entry;

  //     if (errorStatus) {
  //       if (!countryCode || !countryCodePattern.test(countryCode)) {
  //         errors[index] = 'Invalid or missing country code.';
  //       } else if (number.length <= 0) {
  //         errors[index] = 'This field is required';
  //       }
  //       else if (!numberPattern.test(number)) {
  //         errors[index] = 'Invalid number. Must be digit number.';
  //       }


  //       else if (numberPattern.test(number)) {
  //         setNumberValidation(true)
  //       }
  //     }
  //     else {
  //       if (!numberPattern.test(number)) {
  //         errors[index] = 'Invalid number. Must be a 10-digit number.';
  //       }
  //       else if (numberPattern.test(number)) {
  //         setNumberValidation(true)
  //       }
  //     }
  //   });

  //   return errors;
  // };

  const validateOfficialNumbers = (officialNumbers) => {
    let errors = [];
    const countryCodePattern = /^\+\d{1,4}$/;
    const countryCodeLengths = {
      '+1': 10,
      '+7': 10,    // Russia, Kazakhstan
      '+20': 10,   // Egypt
      '+27': 9,    // South Africa
      '+30': 10,   // Greece
      '+31': 9,    // Netherlands
      '+32': 9,    // Belgium
      '+33': 9,    // France
      '+34': 9,    // Spain
      '+36': 9,    // Hungary
      '+39': 10,   // Italy
      '+40': 10,   // Romania
      '+41': 9,    // Switzerland
      '+43': 10,   // Austria
      '+44': 10,   // United Kingdom
      '+45': 8,    // Denmark
      '+46': 9,    // Sweden
      '+47': 8,    // Norway
      '+48': 9,    // Poland
      '+49': 10,   // Germany
      '+51': 9,    // Peru
      '+52': 10,   // Mexico
      '+53': 8,    // Cuba
      '+54': 10,   // Argentina
      '+55': 10,   // Brazil
      '+56': 9,    // Chile
      '+57': 10,   // Colombia
      '+58': 10,   // Venezuela
      '+60': 9,    // Malaysia
      '+61': 9,    // Australia
      '+62': 10,   // Indonesia
      '+63': 10,   // Philippines
      '+64': 9,    // New Zealand
      '+65': 8,    // Singapore
      '+66': 9,    // Thailand
      '+81': 10,   // Japan
      '+82': 10,   // South Korea
      '+84': 9,    // Vietnam
      '+86': 11,   // China
      '+90': 10,   // Turkey
      '+91': 10,   // India
      '+92': 10,   // Pakistan
      '+93': 9,    // Afghanistan
      '+94': 9,    // Sri Lanka
      '+95': 9,    // Myanmar
      '+98': 10,   // Iran
      '+212': 9,   // Morocco
      '+213': 9,   // Algeria
      '+216': 8,   // Tunisia
      '+218': 9,   // Libya
      '+220': 7,   // Gambia
      '+221': 9,   // Senegal
      '+222': 9,   // Mauritania
      '+223': 8,   // Mali
      '+224': 9,   // Guinea
      '+225': 8,   // Côte d’Ivoire
      '+226': 8,   // Burkina Faso
      '+227': 8,   // Niger
      '+228': 8,   // Togo
      '+229': 8,   // Benin
      '+230': 8,   // Mauritius
      '+231': 8,   // Liberia
      '+232': 8,   // Sierra Leone
      '+233': 9,   // Ghana
      '+234': 10,  // Nigeria
      '+235': 8,   // Chad
      '+236': 8,   // Central African Republic
      '+237': 9,   // Cameroon
      '+238': 7,   // Cape Verde
      '+239': 7,   // São Tomé and Príncipe
      '+240': 9,   // Equatorial Guinea
      '+241': 9,   // Gabon
      '+242': 9,   // Congo (Brazzaville)
      '+243': 9,   // Congo (Kinshasa)
      '+244': 9,   // Angola
      '+245': 7,   // Guinea-Bissau
      '+246': 7,   // Diego Garcia
      '+248': 7,   // Seychelles
      '+249': 9,   // Sudan
      '+250': 9,   // Rwanda
      '+251': 9,   // Ethiopia
      '+252': 9,   // Somalia
      '+253': 9,   // Djibouti
      '+254': 10,  // Kenya
      '+255': 9,   // Tanzania
      '+256': 9,   // Uganda
      '+257': 8,   // Burundi
      '+258': 9,   // Mozambique
      '+260': 9,   // Zambia
      '+261': 9,   // Madagascar
      '+262': 9,   // Réunion/Mayotte
      '+263': 9,   // Zimbabwe
      '+264': 9,   // Namibia
      '+265': 9,   // Malawi
      '+266': 8,   // Lesotho
      '+267': 8,   // Botswana
      '+268': 8,   // Eswatini
      '+269': 7,   // Comoros
      '+290': 5,   // Saint Helena
      '+291': 9,   // Eritrea
      '+297': 7,   // Aruba
      '+298': 6,   // Faroe Islands
      '+299': 6,   // Greenland
      // Add more if needed
    };

    errorStatus && officialNumbers.forEach((entry, index) => {
      const { countryCode, number } = entry;
      const resolvedCountryCode = countryCode || '+1';
      const numberLength = countryCodeLengths[resolvedCountryCode];
      const numberPattern = new RegExp(`^[0-9]{${numberLength}}$`)

      if (!resolvedCountryCode || !countryCodePattern.test(resolvedCountryCode)) {
        errors[index] = 'Invalid or missing country code.';
      }
      else if (!number || number.length === 0) {
        errors[index] = 'This field is required.';
      }
      else if (number.length !== numberLength) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else if (!numberPattern.test(number)) {
        errors[index] = 'Invalid number. Must contain only digits.';
      }
      else if (
        countryCodeLengths[resolvedCountryCode] &&
        number.length !== countryCodeLengths[resolvedCountryCode]
      ) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else {
        setNumberValidation(true)
      }
    });

    return errors;
  };



  const validateofficialEmailId = async (EmailId) => {
    let errors = [];
    const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailErrorStatus) {
      for (let index = 0; index < EmailId.length; index++) {
        const email = EmailId[index];

        // Check if email is provided
        if (!email) {
          errors[index] = 'This field is required';
        }
        else if (!emailRegexPattern.test(email)) {
          errors[index] = 'Email is invalid. Please provide a correct email.';
        }
        // If email is valid, check if it's a duplicate
        else {
          try {
            // Ensure that email check only occurs for valid email format
            const duplicateEmail = await dispatch(DuplicateEmail({ pageName: 'Contact', email }));

            if (duplicateEmail?.payload?.alreadyExists) {
              // If the email already exists, set error message
              errors[index] = 'Email is already added. Please add a unique email.';
            } else {
              // If the email doesn't exist, clear any previous error and set validation
              errors[index] = '';
              setEmailValidation(true);
            }
          } catch (err) {
            console.error('Error during duplicate email check:', err);
            errors[index] = 'Error checking duplicate email. Please try again.';
          }
        }
      }
    } else {

      errors = [];
    }

    return errors;
  };

  useEffect(() => {
    if (formData?.country) {
      const country = CountryDropDown.find(
        (item) => item.name === formData.country
      );
      if (country) {
        dispatch(state_DropDown({ countryCode: country.isoCode }));
      }

    }
    // else {
    //   dispatch(state_DropDown({ countryCode: "US" }));
    // }
  }, [formData.country, CountryDropDown]);

  useEffect(() => {
    if (formData?.state) {
      const state = stateDropDown.find((item) => item.name === formData.state);
      const country = CountryDropDown.find(
        (item) => item.name === formData.country
      );
      if (state && country) {
        dispatch(
          city_DropDown({
            countryCode: country.isoCode,
            stateCode: state.isoCode,
          })
        );
      }
      // else if (state) {
      //   dispatch(
      //     city_DropDown({
      //       countryCode: "US",
      //       stateCode: state.isoCode,
      //     })
      //   );
      // }
    }

  }, [formData.state, stateDropDown, CountryDropDown]);

  useEffect(() => {
    let endpoints = "contact";
    dispatch(client_DropDown({ endpoints }));
  }, [dispatch])

  useEffect(() => {
    let endpoints = "contact";
    dispatch(documents_Dropdown({ endpoints }));
  }, [dispatch])

  useEffect(() => {
    //dispatch(Job_Client_DropDown())
    dispatch(user_Job_DropDown());
  }, [])

  const Company_DropDwon = useMemo(() => {
    return jobClientDropDown?.map((item) => ({
      value: item?.clientId,
      label: item?.client_name,
    }));
  }, [jobClientDropDown]);


  const AllowOwnerData = useMemo(() => {
    return user_JobDropDown?.map((item) => ({
      value: item?.userId,
      label: item?.user,
    }))
  }, [user_JobDropDown])



  const handleChange = useCallback(
    (e, name) => {
      const { type, checked, value } = e?.target || {};
      const fieldName = name || e?.target?.name;
      const zipCodeRegex = /^\d{1,6}$/;
      const nameRoleRegex = /^[A-Za-z\s]+$/;
      const roleRegext = /^\s*[A-Za-z][a-zA-Z]*(?:\s+[A-Za-z][a-zA-Z]*)*\s*$/;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: type === "checkbox" ? checked : value,
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: value?.trim() ? fieldName === "zipCode" && !zipCodeRegex.test(value) ? "Invalid ZIP Code. Must be numeric and up to 6 digits." : fieldName === "fullName" && !nameRoleRegex.test(value) ? "please enter correct name" : fieldName === "role" && !roleRegext.test(value) ? "please enter correct name" : " " : "This field is required",
      }));

      if (type === "checkbox") {
        setAccessOwnerShip(!accessOwnerShip);
        setErrors((prevErrors) => ({
          ...prevErrors,
          ["allowOwnerShip"]: "",
        }));
      }
    },
    [formData, accessOwnerShip]
  );


  const handleChangeDropDown = useCallback(
    (field, value) => {
      setFormData((prevState) => ({
        ...prevState,
        [field]: value,
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: value?.trim() ? "" : "This field is required",
      }));
    },
    [setFormData]
  );

  const validate = (FormData) => {
    let formErrors = {};
    let updatedFormData = FormData || formData;
    if (!updatedFormData.fullName)
      formErrors.fullName = "Full Name is required";
    if (!updatedFormData.role) formErrors.role = "Role is required";
    // if (
    //   !updatedFormData.officialNumber
    // ) {
    //   formErrors.officialNumber = ["official number is required"];
    // } else {
    //   if (updatedFormData.officialNumber.length > 0) {
    //     const officialNumberErrors = validateOfficialNumbers(
    //       updatedFormData.officialNumber,
    //       updatedFormData.officialNumber.length
    //     );
    //     formErrors.officialNumber = officialNumberErrors;
    //   }
    // }
    // if (!updatedFormData.email || updatedFormData.email.length === 0) {
    //   formErrors.email = ["Email is required"];
    // } else {
    //   if (updatedFormData.email.length > 0) {
    //     const emailErrors = validateofficialEmailId(
    //       updatedFormData.email,
    //       updatedFormData.email.length
    //     );
    //     formErrors.email = emailErrors;
    //   }
    // }
    if (!updatedFormData.company) formErrors.company = "Company is required";
    if (!updatedFormData.clientType)
      formErrors.clientType = "Client Type is required";
    if (!updatedFormData.country) formErrors.country = "Country is required";
    if (!updatedFormData.state) formErrors.state = "State is required";

    if (!updatedFormData.status) formErrors.status = "Status is required";
    if (!updatedFormData.allowOwnership.length === 0 && !accessOwnerShip) formErrors.allowOwnerShip = "Allow ownerShip is required"
    // if (!found_Document_Title?.doc_type && documents.length < 0) {
    //   formErrors.selectedDocument = "Please select a document";
    // }
    // if (!found_Document_Title?.title) {
    //   formErrors.documentTitle = "Please enter a document title";
    // }
    // if (!found_Document_Title?.description) {
    //   formErrors.description = "Please enter a description";
    // }
    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleChangeArray = useCallback(
    (data) => {
      setFormData((prevState) => ({
        ...prevState,
        officialNumber: data,
      }));

      const officialNumberErrors = validateOfficialNumbers(data);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };

        if (officialNumberErrors.length > 0) {
          newErrors.officialNumber = officialNumberErrors;
        } else {
          delete newErrors.officialNumber;
        }

        return newErrors;
      });

    },
    [formData.officialNumber]
  );

  const handleChangeEmailArray = useCallback(
    async (data) => {
      setFormData((prevState) => ({
        ...prevState,
        email: data,
      }));
      const EmailNumberErrors = await validateofficialEmailId(data);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };

        if (EmailNumberErrors.length > 0) {
          newErrors.email = EmailNumberErrors;
        } else {
          delete newErrors.email;
        }

        return newErrors;
      });

    },
    [formData.email]
  );

  const handleChangeObject = useCallback(
    (data) => {
      setFormData((prevState) => ({
        ...prevState,
        Url: data,
      }));
    },
    [formData.Url]
  );

  const handleImageFileChange = async (event) => {
    const file = event.target.files[0];
    const fileLocation = await uploadFile(file);
    if (fileLocation) {
      toast.success("Image is uscessfully uploaded");
      setImage(file.name);
      setFormData((prevState) => ({
        ...prevState,
        image: fileLocation.Location,
      }));

    }
    else {
      toast.success("Image is uscessfully uploaded");
    }
  };

  const handleButtonClick = (e) => {
    setLoader(true);
    e.preventDefault();
    handleSubmit(e);
  };

  const countryData = useMemo(() => {
    return CountryDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [CountryDropDown]);

  const stateData = useMemo(() => {
    return stateDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [stateDropDown]);

  const cityData = useMemo(() => {
    return cityDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [cityDropDown]);

  const Industry_DropDown = useMemo(() => {
    return industryDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [industryDropDown]);



  const Status_DropDwon = useMemo(() => {
    return statusDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [statusDropDown]);

  const client_TypeDropDwon = useMemo(() => {
    return clientDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [clientDropDown]);

  const document_DropDwon = useMemo(() => {
    return documentDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [documentDropDown]);

  const handleOnwerSelect = (data) => {
    setFormData((prevState) => ({
      ...prevState,
      allowOwnership: data,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      ["allowOwnerShip"]: data ? "" : "This field is required",
    }));
  };



  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileLocation = await uploadFile(file);
      setFileshow(file);
      if (fileLocation?.Location) {
        toast.success("file is uscessfully uploaded");
        setDocumentFormData((prevState) => {
          const hasEmptyTitleDocument = prevState?.document && prevState?.document.some(
            (doc) => doc.title === ""
          );
          const updatedDocuments = hasEmptyTitleDocument
            ? prevState?.document && prevState?.document.map((doc) =>
              doc.title === ""
                ? {
                  ...doc,
                  title: file.name,
                  url: fileLocation?.Location,
                  description: "",
                }
                : doc
            )
            : [
              ...prevState.document,
              {
                title: file.name,
                url: fileLocation?.Location,
                description: "",
              },
            ];

          return {
            ...prevState,
            document: updatedDocuments,
          };
        });
      }
      else {
        toast.error("file is not uploaded")
      }
    }
  };

  const handleDriveOpen = () => {
    const googleDriveUrl = "https://drive.google.com/drive/my-drive";
    window.open(googleDriveUrl, "_blank");
  };
  const handleDropboxSignIn = () => {
    const dropboxSignInUrl = "https://www.dropbox.com/login";
    window.open(dropboxSignInUrl, "_blank");
  };


  const handleSelectChangeDocument = useCallback(
    (index, e) => {
      const newTitle = e;
      setDocumentFormData((prevState) => {
        const updatedDocuments = prevState.document.map((doc, i) =>
          i === index ? { ...doc, doc_type: newTitle } : doc
        );

        return {
          ...prevState,
          document: updatedDocuments,
        };
      });
    },
    [documentFormData?.document]
  );

  const handleChangeDocument = useCallback((index, e, name) => {
    const newTitle = e.target.value;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newTitle?.trim() ? "" : "This field is required",
    }));
    setDocumentFormData((prevState) => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, title: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  }, []);

  const handleChangeDescription = (index, e) => {
    const newTitle = e.target.value;
    setDocumentFormData((prevState) => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, description: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorStatus(true);
    setEmailErrorStatus(true);
    const validataData = validate();
    let Client_Data = [];
    if (accessOwnerShip) {
      Client_Data = ["all"]
    }
    else if (!accessOwnerShip) {
      Client_Data = formData?.allowOwnership && formData?.allowOwnership.map((item) => {

        return `${item.value}`
      })
    }
    if (validataData && emailValidation && numberValidation) {
      try {
        let DocumentData;
        if (showData1) {
          DocumentData = documents && documents?.map((item) => {


            return {
              doc_type: item.type,
              title: item.title,
              description: item.description,
              url: item?.url,
            };
          });
        }
        else if (!showData1) {
          DocumentData =
            documentFormData &&
            documentFormData.document?.map((item) => {
              return {
                doc_type: item.doc_type,
                title: item.title,
                description: item.description,
                url: item?.url,
              };
            });
        }
        const newData = {
          full_name: formData?.fullName,
          role: formData?.role,
          mobile: formData?.officialNumber,
          email: formData?.email,
          company: formData?.company,
          client_type: formData?.clientType,
          country: formData?.country,
          state: formData?.state,
          city: formData?.city,
          address: formData?.address,
          urls: formData?.Url,
          zipcode: formData?.zipCode,
          status: formData?.status,
          notes: formData?.notes,
          profile_image: formData?.image,
          allow_ownership: Client_Data,
          documents: DocumentData,
          template: {
            subject: `${headerData}`,
            body: `<html>${bodyData}</html>`,
            isDefault: false,
          },
        };
        const ResponseData = await dispatch(createContact({ newData }))
          .then((resp) => {
            return resp;
          })
          .catch((err) => console.log(err));
        // console.log(ResponseData, "data1568");
        let header = "Contact";
        if (
          ResponseData?.payload?.message &&
          ResponseData?.payload?.message === "Success"
        ) {
          setFormData({
            fullName: '',
            role: '',
            officialNumber: [],
            email: [],
            company: '',
            clientType: '',
            country: '',
            state: '',
            city: '',
            address: '',
            Url: {},
            zipCode: '',
            status: '',
            image: '',
            allowOwnership: [],
            allowAcess: false,
            notes: '',
          });
          setDocumentFormData()
          setDocuments([]);
          Navigate("/dashboard/allcontact-list", {
            state: { from: "/dashboard/", header },
          });
          setLoader(false);
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error submitting form:", error);
      }
    } else {
      toast.error("please add all required field");
      setLoader(false);
    }
  };

  const handleContact = () => {
    Navigate("/dashboard/allcontact-list");
  }

  const newImageData = formData?.image;

  const handleAdd = () => {

    if (documentFormData?.document.length > 0) {
      const newDocuments = documentFormData.document.map((doc, index) => ({
        id: documents.length + index + 1,
        type: doc.doc_type,
        title: doc.title,
        url: doc.url,
        description: doc.description
      }));
      setDocuments(prevDocuments => [...prevDocuments, ...newDocuments]);
      setDocumentFormData(ContactDocumentFromState)

      setShowData1(true);
    }

  }

  const handleChildItemDelete = (id) => {
    setDocuments(prevDocuments => prevDocuments.filter(doc => doc.id !== id));
  };
  const handleChangeClick = () => {
    setErrorStatus(true);
  }
  const handleChangeEmailClick = () => {
    setEmailErrorStatus(true);
  }

  return (
    <div className="ring-black ring-opacity-50 w-full h-full rounded-xl p-4">
      <div className="bg-[#F5F5F5] rounded-xl p-6">
        <div className="flex flex-col md:flex-row items-center justify-between  pl-6 text-2xl text-[#004C95] font-bold rounded-t-lg">
          <div className="flex-1 md:flex-[0.2]">
            <p>New Contact</p>
          </div>
          <div>
            <button className=" text-[#3e5c82]" onClick={handleContact}
              style={{
                fontSize: '16px',
                borderRadius: "10px",
                border: "1px solid lightblue",
                padding: "5px"
              }}
            >
              View Contact
            </button>
          </div>
        </div>
        <div className="pt-6 pl-4 pr-4">
          <div className="text-lg text-[#004C95] font-bold">
            Personal Details :
          </div>
          <div className="mb-4 text-[#0000004D] border"></div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <CustomInput
                type="text"
                name="fullName"
                placeholder="Enter Full Name"
                label="Full Name"
                icon="BsPersonFill"
                value={formData.fullName}
                onChange={handleChange}
                error={errors.fullName}
                required={"true"}
              />
            </div>
            <div>
              <CustomInput
                type="text"
                name="role"
                placeholder="Enter Role"
                label="Role"
                icon="BsPersonFill"
                value={formData.role}
                onChange={handleChange}
                error={errors.role}
                required={"true"}
              />
            </div>
            <div>
              <ContactNumber
                type="text"
                name="officialNumber"
                placeholder="Enter Mobile Number"
                label="Contact Number"
                value={formData.officialNumber}
                onChange={handleChangeArray}
                onClick={handleChangeClick}
                errors={errors.officialNumber}
                required={true}
              />
            </div>
            <div>
              <CustomEmail
                type="email"
                name="email"
                label="Email ID"
                placeholder="Enter Email ID"
                value={formData.email}
                onChange={handleChangeEmailArray}
                onClick={handleChangeEmailClick}
                errors={errors.email}
                required={true}
              />
            </div>
            <div>
              <CustomAddSelect
                name="company"
                icon="CiLocationOn"
                label="Company"
                placeholder="Enter Company Name"
                value={formData.company}
                options={Company_DropDwon}
                onChange={(data) => handleChangeDropDown("company", data)}
                error={errors.company}
                required={"true"}
                name2={"contact"}
              />
            </div>
            <div>
              <CustomAddSelect
                name="clientType"
                icon="FaClipboardUser"
                label="Client Type"
                placeholder="Enter Client Type"
                value={formData.clientType}
                options={client_TypeDropDwon}
                onChange={(data) => handleChangeDropDown("clientType", data)}
                error={errors.clientType}
                required={"true"}
                name2={"contact"}
              />
            </div>
            <div>
              <CustomSelect
                name="country"
                icon="CiLocationOn"
                label="Country"
                value={formData.country }
                onChange={(data) => handleChangeDropDown("country", data)}
                error={errors.country}
                options={countryData}
                required={"true"}
              />
            </div>
            <div>
              <CustomSelect
                name="state"
                icon="CiLocationOn"
                label="State"
                value={formData.state}
                onChange={(data) => handleChangeDropDown("state", data)}
                error={errors.state}
                options={stateData}
                required={"true"}
              />
            </div>
            <div>
              <CustomSelect
                name="city"
                icon="IoEarth"
                label="City"
                value={formData.city}
                onChange={(data) => handleChangeDropDown("city", data)}
                error={errors.city}
                options={cityData}
              />
            </div>
            <div>
              <CustomInput
                type="text"
                name="address"
                placeholder="Enter Address"
                label="Address"
                icon="CiLocationOn"
                value={formData.address}
                onChange={handleChange}
                error={errors.address}
              />
            </div>

            <div>
              <CustomInput
                type="text"
                name="zipCode"
                placeholder="Enter Zip Code"
                label="Zip Code"
                icon="CiLocationOn"
                value={formData.zipCode}
                onChange={handleChange}
                error={errors.zipCode}
              />
            </div>
            <div>
              <CustomAddSelect
                name="status"
                icon="GrStatusGood"
                label="Status"
                placeholder="Select Status"
                value={formData.status}
                options={Status_DropDwon}
                onChange={(data) => handleChangeDropDown("status", data)}
                error={errors.status}
                required={"true"}
              />
            </div>
            <div>
              <label>Profile Image</label>
              <div
                className="flex  border border-gray-300  h-9 bg-white"
                style={{
                  borderRadius: "5px",
                }}
              >
                <button
                  className="bg-gray-300 shadow-lg w-40 text-gray-500 h-full flex items-center justify-center px-2"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <div>Choose File</div>
                </button>
                <div className="relative w-full">
                  <input
                    type="file"
                    id="fileInput"
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    onChange={handleImageFileChange}
                  />
                  <div className="py-2 px-4 text-gray-500 truncate">
                    {image
                      ? image
                      : "admin"}
                  </div>
                </div>

              </div>
            </div>
            <div>
              <MultipleSelect
                label="Allow Ownership"
                value={formData.allowOwnership}
                onChange={handleOnwerSelect}
                error={errors.allowOwnership}
                disabled={accessOwnerShip}
                options={AllowOwnerData}
              />

              <div className="flex space-x-2 mt-4">
                <input
                  type="checkbox"
                  name="allowAccess"
                  className="w-6 h-6"
                  checked={formData.allowAccess}
                  onChange={handleChange}
                />
                <div>Allow Access to All users</div>
              </div>
              <p className="text-red-500 text-sm">{accessOwnerShip ? "" : formData.allowAccess ? "" : errors.allowOwnerShip}</p>
            </div>
            <div>
              <CustomSocialMedia
                type="text"
                name="Url"
                placeholder="Enter URL's"
                label="Enter URLs"
                value={formData.Url}
                onChange={handleChangeObject}
                error={errors.Url}
              />
            </div>
            <div>
              <label>Notes</label>
              <textarea
                name="notes"
                rows={6}
                placeholder="Add Notes"
                className="w-full p-2 bg-white border border-gray-300 rounded-lg shadow-md"
                value={formData.notes}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="text-lg text-[#004C95] font-bold">
             Upload Document :
          </div>
          <div className="mb-4 text-[#0000004D] border"></div>


          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">

            <div>
              {documentFormData?.document && documentFormData?.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <CustomAddSelect
                      label={"Select Document"}
                      icon={"BsPersonFill"}
                      value={doc?.doc_type || ""}
                      onChange={(e) => {
                        setFileUploadStatus(true);
                        handleSelectChangeDocument(index, e);
                      }}
                      error={errors.selectedDocument}
                      options={document_DropDwon}
                      name2={"contact"}
                    />
                  )}
                </div>
              ))}
            </div>

            <div>
              <div className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                Upload File
              </div>
              <div className="mt-3 flex">
                <div className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl text-blue-600">
                  <label>
                    <CustomIcon name={"CgScreen"} />
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
                <div
                  className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                  onClick={handleDriveOpen}
                  style={{ cursor: "pointer" }}
                >
                  <CustomIcon name={"BsCloudsFill"} />
                </div>
                <div
                  className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                  onClick={handleDropboxSignIn}
                  style={{ cursor: "pointer" }}
                >
                  <CustomIcon name={"FaDropbox"} />
                </div>
              </div>
              <div className=" text-sm text-red-500 mt-2">
                {found_Document_Title?.title}
              </div>
            </div>

            <div>
              {documentFormData?.document && documentFormData?.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <CustomInput
                      type="text"
                      placeholder="Please enter title"
                      label="Document Title"
                      icon="IoDocumentText"
                      value={doc.title || ""}
                      onChange={(e) => handleChangeDocument(index, e, "documentTitle")}
                      error={errors.documentTitle}
                      disabled={!fileUploadStatus}
                    />
                  )}
                </div>
              ))}
            </div>

            <div>
              <label className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                Description
              </label>
              {documentFormData?.document && documentFormData?.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <textarea
                      id="message"
                      rows="4"
                      className={`block p-2.5 w-full text-sm bg-gray-50 rounded-lg border  focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      placeholder="Write here..."
                      value={doc.description || ""}
                      onChange={(e) => handleChangeDescription(index, e)}
                    ></textarea>
                  )}
                </div>
              ))}
            </div>


            <div>
              <button
                className="flex items-center justify-center bg-custom-blue text-white font-normal py-2 px-4 h-9 rounded-xl"
                disabled={!fileUploadStatus}
                onClick={handleAdd}
              >
                <span className="text-lg sm:text-xl mr-1 h-5">
                  <CustomIcon name={"TiPlus"} />
                </span>
                Add
              </button>
            </div>

            <div>
              {showData1 && documents.length > 0 && (
                <div>
                  <div className="border-b-2 text-gray-500 mt-4 sm:mt-6 w-full sm:w-[50%]"></div>
                  <div className="relative w-full sm:w-[50%]">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-lg sm:text-xl text-gray-600 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">S.No.</th>
                          <th scope="col" className="px-6 py-3">Document Type</th>
                          <th scope="col" className="px-6 py-3">Document Title</th>
                          <th scope="col" className="px-6 py-3"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents && documents?.map((doc, index) => (
                          <tr key={index} className="dark:bg-gray-800 mt-5">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {index + 1}
                            </th>
                            <td className="px-6 py-4">{doc?.type}</td>
                            <td className="px-6 py-4">{doc?.title.toUpperCase()}</td>
                            <td className="px-6 py-4 text-red-500 cursor-pointer">
                              <div onClick={() => handleChildItemDelete(doc.id)}>
                                <CustomIcon name={"IoCloseCircle"} />
                              </div>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>

        <div className="text-lg text-[#004C95] font-bold mt-6">Other Files :</div>
        <div className="mb-4 text-[#0000004D] border"></div>
        <div className="flex flex-col sm:flex-row justify-between mt-4 sm:mt-6 mb-8">
          <div className="text-lg sm:text-xl text-gray-800 font-bold font-sans">
            Submission Template
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
          <div className="text-[#004C95] text-lg sm:text-xl font-medium">
            Style
          </div>
          {deFaultUi.map((item) => (
            <div className="flex items-center" key={item.id}>
              <input
                id={item.id}
                type={item.type}
                value={item.label}
                checked={selectedOption === item.label}
                onChange={handleOptionChange}
                name={item.name}
                className="w-4 h-4 sm:w-5 sm:h-5 text-[#004C95] bg-gray-100 border-gray-300 focus:ring-[#004C95]"
              />
              <label
                htmlFor={item.id}
                className="text-black text-lg sm:text-xl font-medium ml-2 sm:ml-3"
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>

        <div className="text-[#004C95] text-lg sm:text-xl font-medium mt-6 sm:mt-10">
          Merge Fields
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mt-4">
          <div>
            <CustomSelecField
              Placeholder={"Select Table"}
              optionData={field}
              disabled={disabledData}
              value={firstSelectedValue}
              onChange={handleFirstChange}
            />
            {errors1.firstSelect && (
              <p className="text-red-500 text-sm">{errors1.firstSelect}</p>
            )}
          </div>
          <div>
            <CustomSelecField
              Placeholder={"Select Fields"}
              disabled={disabledData}
              optionData={field}
              value={secondSelectedValue}
              onChange={handleSecondChange}
            />
            {errors1.secondSelect && (
              <p className="text-red-500 text-sm">{errors1.secondSelect}</p>
            )}
          </div>
          <div>
            <CustomSelecField
              Placeholder={"Select"}
              disabled={disabledData}
              optionData={presentationDropDwon}
              value={bodyValue}
              onChange={handleChangeSelect}
            />
            {errors1.bodySelect && (
              <p className="text-red-500 text-sm">{errors1.bodySelect}</p>
            )}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between mt-6 sm:mt-10 mb-8">
          <div className="text-md text-gray-800 w-full sm:w-2/3 lg:w-2/3 sm:pr-6 lg:pr-4">
            <label>Subject Line</label>
            <div className="mt-2">
              <input
                type="text"
                className="w-full py-1 rounded-md focus:outline-none pl-3 border border-gray-300"
                disabled={disabledData}
                value={showData ? headerData : ""}
                readOnly
              />
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-4">
            <button
              className="flex items-center justify-center bg-custom-blue text-white font-normal py-2 px-4 h-9 rounded-xl"
              disabled={disabledData}
              onClick={handleAddPlus}
            >
              <span className="text-lg sm:text-xl mr-1 h-5">
                <CustomIcon name={"TiPlus"} />
              </span>
              Add
            </button>
          </div>
        </div>

        <div className="text-[#004C95] text-lg sm:text-xl font-medium mt-6 sm:mt-10 w-full sm:w-2/3">
          Merge Fields
        </div>
        <div className="mt-3 w-full sm:w-2/3 pr-4 border-none-custom">
          <JoditEditor
            value={showData ? bodyData : ""}
            config={editorConfig}
          />
        </div>

        <div className="mt-20 pb-20 flex justify-center">
          <CutomButton
            text={loader ? "" : "Submit"}
            bgColor="#3D5B81"
            width="200"
            onClick={handleButtonClick}
            loader={loader}
          />
        </div>
      </div>
    </div>

  );
};

export default NewContact;
