import React, { useState } from "react";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import CustomSocialMedia from "./CustomSocialMedia";
import CutomButton from "./CutomButton";

const AddCompanyInfo = ({ isModalOpen,label , close}) => {


  return (
    <>
    {
      isModalOpen && 
      <div
        id="default-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
      >
        <div className="relative p-4 w-full max-w-7xl">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Company Information
              </h3>
              <button
                type="button"
                onClick={() => close()}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            
            <div className="p-4 md:p-5 space-y-4">
              <div className=" grid grid-cols-2 gap-8 px-4 py-3">
                <div>
                  <CustomInput
                    label={"Company Name"}
                    placeholder={"Enter Company Name"}
                    icon={"BsBuildings"}
                  />{" "}
                </div>
                <div>
                  <CustomSelect icon={"BsBuildings"} label={"Industry"} />
                </div>
              </div>
              <div className=" grid grid-cols-2 gap-8 px-4 py-3">
                <div>
                  <CustomInput
                    label={"Location"}
                    icon={"CiLocationOn"}
                    placeholder={"Enter Location"}
                  />{" "}
                </div>
                <div>
                  <CustomInput
                    label={"Total Employees"}
                    icon={"CiLocationOn"}
                    placeholder={"Enter Employees"}
                  />{" "}
                </div>
              </div>
              <div className=" grid grid-cols-2 py-4 px-4">
                <CustomSocialMedia label={"Enter URL's"} />
              </div>
            </div>
            <div className="mt-20 pb-20 flex justify-center animate-slide-in-left">
              <CutomButton text={"Submit"} bgColor={"#3D5B81"} width={"200"} />
            </div>
          </div>
        </div>
      </div>
    }
      
     
    </>
  );
};

export default AddCompanyInfo;