import React from "react";
import CustomIcon from "./CustomIcon";

const CustomSelect = ({ icon, label, name, value , onChange, error, disabled, options, required }) => {

  const handleChange = (e) => {
    onChange(e.target.value)
  }
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-md font-medium leading-6 text-gray-600"
      >
        {label} {
          required === "true" ? (
            <span className="text-red-500 font-medium">
              *
              </span>
          ):(
            <>
            </>
          )

        }
      </label>
      <div className="relative  text-gray-700" style={{ color: '#808080', borderRadius: "5px", border: "1px solid lightgray" }}>
        <select
          id={name}
          name={name}
          value={value ? value  : ""}
          onChange={handleChange}
          disabled={disabled}
          className={` py-1.5  w-full  px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50`}
          style={{ color: '#4b5563', backgroundColor: 'white', borderRadius: "5px", fontSize:"14px" }}
        >
          
          {
          options && options.length > 0 ?(options ? options : []).map((option, index) => (
            <option key={index} value={option.label}>
              {option.label}
            </option>
          ))
          :(
            <>
            <p> No Data Found</p>
            </>
          )
          }
        </select>
        
      </div>
      {error && <p className="text-red-500 text-sm ">{error}</p>}
    </div>
  );
};

export default CustomSelect;
