import React from "react";

const CustomSelecField = ({Placeholder, optionData , value, onChange, name }) => {

  
  
  return (
    <>
      <div className="relative animate-slide-in-left w-full  py-1 pr-2 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50">
        <div className="relative mt-2 border border-gray-300 rounded-lg">
          <select 
            id={name}
            className=" pl-4 pr-4 border-gray-400 w-full rounded-lg py-2 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
            value={value}
            onChange={onChange}
            style={{
              fontSize:"14px"
            }}
          >
            {/* <option className="border-gray-400 relative" selected>
              {Placeholder}
            </option> */}
            {optionData.map((field, index) => (
              <option key={index} value={field.name} >{field.name}</option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default CustomSelecField;

