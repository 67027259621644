import React from "react";

const UserPlacement = () => {
  return (
    <>
    
      <div className="flex item-center justify-center">
        {/* <table className="rounded-t-lg overflow-hidden">
          <thead className="text-xs text-gray-700  dark:bg-gray-700 bg-custom-gray dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-2">
                Candidate
              </th>
              <th scope="col" class="px-6 py-2">
                Role
              </th>
              <th scope="col" class="px-6 py-2">
                Joining Date
              </th>
              <th scope="col" class="px-6 py-2">
                End Date
              </th>
              <th scope="col" class="px-6 py-2">
                Margin / Billing
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600 text-center">
              <td className=" text-[14px] text-[#383838] font-medium  whitespace-nowrap dark:text-white" >Deepak Singh</td>
              <td class="text-[13px] px-4 text-[#383838]   py-4">Supplier</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">10 January 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4">31 February 2024</td>
              <td class="text-[13px] px-4 text-[#383838]  py-4 ml-4">HCL Technology Pvt. Ltd.</td>
            </tr>
          </tbody>
        </table> */}

        <h2> No Data Found </h2>
      </div>
    </>
  );
};

export default UserPlacement;
