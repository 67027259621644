import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { HiDotsVertical } from "react-icons/hi";

const stages = [
  { name: "Leads", amount: 5000, color: "#FFCCC1", textColor: "text-red-600" },
  { name: "Contacted", amount: 15000, color: "#9BD8E8", textColor: "text-blue-600" },
  { name: "Qualified", amount: 4500, color: "#EFD489", textColor: "text-yellow-600" },
  { name: "Proposal", amount: 5000, color: "#FFCFF4", textColor: "text-pink-600" },
  { name: "Won", amount: 65000, color: "#9FDE98", textColor: "text-green-600" },
  { name: "Lost", amount: 5000, color: "#FFA5A5", textColor: "text-red-600" },
  { name: "Hold", amount: 5000, color: "#D3CCF1", textColor: "text-purple-600" },
];

const CardViews = ({ rowData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [dataToMove, setDataToMove] = useState(null);
  const [updatedRowData, setUpdatedRowData] = useState([]);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

  useEffect(() => {
    const initializedData = rowData.map((item) => ({
      ...item,
      status: "Leads", // Default status can be set here
    }));
    setUpdatedRowData(initializedData);
  }, [rowData]);

  const handleDropdownClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setDataToMove(data); // Set data of the card you want to change status
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedStatus(null);
    //setStatusDropdownOpen(false);
  };

  const handleChangeStatus = (newStatus) => {
    if (dataToMove) {
      setUpdatedRowData((prevData) =>
        prevData.map((item) =>
          item._id === dataToMove._id ? { ...item, status: newStatus } : item
        )
      );
    }
    handleMenuClose();
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "Change Status":
        setStatusDropdownOpen(true);
        break;
      case "View Details":
        console.log(`Viewing details for: ${dataToMove.name}`);
        break;
      case "Send Mail":
        console.log(`Sending mail to: ${dataToMove.name}`);
        break;
      case "Delete":
        setUpdatedRowData((prevData) =>
          prevData.filter((item) => item.id !== dataToMove.id)
        );
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  return (
    <>
      <TableContainer sx={{ width: "100%", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {stages.map((stage, index) => (
                <TableCell key={index} sx={{ borderBottom: "none" }}>
                  <Card
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: stage.color,
                      width: "130px",
                      height: "80px",
                      minWidth: "8rem",
                      borderRadius: "0px",
                      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <p style={{ fontSize: "larger", fontWeight: "500" }}>{stage.name}</p>
                      <p style={{ fontWeight: "500", color: "gray" }}>${stage.amount}</p>
                    </CardContent>
                  </Card>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {stages.map((item) => (
                <TableCell key={item.name} sx={{ borderBottom: "none" }}>
                  {updatedRowData &&
                    updatedRowData.map((data) =>
                      data.status === item.name ? (
                        <Card
                          key={data.id}
                          sx={{
                            backgroundColor: item.color,
                            width: "130px",
                            height: "80px",
                            minWidth: "8rem",
                            marginBottom: "12px",
                          }}
                        >
                          <CardContent
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingBottom: "0px",
                              padding: "0px",
                            }}
                          >
                            <div>
                              <p style={{ fontSize: "12px", fontWeight: "500" }}>{data.name}</p>
                            </div>
                            <div style={{ justifyContent: "flex-end" }}>
                              <IconButton onClick={(e) => handleDropdownClick(e, data)}>
                                <HiDotsVertical size={15} />
                              </IconButton>
                            </div>
                          </CardContent>
                        </Card>
                      ) : null
                    )}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleMenuItemClick("Change Status")}>Change Status</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("View Details")}>View Details</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Send Mail")}>Send Mail</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Delete")}>Delete</MenuItem>
        </Menu>

        {statusDropdownOpen && (
          <div style={{ position: 'absolute', zIndex: 1, backgroundColor: 'white', padding: '10px' }}>
            <FormControl fullWidth>
              <InputLabel>Select Stage</InputLabel>
              <Select
                onChange={(e) => handleChangeStatus(e.target.value)}
                defaultValue=""
              >
                {stages.map((stage) => (
                  <MenuItem key={stage.name} value={stage.name}>
                    {stage.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </TableContainer>
    </>
  );
};

export default CardViews;
