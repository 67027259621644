import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Message from "../../assets/images/message.svg";
import Restart from "../../assets/images/restart.svg";
import Delete from "../../assets/images/delete.svg";
import Edit from "../../assets/images/edit.svg";
import Document from "../../assets/images/document.svg";
import Swal from "sweetalert2";
import { DeleteLeads, LeadData, getLeadDataId } from "../../stores/Reducers/newleads";
import { useDispatch } from "react-redux";

const ColumnView = ({
  filterColumnData,
  setFilterColumnData,
  rowData,
  setRowData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passData, setPassData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columnData = useMemo(() => {
    return [
      { field: "name", headerName: "Name", visible: true },
      { field: "company", headerName: "Company", visible: true },
      {
        field: "amount",
        headerName: "Amount",
        visible: true,
        valueGetter: (params) => {
          const budgetCurrency = params.data.budget_currency || "";
          const budgetAmount = params.data.budget_amount || "";
          return `${budgetCurrency} ${budgetAmount}`.trim();
        },
      },
      { field: "actions", headerName: "Action", visible: true },
    ];
  }, []);

  useEffect(() => {
    if (rowData) {
      setFilterColumnData(columnData);
    }
  }, [rowData]);

  const onRowClicked = (data) => {
    setModalOpen(true);
    setPassData(data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = async (row) => {
    let id = row?._id;
    await dispatch(getLeadDataId({ id })).then((res) => console.log(res)).catch((err) => console.log(err));
    navigate("/dashboard/editleadsFrom", {
      state: { from: "/dashboard/leadsData", id },
    });
    console.log("Edit:", row);
  };

  const handleDelete = (row) => {
    let id = row?._id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Call your API to delete the lead here
        await dispatch(DeleteLeads({ id }))
          .then((res) => console.log(res))
          .catch((resp) => console.log(resp));
        await dispatch(LeadData());
        console.log("Delete confirmed:", row);
        // Optionally, remove the row from your local state or dispatch a delete action here
      }
    });
  };

  const handleDocument = (row) => {
    navigate();
    console.log("Document:", row);
  };

  const handleRestart = (row) => {
    console.log("Restart:", row);
  };

  return (
    <div style={{}}>
      <TableContainer >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#c2c2c2",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Select
              </TableCell>
              {filterColumnData.map((col) => (
                <TableCell
                  key={col.field}
                  sx={{
                    backgroundColor: "#c2c2c2",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {col.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} onClick={() => onRowClicked(row)}>
                  <TableCell>
                    <input type="checkbox" />
                  </TableCell>
                  {filterColumnData.map((col) => (
                    <TableCell key={col.field}>
                      {col.field === "amount" ? (
                        `${row.budget_currency || ""} ${row.budget_amount || ""
                        }`
                      ) : col.field === "actions" ? (
                        <>
                          <IconButton onClick={() => handleDocument(row)}>
                            <img
                              src={Document}
                              alt="document"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </IconButton>
                          <IconButton onClick={() => handleEdit(row)}>
                            <img
                              src={Edit}
                              alt="edit"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </IconButton>
                          <IconButton>
                            <img
                              src={Message}
                              alt="message"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </IconButton>
                          <IconButton onClick={() => handleRestart(row)}>
                            <img
                              src={Restart}
                              alt="restart"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </IconButton>
                          <IconButton onClick={() => handleDelete(row)}>
                            <img
                              src={Delete}
                              alt="delete"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </IconButton>
                        </>
                      ) : (
                        row[col.field]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

export default ColumnView;
