import React, { useState, useRef, useEffect } from "react";
import { IoIosSearch } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Header = ({ companyName, searchBar, profile }) => {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  const userName = sessionStorage.getItem('user');

  let UserData = "";
  if (userName) {
    try {
      UserData = JSON.parse(userName); 
    } catch (error) {
      console.error("Error parsing user data:", error);
      UserData = ""; 
    }
  } else {
    UserData = ""; 
  }



  const handleHome = () => {
    navigate("/dashboard");
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close the dropdown when clicking outside
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleLogout = () => {
    // await dispatch(logout());
    sessionStorage.removeItem('auth');
    sessionStorage.clear();
    window.location.href = "/";
    window.history.replaceState(null, '', "/");
    window.onpopstate = () => {
      if (!sessionStorage.getItem('auth')) {
        window.history.replaceState(null, '', "/");
      }
    };
    toast.success("sucessfully logout");

  };
  return (
    <>
      <header
        className={`sticky top-0 z-50 flex items-center justify-between p-5 bg-current ${darkMode ? "bg-gray-800 text-white" : "bg-gray-100 text-black"
          } transition-colors duration-300 ease-in-out`}
        style={{
          backgroundColor: "#026AA2",
          justifyContent: "space-between"
        }}
      >
        <div className="flex  flex-[0.1] items-center cursor-pointer" onClick={handleHome}>
          <h1 className="text-xl font-bold cursor-pointer">
            {companyName ? companyName : "RUTHOX"}
          </h1>
        </div>
        <div className="flex-[0.7] flex items-center justify-center">
          <div className="relative w-[50%] ">
            <input
              type="text"
              placeholder="Search here"
              className="w-full  focus:outline-none  bg-[#026AA2]"
              style={{
                color: "white",
                borderBottomWidth: "1px"
              }}
            />
            <span className="absolute inset-y-0 right-0 flex items-center ">
              <IoIosSearch size="24" color="white" />
            </span>
          </div>

        </div>
        <div className="flex items-center flex-[0.1] gap-[5px]">
          <div className="flex items-center "
            style={{
              borderRight: "ridge",
            }}
          >
            <IoNotifications size="24" color="white" />
            <CiCirclePlus size="30" color="white" />
          </div>

          <div className="flex flex-[2.2] items-center" style={{
            border: "1px solid white",
            marginLeft: "2px",
            height: "2rem",
            borderRadius: "10px",
            fontSize:"13px",
            width: "100%",
            paddingLeft: "5px",
            cursor: "pointer"

          }}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >

            {/* <span className="font-medium"> */}
            {UserData && UserData || ""}
            {/* </span> */}
          </div>
        </div>


      </header>
      {isDropdownOpen && (
        <div
          //ref={dropdownRef}
          className="absolute right-2 mt-20 w-48 bg-white text-black border border-gray-300 rounded-lg shadow-md"
        >
          <ul className="space-y-2 p-2">
            {/* <li className="cursor-pointer px-4 py-2 hover:bg-gray-100" onClick={() => navigate("/profile")}>Profile</li>
            <li className="cursor-pointer px-4 py-2 hover:bg-gray-100" onClick={() => navigate("/settings")}>Settings</li> */}
            <li className="cursor-pointer px-4 py-2 hover:bg-gray-100" onClick={handleLogout}>Logout</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
