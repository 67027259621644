export const ContactColumn = [
  { field: "_id", headerName: "ID", visible: false },
  { field: "full_name", headerName: "Name", visible: false },
  { field: "role", headerName: "Role", visible: false },
  { field: "mobile", headerName: "Mobile_No.", visible: false },
  { field: "email", headerName: "E_mail_ID", visible: false },
  { field: "company", headerName: "Company Name", visible: false },
  { field: "status", headerName: "Status", visible: false },
  { field: "state", headerName: "state", visible: false },
  { field: "country", headerName: "Country", visible: false },
  { field: "createdAt", headerName: "Create", visible: false },
  { field: "city", headerName: "City", visible: false },
  { field: "updatedAt", headerName: "UpDate", visible: false },
  { field: "zipcode", headerName: "Zip code", visible: false },
];

export const AccountColumn = [
  { field: "_id", headerName: "ID", visible: false },
  { field: "company_name", headerName: "Company Name", visible: false },
  { field: "industry", headerName: "Industry", visible: false },
  { field: "official_number", headerName: "Number", visible: false },
  { field: "email", headerName: "E_mail_ID", visible: false },
  { field: "company_headcount", headerName: "Head Count", visible: false },
  { field: "state", headerName: "state", visible: false },
  { field: "country", headerName: "Country", visible: false },
  { field: "createdAt", headerName: "Create", visible: false },
  { field: "city", headerName: "City", visible: false },
  { field: "updatedAt", headerName: "UpDate", visible: false },
  { field: "zipcode", headerName: "Zip code", visible: false },
];

export const CandiDateColumn = [
  { field: "_id", headerName: "ID", visible: false },
  { field: "full_name", headerName: "Name", visible: false },
  { field: "job_title", headerName: "Job Title", visible: false },
  {
    field: "candidate_type",
    headerName: "CandiDate Type",
    visible: false,
  },
  { field: "mobile", headerName: "Mobile_No.", visible: false },
  { field: "email", headerName: "E_mail_ID", visible: false },

  { field: "status", headerName: "Status", visible: false },
  { field: "state", headerName: "state", visible: false },
  { field: "country", headerName: "Country", visible: false },
  { field: "createdAt", headerName: "Create", visible: false },
  { field: "city", headerName: "City", visible: false },
  { field: "updatedAt", headerName: "UpDate", visible: false },
  { field: "zipcode", headerName: "Zip code", visible: false },
];
