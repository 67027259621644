import React from "react";


const InvoicePreview = () => {
  return (
    <>      
      <div className="min-h-screen bg-gray-100 w-full max-w-xl py-6 px-6">
        {/* Preview Header */}
        <div className="flex flex-col md:flex-row justify-between mb-4">
          <div className="text-lg font-bold mb-4 md:mb-0">Preview</div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-x-2 md:space-y-0 items-center justify-center lg:pl-16">
            <div className="flex items-center justify-center w-full md:w-28 text-sm bg-white rounded-md border-[#3D5B81] border">PDF</div>
            <div className="flex items-center justify-center w-full md:w-28 text-sm bg-white rounded-md border-[#3D5B81] border">E-mail</div>
            <div className="flex items-center justify-center w-full md:w-32 text-sm bg-white rounded-md border-[#3D5B81] border">Payment Page</div>
          </div>
        </div>

        {/* Invoice Preview */}
        <div className="bg-white p-4 shadow-lg rounded-lg w-full max-w-2xl">
          <div className="py-8">
            {/* Invoice Number */}
            <div className="border-b border-gray-300 pb-4 text-gray-600">INV-123456/09</div>

            {/* From and To Info */}
            <div className="flex flex-col sm:flex-row justify-between text-sm mt-6">
              <div className="mb-4 sm:mb-0">
                <strong>To:</strong><br />
                HCL Technology<br />
                Shoaib<br />
                A-34, Sector-63, Noida<br />
                Tel: 0000-000-00<br />
                E: adc@abc.com
              </div>
              <div>
                <strong>From:</strong><br />
                Ruthox Pvt. Ltd.<br />
                Accounts Team<br />
                H-25, Sector-63, Noida<br />
                Tel: 1111-111-11<br />
                E: xyz@xyz.com
              </div>
            </div>

            {/* Date Information */}
            <div className="mt-4 border-b border-gray-500">
              <strong>Date:</strong> 10 Sep 2024<br />
              <strong className="text-red-600">Due Date:</strong> 30 Sep 2024
            </div>

            {/* Items Table */}
            <div className="rounded-lg overflow-x-auto">
              <table className="w-full border border-gray-400 mt-8 text-left rounded-lg">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="font-medium p-2">Item</th>
                    <th className="font-medium p-2">Qty</th>
                    <th className="font-medium p-2">Tax%</th>
                    <th className="font-medium p-2">per pc. price</th>
                    <th className="font-medium p-2">Sub Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="flex items-center">
                        <img src="https://via.placeholder.com/50" alt="" className="w-10 h-10" />
                        <div className="flex flex-col ml-2">
                          <span className=" text-sm">123456</span>
                          <span className=" text-sm">Black T-Shirt, Half</span>
                          <span className=" text-sm">Levis Company</span>
                        </div>
                      </div>
                    </td>
                    <td>10</td>
                    <td>2%</td>
                    <td>₹250000</td>
                    <td>₹255000.00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Totals */}
            <div className="text-right border-b border-gray-500 mt-4">
              <div>Subtotal: ₹2550000</div>
              <div>Discount: 10%</div>
              <div className="font-bold">Total: ₹2295000</div>
            </div>

            {/* File Attachment */}
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Attachment</label>
              <input type="file" className="w-full sm:w-1/2 border border-gray-400 rounded-md" />
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default InvoicePreview;
