import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3500";



export const createCandiDate = createAsyncThunk(
  'create/CandiDate',
  async ({ newData }, { rejectWithValue }) => {
    try {

      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.post(`${apiUrl}/api/createCandidate`, newData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data?.data;
    } catch (error) {

      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const CandiDateSubmission = createAsyncThunk(
  'submission/CandiDateSubmission',
  async ({ newData }, { rejectWithValue }) => {
    try {

      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.post(`${apiUrl}/api/createJobSubmission`, newData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data?.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const candidateSubmissionData = createAsyncThunk(
  'submissionData/CandiDateSubmissionData',
  async ({ jobId }, { rejectWithValue }) => {
    try {

      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.get(`${apiUrl}/api/getJobSubmission?job_id=${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data?.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const getCandiDateData = createAsyncThunk(
  'candidates/getCandiDateData',
  async (_, { rejectWithValue }) => {
    try {
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.get(`${apiUrl}/api/getCandidates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data) {
        return response?.data;
      }
      return response?.data?.data;
    } catch (error) {
      toast.error("Please add all required field");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


const newCandiDate = createSlice({
  name: 'candiDateData',
  initialState: {
    loading: false,
    message: "",
    getNewData: [],
    CandiDateData: [],
   candidateSubmissionResponse:[]

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCandiDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCandiDate.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Sucessfully Post";
        state.getNewData = action.payload

      })
      .addCase(createCandiDate.rejected, (state, action) => {
        state.loading = false;
        state.message = "you have wrongCredatins filled that";
        state.error = action.payload;
      })
      .addCase(getCandiDateData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCandiDateData.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Sucessfully Post";
        state.CandiDateData = action?.payload?.data

      })
      .addCase(getCandiDateData.rejected, (state, action) => {
        state.loading = false;
        state.message = "you have wrongCredatins filled that";
        state.error = action.payload;
      })
      .addCase(CandiDateSubmission.pending, (state) => {
        state.loading = true;
      })
      .addCase(CandiDateSubmission.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Sucessfully Update";
        // state.CandiDateData = action?.payload?.data

      })
      .addCase(CandiDateSubmission.rejected, (state, action) => {
        state.loading = false;
        state.message = "you have wrongCredatins filled that";
        state.error = action.payload;
      })
      .addCase(candidateSubmissionData.pending, (state) => {
        state.loading = true;
      })
      .addCase(candidateSubmissionData.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Sucessfully Update";
        // state.CandiDateData = action?.payload?.data
        state.candidateSubmissionResponse = action?.payload

      })
      .addCase(candidateSubmissionData.rejected, (state, action) => {
        state.loading = false;
        state.message = "you have wrongCredatins filled that";
        state.error = action.payload;
      })

  },
});


export default newCandiDate.reducer;