import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3500";


const tokenData = sessionStorage.getItem('auth');

const token = JSON.parse(tokenData);



export const JobStatusDropDown =  createAsyncThunk(
    'jobStatus/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobStatusList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const JobTypeDropDown =  createAsyncThunk(
    'jobType/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobTypeList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const JobDocumentDropDown =  createAsyncThunk(
    'jobDocument/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobDocumentList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const JobPriorityDropDown =  createAsyncThunk(
    'jobPriority/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobPriorityList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const Job_Work_DropDown =  createAsyncThunk(
    'Job_Work_DropDown/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobWorkAuthList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const JobNoticeDropDown =  createAsyncThunk(
    'jobWork/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobNoticePeriodList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const JobDegreeDropDown =  createAsyncThunk(
    'jobDegree/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobDegreeList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const JobLanguageDropDown =  createAsyncThunk(
    'jobLanguage/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobLanguageList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const JobTaxTermDropDown =  createAsyncThunk(
    'jobTaxTerm/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobTaxTermList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)
export const Job_User_DropDown =  createAsyncThunk(
    'Job_User_DropDown/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getUsersList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)
export const Job_Client_DropDown =  createAsyncThunk(
    'Job_Client_DropDown/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getClientsList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const Job_Code_DropDown =  createAsyncThunk(
    'Job_Code_DropDown/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getJobCode`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

const jobDropDownReducers = createSlice({
    name: 'JobDropDown',
    initialState: {
        user: '',
        loading: false,
        jobStatusDropDown:[],
        jobTypeDropDown:[],
        jobDocumentDropDown:[],
        jobPriorityDropDown:[],
        jobWorkDropDown:[],
        jobNoticeDropDown:[],
        jobDegreeDropDown:[],
        jobLanguageDropDown:[],
        jobTaxTermDropDown:[],
        jobUserDropDown:[],
        jobClientDropDown:[],
        jobCodeDropDown:[],
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(JobStatusDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(JobStatusDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobStatusDropDown = action?.payload?.job_status_list;
            })
            .addCase(JobStatusDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(JobTypeDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(JobTypeDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobTypeDropDown = action?.payload?.job_type_list;
            })
            .addCase(JobTypeDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(JobDocumentDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(JobDocumentDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobDocumentDropDown = action?.payload?.job_document_list;
            })
            .addCase(JobDocumentDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(JobPriorityDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(JobPriorityDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobPriorityDropDown = action?.payload?.job_priority_list;
            })
            .addCase(JobPriorityDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(Job_Work_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(Job_Work_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobWorkDropDown = action?.payload?.job_workAuth_list;
            })
            .addCase(Job_Work_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(JobNoticeDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(JobNoticeDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobNoticeDropDown = action?.payload?.job_noticePeriod_list;
            })
            .addCase(JobNoticeDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(JobDegreeDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(JobDegreeDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobDegreeDropDown = action?.payload?.job_degree_list;
            })
            .addCase(JobDegreeDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(JobLanguageDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(JobLanguageDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobLanguageDropDown = action?.payload?.job_language_list;
            })
            .addCase(JobLanguageDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(JobTaxTermDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(JobTaxTermDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobTaxTermDropDown = action?.payload?.job_taxTerm_list;
            })
            .addCase(JobTaxTermDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(Job_User_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(Job_User_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobUserDropDown = action?.payload?.users_list;
            })
            .addCase(Job_User_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(Job_Client_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(Job_Client_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobClientDropDown = action?.payload?.clients_list;
            })
            .addCase(Job_Client_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(Job_Code_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(Job_Code_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.jobCodeDropDown = action?.payload?.new_job_code;
            })
            .addCase(Job_Code_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            
            



    },
});

export default jobDropDownReducers.reducer;

