import React from 'react';
import { IoIosMail } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdOutlinePassword } from "react-icons/md";
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { PiBuildingsBold } from "react-icons/pi";
import { TiSocialFacebook } from "react-icons/ti";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { RiLinkedinFill } from "react-icons/ri";
import { SlSocialYoutube } from "react-icons/sl";
import { PiGenderMaleBold } from "react-icons/pi";
import { MdLocationCity } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
import { TbPasswordUser } from "react-icons/tb";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";
import { FaPlusCircle } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { BsBuildings } from "react-icons/bs";
import { IoEarth } from "react-icons/io5";
import { FaUsers } from "react-icons/fa6";
import { TbCirclesRelation } from "react-icons/tb";
import { FaUserGear } from "react-icons/fa6";
import { TiPlus } from "react-icons/ti";
import { TbDownload } from "react-icons/tb";
import { TbUpload } from "react-icons/tb";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { IoMdPerson } from "react-icons/io";
import { MdLocalPhone } from "react-icons/md";
import { GiRotaryPhone } from "react-icons/gi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { FaCircleCheck } from "react-icons/fa6";
import { IoDocumentText } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaDropbox } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { FaLink } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { CgScreen } from "react-icons/cg";
import { BsCloudsFill } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import { GrStatusGood } from "react-icons/gr";
import { IoFilter } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { RiShareCircleLine } from "react-icons/ri";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import { LuChevronDown } from "react-icons/lu";
import { IoCloseSharp } from "react-icons/io5";
import { BiSolidPhone } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { GoPlus } from "react-icons/go";



const iconMap = {
    IoIosMail, FaArrowRightLong, RiLockPasswordFill, MdOutlinePassword, FcGoogle, BsLinkedin, FaEyeSlash,IoFilter,
    IoEyeSharp, BsPersonFill, MdOutlinePhoneIphone, PiBuildingsBold, TiSocialFacebook, AiOutlineInstagram,FaEdit,
    FaXTwitter, RiLinkedinFill, SlSocialYoutube, PiGenderMaleBold, TbPasswordUser, MdLocationCity, GoArrowLeft,
    RiDoubleQuotesL, RiDoubleQuotesR, FaPlusCircle, FaMinusCircle, CiLocationOn, BsBuildings, IoEarth, TbCirclesRelation,
    FaUsers, FaUserGear, TiPlus, TbDownload, TbUpload, MdArrowBackIosNew, MdArrowForwardIos, RxCrossCircled, IoMdPerson, MdLocalPhone,
    GiRotaryPhone, BsFillPersonLinesFill, FaCircleCheck, IoDocumentText, IoMdArrowDropdown, FaDropbox, IoSearch, FaLink, FaBell, RiArrowDropDownLine,
    FaEdit,FaClipboardUser,GrStatusGood, CgScreen, BsCloudsFill, IoCloseCircle, FaRegEdit, FaClipboardUser,GrStatusGood, GrStatusGood, MdDelete, RiShareCircleLine,
    IoChevronUpSharp, IoChevronDownSharp, LuChevronDown,IoCloseSharp, BiSolidPhone, MdEmail , GoPlus
};

const CustomIcon = ({ name, color, size, onClick, className, style }) => {
    const IconComponent = iconMap[name];

    if (!IconComponent) {
        console.error(`Icon "${name}" not found`);
        return null;
    }
    const responsiveSize = size || 'text-lg';
    return (
        <IconComponent 
            onClick={onClick} 
            className={`${responsiveSize} ${className}`} 
            style={{ color, fontSize: size, ...style }} 
        />
    );
};

export default CustomIcon;