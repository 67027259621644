import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import JobHeader from "./JobHeader";
import JobTable from "./JobTable";
import  {Joblist} from "../../stores/Reducers/newJob";

const JobData = () => {
  const [filterOpen, setFilterOpen] = useState(false)
 const [rowData, setRowData] = useState([]);
 const [filterColumnData, setFilterColumnData ] = useState([]);

  

 const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Joblist())
  }, [dispatch]);

  const {jobDatalist} = useSelector((state) =>  state?.joblist)


  useEffect(() =>{
   if(jobDatalist){
    setRowData(jobDatalist);
   }
  },[jobDatalist])
  
 const closeFilter = () =>{
    setFilterOpen(false);
 }

 const openFilter = () =>{
    setFilterOpen(true);
 }

 

  return (
    <>
      
      <div
        className="min-w-full px-4 py-6 bg-[#F2F3F3] border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700"
        style={{ height: "86.5vh" }}
      >
        <JobHeader title={"Job"} isOpen={filterOpen} closeFilter={closeFilter} openFilter={openFilter} setFilterColumnData={setFilterColumnData} />
        <div className="mt-4">
            <JobTable rowData={rowData}  filterColumnData={filterColumnData} setFilterColumnData={setFilterColumnData}  />
        </div>
        
      </div>
    </>
  );
};

export default JobData;
