import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { remove_LocalStorage } from '../../Modules/formData';


import { toast } from "react-toastify";


const apiUrl = process.env.REACT_APP_API_URL;



export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiUrl}/api/auth/login`, { email, password });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendotp = createAsyncThunk(
  'auth/sendotp',
  async ({ email }, { rejectWithValue }) => {
    try {

      const response = await axios.post(`${apiUrl}/api/auth/send_otp`, { email });
      // if(response && response.status ===  200){

      // }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createpassword = createAsyncThunk(
  'auth/createpassword',
  async ({ newPassword, confirmPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/auth/update_password', { newPassword, confirmPassword });
      return response.data;

    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const signup = createAsyncThunk(
  'auth/signup',
  async ({ full_name, mobile, email, gender, company_name, industry, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiUrl}/api/auth/signup`, { full_name, mobile, email, gender, company_name, industry, password });
      if (response?.data?.token) {
        const token = response?.data?.token
        sessionStorage.setItem('auth', JSON.stringify(token));
        window.location.href = "/dashboard";
        toast.success("Login Successful");
        return response.data;
      }
      else {
        toast.error(response?.data?.message)
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userId: null,
    user: null,
    userName: null,
    loading: false,
    authenticate: false,
    OtpData: null,
    verfiedOtp: false,
    createpassword: false,
    error: '',
    token: null,
    OtpData: [],
    message: null
  },
  reducers: {
    logout(state) {
      state.user = null;
      state.authenticate = false;
      state.token = null;
      state.message = "sucessfully logout";
      sessionStorage.removeItem('auth');
      sessionStorage.clear();
      remove_LocalStorage();
      window.location.href = "/";
      toast.success("sucessfully logout")
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.authenticate = true;
        state.userName = action?.payload?.full_name;
        const token = action?.payload?.token;
        if (token) {
          state.authenticate = true;
          state.token = token;
          state.userName = action?.payload?.full_name;
          const decoded = jwt_decode(token);
          state.userId = decoded?.id;
          console.log("Login Successful, Token:", token, "Decoded ID:", state.userId);
        } else {
          console.error("Token missing in response:", action.payload);
          state.error = "Login failed, no token received.";
          toast.error("Login failed, please try again.");
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
        state.authenticate = true;
        state.token = action?.payload?.token;
        const token = action?.payload?.token;
        const decode = jwt_decode(token);
        const id = decode._id;
        console.log("ID", id);
        state.userId = id;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sendotp.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.verfiedOtp = false;
      })
      .addCase(sendotp.fulfilled, (state, action) => {
        state.loading = false;
        state.OtpData = action.payload;
        state.verfiedOtp = true;
        state.createpassword = true;
      })
      .addCase(sendotp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error sending OTP";
        state.verfiedOtp = false;
        state.OtpData = null;
      })

      .addCase(createpassword.fulfilled, (state, action) => {
        state.user = action.payload;
        state.create = true
        state.loading = false;
      })
      .addCase(createpassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(createpassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
