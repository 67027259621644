import React from 'react'

const DashboardPageData = () => {
  return (
    <div>
      <p>Dashboard Page</p>
    </div>
  )
}

export default DashboardPageData
