import React, { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ModalUserDetails from "../ModalUserDetails";
import moment from "moment";
import TotalSubmissionData from "./TotalSubmissionData";

const JobTable = ({ rowData, filterColumnData, setFilterColumnData }) => {
  const [passData, setPassData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(rowData);
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [submissionData, setSubmissionData] = useState();

  useEffect(() => {
    if (rowData) {
      setFilterColumnData([
        { field: "business_unit", headerName: "Business Name", visible: true, filter: true },
        { field: "total_submissions", headerName: "Total Submissions", visible: true, filter: false },
        { field: "job_code", headerName: "Job Code", visible: true, filter: true },
        { field: "job_title", headerName: "Job Title", visible: true, filter: true, },
        { field: "job_type", headerName: "Job Type", visible: true, filter: true, },
        { field: "job_status", headerName: "Job Status", visible: true, filter: true },
        { field: "notice_period", headerName: "Notice Period", visible: true, filter: true },
        { field: "number_of_positions", headerName: "Opening", visible: true, filter: true },
        {
          field: "max_allowed_submissions",
          headerName: "Max Allowed Submission",
          visible: true,
        },
        { field: "priority", headerName: "Priority", visible: true },
        { field: "assigned_to", headerName: "Assigned To", visible: true },
        { field: "city", headerName: "City", visible: true, filter: true },
        { field: "state", headerName: "States", visible: true, filter: true },
        { field: "country", headerName: "Country", visible: true, filter: true },
      ]);
    }
  }, [rowData]);

  useEffect(() => {
    if (searchText.trim() === "") {
      setFilteredData(rowData);
    } else {
      const lowerCaseSearchText = searchText.toLowerCase();
      setFilteredData(
        rowData.filter((item) =>
          Object.values(item)
            .join(" ")
            .toLowerCase()
            .includes(lowerCaseSearchText)
        )
      );
    }
  }, [searchText, rowData]);

  const onRowClicked = (event) => {
    setModalOpen(true);
    setPassData(event?.data);
  };

  console.log(submissionData, "submissionData", checkboxStatus);

  const columnDefs = useMemo(() => {
    const checkboxColumn = {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      headerClass: "bg-[#3C9DD1] text-black",
    };

    const visibleColumns = filterColumnData
      .filter((col) => col.visible)
      .map((col) => {
        if (col.field === "total_submissions") {
          return {
            ...col,
            headerClass: "bg-[#3C9DD1]",
            cellRenderer: (params) => (
              <div
                onClick={(event) => {
                  event.stopPropagation(); // Prevent row click event
                  setSubmissionData(params.data);
                  setSubmissionModal(true);
                  setModalOpen(false);
                }}
                style={{
                  cursor: "pointer",
                  color: "#007bff",
                  textDecoration: "underline",
                }}
              >
                {params.value}
              </div>
            ),
          };
        }
        if (col.field === "business_unit") {
          return {
            ...col,
            headerClass: "bg-[#3C9DD1]",
            cellRenderer: (params) => (
              <div
                onClick={(event) => {
                  event.stopPropagation(); // Prevent row click event
                  onRowClicked(params);
                }}
                style={{
                  cursor: "pointer",
                  // color: "#007bff",
                  // textDecoration: "underline",
                }}
              >
                {params.value}
              </div>
            ),
          };
        }

        return {
          field: col?.field,
          headerName: col?.headerName,
          filter: col?.filter,
          width: 150,
          headerClass: "bg-[#3C9DD1]",
          tooltipField: col?.field,
          cellRenderer: (params) => {
            if (col.field === "official_number" && Array.isArray(params.value)) {
              return (
                <ul>
                  {params.value.map((item, index) => (
                    <li key={index}>{item.number}</li>
                  ))}
                </ul>
              );
            }
            if (col.field === "createdAt" || col.field === "updatedAt") {
              return moment(params.value).format("DD-MMM-YYYY");
            }
            if (col?.field === "job_status") {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    borderRadius: "20px",
                    height: "2rem",
                    backgroundColor:
                      params.value?.toLowerCase() === "pending"
                        ? "yellow"
                        : params.value?.toLowerCase() === "active"
                          ? "green"
                          : "red",
                  }}
                >
                  <span>{params?.value}</span>
                </div>
              );
            }
            return params.value;
          },
          valueFormatter:
            col.field === "createdAt" || col.field === "updatedAt"
              ? (params) => moment(params.value).format("DD-MMM-YYYY")
              : undefined,
        };
      });

    return [checkboxColumn, ...visibleColumns];
  }, [filterColumnData]);



  const gridOptions = useMemo(
    () => ({
      rowDragManaged: true,
      animateRows: true,
    }),
    []
  );

  const modalClose = () => {
    setModalOpen(false);
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: false,
      floatingFilter: false,
      enableRowGroup: true,
    };
  }, []);

  const selection = useMemo(() => {
    return { mode: "multiRow" };
  }, []);

  const handleCloseSubmission = () => {
    setSubmissionModal(false);
  }

  return (
    <>
      <div
        className="flex justify-end mb-4"
        style={{ width: "100%" }}
      >
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="border p-2"
          style={{
            borderRadius: "9px",
            width: "30%",

          }}
        />
      </div>
      <div
        className="ag-theme-quartz"
        style={{ height: "65vh", width: "100%" }}
      >
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          gridOptions={{
            ...gridOptions,
            suppressHorizontalScroll: true,
          }}
          defaultColDef={defaultColDef}
          rowDragManaged={true}
          rowDragEntireRow={true}
          rowDragMultiRow={true}
          selection={selection}
          //onRowClicked={onRowClicked}
          pagination={true}
        />
      </div>

      {modalOpen && (
        <ModalUserDetails
          isVisible={modalOpen}
          onClose={modalClose}
          data={passData}
          title={"Job"}
        />
      )}
      {
        submissionModal && (
          <TotalSubmissionData
            open={submissionData}
            onClose={handleCloseSubmission}
            submissionData={submissionData}

          />
        )
      }
    </>
  );
};

export default JobTable;
