import React, { useState, useEffect, useMemo } from "react";
import CustomInput from "./CustomInput";
import CutomButton from "./CutomButton";
import CustomIcon from "./CustomIcon";
import { createAccount } from "../stores/Reducers/newAccount";
import SingleSearchableSelect from "./SingleSearchableSelect";
import CustomSelect from "./CustomSelect";
import { useSelector, useDispatch } from "react-redux";
import {
    state_DropDown,
    city_DropDown,
} from "../stores/Reducers/dropDownApiReducers";

import { Job_Client_DropDown } from "../stores/Reducers/jobDropDownReducer";

import { Modal, Box, Typography, IconButton } from "@mui/material";
import CustomAddSelect from "./CustomAddSelect";


const AddNewJobClient = ({ isModalOpen, close, label, onClick, icon }) => {
    const dispatch = useDispatch();

    const [addField, setAddField] = useState("");

    const [countryName, setCountryName] = useState("United States");
    const [statesName, setStatesName] = useState("Alabama");
    const [emailError, setEmailError] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [cityName, setCityName] = useState("");

    const [uiData, setUiData] = useState([
        { id: "1", name: "Company Name", value: "", type: "text", error: "", required: "true" },
        { id: "2", name: "Email ID", value: "", type: "text", error: "", required: "false" },
        { id: "3", name: "Website", value: "", type: "text", error: "", required: "true" },
        { id: "4", name: "Status", value: "", type: "dropDown", error: "", required: "false" },
        { id: "5", name: "Country", value: "", type: "dropDown", error: "", required: "true" },
        { id: "6", name: "State", value: "", type: "dropDown", error: "", required: "false" },
        { id: "7", name: "City", value: "", type: "dropDown", error: "", required: "false" },
        { id: "8", name: "Address", value: "", type: "text", error: "", required: "false" },
        { id: "9", name: "Zip Code", value: "", type: "text", error: "", required: "false" },
        { id: "10", name: "Notes", value: "", type: "textArea", error: "", required: "false" }
    ]);

    const {
        CountryDropDown,
        stateDropDown,
        cityDropDown,
        statusDropDown

    } = useSelector((data) => data.dropDown);

    const keyValueData = uiData.reduce((acc, field) => {
        acc[field.name] = field.value; // Set 'name' as key and 'value' as value
        return acc;
    }, {});
    
    const handleSubmit = async () => {
        let isValid = true;


        const updatedUiData = uiData.map((field) => {
            if (field.required === "true" && !field.value) {
                field.error = `${field.name} is required`;
                isValid = false;
            } else {
                field.error = "";
            }
            return field;
        });


        setUiData(updatedUiData);
        if (isValid) {
            console.log("Submitting new option:", addField);
            let newData1 = [];
            newData1.push(keyValueData["Email ID"])
            const newData = {
                company_name: keyValueData["Company Name"],
                email: newData1,
                status: keyValueData["Status"],
                Ownership: keyValueData["Ownership"],
                country: keyValueData["Country"],
                state: keyValueData["State"],
                city: keyValueData["City"],
                address: keyValueData["Address"],
                urls: keyValueData["Website"],
                zipcode: keyValueData["Zip Code"],
                notes: keyValueData["Notes"],
            };
            const Response_data = await dispatch(createAccount({ newData }))
                .then((res) => {
                    return res;
                })
                .catch((err) => console.error(err));

            if (Response_data?.payload?.message === "Success") {
                 dispatch(Job_Client_DropDown());
                close();
                setUiData([
                    { id: "1", name: "Company Name", value: "", type: "text", error: "", required: "true" },
                    { id: "2", name: "Email ID", value: "", type: "text", error: "", required: "false" },
                    { id: "3", name: "Website", value: "", type: "text", error: "", required: "true" },
                    { id: "4", name: "Status", value: "", type: "dropDown", error: "", required: "false" },
                    { id: "5", name: "Country", value: "", type: "dropDown", error: "", required: "true" },
                    { id: "6", name: "State", value: "", type: "dropDown", error: "", required: "false" },
                    { id: "7", name: "City", value: "", type: "dropDown", error: "", required: "false" },
                    { id: "8", name: "Address", value: "", type: "text", error: "", required: "false" },
                    { id: "9", name: "Zip Code", value: "", type: "text", error: "", required: "false" },
                    { id: "10", name: "Notes", value: "", type: "textArea", error: "", required: "false" }
                ]);
                return ;
            }
        } else {
            console.log("Please fill in all required fields.");
        }
    };





    useEffect(() => {
        if (countryName) {
            const country = CountryDropDown.find(
                (item) => item.name === countryName
            );
            if (country) {
                dispatch(state_DropDown({ countryCode: country.isoCode }));
            }
        }
    }, [countryName, dispatch]);

    useEffect(() => {
        if (statesName) {
            const state = stateDropDown.find(
                (item) => item.name === statesName
            );
            const country = CountryDropDown.find(
                (item) => item.name === countryName
            );
            if (state && country) {
                dispatch(
                    city_DropDown({
                        countryCode: country.isoCode,
                        stateCode: state.isoCode,
                    })
                );
            }
        }
    }, [statesName, dispatch])

    const countryData = useMemo(() => {
        return CountryDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [CountryDropDown]);

    const stateData = useMemo(() => {
        return stateDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [stateDropDown]);


    const cityData = useMemo(() => {
        return cityDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [cityDropDown]);

    const Status_Drop_Down = useMemo(() => {
        return statusDropDown?.map((item) => ({
            label: item,
            code: item,
            value: item
        }));
    }, [statusDropDown])

    const handleField = (e, id) => {
        const value = e.target.value;

        // Update value for the specific field
        setUiData((prevData) =>
            prevData.map((field) =>
                field.id === id ? { ...field, value: value, error: "" } : field
            )
        );
    };

    const handleDropDownChange = (e, id) => {
        setUiData((prevData) =>
            prevData.map((field) =>
                field.id === id ? { ...field, value: e, error: "" } : field
            )
        );
    }


    const getOptions = (fieldName) => {
        switch (fieldName) {
            case "Country":
                return countryData;
            case "State":
                return stateData;
            case "City":
                return cityData;
            case "Status":
                return Status_Drop_Down;
            default:
                return [];
        }
    };

    // Function to render input fields based on the type
    const renderField = (field) => {
        switch (field.type) {
            case "text":
                return (
                    <div key={field.id} className="flex flex-col">
                        <CustomInput
                            label={field.name}
                            name={field.name}
                            value={field.value}
                            onChange={(e) =>{
                                const zipCodeRegex = /^\d{1,6}$/;
                                const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                if(e?.target?.name === "Zip Code" ){
                                    if(!zipCodeRegex.test(e.target.value)){
                                      setZipCodeError("Invalid ZIP Code. Must be numeric and up to 6 digits.");
                                    }
                                    else{
                                        setZipCodeError("");
                                    }
                                }
                                else if(e?.target?.name === "Email ID"){
                                    if(!emailRegexPattern.test(e.target.value)){
                                       setEmailError("Email is Invalid please write correct email");
                                    }
                                    else{
                                      setEmailError("");
                                    }
                                }
                                handleField(e, field.id)
                            }}
                            error={field.name === "Email ID" ? emailError : field.name === "Zip Code" ? zipCodeError :  field.error}
                            required={field.required}
                        />
                        {/* {field.error && <span className="text-red-500 text-sm">{field.error}</span>} */}
                    </div>
                );
            case "dropDown":

                return (
                    <div key={field.id} className="flex flex-col">
                        <CustomSelect
                            label={field.name}
                            value={field.value}
                            options={getOptions(field.name)}
                            onChange={(e) => handleDropDownChange(e, field.id)}
                            error={field.error}
                            required={field.required}
                        />
                        {/* {field.error && <span className="text-red-500 text-sm">{field.error}</span>} */}
                    </div>
                );
            case "textArea":
                return (
                    <div key={field.id} className="flex flex-col">
                        <label className="text-md font-medium leading-6 text-gray-600">
                            {field.name}
                        </label>
                        <textarea
                            value={field.value}
                            onChange={(e) => handleField(e, field.id)}
                            placeholder={field.name}
                            className="w-full p-2 border rounded"
                        />
                        {/* {field.error && <span className="text-red-500 text-sm">{field.error}</span>} */}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className="relative bg-white rounded-lg shadow dark:bg-gray-700"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "800px",
                        width: "100%",
                        maxHeight: "80vh",
                        overflowY: "scroll"
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                        borderBottom="1px solid #e0e0e0"
                    >
                        <Typography id="modal-modal-title" variant="h6" component="h2" className="ml-4">
                            {`Update ${label}`}
                        </Typography>
                        <IconButton onClick={close} aria-label="close">
                            {/* <CloseIcon /> */}
                            <CustomIcon name="IoCloseSharp" />
                        </IconButton>

                    </Box>

                    <Box p={3}>
                        <div className="grid grid-cols-2 gap-4">
                            {uiData.map((field) => {
                                if (field.type === "dropDown" && (field.name === "Country" || field.name === "State" || field.name === "City")) {
                                    return (
                                        <CustomSelect
                                            key={field.id}
                                            label={field.name}
                                            value={field.value}
                                            onChange={(e) => {
                                                if (field.name === "Country") {
                                                    setCountryName(e);
                                                    setStatesName("");
                                                    setCityName("");
                                                }
                                                else if (field.name === "State") {
                                                    setStatesName(e);
                                                }
                                                handleDropDownChange(e, field.id)
                                            }

                                            }
                                            options={getOptions(field.name)}
                                            error={field.error}
                                            required={field.required}
                                        />
                                    );
                                } else {
                                    return renderField(field);
                                }
                            })}
                        </div>
                    </Box>

                    <Box mt={4} pb={4} display="flex" justifyContent="center">
                        <CutomButton text={"Submit"} bgColor={"#3D5B81"} width={"200"} onClick={handleSubmit} />
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default AddNewJobClient;
