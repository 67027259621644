import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { RxDashboard } from "react-icons/rx";
import { TbLayoutDashboardFilled, TbReport, TbReportAnalytics, TbTable, TbTableFilled } from "react-icons/tb";
import { RiContactsLine, RiContactsFill, RiContactsBook3Fill, RiContactsBook3Line } from "react-icons/ri";
import { MdOutlineLocalActivity, MdWorkOutline, MdWork } from "react-icons/md";
import { LiaSalesforce } from "react-icons/lia";
import { IoLogOutOutline, IoLogOutSharp } from "react-icons/io5";
import { SiSalesforce } from "react-icons/si";
import { GoDatabase } from "react-icons/go";
import { FaDatabase } from "react-icons/fa6";
import { logout } from '../stores/Reducers/authSlice';
import { toast } from 'react-toastify';


const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeItemId, setActiveItemId] = useState(() => {
    const savedActiveId = sessionStorage.getItem('activeItemId');
    return savedActiveId ? savedActiveId : null;
  });

  const [expandedItemId, setExpandedItemId] = useState(() => {
    const savedExpandedId = sessionStorage.getItem('expandedItemId');
    return savedExpandedId ? savedExpandedId : null;
  });

  const [childItemId, setChildItemId] = useState(() => {
    const savedChildId = sessionStorage.getItem('childItemId');
    return savedChildId ? savedChildId : null;
  });

  // Set active dashboard item on load
  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setActiveItemId(1);
    }
  }, [location.pathname]);

  useEffect(() => {
    sessionStorage.setItem('activeItemId', activeItemId);
    sessionStorage.setItem('expandedItemId', expandedItemId);
    sessionStorage.setItem('childItemId', childItemId);
  }, [activeItemId, expandedItemId, childItemId]);

  const handleItemClick = (id, title) => {
    if (id === expandedItemId) {
      setExpandedItemId(null); // Collapse if it's already expanded
    } else {
      setExpandedItemId(id);   // Expand the clicked item
    }
    setActiveItemId(id);  // Set clicked item as active
    handleAccount(title);
  };

  const handleChildClick = (e, id, name) => {
    e.stopPropagation();  // Prevent parent click from being triggered
    setChildItemId(id);  // Set child item as active
    if (name === "New Contact") {
      navigate('/dashboard/newContact', { replace: true });
    } else if (name === "Contact List") {
      navigate('/dashboard/allcontact-list', { replace: true });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  const handleLogout =  () => {
    // await dispatch(logout());
    sessionStorage.removeItem('auth');
    sessionStorage.clear();
    window.location.href = "/";
    window.history.replaceState(null, '', "/");
    window.onpopstate = () => {
      if (!sessionStorage.getItem('auth')) {
        window.history.replaceState(null, '', "/");
      }
    };
    toast.success("sucessfully logout");
    
  };

  const handleAccount = (name) => {
    if (name === "Dashboard") {
      navigate('/dashboard', { replace: true });
    } else if (name === "CandiDates") {
      navigate('/dashboard/candidatelist', { replace: true });
    }
    else if (name === "Contact List") {
      navigate('/dashboard/allcontact-list', { replace: true });
    }
    else if (name === "Account List") {
      navigate('/dashboard/accountlist', { replace: true });
    }
    else if (name === "Job") {
      navigate('/dashboard/job-list/joblistData', { replace: true });
    }
    else if (name === "Leads") {
      navigate('/dashboard/leadsData', { replace: true });
    }
    else {
      navigate('/dashboard', { replace: true });
    }
  };

  const listData = [
    { id: 1, title: "Dashboard", icon: RxDashboard, iconFilled: TbLayoutDashboardFilled },
    {
      id: 2, title: "Contact List", icon: RiContactsBook3Line, iconFilled: RiContactsBook3Fill
    },
    { id: 3, title: "Account List", icon: RiContactsLine, iconFilled: RiContactsFill },
    { id: 4, title: "Leads", icon: LiaSalesforce, iconFilled: SiSalesforce },
    { id: 5, title: "CandiDates", icon: RiContactsLine, iconFilled: RiContactsFill },
    { id: 6, title: "Job", icon: MdWorkOutline, iconFilled: MdWork },
    { id: 7, title: "Placements", icon: TbTable, iconFilled: TbTableFilled },
    { id: 8, title: "Opportunities", icon: GoDatabase, iconFilled: FaDatabase },
    { id: 9, title: "Activities", icon: MdOutlineLocalActivity, iconFilled: MdOutlineLocalActivity },
    { id: 10, title: "Sales", icon: LiaSalesforce, iconFilled: SiSalesforce },
    { id: 11, title: "Reports and Analytics", icon: TbReport, iconFilled: TbReportAnalytics },
    { id: 12, title: "Logout", icon: IoLogOutOutline, iconFilled: IoLogOutSharp },
  ];

  const getItemClasses = (itemId) => {
    return `flex items-center gap-2 cursor-pointer p-2 ${itemId === activeItemId ? 'bg-[#98C0D9] text-black' : ''}`;
  };

  const getItemChildClasses = (childId) => {
    return `flex items-center gap-2 cursor-pointer p-2 ${childId === childItemId ? 'bg-[#98C0D9] text-black' : ''}`;
  };

  return (
    <aside className="top-0 left-0 z-40 w-64 lg:w-60 bg-[#3D5B81] text-white h-full overflow-y-auto md:relative md:block md:w-64" style={{ position: 'sticky', top: 0, left: 0 }}>
      <div className="p-4">
        <nav className="mt-10">
          <ul className="flex flex-col gap-2">
            {listData.map((item) => (
              <React.Fragment key={item.id}>
                <li
                  className={getItemClasses(item.id)}
                  onClick={() => {
                    if (item.title === "Logout") {
                      handleLogout();
                    } else {
                      handleItemClick(item.id, item.title);
                    }
                  }}
                >
                  {item.icon && (
                    item.id === activeItemId
                      ? <item.iconFilled className="text-xl" />
                      : <item.icon className="text-xl" />
                  )}
                  <span className="ms-3">{item.title}</span>
                </li>

                {/* Render child items if any */}
                {item.children && item.id === expandedItemId && (
                  <ul className="flex flex-col gap-2 pl-8">
                    {item.children.map((child) => (
                      <li
                        key={child.id}
                        className={getItemChildClasses(child.id)}
                        onClick={(e) => handleChildClick(e, child.id, child.title)}
                      >
                        {child.icon && (
                          child.id === childItemId
                            ? <child.iconFilled className="text-xl" />
                            : <child.icon className="text-xl" />
                        )}
                        <span className="ms-3">{child.title}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
