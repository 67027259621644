import React from 'react';
import './skelstonLoader.css'; // Import CSS file for the skeleton styles

const SkeletonLoader = () => {
  return (
    <div className="skeleton-container">
      {/* Top section */}
      <div className="skeleton-row">
        <div className="skeleton-box"></div>
        <div className="skeleton-box"></div>
        <div className="skeleton-box"></div>
      </div>
      
      {/* Middle section */}
      <div className="skeleton-middle">
        <div className="skeleton-line large"></div>
        <div className="skeleton-line"></div>
      </div>

      {/* Rows with circles and lines */}
      <div className="skeleton-row">
        <div className="skeleton-circle"></div>
        <div className="skeleton-circle"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-line"></div>
      </div>
      <div className="skeleton-divider"></div>
      
      {/* Repeat rows for more skeleton content */}
      <div className="skeleton-row">
        <div className="skeleton-circle"></div>
        <div className="skeleton-circle"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-line"></div>
      </div>
      <div className="skeleton-divider"></div>
    </div>
  );
};

export default SkeletonLoader ;
