import React, { StrictMode, useMemo, useState } from "react";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import CutomButton from "./CutomButton";
import CustomIcon from "./CustomIcon";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
ModuleRegistry.registerModules([ClientSideRowModelModule]);
const gridDiv = document.querySelector("#myGrid");

const CreateNewgroupNext = ({ isvisible, close }) => {
  const [openModal, setOpenModal] = useState(false);
  const [shows, setShows] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const handleSave = () => {
    setShowNext(true);
  };

  const [rowData, setRowData] = useState([
    {
      Name: "Amit",
      Role: "FrontEnd",
      Mobile_No: 649507363,
      E_mail_ID: "amit35@gmail.com",
      Company_Name: "Ruthox Pvt Ltd",
    },
    {
      Name: "Rohit",
      Role: "BackEnd",
      Mobile_No: 33853786830,
      E_mail_ID: "amit35@gmail.com",
      Company_Name: "Ruthox Pvt Ltd",
    },
    {
      Name: "Tomar",
      Role: "HR",
      Mobile_No: 296003637676,
      E_mail_ID: "amit35@gmail.com",
      Company_Name: "Ruthox Pvt Ltd",
    },
    {
      Name: "Toshika",
      Role: "TL",
      Mobile_No: 48890,
      E_mail_ID: "amit35@gmail.com",
      Company_Name: "Ruthox Pvt Ltd",
    },
    {
      Name: "Anushka",
      Role: "GD",
      Mobile_No: 15774,
      E_mail_ID: "amit35@gmail.com",
      Company_Name: "Ruthox Pvt Ltd",
    },
    {
      Name: "Neha",
      Role: "BD",
      Mobile_No: 20675,
      E_mail_ID: "amit35@gmail.com",
      Company_Name: "Ruthox Pvt Ltd",
    },
  ]);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerCheckboxSelection: true,
      headerClass: "bg-[#c2c2c2] text-black",
      checkboxSelection: true,
      width: 50,
    },
    {
      field: "Name",
      headerClass: "bg-[#c2c2c2]",
      flex: 1,
    },
    { field: "Role", flex: 1, headerClass: "bg-[#c2c2c2]" },
    { field: "Mobile_No", flex: 1, headerClass: "bg-[#c2c2c2]" },
    { field: "E_mail_ID", flex: 1, headerClass: "bg-[#c2c2c2]" },
    {
      field: "Company_Name",
      flex: 1,
      headerClass: "bg-[#c2c2c2]",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      // filter: 'agTextColumnFilter',
      // floatingFilter: true,
    };
  }, []);

  if (!isvisible) return null;
  return (
    <>
      <div
        id="default-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed inset-0 z-50 flex justify-center sm:justify-end items-center w-full h-full bg-black bg-opacity-50"
      >
        <div className="relative w-full max-w-4xl px-4">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
              <div className="relative flex items-center justify-between p-4 sm:p-5 rounded-t dark:border-gray-600">
                <button
                  type="button"
                  onClick={() => close()}
                  className="text-white absolute top-2 right-2 bg-[#3D5B81] hover:bg-gray-200 hover:text-gray-900 rounded-full w-6 h-6 flex justify-center items-center transition duration-200 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>

                <h3 className="text-base sm:text-lg md:text-xl font-semibold text-gray-900 dark:text-white mt-4 sm:mt-6 ml-4 sm:ml-8">
                  List 6 Portal
                </h3>

                <div className="mt-4 sm:mt-6 flex space-x-2 md:space-x-4">
                  

                  <button className="w-24 sm:w-28 md:w-32 py-1 text-sm sm:text-base md:text-lg rounded-full flex justify-center bg-[#3D5B81] text-white">
                    Update
                    <CustomIcon
                      name={"FaArrowRightLong"}
                      className="ml-2 mt-1.5"
                    />
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center border-b-gray-300 border-b mx-4 sm:mx-12 md:mx-16"></div>

              <div className="p-4 sm:p-5 space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-4 sm:px-8 md:px-12 py-3">
                  <CustomInput
                    placeholder={"Enter Group Name"}
                    label={"Group Name"}
                    type={"text"}
                  />
                  <CustomSelect icon={"BsBuildings"} label={"Restriction"} />
                  <CustomSelect icon={"BsBuildings"} label={"Name or Role"} />
                </div>
              </div>
              <div className="ag-theme-quartz" style={{ height: 250 }}>
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  pagination={true}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[10, 25, 50]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewgroupNext;
