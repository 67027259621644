import React from 'react';
import Select from 'react-select';
import chroma from 'chroma-js';
import { radio } from '@material-tailwind/react';
import { TbBorderRadius } from 'react-icons/tb';


const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        border: 'none',
        borderRadius: '0.5rem',

        // boxShadow: 'none',

    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color ? chroma(data.color) : chroma('gray');
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = data.color ? chroma(data.color) : chroma('gray'); 
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color || 'black', 
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color || 'black',
        ':hover': {
            backgroundColor: data.color || 'gray',
            color: 'white',
        },
    }),
};

const MultipleSelect = ({
    options,
    value,
    onChange,
    label,
    error,
    disabled
}) => {

    const handleChange = (option) => {
        onChange(option)
    }
    return (
        <div className="">
            {label && (
                <label className="block text-md font-medium leading-6 text-gray-600">
                    {label} {label && <span className="text-red-500">*</span>}
                </label>
            )}
            <div
                style={{
                    border: "1px solid lightgray",
                    borderRadius:"8px"
                }}
            >

                <Select
                    closeMenuOnSelect={false}
                    value={value}
                    onChange={handleChange}
                    isMulti
                    options={options}
                    styles={colourStyles}
                    isDisabled={disabled}
                    className='w-full  leading-tight focus:outline-none'

                />

            </div>

            {error && <p className="text-red-500 rounded-lg text-sm mt-1">{error}</p>}
        </div>
    );
};

export default MultipleSelect;
