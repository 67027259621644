import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import newContact from './newContact';
import dropDownApiReducers from './dropDownApiReducers';
import newCandiDate from './newCandiDate';
import newAccount from './newAccount';
import newleads from './newleads';
import jobDropDownReducer from "./jobDropDownReducer";
import newJob from "./newJob";
import jopPatch from "./jobPatchDropDownReducer";
import checkEmail from "./emailCheck";

const store = configureStore({
  reducer: {
    auth: authSlice, 
    dropDown:dropDownApiReducers,
    jobDropDown:jobDropDownReducer,
    contactlist:newContact,
    joblist:newJob,
    candiDatelist:newCandiDate,
    accountlist:newAccount,
    leadlist:newleads,
    Update_JobDropdown:jopPatch,
    emailCheck:checkEmail

  },
});

export default store;
