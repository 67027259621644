import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  state_DropDown,
  city_DropDown, client_DropDown, documents_Dropdown,
  Guideline_documents_Dropdown
} from "../../stores/Reducers/dropDownApiReducers";
import CustomIcon from "../../components/CustomIcon";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomNumber from "../../components/CustomNumber";
import { createAccount } from "../../stores/Reducers/newAccount";
import CustomEmail from "../../components/CustomEmail";
import CustomSocialMedia from "../../components/CustomSocialMedia";
import CutomButton from "../../components/CutomButton";
import CustomAddSelect from "../../components/CustomAddSelect";
import { ContextData } from "../../Context/context";
import MultipleSelect from "../../components/MultipleSelect";
import { uploadFile } from "../../Modules/Document_Upload";
import {
  AccountFormState,
  AccountDocumentFromState,
} from "../../Modules/formState";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

const NewAccount = () => {
  const Navigate = useNavigate();
  const {
    accountformData,
    setAccountFormData,
    accountDocumentFormData,
    setAccountDocumentFormData,
    accountDocuments,
    setAccountDocuments,
  } = useContext(ContextData);
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [documentFormData, setDocumentFormData] = useState({
    document: [
      {
        id: uuidv4(),
        doc_type: "",
        title: "",
        description: "",
        url: "",
        status: true,
      },
    ],
    template: {},
  });
  const [fileshow, setFileshow] = useState({});
  const [selectedDocument1, setSelectedDocument1] = useState("");
  const [documentTitle1, setDocumentTitle1] = useState("");
  const [fileshow1, setFileshow1] = useState({});
  const [openGroup, setOpenGroup] = useState(false);
  const [errors, setErrors] = useState({});
  const [documents1, setDocuments1] = useState([]);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [accessOwnerShip, setAccessOwnerShip] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [uploadStatus1, setUploadStatus1] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [emailErrorStatus, setEmailErrorStatus] = useState(false);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const {
    CountryDropDown,
    stateDropDown,
    cityDropDown,
    industryDropDown,
    statusDropDown,
    clientDropDown,
    componayDropDown,
    documentDropDown,
    guidlineDropDown
  } = useSelector((data) => data.dropDown);

  const handleGroup = () => {
    setOpenGroup(true);
  };

  const found_Document_Title =
    accountDocumentFormData?.document &&
    accountDocumentFormData?.document.find((doc) => doc.status);

  const found_Document_Title1 =
    documentFormData?.document &&
    documentFormData?.document.find((doc) => doc.status);

  const handleAddDocument = () => {
    if (
      found_Document_Title &&
      found_Document_Title?.title?.length > 1 &&
      found_Document_Title?.doc_type?.length > 1
    ) {
      const newDocuments = accountDocumentFormData.document.map(
        (doc, index) => ({
          id: accountDocuments && accountDocuments.length + doc.id,
          type: doc.doc_type,
          title: doc.title,
          url: doc.url,
          description: doc.description,
        })
      );
      setAccountDocuments((prevDocuments) => [
        ...prevDocuments,
        ...newDocuments,
      ]);
      setAccountDocumentFormData(AccountDocumentFromState);
      setErrors({
        documentTitle: " ",
        selectedDocument: " ",
      });

      setDropdownOpen(true);
      return;
    } else if (
      found_Document_Title?.title?.length < 1 &&
      found_Document_Title?.doc_type?.length < 1
    )
      setErrors({
        documentTitle: "Document title is required",
        selectedDocument: "Document type is required",
      });
    else if (found_Document_Title?.title?.length < 1) {
      setErrors({
        documentTitle: "Document title is required",
      });
    } else if (found_Document_Title?.doc_type?.length < 1) {
      setErrors({
        selectedDocument: "Document type is required",
      });
    }
  };

  // const validateOfficialNumbers = (officialNumbers, length) => {
  //   let errors = [];
  //   const numberPattern = /^[0-9]{10}$/; 
  //   const countryCodePattern = /^\+\d{1,4}$/; 

  //   errorStatus && officialNumbers.forEach((entry, index) => {
  //     const { countryCode, number } = entry;

  //     if (errorStatus) {
  //       if (!countryCode || !countryCodePattern.test(countryCode)) {
  //         errors[index] = 'Invalid or missing country code.';
  //       } else if (!number || !numberPattern.test(number)) {
  //         errors[index] = 'Invalid number. Must be a 10-digit number.';
  //       } 
  //     } else {
  //       if (!numberPattern.test(number)) {
  //         errors[index] = 'Invalid number. Must be a 10-digit number.';
  //       } 
  //     }
  //   });

  //   return errors;
  // }; 

  const validateOfficialNumbers = (officialNumbers) => {
    let errors = [];
    const countryCodePattern = /^\+\d{1,4}$/;
    const countryCodeLengths = {
      '+1': 10,
      '+7': 10,    // Russia, Kazakhstan
      '+20': 10,   // Egypt
      '+27': 9,    // South Africa
      '+30': 10,   // Greece
      '+31': 9,    // Netherlands
      '+32': 9,    // Belgium
      '+33': 9,    // France
      '+34': 9,    // Spain
      '+36': 9,    // Hungary
      '+39': 10,   // Italy
      '+40': 10,   // Romania
      '+41': 9,    // Switzerland
      '+43': 10,   // Austria
      '+44': 10,   // United Kingdom
      '+45': 8,    // Denmark
      '+46': 9,    // Sweden
      '+47': 8,    // Norway
      '+48': 9,    // Poland
      '+49': 10,   // Germany
      '+51': 9,    // Peru
      '+52': 10,   // Mexico
      '+53': 8,    // Cuba
      '+54': 10,   // Argentina
      '+55': 10,   // Brazil
      '+56': 9,    // Chile
      '+57': 10,   // Colombia
      '+58': 10,   // Venezuela
      '+60': 9,    // Malaysia
      '+61': 9,    // Australia
      '+62': 10,   // Indonesia
      '+63': 10,   // Philippines
      '+64': 9,    // New Zealand
      '+65': 8,    // Singapore
      '+66': 9,    // Thailand
      '+81': 10,   // Japan
      '+82': 10,   // South Korea
      '+84': 9,    // Vietnam
      '+86': 11,   // China
      '+90': 10,   // Turkey
      '+91': 10,   // India
      '+92': 10,   // Pakistan
      '+93': 9,    // Afghanistan
      '+94': 9,    // Sri Lanka
      '+95': 9,    // Myanmar
      '+98': 10,   // Iran
      '+212': 9,   // Morocco
      '+213': 9,   // Algeria
      '+216': 8,   // Tunisia
      '+218': 9,   // Libya
      '+220': 7,   // Gambia
      '+221': 9,   // Senegal
      '+222': 9,   // Mauritania
      '+223': 8,   // Mali
      '+224': 9,   // Guinea
      '+225': 8,   // Côte d’Ivoire
      '+226': 8,   // Burkina Faso
      '+227': 8,   // Niger
      '+228': 8,   // Togo
      '+229': 8,   // Benin
      '+230': 8,   // Mauritius
      '+231': 8,   // Liberia
      '+232': 8,   // Sierra Leone
      '+233': 9,   // Ghana
      '+234': 10,  // Nigeria
      '+235': 8,   // Chad
      '+236': 8,   // Central African Republic
      '+237': 9,   // Cameroon
      '+238': 7,   // Cape Verde
      '+239': 7,   // São Tomé and Príncipe
      '+240': 9,   // Equatorial Guinea
      '+241': 9,   // Gabon
      '+242': 9,   // Congo (Brazzaville)
      '+243': 9,   // Congo (Kinshasa)
      '+244': 9,   // Angola
      '+245': 7,   // Guinea-Bissau
      '+246': 7,   // Diego Garcia
      '+248': 7,   // Seychelles
      '+249': 9,   // Sudan
      '+250': 9,   // Rwanda
      '+251': 9,   // Ethiopia
      '+252': 9,   // Somalia
      '+253': 9,   // Djibouti
      '+254': 10,  // Kenya
      '+255': 9,   // Tanzania
      '+256': 9,   // Uganda
      '+257': 8,   // Burundi
      '+258': 9,   // Mozambique
      '+260': 9,   // Zambia
      '+261': 9,   // Madagascar
      '+262': 9,   // Réunion/Mayotte
      '+263': 9,   // Zimbabwe
      '+264': 9,   // Namibia
      '+265': 9,   // Malawi
      '+266': 8,   // Lesotho
      '+267': 8,   // Botswana
      '+268': 8,   // Eswatini
      '+269': 7,   // Comoros
      '+290': 5,   // Saint Helena
      '+291': 9,   // Eritrea
      '+297': 7,   // Aruba
      '+298': 6,   // Faroe Islands
      '+299': 6,   // Greenland
      // Add more if needed
    };

    errorStatus && officialNumbers.forEach((entry, index) => {
      const { countryCode, number } = entry;
      const resolvedCountryCode = countryCode || '+1';
      const numberLength = countryCodeLengths[resolvedCountryCode];
      const numberPattern = new RegExp(`^[0-9]{${numberLength}}$`)

      if (!resolvedCountryCode || !countryCodePattern.test(resolvedCountryCode)) {
        errors[index] = 'Invalid or missing country code.';
      }
      else if (!number || number.length === 0) {
        errors[index] = 'This field is required.';
      }
      else if (number.length !== numberLength) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else if (!numberPattern.test(number)) {
        errors[index] = 'Invalid number. Must contain only digits.';
      }
      else if (
        countryCodeLengths[resolvedCountryCode] &&
        number.length !== countryCodeLengths[resolvedCountryCode]
      ) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      // else {
      //   setNumberValidation(true)
      // }
    });

    return errors;
  };

  const validateofficialEmailId = (EmailId, length) => {
    const errors = [];
    const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    emailErrorStatus && EmailId.forEach((email, index) => {
      if (emailErrorStatus) {
        if (!email) {
          errors[index] = `This field is required`;
        }
        else if (!emailRegexPattern.test(email)) {
          errors[index] = `Email is Invalid please write correct email`;
        }


      } else if (!emailRegexPattern.test(email)) {
        errors[index] = `Email is Invalid please write correct email`;
      }
    });


    return errors;
  };

  useEffect(() => {
    if (accountformData?.country) {
      const country = CountryDropDown.find(
        (item) => item.name === accountformData.country
      );
      if (country) {
        dispatch(state_DropDown({ countryCode: country.isoCode }));
      }
    }
  }, [accountformData.country, CountryDropDown, dispatch]);

  useEffect(() => {
    if (accountformData?.state) {
      const state = stateDropDown.find(
        (item) => item.name === accountformData.state
      );
      const country = CountryDropDown.find(
        (item) => item.name === accountformData.country
      );
      if (state && country) {
        dispatch(
          city_DropDown({
            countryCode: country.isoCode,
            stateCode: state.isoCode,
          })
        );
      }
    }
  }, [accountformData.state, stateDropDown, CountryDropDown, dispatch]);

  useEffect(() => {
    let endpoints = "account";
    dispatch(client_DropDown({ endpoints }));
  }, [dispatch])

  useEffect(() => {
    let endpoints = "account";
    dispatch(documents_Dropdown({ endpoints }));
  }, [dispatch])

  useEffect(() => {
    let endpoints = "account"
    dispatch(Guideline_documents_Dropdown({ endpoints }))
  }, [dispatch])

  const handleChange = useCallback(
    (e, name) => {
      const { type, checked, value } = e?.target || {};
      const fieldName = name || e?.target?.name;
      const zipCodeRegex = /^\d{1,6}$/;
      const NameRegext = /^[A-Za-z\s.]+$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: value?.trim()
          ? fieldName === "zipCode" && !zipCodeRegex.test(value)
            ? "Invalid ZIP Code. Must be numeric and up to 6 digits."
            : fieldName === "fullName" && !NameRegext.test(value)
              ? "please enter correct name"
              : ""
          : "This field is required",
      }));
      setAccountFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [fieldName]: type === "checkbox" ? checked : value,
        };
        return updatedFormData;
      });

      if (type === "checkbox") {
        setAccessOwnerShip(!accessOwnerShip);
      }
    },
    [accessOwnerShip]
  );


  const handleChangeDropDown = useCallback(
    (field, value) => {


      setAccountFormData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: value?.trim() ? "" : "This field is required",
      }));

    },
    [setAccountFormData]
  );

  const validate = (FormData) => {
    let formErrors = {};

    let updatedFormData = FormData || accountformData;
    if (!updatedFormData.fullName)
      formErrors.fullName = "Full Name is required";

    // if (!updatedFormData.industry) formErrors.industry = "Industry is required";

    // if (
    //   !updatedFormData.officialNumber
    // ) {
    //   formErrors.officialNumber = ["official number is required"];
    // } else {
    //   if (updatedFormData.officialNumber.length > 0) {
    //     const officialNumberErrors = validateOfficialNumbers(
    //       updatedFormData.officialNumber,
    //       updatedFormData.officialNumber.length
    //     );
    //     formErrors.officialNumber = officialNumberErrors;
    //   }
    // }
    // if (!updatedFormData.email || updatedFormData.email.length === 0) {
    //   formErrors.email = ["Email is required"];
    // } else {
    //   if (updatedFormData.email.length > 0) {
    //     const emailErrors = validateofficialEmailId(
    //       updatedFormData.email,
    //       updatedFormData.email.length
    //     );
    //     formErrors.email = emailErrors;
    //   }
    // }
    // if (!updatedFormData.companyheadCount)
    //   formErrors.companyheadCount = "Company HeadCount is required";
    if (!updatedFormData.clientType)
      formErrors.clientType = "Client Type is required";
    if (!updatedFormData.country) formErrors.country = "Country is required";
    // if (!updatedFormData.state) formErrors.state = "State is required";
    // if (!updatedFormData.city) formErrors.city = "City is required";
    // if (!updatedFormData.address) formErrors.address = "Address is required";
    if (!updatedFormData.status) formErrors.status = "Status is required";

    // if (!found_Document_Title?.doc_type) {
    //   formErrors.selectedDocument = "Please select a document";
    // }
    // if (!found_Document_Title?.title) {
    //   formErrors.documentTitle = "Please enter a document title";
    // }

    // if (!found_Document_Title1?.doc_type) {
    //   formErrors.selectedDocument1 = "Please select a document";
    // }
    // if (!found_Document_Title1?.title) {
    //   formErrors.documentTitle1 = "Please enter a document title";
    // }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleChangeArray = useCallback(
    (data) => {
      setAccountFormData((prevState) => ({
        ...prevState,
        officialNumber: data,
      }));
      const officialNumberErrors = validateOfficialNumbers(data);
      //  console.log(data, officialNumberErrors, "data156")
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["officialNumber"]: officialNumberErrors,
      }));
    },
    [accountformData.officialNumber]
  );

  const handleChangeEmailArray = useCallback(
    (data) => {
      setAccountFormData((prevState) => ({
        ...prevState,
        email: data,
      }));
      const EmailNumberErrors = validateofficialEmailId(data);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (EmailNumberErrors.length > 0) {
          newErrors.email = EmailNumberErrors;
        } else {
          delete newErrors.email;
        }
        return newErrors;
      });
    },
    [accountformData.email]
  );

  const handleChangeObject = useCallback(
    (data) => {
      setAccountFormData((prevState) => ({
        ...prevState,
        Url: data,
      }));
    },
    [accountformData.Url]
  );

  const handleImageFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setImage(objectUrl);
      setAccountFormData((prevState) => ({
        ...prevState,
        image: objectUrl,
      }));
      return () => URL.revokeObjectURL(objectUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setErrorStatus(true);
    // setEmailErrorStatus(true);
    let DocumentData;
    let Data;
    let Client_Data = ["all"];
    const validataData = validate();
    if (validataData) {
      try {
        if (dropdownOpen) {
          DocumentData =
            accountDocuments &&
            accountDocuments.map((item) => {
              return {
                doc_type: item.doc_type,
                title: item.title,
                description: item.description,
                url: item?.url,
              };
            });
        } else if (dropdownOpen1) {
          Data = documents1.map((item) => {
            return {
              doc_type: item.doc_type,
              guidelines_title: item.title,
              description: item.description,
              url: item?.url,
            };
          });
        } else if (dropdownOpen && dropdownOpen1) {
          DocumentData =
            accountDocuments &&
            accountDocuments.map((item) => {
              return {
                doc_type: item.doc_type,
                guidelines_title: item.title,
                description: item.description,
                url: item?.url,
              };
            });
          Data = documents1.map((item) => {
            return {
              doc_type: item.doc_type,
              guidelines_title: item.title,
              description: item.description,
              url: item?.url,
            };
          });
        }
        else {
          DocumentData =
            accountDocumentFormData &&
            accountDocumentFormData.document.map((item) => {
              return {
                doc_type: item.doc_type,
                title: item.title,
                description: item.description,
                url: item?.url,
              };
            });
          Data =
            documentFormData &&
            documentFormData.document.map((item) => {
              return {
                doc_type: item.doc_type,
                guidelines_title: item.title,
                description: item.description,
                url: item?.url,
              };
            });
        }
        // const Client_Data = accountformData?.allowOwnership && accountformData?.allowOwnership.map((item) => {
        //   return `${item.value ? item.value : item.label ? item.label : item}`
        // })
        const newData = {
          company_name: accountformData?.fullName,
          industry: accountformData?.industry,
          official_number: accountformData?.officialNumber,
          email: accountformData?.email,
          // company: accountformData?.company,
          client_type: accountformData?.clientType,
          company_headcount: accountformData?.companyheadCount,
          country: accountformData?.country,
          state: accountformData?.state,
          city: accountformData?.city,
          address: accountformData?.address,
          urls: accountformData?.Url,
          zipcode: accountformData?.zipCode,
          status: accountformData?.status,
          notes: accountformData?.notes,
          // profile_image: accountformData?.image,
          allow_ownership: Client_Data,
          documents: DocumentData,
          guidelines_documents: Data,
        };
        const Response_data = await dispatch(createAccount({ newData }))
          .then((res) => {
            return res;
          })
          .catch((err) => console.error(err));

        if (Response_data?.payload?.message === "Success") {
          setLoader(false);
          Navigate("/dashboard/accountlist");
          setAccountFormData({
            fullName: '',
            industry: '',
            officialNumber: [],
            email: [],
            companyheadCount: '',
            clientType: '',
            country: '',
            state: '',
            city: '',
            address: '',
            Url: {},
            zipCode: '',
            status: '',
            image: '',
            allowOwnership: [],
            allowAcess: false,
            notes: '',
          });
          setAccountDocumentFormData(AccountDocumentFromState);
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error submitting form:", error);
      }
    } else {
      setLoader(false);
      toast.error("please add all required field");
    }
  };

  const handleButtonClick = (e) => {
    setLoader(true);
    e.preventDefault();
    handleSubmit(e);
  };

  const countryData = useMemo(() => {
    return CountryDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [CountryDropDown]);

  const stateData = useMemo(() => {
    return stateDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [stateDropDown]);

  const cityData = useMemo(() => {
    return cityDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [cityDropDown]);

  const Industry_DropDown = useMemo(() => {
    return industryDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [industryDropDown]);

  const Company_DropDwon = useMemo(() => {
    return componayDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [componayDropDown]);

  const Status_DropDwon = useMemo(() => {
    return statusDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [statusDropDown]);

  const client_TypeDropDwon = useMemo(() => {
    return clientDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [clientDropDown]);

  const document_DropDwon = useMemo(() => {
    return documentDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [documentDropDown]);

  const guidlineDropDownData = useMemo(() => {
    return guidlineDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [guidlineDropDown]);

  const handleOnwerSelect = (data) => {
    setAccountFormData((prevState) => ({
      ...prevState,
      allowOwnership: data,
    }));
  };

  const colourOptions = [
    { value: "Owner ", label: "Owner" },
    { value: "admin", label: "admin" },
  ];

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileLocation = await uploadFile(file);
      setFileshow(file);
      if (fileLocation?.Location) {
        setUploadStatus(true);
        setAccountDocumentFormData((prevState) => {
          const hasEmptyTitleDocument = prevState.document.some(
            (doc) => doc.title === ""
          );
          const updatedDocuments = hasEmptyTitleDocument
            ? prevState.document.map((doc) =>
              doc.title === ""
                ? {
                  ...doc,
                  title: file.name,
                  url: fileLocation?.Location,
                  description: "",
                }
                : doc
            )
            : [
              ...prevState.document,
              {
                title: file.name,
                url: fileLocation?.Location,
                description: "",
              },
            ];

          return {
            ...prevState,
            document: updatedDocuments,
          };
        });
      }

    }
  };

  const handleDriveOpen = () => {
    const googleDriveUrl = "https://drive.google.com/drive/my-drive";
    window.open(googleDriveUrl, "_blank");
  };
  const handleDropboxSignIn = () => {
    const dropboxSignInUrl = "https://www.dropbox.com/login";
    window.open(dropboxSignInUrl, "_blank");
  };



  const handleSelectChangeDocument = useCallback((index, e, name) => {
    const newTitle = e;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newTitle?.trim() ? "" : ` ${name} field is required`,
    }));
    setAccountDocumentFormData((prevState) => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, doc_type: newTitle } : doc
      );
      return {
        ...prevState,
        document: updatedDocuments,
      };
    });

  }, []);

  const handleChangeDocument = useCallback((index, e,) => {
    const newTitle = e.target.value;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: newTitle?.trim() ? "" : ` ${e.target.name} field is required`,
    }));
    setAccountDocumentFormData((prevState) => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, title: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  }, []);

  const handleChangeDescription = (index, e) => {
    const newTitle = e.target.value;
    setAccountDocumentFormData((prevState) => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, description: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  };

  const handleChildItemDelete = (id) => {
    setAccountDocuments((prevDocuments) =>
      prevDocuments.filter((doc) => doc.id !== id)
    );
  };

  const handleAnotherAddDocument = () => {
    if (
      found_Document_Title1 &&
      found_Document_Title1?.title?.length > 1 &&
      found_Document_Title1?.doc_type?.length > 1
    ) {
      const newDocuments = documentFormData.document.map((doc, index) => ({
        id: documents1.length + index + 1,
        type: doc.doc_type,
        title: doc.title,
        url: doc.url,
        description: doc.description,
      }));
      setDocuments1((prevDocuments) => [...prevDocuments, ...newDocuments]);
      setDocumentFormData({
        document: [
          {
            id: 1,
            doc_type: "",
            title: "",
            description: "",
            url: "",
            status: true,
          },
        ],
        template: {},
      });
      setErrors({
        documentTitle1: " ",
        selectedDocument1: " ",
      });

      setDropdownOpen1(true);
      return;
    } else if (
      found_Document_Title1?.title?.length < 1 &&
      found_Document_Title1?.doc_type?.length < 1
    )
      setErrors({
        documentTitle1: "Document title is required",
        selectedDocument1: "Document type is required",
      });
    else if (found_Document_Title1?.title?.length < 1) {
      setErrors({
        documentTitle1: "Document title is required",
      });
    } else if (found_Document_Title1?.doc_type?.length < 1) {
      setErrors({
        selectedDocument1: "Document type is required",
      });
    } else {
      setErrors({
        selectedDocument1: !selectedDocument1
          ? "Document type is required"
          : "",
        documentTitle1: !documentTitle1 ? "Document title is required" : "",
      });
    }
  };

  const handleAnotherFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileLocation = await uploadFile(file);
      setFileshow1(file);
      if (fileLocation?.Location) {
        setUploadStatus1(true);
        setDocumentFormData((prevState) => {
          const hasEmptyTitleDocument = prevState.document.some(
            (doc) => doc.title === ""
          );
          const updatedDocuments = hasEmptyTitleDocument
            ? prevState.document.map((doc) =>
              doc.title === ""
                ? {
                  ...doc,
                  title: file.name,
                  url: fileLocation?.Location,
                  description: "",
                }
                : doc
            )
            : [
              ...prevState.document,
              {
                title: file.name,
                url: fileLocation?.Location,
                description: "",
              },
            ];

          return {
            ...prevState,
            document: updatedDocuments,
          };
        });
      }
    }
  };

  const handleAnotherDriveOpen = () => {
    const googleDriveUrl = "https://drive.google.com/drive/my-drive";
    window.open(googleDriveUrl, "_blank");
  };
  const handleAnotherDropboxSignIn = () => {
    const dropboxSignInUrl = "https://www.dropbox.com/login";
    window.open(dropboxSignInUrl, "_blank");
  };

  const handleSelectAnotherChangeDocument = useCallback(
    (index, e, name) => {
      const newTitle = e;
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: newTitle?.trim() ? "" : ` ${e.target.name} field is required`,
      }));
      setDocumentFormData((prevState) => {
        const updatedDocuments = prevState.document.map((doc, i) =>
          i === index ? { ...doc, doc_type: newTitle } : doc
        );

        return {
          ...prevState,
          document: updatedDocuments,
        };
      });
    },
    [documentFormData.document]
  );

  const handleAnotherChangeDocument = useCallback((index, e, name) => {
    const newTitle = e.target.value;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newTitle?.trim() ? "" : ` ${e.target.name} field is required`,
    }));
    setDocumentFormData((prevState) => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, title: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  }, []);

  const handleChangeAnotherDescription = (index, e) => {
    const newTitle = e.target.value;
    setDocumentFormData((prevState) => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, description: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  };

  const handleChildAnotherItemDelete = (id) => {
    setDocuments1((prevDocuments) =>
      prevDocuments.filter((doc) => doc.id !== id)
    );
  };

  const handleContact = () => {
    Navigate("/dashboard/accountlist");
  }

  const handleChangeArrauClick = () => {
    setErrorStatus(true);
  }
  const handleChangeArrayEmailClick = () => {
    setEmailErrorStatus(true);
  }
  // console.log(accountformData?.companyheadCount, "accountData");

  return (
    <div className="shadow-md shadow-slate-700 bg-[#F2F3F3] ring-black ring-opacity-50 w-full h-full rounded-xl p-4"

    >
      <div className="bg-[#F5F5F5] rounded-xl">
        <div className="flex flex-col md:flex-row items-center justify-between  pl-6 text-2xl text-[#004C95] font-bold rounded-t-lg">
          <div className="flex-1 md:flex-[0.2]">
            <p>New Account</p>
          </div>
          <div>
            <button className=" text-[#3e5c82]" onClick={handleContact}
              style={{
                fontSize: '16px',
                borderRadius: "10px",
                border: "1px solid lightblue",
                padding: "5px"
              }}
            >
              View Account
            </button>
          </div>
        </div>
        <div className="pt-6 pl-4 pr-2">
          <div className="text-lg text-[#004C95] font-bold">
            Personal Details :
          </div>
          <div className="mb-4 text-[#0000004D] border"></div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="md:mr-4">
              <CustomInput
                type="text"
                name="fullName"
                placeholder="Enter company Name"
                label="Company Name"
                icon="BsPersonFill"
                value={accountformData.fullName}
                onChange={handleChange}
                error={errors.fullName}
                required={"true"}
              />
            </div>
            <div>
              <CustomSelect
                name="industry"
                icon="FaUserGear"
                label="Industry"
                placeholder="Enter Industry Name"
                value={accountformData.industry}
                options={Industry_DropDown}
                onChange={(selectedValues) =>
                  handleChangeDropDown("industry", selectedValues)
                }
                error={errors.industry}
                required={true}
              />
            </div>
            <div className="mt-2 md:mr-4">
              <CustomNumber
                type="text"
                name="officialNumber"
                placeholder="Enter Mobile Number"
                label="Official Number"
                value={accountformData.officialNumber}
                onChange={handleChangeArray}
                onClick={handleChangeArrauClick}
                icon={"MdOutlinePhoneIphone"}
                errors={errors.officialNumber}
                required={false}
              />
            </div>
            <div className="mt-2">
              <CustomEmail
                type="email"
                name="email"
                label="Email ID"
                placeholder="Enter Email ID"
                value={accountformData.email}
                onChange={handleChangeEmailArray}
                onClick={handleChangeArrayEmailClick}
                icon={"IoIosMail"}
                errors={errors.email}
                required={false}
              />
            </div>
            {/* <div className="mt-2 md:mr-4">
              <CustomInput
                name="companyheadCount"
                icon="CiLocationOn"
                label="Company Headcount"
                placeholder="Enter Company HeadCount"
                value={accountformData.company}
                onChange={handleChange}
                error={errors.company}
                required={"false"}
              />
            </div> */}
            <div className="mt-2">
              <CustomAddSelect
                name="clientType"
                icon="FaClipboardUser"
                label="Client Type"
                placeholder="Enter clientType Name"
                value={accountformData.clientType}
                options={client_TypeDropDwon}
                onChange={(selectedValues) =>
                  handleChangeDropDown("clientType", selectedValues)
                }
                error={errors.clientType}
                required={"true"}
                name2={"account"}
              />
            </div>
            <div className="mt-2 md:mr-4">
              <CustomSelect
                name="country"
                icon="CiLocationOn"
                label="Country"
                value={accountformData.country}
                onChange={(selectedValues) =>
                  handleChangeDropDown("country", selectedValues)
                }
                error={errors.country}
                options={countryData}
                required={"true"}
              />
            </div>
            <div className="mt-2">
              <CustomSelect
                name="state"
                icon="CiLocationOn"
                label="State"
                value={accountformData.state}
                onChange={(selectedValues) =>
                  handleChangeDropDown("state", selectedValues)
                }
                error={errors.state}
                options={stateData}
              />
            </div>
            <div className="mt-2 md:mr-4">
              <CustomSelect
                name="city"
                icon="IoEarth"
                label="City"
                value={accountformData.city}
                onChange={(selectedValues) =>
                  handleChangeDropDown("city", selectedValues)
                }
                error={errors.city}
                options={cityData}
              />
            </div>
            <div className="mt-2">
              <CustomInput
                type="text"
                name="address"
                placeholder="Enter Address"
                label="Address"
                icon="CiLocationOn"
                value={accountformData.address}
                onChange={handleChange}
                error={errors.address}
              />
            </div>
            <div className="mt-2 lg:pr-4 sm:px-0">
              <CustomSocialMedia
                type="text"
                name="Url"
                placeholder="Enter URL's"
                label="Enter URLs"
                value={accountformData.Url}
                onChange={handleChangeObject}
                error={errors.Url}
              />
            </div>
            <div className="mt-2">
              <CustomInput
                type="text"
                name="zipCode"
                placeholder="Enter Zip Code"
                label="Zip Code"
                icon="CiLocationOn"
                value={accountformData.zipCode}
                onChange={handleChange}
                error={errors.zipCode}
              />
            </div>
            <div className="mt-2 md:mr-4">
              <CustomAddSelect
                name="status"
                icon="GrStatusGood"
                label="Status"
                placeholder="Select Status"
                value={accountformData.status}
                options={Status_DropDwon}
                onChange={(selectedValues) =>
                  handleChangeDropDown("status", selectedValues)
                }
                error={errors.status}
                required={"true"}
              />
            </div>
            {/* <div className="mt-2 lg:pr-4 md:pr-4">
              <MultipleSelect
                label="Allow Ownership"
                value={accountformData.allowOwnership}
                onChange={handleOnwerSelect}
                error={errors.allowOwnership}
                disabled={accessOwnerShip}
                options={colourOptions}
              />
              <div className="flex space-x-2 mt-4">
                <input
                  type="checkbox"
                  name="allowAccess"
                  className="w-4 h-4 mt-1"
                  checked={accountformData.allowAccess}
                  onChange={handleChange}
                />
                <div>Allow Access to All users</div>
              </div>
            </div> */}
            <div className="mt-2 ">
              <label>Notes</label>
              <div className="mt-2">
                <textarea
                  name="notes"
                  rows={5}
                  placeholder="Add Notes"
                  className="w-full pl-4 pt-2 bg-white border border-gray-300 rounded-lg shadow-md"
                  value={accountformData.notes}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="text-lg text-[#004C95] font-bold">
            Upload Document :
          </div>
          <div className="mb-4 text-[#0000004D] border"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              {accountDocumentFormData.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <CustomAddSelect
                      label={"Select Document"}
                      icon={"BsPersonFill"}
                      value={doc?.doc_type || ""}
                      onChange={(e) => handleSelectChangeDocument(index, e, "Select Document")}
                      options={document_DropDwon}
                      //disabled={!uploadStatus}
                      name2={"account"}
                    />
                  )}
                </div>
              ))}
            </div>
            <div>
              <div className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                Upload File
              </div>
              <div className="mt-3 flex">
                <div className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl text-blue-600">
                  <label>
                    <CustomIcon name={"CgScreen"} />
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                      disabled={!uploadStatus}
                    />
                  </label>
                </div>
                <div
                  className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                  onClick={handleDriveOpen}
                  style={{ cursor: "pointer" }}
                >
                  <CustomIcon name={"BsCloudsFill"} />
                </div>
                <div
                  className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                  onClick={handleDropboxSignIn}
                  style={{ cursor: "pointer" }}
                >
                  <CustomIcon name={"FaDropbox"} />
                </div>
              </div>
              <div className=" text-sm text-red-500 mt-2">
                {found_Document_Title?.title}
              </div>
            </div>

            <div>
              {accountDocumentFormData.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <CustomInput
                      type="text"
                      placeholder="Please enter title"
                      label="Document Title"
                      name="documentTitle"
                      icon="IoDocumentText"
                      value={doc.title || ""}
                      onChange={(e) => handleChangeDocument(index, e)}
                      disabled={!uploadStatus}
                    />
                  )}
                </div>
              ))}
            </div>
            <div>
              <label className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                Description
              </label>
              {accountDocumentFormData.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <>
                      <textarea
                        id="message"
                        rows="4"
                        className={`block p-2.5 w-full text-sm bg-gray-50 rounded-lg border  focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Write here..."
                        value={doc.description || ""}
                        disabled={!uploadStatus}
                        onChange={(e) => handleChangeDescription(index, e)}
                      ></textarea>

                    </>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-between mt-8 sm:mt-10 mb-8">
            <div className="flex-[0.1]">
              <button
                className="flex items-center justify-center bg-custom-blue text-white font-normal py-2 px-4 h-9 rounded-xl"
                onClick={handleAddDocument}
                disabled={!uploadStatus}
              >
                <span className="text-lg sm:text-xl mr-1 h-5">
                  <CustomIcon name={"TiPlus"} />
                </span>
                Add
              </button>
            </div>
            <div className="flex-[0.7]">
              {dropdownOpen && (
                <div>
                  <div className="border-b-2 text-gray-500 mt-4 sm:mt-6 w-full sm:w-[100%]"></div>
                  <div className="relative w-full sm:w-[100%]">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-lg sm:text-xl text-gray-600 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            S.No.
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Document Type
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Document Title
                          </th>
                          <th scope="col" className="px-6 py-3"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {accountDocuments &&
                          accountDocuments?.map((doc, index) => (
                            <tr key={index} className="dark:bg-gray-800 mt-5">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {index + 1}
                              </th>
                              <td className="px-6 py-4">{doc?.type}</td>
                              <td className="px-6 py-4">
                                {doc?.title.toUpperCase()}
                              </td>
                              <td className="px-6 py-4 text-red-500 cursor-pointer">
                                <div
                                  onClick={() => handleChildItemDelete(doc.id)}
                                >
                                  <CustomIcon name={"IoCloseCircle"} />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-lg text-[#004C95] font-bold">GuideLine:</div>
          <div className="mb-4 text-[#0000004D] border"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <div className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                Select Document
              </div>
              <div className="mt-3 flex">
                <div className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl text-blue-600">
                  <label>
                    <CustomIcon name={"CgScreen"} />
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleAnotherFileUpload}
                    />
                  </label>
                </div>
                <div
                  className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                  onClick={handleAnotherDriveOpen}
                  style={{ cursor: "pointer" }}
                >
                  <CustomIcon name={"BsCloudsFill"} />
                </div>
                <div
                  className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                  onClick={handleAnotherDropboxSignIn}
                  style={{ cursor: "pointer" }}
                >
                  <CustomIcon name={"FaDropbox"} />
                </div>
              </div>
              <div className=" text-sm text-red-500 mt-2">
                {found_Document_Title1?.title}
              </div>
            </div>
            <div>
              {documentFormData.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <CustomAddSelect
                      label={"Select Document"}
                      icon={"BsPersonFill"}
                      value={doc?.doc_type || ""}
                      onChange={(e) =>
                        handleSelectAnotherChangeDocument(index, e, "selectedDocument1")
                      }
                      options={guidlineDropDownData}
                      disabled={!uploadStatus1}
                      name2={"GuidelineAccount"}
                    />
                  )}
                </div>
              ))}
            </div>
            <div>
              {documentFormData.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <CustomInput
                      type="text"
                      placeholder="Please enter title"
                      label="Document Title"
                      icon="IoDocumentText"
                      name="Document Title1"
                      value={doc.title || ""}
                      onChange={(e) => handleAnotherChangeDocument(index, e, "Document Title1")}
                      disabled={!uploadStatus1}
                    />
                  )}
                </div>
              ))}
            </div>
            <div>
              <label className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                Description
              </label>
              {documentFormData.document.map((doc, index) => (
                <div key={index}>
                  {doc?.status && (
                    <>
                      <textarea
                        id="message"
                        rows="4"
                        className={`block p-2.5 w-full text-sm bg-gray-50 rounded-lg border  focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Write here..."
                        value={doc.description || ""}
                        onChange={(e) =>
                          handleChangeAnotherDescription(index, e)
                        }
                        disabled={!uploadStatus1}
                      ></textarea>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-between mt-8 sm:mt-10">
            <div className="flex-[0.1]">
              <button
                className="flex items-center justify-center bg-custom-blue text-white font-normal py-2 px-4 h-9 rounded-xl"
                onClick={handleAnotherAddDocument}
                disabled={!uploadStatus1}
              >
                <span className="text-lg sm:text-xl mr-1 h-5">
                  <CustomIcon name={"TiPlus"} />
                </span>
                Add
              </button>
            </div>
            <div className="flex-[0.7]">
              {dropdownOpen1 && (
                <div>
                  <div className="border-b-2 text-gray-500 mt-4 sm:mt-6 w-full sm:w-[50%]"></div>
                  <div className="relative w-full sm:w-[50%]">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-lg sm:text-xl text-gray-600 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            S.No.
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Document Type
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Document Title
                          </th>
                          <th scope="col" className="px-6 py-3"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents1 &&
                          documents1?.map((doc, index) => (
                            <tr key={index} className="dark:bg-gray-800 mt-5">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {index + 1}
                              </th>
                              <td className="px-6 py-4">{doc?.type}</td>
                              <td className="px-6 py-4">
                                {doc?.title.toUpperCase()}
                              </td>
                              <td className="px-6 py-4 text-red-500 cursor-pointer">
                                <div
                                  onClick={() =>
                                    handleChildAnotherItemDelete(doc.id)
                                  }
                                >
                                  <CustomIcon name={"IoCloseCircle"} />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-10 pb-10 flex justify-center">
            <CutomButton
              text={loader ? "" : "Submit"}
              bgColor="#3D5B81"
              width="200"
              onClick={handleButtonClick}
              loader={loader}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAccount;
