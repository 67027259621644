import React, { useState, useEffect, useRef,} from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // Importing icons from react-icons
import AddNewJobField from "./AddNewJobField";
import AddNewField from "./AddNewField";
import AddNewJobClientManager from "./AddNewJobClientManager";
import AddNewJobClient from "./AddNewJobClient";
import { toast } from "react-toastify";

const CustomAddSelect = ({
  icon,
  label,
  name,
  value,
  onChange,
  error,
  disabled,
  options,
  required,
  name1,
  name2,
  CompanyID
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value ? value   : "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("")
  const dropdownRef = useRef(null); // Ref to track the dropdown container

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSelectOption = (selectedValue) => {
    if(selectedValue === "add-new" && label === "Contact Person" && CompanyID.length === 0){
     toast.error("Please Select Company Name");
     return ;
    }
    if (selectedValue === "add-new") {
      setIsModalOpen(true);
    }
    else {
      onChange(selectedValue?.value);
    }
    setSelectedValue(selectedValue?.label)
    setSearchTerm("");
    setIsDropdownOpen(false);
  };

  const filteredOptions = options && options.filter((option) =>
    option?.label ? option?.label.toLowerCase().includes(searchTerm.toLowerCase()) : option
  );



  return (
    <>
      <div className="relative" ref={dropdownRef}>
        <label
          htmlFor={name}
          className="block text-md font-medium leading-6 text-gray-600"
        >
          {label}{" "}
          {required === "true" && <span className="text-red-500 font-medium">*</span>}
        </label>
        <div
          className="relative text-gray-700 w-full flex items-center"
          style={{
            color: "#808080",
            borderRadius: "5px",
            border: "1px solid lightgray",
          }}
        >
          <input
            type="text"
            //placeholder={label}
            value={searchTerm || selectedValue}
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={disabled}
            className="py-1.5 w-full px-3 leading-tight text-gray-700 focus:outline-none focus:ring-2 focus:ring-light-blue-50"
            style={{
              color: "#4b5563",
              backgroundColor: disabled ? "lightgray" : "white",
              borderRadius: "5px",
              fontSize: "14px",
            }}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          />
          <span
            className="absolute right-3 cursor-pointer"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {isDropdownOpen ? (
              <FiChevronUp className="text-gray-600" />
            ) : (
              <FiChevronDown className="text-gray-600" />
            )}
          </span>
        </div>
        {isDropdownOpen && (
          <ul className="absolute left-0 right-0 z-10 max-h-60 overflow-auto bg-white shadow-md border mt-1">
            {
              label === "Client category" ? (
                <></>
              ) : (
                <>
                  <li
                    onClick={() => handleSelectOption("add-new")}
                    className="px-4 py-2 text-blue-500 cursor-pointer hover:bg-gray-200"
                  >
                    Add New
                  </li>
                </>
              )
            }
            {filteredOptions.length ? (
              <>

                {
                  filteredOptions.map((option, index) => (

                    <li
                      key={index}
                      onClick={() => handleSelectOption(option)}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    >
                      {option.label}
                    </li>



                  ))

                }

              </>)
              : (
                <li className="px-4 py-2 text-gray-500">No results found</li>
              )
            }


          </ul>
        )}
        {error &&
          <>
            <p className="text-red-500 text-sm">{error}</p>

          </>

        }
        {/* {
          (
            <>
              {
                label !== "Client category" && filteredOptions?.length === 0 && <p className="text-red-500 text-sm">No Data Found</p>
              }
            </>
          )
        } */}
      </div>
      {isModalOpen &&
        (name1 === "Job" && label !== "Company Name" && label !== "Contact Person" && label !== "Company" ? (
          <AddNewJobField
            isModalOpen={isModalOpen}
            label={label}
            close={() => setIsModalOpen(false)}
            icon={icon}
          />
        ) : (
          <>
            {
              ((name1 === "Job" && label === "Company Name") || (name2 === "contact" && label === "Company")) ? (
                <>
                  <AddNewJobClient
                    isModalOpen={isModalOpen}
                    label={label}
                    close={() => setIsModalOpen(false)}
                    // onClick={onAddNew}
                    icon={icon}
                  />
                </>
              ) : (
                <>
                  {
                    (name1 === "Job" && label === "Contact Person") ? (
                      <>
                        <AddNewJobClientManager
                          isModalOpen={isModalOpen}
                          label={label}
                          close={() => setIsModalOpen(false)}
                          //onClick={onAddNew}
                          icon={icon}
                          CompanyID={CompanyID}
                        />
                      </>
                    ) : (
                      <>
                        <AddNewField
                          isModalOpen={isModalOpen}
                          label={label}
                          close={() => setIsModalOpen(false)}
                          //onClick={onAddNew}
                          icon={icon}
                          name2={name2}
                        />
                      </>
                    )
                  }

                </>
              )
            }

          </>
        ))}
    </>
  );
};

export default CustomAddSelect;
