import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3500";

export const patchJob = createAsyncThunk(
  "Patch/job",
  async ({ newData,endPoint }, { rejectWithValue }) => {
    try {
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.patch(
        `${apiUrl}/api/${endPoint}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        toast.success(`${response?.data?.message}`);
      }
      return response?.data;
    } catch (error) {
      toast.error("Please add all required field");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const putJob = createAsyncThunk(
  "Put/job",
  async ({ newData,endPoint, name2 }, { rejectWithValue }) => {
    try {
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      let response ;
      if(name2 === "account"){
         response = await axios.put(
          `${apiUrl}/api/${name2}/${endPoint}`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (name2 === "contact"){
        response = await axios.put(
          `${apiUrl}/api/${name2}/${endPoint}`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (name2 === "candidate"){
        response = await axios.put(
          `${apiUrl}/api/${name2}/${endPoint}`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else if (name2 === "job"){
        response = await axios.put(
          `${apiUrl}/api/${name2}/${endPoint}`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      else{
        response = await axios.put(
          `${apiUrl}/api/${endPoint}`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      
      if (response?.data) {
        toast.success(`${response?.data?.message}`);
      }
      return response?.data;
    } catch (error) {
      toast.error("Please add all required field");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const putGuidelineJob = createAsyncThunk(
  "guideline/job",
  async ({ newData,accountName }, { rejectWithValue }) => {
    try {
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      let response = await axios.put(
          `${apiUrl}/api/${accountName}/updateGLDocumentList`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      if (response?.data) {
        toast.success(`${response?.data?.message}`);
      }
      return response?.data;
    } catch (error) {
      toast.error(error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const jobpatchDropDown = createSlice({
  name: "JobDropDown",
  initialState: {
    loading: false,
    message: "",
    getNewData: [],
    getPutDropDown:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(patchJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchJob.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data){
        state.message = action?.payload?.message;
        state.getNewData = action.payload?.data;
        }
      })
      .addCase(patchJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(putJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(putJob.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data){
        state.message = action?.payload?.message;
        state.getPutDropDown = action.payload?.data;
        }
      })
      .addCase(putJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(putGuidelineJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(putGuidelineJob.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data){
        state.message = action?.payload?.message;
        state.getPutDropDown = action.payload?.data;
        }
      })
      .addCase(putGuidelineJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
  },
});

export default jobpatchDropDown.reducer;
