import React from 'react';

const Layout = ({ children, gradientFrom, gradientTo  }) => {
  return (
    <div
      className={`min-h-screen w-full flex flex-col`}
      style={{
        backgroundImage: `linear-gradient(to right, ${gradientFrom}, ${gradientTo})`,
      }}
    >
      {children}
    </div>
  );
};

export default Layout;
//#6D90AE

//? gradientTo : '#3D5B81'