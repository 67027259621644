import React, { useState } from "react";
import CustomIcon from "./CustomIcon";

const CustomPassword = ({ label, placeholder, value, onChange, error }) => {
  const [showPassword, setShowPassword] = useState(false);

  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div >
        <label
          htmlFor="password"
          className="block text-sm md:text-md font-medium leading-6 text-gray-600"
        >
          {label} <span className="text-red-500 font-medium">*</span>
        </label>
        <div 
        className="relative border-gray-300" 
        style={{
         borderRadius:"10px"
        }}
         >
          <input
            type={showPassword ? "text" : "password"}
            //placeholder={placeholder}
            value={value}
            onChange={onChange}
            required
            className=" py-1.5   w-full   px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
          style={{borderRadius:"5px", border:"1px solid lightgray", fontSize:"14px"}}
          />
          {/* <span
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl md:text-2xl"
            style={{ color: "#3d5b81", fontSize: "27px" }}
          >
            <CustomIcon name={"TbPasswordUser"} />
          </span> */}
          <span
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xl md:text-2xl cursor-pointer"
            style={{ color: "#3d5b81", fontSize: "20px" }}
            onClick={passwordVisibility}
          >
            {showPassword ? (
              <CustomIcon name={"IoEyeSharp"} />
            ) : (
              <CustomIcon name={"FaEyeSlash"} />
            )}
          </span>
        </div>
        {error && (
          <p className="mt-1 text-sm text-red-500">{error}</p>
        )}
      </div>
    </>
  );
};

export default CustomPassword;
