import React, { useState, useContext } from "react";

import screenImg from "../assets/images/screenImg.png";
import CutomButton from "../components/CutomButton";
import CustomPassword from "../components/CustomPassword";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { ContextData } from "../Context/context";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveCard from "../components/ResponsiveCard";


const CreatePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const { response } = useSelector((state) => state?.auth);
  const {forgetPasswordEmail} = useContext(ContextData)


  const getLogin = () => {
    // navigate("/");
  };
  const getVerify = () => {
    // navigate("/verify-otp");
  };
 const handleUpdate = () =>{

  const passwordCriteria = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{9,}$/;

    if (!passwordCriteria.test(newPassword)) {
      setError("Password must be at least 9 characters long, letter.");
      return;
    }
 

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match!");
      navigate("/login");
      return;
    }

    // dispatch(createpassword({ newPassword, confirmPassword, forgetPasswordEmail }));
    setNewPassword("");
  setConfirmPassword("");
 }
  
  const uiContent = () => {
    return (
      <div className="flex flex-col space-y-4">
        <div>
          <CustomPassword
            type={"password"}
            placeholder={"Enter New Password"}
            label={"New Password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="text-red-500 text-sm mt-2">
            {error}
          </div>
        <div>
          <CustomPassword
            type={"password"}
            placeholder={"Enter Confirm Password"}
            label={"Confirm Password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {error && (
          <div className="text-red-500 text-sm mt-2">
            {error}
          </div>
        )}
        <div className=" flex justify-center">
          <CutomButton text={"Update"} bgColor={"#3D5B81"} width={"200"} onClick={handleUpdate} />
        </div>
      </div>
    )
  }
  return (
    <Layout gradientFrom="#6D90AE" gradientTo="#3D5B81">

      <ResponsiveCard imageSrc={screenImg} gradientFrom="#6D90AE" gradientTo="#3D5B81" textHeading="Create Password"
        dataContent={uiContent()}
      />
    </Layout>
  );
};

export default CreatePassword;
