import React, { useState, useEffect, useRef } from "react";
import CustomIcon from "./CustomIcon";

const SingleSearchableSelect = ({
    icon,
    label,
    name,
    value,
    onChange,
    error,
    disabled,
    options,
    required,
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleChange = (selectedValue) => {
        onChange(selectedValue); // Update parent state
        setSearchTerm(""); // Clear the search bar
        setIsDropdownOpen(false); // Close dropdown
    };

    const handleClearSelection = (e) => {
        e.stopPropagation(); // Prevent dropdown toggle
        onChange(""); // Clear the selected value in the parent
        setSearchTerm(""); // Clear search term
    };

    const filteredOptions = options?.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleOutsideClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    return (
        <div>
            <label
                htmlFor={name}
                className="block text-md font-medium leading-6 text-gray-600"
            >
                {label}{" "}
                {required && <span className="text-red-500 font-medium">*</span>}
            </label>
            <div
                ref={dropdownRef}
                className="relative text-gray-700"
                style={{
                    color: "#808080",
                    borderRadius: "5px",
                    border: "1px solid lightgray",
                }}
            >

                <div
                    className="py-1.5 px-3 w-full bg-white cursor-pointer flex justify-between items-center"
                    onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
                    style={{ borderRadius: "5px" }}
                >
                    <span className="text-gray-700">
                        {value || `Select ${label}`}
                    </span>
                    <div className="flex items-center gap-2">
                        {

                            isDropdownOpen ? (<>
                                <CustomIcon name="IoChevronUpSharp" />
                            </>) : (
                                <>
                                    <CustomIcon name="LuChevronDown" />
                                </>
                            )
                        }


                    </div>
                </div>
                {isDropdownOpen && (
                    <div
                        className="absolute top-full left-0 w-full bg-white z-10 border border-gray-300 rounded-md shadow-md"
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                        {/* Search Bar */}
                        <div className="p-2 border-b border-gray-300">
                            <input
                                type="text"
                                placeholder={`Search ${label}`}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="w-full px-3 py-1 text-gray-700 border rounded focus:outline-none focus:ring focus:ring-light-blue-50"
                            />
                        </div>

                        {/* Options List */}
                        <ul className="max-h-48">
                            {filteredOptions && filteredOptions.length > 0 ? (
                                filteredOptions.map((option, index) => (
                                    <li
                                        key={index}
                                        className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${option.label === value ? "bg-gray-100 font-medium" : ""
                                            }`}
                                        onClick={() => handleChange(option.label)}
                                    >
                                        {option.label}
                                    </li>
                                ))
                            ) : (
                                <li className="px-4 py-2 text-gray-500">No options found</li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>
    );
};

export default SingleSearchableSelect;
