import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SkeletonLoader from "../components/Skeleton/SkeletonLoader"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./PrivateRoute";
import { Country_DropDown, company_DropDown, status_DropDown, Industry_DropDown, leadDropDown, sourceDropDown } from '../stores/Reducers/dropDownApiReducers';
import { Contactlist } from '../stores/Reducers/newContact';
import DashboardPage from '../pages/Dashboard';
import DashboardPageData from '../pages/DashboardPageData';
import NewContact from '../pages/NewContact/NewContact';
import CreatePassword from '../pages/CreatePassword';
import NewAccount from '../pages/NewAccount/NewAccount';
import CandiDate from '../pages/CandiDate/Candidate';
import JobCandidateSubmission from '../pages/GetJob/JobCandidateSubmission';
import JobDetails from '../pages/GetJob/JobDetails';
import AccountListData from '../pages/AllContactList/Accountlist';
import AllContectList from '../pages/AllContactList/AllContectList';
import AccountData from '../pages/AllAccountList/AccountListData';
import AllCandiDateData from '../pages/GetCandiDate/CandiDateData';
import LeadsFrom from '../pages/Leads/ContactLeads';
import EditLeadsFrom from '../pages/Leads/EditContactLeads';
import AllLeads from '../pages/Leads/AllLeads';
import CreateInvoice from '../pages/Leads/CreateInvoice';
import JobsForm from '../pages/JobList/NewJob';
import JobsList from '../pages/GetJob/JobData';




const HomePage = lazy(() => import('../pages/Home/HomePage'));
const LoginPage = lazy(() => import('../pages/Login'));
const SignUpPage = lazy(() => import('../pages/SignUp'));
const VerifyOtp = lazy(() => import('../pages/VerifyOtp'));
const ForgetPassword = lazy(() => import('../pages/ForgotPassword'));


const AppRoutes = () => {
  const [tokenStatus, setTokenStatus] = useState(false);
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('auth')

  useEffect(() => {
    if (token) {
      setTokenStatus(true);
    }
    else {
      setTokenStatus(false);
    }
  }, [token])
  useEffect(() => {
    if (token) {

      dispatch(Country_DropDown());
      dispatch(company_DropDown());
      dispatch(status_DropDown());
      dispatch(Industry_DropDown());
      dispatch(Contactlist());
      dispatch(sourceDropDown());
      dispatch(leadDropDown());
    }
  }, [token, dispatch]);




  return (
    <>
      <div>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <Suspense fallback={<div><SkeletonLoader /></div>}>
        <Routes>
          {/* <Route path="/" element={token ? <Navigate to="/dashboard" /> : <HomePage />} /> */}
          <Route path="/" element={tokenStatus ? <Navigate to="/dashboard" /> : <LoginPage />} />
          <Route path="/signup" element={tokenStatus ? <Navigate to="/dashboard" /> : <SignUpPage />} />
          <Route path="/verify-otp" element={tokenStatus ? <Navigate to="/dashboard" /> : <VerifyOtp />} />
          <Route path="/create-password" element={tokenStatus ? <Navigate to="/dashboard" /> : <CreatePassword />} />
          <Route path="/forget-password" element={tokenStatus ? <Navigate to="/dashboard" /> : <ForgetPassword />} />
          {/* Protected Routes */}
          <Route element={<PrivateRoute isAuthenticated={token} />}>
            <Route path="/dashboard/*" element={<DashboardPage />}>
              <Route path="dashoardData" element={<DashboardPageData />} />
              <Route path="newContact" element={<NewContact />} />
              <Route path="account" element={<NewAccount />} />
              <Route path="candiDate" element={<CandiDate />} />
              <Route path="allcontact-list" element={<AllContectList />} />
              <Route path="accountlist" element={<AccountData />} />
              <Route path="candidatelist" element={<AllCandiDateData />} />
              <Route path="job-list" element={<JobDetails />} >
                <Route path="joblistData" element={<JobsList />} />
                <Route path="create-job" element={< JobsForm />} />
                <Route path="candidateSubmission" element={<JobCandidateSubmission />} />
              </Route>
              <Route path="leadsData" element={<AllLeads />} />
              <Route path="leadsFrom" element={<LeadsFrom />} />
              <Route path="editleadsFrom" element={<EditLeadsFrom />} />

              <Route path="invoiceFrom" element={<CreateInvoice />} />
            </Route>
          </Route>

          {/* Fallback route to catch unknown paths */}
          <Route path="*" element={<Navigate to={token ? "/dashboard" : "/login"} replace />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
