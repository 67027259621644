import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3500";


const tokenData = sessionStorage.getItem('auth');

const token = JSON.parse(tokenData);

export const Industry_DropDown = createAsyncThunk(
    'dropdown/industry',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getIndustriesList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const Country_DropDown = createAsyncThunk(
    'dropdown/country',
    async (_, { rejectWithValue }) => {

        try {
            const response = await axios.get(`${apiUrl}/api/getAddress`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const state_DropDown = createAsyncThunk(
    'dropdown/state',
    async ({ countryCode }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getAddress?countryCode=${countryCode}`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const status_DropDown = createAsyncThunk(
    'dropdown/status',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getStatusList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const client_DropDown = createAsyncThunk(
    'dropdown/client',
    async ({ endpoints }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/${endpoints}/getCTList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const documents_Dropdown = createAsyncThunk(
    'dropdown/documents',
    async ({ endpoints }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/${endpoints}/getDocumentList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const Guideline_documents_Dropdown = createAsyncThunk(
    'GuidelInedropdown/documents',
    async ({ endpoints }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/${endpoints}/getGLDocumentList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const city_DropDown = createAsyncThunk(
    'dropdown/city',
    async ({ countryCode, stateCode }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getAddress?countryCode=${countryCode}&stateCode=${stateCode}`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);
export const company_DropDown = createAsyncThunk(
    'dropdown/company',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getCompaniesList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);


export const candiDate_DropDown = createAsyncThunk(
    'dropdown/candiDate',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getCandidateTypeList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const sourceDropDown = createAsyncThunk(
    'source/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getLeadSourceList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const leadDropDown = createAsyncThunk(
    'lead/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getLeadLebelList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const user_Job_DropDown = createAsyncThunk(
    'useJobDropdown/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getUsersList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const user_visa_DropDown = createAsyncThunk(
    'visaDropDown/DropDown',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getVisaTypeList`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

export const user_JobClientManager_DropDown = createAsyncThunk(
    'useJobClientManager/DropDown',
    async ({ clientId }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiUrl}/api/getContactsList?clientId=${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)

const dropDownApiReducer = createSlice({
    name: 'dropDown',
    initialState: {
        user: '',
        loading: false,
        OtpData: [],
        CountryDropDown: [],
        stateDropDown: [],
        cityDropDown: [],
        industryDropDown: [],
        statusDropDown: [],
        clientDropDown: [],
        componayDropDown: [],
        candiDateDropDown: [],
        sourceDropdown: [],
        leadLabelDropDown: [],
        documentDropDown: [],
        guidlineDropDown: [],
        user_JobDropDown: [],
        clientManagerDropDown: [],
        visaDropDown:[],
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder

            .addCase(Country_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(Country_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.CountryDropDown = action.payload.CountryList;
            })
            .addCase(Country_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(state_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(state_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.stateDropDown = action.payload.StateList;
            })
            .addCase(state_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(city_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(city_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.cityDropDown = action.payload.CityList;
            })
            .addCase(city_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(Industry_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(Industry_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.industryDropDown = action?.payload?.industry_dropdown;
            })
            .addCase(Industry_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(client_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(client_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.clientDropDown = action?.payload?.client_type_list;
            })
            .addCase(client_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(company_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(company_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.componayDropDown = action?.payload?.companies_list;
            })
            .addCase(company_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(status_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(status_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.statusDropDown = action?.payload?.status_list;
            })
            .addCase(status_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(candiDate_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(candiDate_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.candiDateDropDown = action?.payload?.candidate_type;
            })
            .addCase(candiDate_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(leadDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(leadDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.leadLabelDropDown = action?.payload?.lead_lebel;
            })
            .addCase(leadDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(sourceDropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sourceDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.sourceDropdown = action?.payload?.lead_source;
            })
            .addCase(sourceDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(documents_Dropdown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(documents_Dropdown.fulfilled, (state, action) => {
                state.loading = false;
                state.documentDropDown = action?.payload?.document_name_list;
            })
            .addCase(documents_Dropdown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(Guideline_documents_Dropdown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(Guideline_documents_Dropdown.fulfilled, (state, action) => {
                state.loading = false;
                state.guidlineDropDown = action?.payload?.document_name_list;
            })
            .addCase(Guideline_documents_Dropdown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(user_JobClientManager_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(user_JobClientManager_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.clientManagerDropDown = action?.payload?.contact_list;
            })
            .addCase(user_JobClientManager_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(user_Job_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(user_Job_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.user_JobDropDown = action?.payload?.users_list;
            })
            .addCase(user_Job_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(user_visa_DropDown.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(user_visa_DropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.visaDropDown = action?.payload?.visa_type;
            })
            .addCase(user_visa_DropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })




    },
});

export default dropDownApiReducer.reducer;

