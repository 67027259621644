import React, { useEffect, useState } from 'react';
import CustomIcon from './CustomIcon';

const useDebounce = (callback, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};

const CustomNumber = ({
  value = [],
  name,
  onChange,
  label,
  type = 'text',
  placeholder,
  errors = [],
  onClick,
  required,
}) => {
  const [inputFields, setInputFields] = useState(
    value.length > 0
      ? value.map((val, index) => ({
        id: `field-${index}`,
        countryCode: val.countryCode || '+1',
        value: val.number || '',
      }))
      : [{ id: 'field-1', countryCode: '+1', value: '' }]
  );

  useEffect(() => {
    const updatedFields = value.length > 0
      ? value.map((val, index) => ({
        id: `field-${index}`,
        countryCode: val.countryCode || '+1',
        value: val.number || '',
      }))
      : [{ id: 'field-1', countryCode: '+1', value: '' }];
    setInputFields(updatedFields);
  }, []);

  useDebounce(() => {
    const valuesArray = inputFields.map((field) => ({
      countryCode: field.countryCode,
      number: field.value,
    }));
    onChange(valuesArray);
  }, 300);

  const handleFieldChange = (id, e) => {
    setInputFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, value: e.target.value } : field
      )
    );
  };

  const handleCountryCodeChange = (id, e) => {
    setInputFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, countryCode: e.target.value } : field
      )
    );
  };

  const handleClick = () => {
    const newId = `field-${inputFields.length + 1}`;
    setInputFields((prevFields) => [
      ...prevFields,
      { id: newId, countryCode: '+1', value: '' },
    ]);
  };

  const handleRemove = (id) => {
    setInputFields((prevFields) =>
      prevFields.filter((input) => input.id !== id)
    );
  };

  return (
    <div>
      <label className="block text-md font-medium leading-6 text-gray-600">
        {label}{
          required === true && <span className="text-red-500 font-medium">*</span>
        }

      </label>
      {inputFields.map((input, index) => (
        <div className='flex flex-col'>
          <div key={input.id} className="flex items-center space-x-2 mb-2">
            <select
              value={input.countryCode}
              onChange={(e) => handleCountryCodeChange(input.id, e)}
              className="border border-gray-300 rounded p-1 text-gray-700 focus:outline-none focus:ring-2 focus:ring-light-blue-50"
              style={{
                width: "40%"
              }}
            >
              <option value="+1">+1 (United States)</option>
              <option value="+7">+7 (Russia)</option>
              <option value="+20">+20 (Egypt)</option>
              <option value="+27">+27 (South Africa)</option>
              <option value="+30">+30 (Greece)</option>
              <option value="+31">+31 (Netherlands)</option>
              <option value="+32">+32 (Belgium)</option>
              <option value="+33">+33 (France)</option>
              <option value="+34">+34 (Spain)</option>
              <option value="+36">+36 (Hungary)</option>
              <option value="+39">+39 (Italy)</option>
              <option value="+40">+40 (Romania)</option>
              <option value="+41">+41 (Switzerland)</option>
              <option value="+43">+43 (Austria)</option>
              <option value="+44">+44 (United Kingdom)</option>
              <option value="+45">+45 (Denmark)</option>
              <option value="+46">+46 (Sweden)</option>
              <option value="+47">+47 (Norway)</option>
              <option value="+48">+48 (Poland)</option>
              <option value="+49">+49 (Germany)</option>
              <option value="+51">+51 (Peru)</option>
              <option value="+52">+52 (Mexico)</option>
              <option value="+53">+53 (Cuba)</option>
              <option value="+54">+54 (Argentina)</option>
              <option value="+55">+55 (Brazil)</option>
              <option value="+56">+56 (Chile)</option>
              <option value="+57">+57 (Colombia)</option>
              <option value="+58">+58 (Venezuela)</option>
              <option value="+60">+60 (Malaysia)</option>
              <option value="+61">+61 (Australia)</option>
              <option value="+62">+62 (Indonesia)</option>
              <option value="+63">+63 (Philippines)</option>
              <option value="+64">+64 (New Zealand)</option>
              <option value="+65">+65 (Singapore)</option>
              <option value="+66">+66 (Thailand)</option>
              <option value="+81">+81 (Japan)</option>
              <option value="+82">+82 (South Korea)</option>
              <option value="+84">+84 (Vietnam)</option>
              <option value="+86">+86 (China)</option>
              <option value="+90">+90 (Turkey)</option>
              <option value="+91">+91 (India)</option>
              <option value="+92">+92 (Pakistan)</option>
              <option value="+93">+93 (Afghanistan)</option>
              <option value="+94">+94 (Sri Lanka)</option>
              <option value="+95">+95 (Myanmar)</option>
              <option value="+98">+98 (Iran)</option>
              <option value="+212">+212 (Morocco)</option>
              <option value="+213">+213 (Algeria)</option>
              <option value="+216">+216 (Tunisia)</option>
              <option value="+218">+218 (Libya)</option>
              <option value="+220">+220 (Gambia)</option>
              <option value="+221">+221 (Senegal)</option>
              <option value="+222">+222 (Mauritania)</option>
              <option value="+223">+223 (Mali)</option>
              <option value="+224">+224 (Guinea)</option>
              <option value="+225">+225 (Ivory Coast)</option>
              <option value="+226">+226 (Burkina Faso)</option>
              <option value="+227">+227 (Niger)</option>
              <option value="+228">+228 (Togo)</option>
              <option value="+229">+229 (Benin)</option>
              <option value="+230">+230 (Mauritius)</option>
              <option value="+231">+231 (Liberia)</option>
              <option value="+232">+232 (Sierra Leone)</option>
              <option value="+233">+233 (Ghana)</option>
              <option value="+234">+234 (Nigeria)</option>
              <option value="+235">+235 (Chad)</option>
              <option value="+236">+236 (Central African Republic)</option>
              <option value="+237">+237 (Cameroon)</option>
              <option value="+238">+238 (Cape Verde)</option>
              <option value="+239">+239 (São Tomé and Príncipe)</option>
              <option value="+240">+240 (Equatorial Guinea)</option>
              <option value="+241">+241 (Gabon)</option>
              <option value="+242">+242 (Republic of the Congo)</option>
              <option value="+243">+243 (Democratic Republic of the Congo)</option>
              <option value="+244">+244 (Angola)</option>
              <option value="+245">+245 (Guinea-Bissau)</option>
              <option value="+246">+246 (Diego Garcia)</option>
              <option value="+247">+247 (Ascension)</option>
              <option value="+248">+248 (Seychelles)</option>
              <option value="+249">+249 (Sudan)</option>
              <option value="+250">+250 (Rwanda)</option>
              <option value="+251">+251 (Ethiopia)</option>
              <option value="+252">+252 (Somalia)</option>
              <option value="+253">+253 (Djibouti)</option>
              <option value="+254">+254 (Kenya)</option>
              <option value="+255">+255 (Tanzania)</option>
              <option value="+256">+256 (Uganda)</option>
              <option value="+257">+257 (Burundi)</option>
              <option value="+258">+258 (Mozambique)</option>
              <option value="+260">+260 (Zambia)</option>
              <option value="+261">+261 (Madagascar)</option>
              <option value="+262">+262 (Réunion)</option>
              <option value="+263">+263 (Zimbabwe)</option>
              <option value="+264">+264 (Namibia)</option>
              <option value="+265">+265 (Malawi)</option>
              <option value="+266">+266 (Lesotho)</option>
              <option value="+267">+267 (Botswana)</option>
              <option value="+268">+268 (Eswatini)</option>
              <option value="+269">+269 (Comoros)</option>
              <option value="+290">+290 (Saint Helena)</option>
              <option value="+291">+291 (Eritrea)</option>
              <option value="+297">+297 (Aruba)</option>
              <option value="+298">+298 (Faroe Islands)</option>
              <option value="+299">+299 (Greenland)</option>
            </select>
            <div className="relative flex-grow">
              <input
                type={type}
                name={name}
                //placeholder={placeholder}
                value={input.value}
                onClick={onClick}
                required
                className="py-1.5 w-full px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
                onChange={(e) => handleFieldChange(input.id, e)}
                style={{
                  borderRadius: '5px',
                  border: '1px solid lightgray',
                  fontSize: '14px',
                }}
              />
              {index === inputFields.length - 1 && (
                <span
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-2xl cursor-pointer"
                  style={{ color: '#3d5b81', fontSize: '24px' }}
                  onClick={handleClick}
                >
                  <CustomIcon name={'FaPlusCircle'} />
                </span>
              )}
              {index !== 0 && (
                <span
                  className="absolute right-12 top-1/2 transform -translate-y-1/2 text-xl cursor-pointer"
                  style={{ color: '#f00', fontSize: '22px' }}
                  onClick={() => handleRemove(input.id)}
                >
                  <CustomIcon name={'FaMinusCircle'} />
                </span>
              )}
            </div>

          </div>
          {errors[index] && (
            <p className="text-red-500 text-sm mt-1">{errors[index]}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default CustomNumber;
