import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ContectList from "./ContectList";
import SegmentGroup from "./SegmentGroup";
import { Contactlist } from "../../stores/Reducers/newContact";
import { AccountData } from "../../stores/Reducers/newAccount";
import { useDispatch, useSelector } from "react-redux";
import ContactlistHeader from "./ContactlistHeader";
import SegmentAll from "./SegmentAll";
import AccountList from "./Accountlist";

const AllContectList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [allAccount, setAllAccount] = useState(false);
  const [allContact, setAllContact] = useState(true);
  const [contactSegment, setContactSegment] = useState(false);
  const [accountSegment,setAccountSegment] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [filterColumnData, setFilterColumnData ] = useState([]);

  useEffect(() =>{
    if(location?.state?.header === "Account"){
      setAllAccount(true);
      setAllContact(false);
    }
    else if(location?.state?.header === "Contact"){
      setAllAccount(false);
      setAllContact(true);
    }
  },[location])


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Contactlist());
    dispatch(AccountData())
  }, [dispatch]);

 
  const handleContactShow = () => {
    setAllContact(false);
    setAllAccount(true);
    setAccountSegment(false);

  }

  const handleAccountShow = () => {
    setAllContact(true);
    setAllAccount(false);
    setContactSegment(false);
  }

  const handleContactSegment = () => {
    setContactSegment(!contactSegment);
    // setAllContact(false);
    // setAllAccount(true);
  }

  const handleAccountSegment = () => {
    setAccountSegment(!accountSegment);
    // setAllContact(true);
    // setAllAccount(false);
  }

  const onCloseFilterShow = () =>{
    setFilterShow(false);
  }


  const toggleDropdown = () =>{
    setFilterShow(true);
  }

  return (
    <>
      
      <div
        className="min-w-full px-4 py-6 bg-[#F2F3F3] border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700"
        style={{ height: "87.5vh" }}
      >
        
        {
          allContact ? (
            <>
            <ContactlistHeader  title={"ALL Contacts List"} handleContact={handleContactShow} handleContactSegment={handleContactSegment} isOpen={filterShow} onCloseColumnFilter={onCloseFilterShow} toggleDropdown={toggleDropdown} filterColumnData={filterColumnData} setFilterColumnData={setFilterColumnData} segmentGroup={contactSegment} />
            <div className="mt-4">
              {
                contactSegment ? (
                  <>
                  <SegmentAll />
                  </>
                ) : (
                  <>
                  <ContectList setFilterColumnData={setFilterColumnData} filterColumnData={filterColumnData} />
                  
                  </>
                )
              }
              
            </div>
            </>
          ) : (
            <>
            {
              allAccount  && (
                <>
                <ContactlistHeader  title={"ALL Accounts List"} handleAccount={handleAccountShow} handleAccountSegment={handleAccountSegment} isOpen={filterShow} onCloseColumnFilter={onCloseFilterShow} toggleDropdown={toggleDropdown} filterColumnData={filterColumnData} setFilterColumnData={setFilterColumnData} segmentGroup={accountSegment}/>
                  <div className="mt-4">
                    {
                      accountSegment ? (
                        <>
                        <SegmentAll />
                        </>
                      ) : (
                        <>
                         <AccountList setFilterColumnData={setFilterColumnData} filterColumnData={filterColumnData} />
                        </>
                      )
                    }
                  </div>
                </>
              )
            }
            </>
          )
        }
        
      </div>
    </>
  );
};

export default AllContectList;
