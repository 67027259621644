import React, { useState } from "react";
// import CustomIcon from "../../components/CustomIcon";
import { useNavigate, useLocation } from "react-router-dom";
import CustomIcon from "../../components/CustomIcon";
import CandiDateFilter from "./CandidateFilter";

const CandiDateHeader = ({
  isOpen,
  title,
  closeFilter,
  openFilter,
  setFilterColumnData,
}) => {
  const navigate = useNavigate();

  const handleNavigateCandiDate = () => {
    let data = {
      jobId : null,
      status:true
    }
    navigate("/dashboard/candiDate", {
      state: { from: "/dashboard/candidatelist",   state:data },
    });
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-center flex-wrap space-y-4 md:space-y-0">
        <div style={{ flex: 0.2 }}>
          <div className="text-lg md:text-xl font-bold text-custom-blue-light">
            {title === "All CandiDate list" ? "CandiDate" :  title}
          </div>
        </div>
        <div
          style={{
            flex: 0.8,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {title && (
            <>
              <div
                className="w-full md:w-auto px-2 flex items-center border border-[#6b829e] py-1 text-center bg-[#3e5c82] rounded-full text-white"
                onClick={handleNavigateCandiDate}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <button>
                  <CustomIcon name={"TiPlus"} />
                </button>
                <span> Add CandiDate</span>
              </div>
            </>
          )}

          <button className="mt-1 text-[#3e5c82]" onClick={openFilter}>
            <CustomIcon name={"IoFilter"} />
          </button>
          {/* <button className="text-[#3e5c82]">
            <CustomIcon
              name={"FaEdit"}
              size="text-base md:text-xl lg:text-2xl"
            />
          </button> */}
        </div>
      </div>
      {title && (
        <>
          {isOpen && (
            <CandiDateFilter
              isOpen={isOpen}
              onClose={closeFilter}
              title={title}
              setFilterColumnData={setFilterColumnData}
            />
          )}
        </>
      )}
    </>
  );
};

export default CandiDateHeader;
