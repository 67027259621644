import React, { useState, useEffect, useMemo } from "react";
import CustomInput from "./CustomInput";
import CutomButton from "./CutomButton";
import CustomIcon from "./CustomIcon";
import CustomSingleNumber from "./CustomSingleNumber";
import CustomSelect from "./CustomSelect";
import { Job_User_DropDown, JobStatusDropDown, } from "../stores/Reducers/jobDropDownReducer";
import { useSelector, useDispatch } from "react-redux";
import {
    state_DropDown,
    city_DropDown, user_JobClientManager_DropDown, client_DropDown
} from "../stores/Reducers/dropDownApiReducers";
import { createContact } from "../stores/Reducers/newContact";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CustomAddSelectWithoutAddNew from "./CustomAddSelectWithoutAddNew";


const AddNewJobClientManager = ({ isModalOpen, close, label, onClick, icon, CompanyID }) => {
    const dispatch = useDispatch();

    const [addField, setAddField] = useState("");

    useEffect(() => {
        let endpoints = "contact";
        dispatch(client_DropDown({endpoints}));
        dispatch(Job_User_DropDown());
        dispatch(JobStatusDropDown());
    }, [])

    



    const {
        CountryDropDown,
        stateDropDown,
        cityDropDown,
        statusDropDown,
        clientDropDown

    } = useSelector((data) => data.dropDown);

    const {
        jobStatusDropDown,
        jobUserDropDown,
        jobClientDropDown,


    } = useSelector((data) => data.jobDropDown);

    const companyData = jobClientDropDown && jobClientDropDown.find((item) => item?.clientId === CompanyID)

    const client_TypeDropDwon = useMemo(() => {
        return clientDropDown?.map((item) => ({
            value: item,
            label: item,
        }));
    }, [clientDropDown]);


    const jobUserData = useMemo(() => {
        return jobUserDropDown?.map((item) => ({
            label: item?.user,
            value: item?.userId,
            code: item?.user
        }));
    }, [jobUserDropDown])

    const [countryName, setCountryName] = useState("");
    const [statesName, setStatesName] = useState("");
    const [cityName, setCityName] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [numberValidation, setNumberValidation] = useState(false);
    const [numberError, setNumberError] = useState([]);

    const [uiData, setUiData] = useState([
        { id: "1", name: "Full Name", value: "", type: "text", error: "", required: "true" },
        { id: "2", name: "Role", value: "", type: "text", error: "", required: "true" },
        { id: "3", name: "Client Type", value: "", type: "dropDown", error: "", required: "true" },
        { id: "4", name: "Company", value: `${companyData?.client_name ? companyData?.client_name : ""}`, type: "text", error: "", required: "true" },
        { id: "5", name: "Contact Number", value: [], type: "text", error: "", required: "false" },
        { id: "6", name: "Email id", value: "", type: "text", error: "", required: "true" },
        { id: "7", name: "Ownership", value: "", type: "dropDown", error: "", required: "true" },
        { id: "8", name: "Country", value: "", type: "dropDown", error: "", required: "true" },
        { id: "9", name: "State", value: "", type: "dropDown", error: "", required: "true" },
        { id: "10", name: "City", value: "", type: "dropDown", error: "", required: "false" },
        { id: "11", name: "Address", value: "", type: "text", error: "", required: "false" },
        { id: "12", name: "Zip Code", value: "", type: "text", error: "", required: "false" },
        { id: "13", name: "Linkedin Profile", value: "", type: "text", error: "", required: "false" },
        { id: "14", name: "Twitter Profile", value: "", type: "text", error: "", required: "false" },
        { id: "15", name: "facebook Profile", value: "", type: "text", error: "", required: "false" },
        { id: "16", name: "Status", value: "", type: "dropDown", error: "", required: "true" },
        { id: "17", name: "Notes", value: "", type: "textArea", error: "", required: "false" }
    ]);




    const validateOfficialNumbers = (officialNumbers) => {
        let errors = [];
        const countryCodePattern = /^\+\d{1,4}$/;
        const countryCodeLengths = {
            '+1': 10,
            '+7': 10,    
            '+20': 10,   
            '+27': 9,    
            '+30': 10,   
            '+31': 9,    
            '+32': 9,    
            '+33': 9,    
            '+34': 9,    
            '+36': 9,    
            '+39': 10,   
            '+40': 10,   
            '+41': 9,    
            '+43': 10,   // Austria
            '+44': 10,   // United Kingdom
            '+45': 8,    // Denmark
            '+46': 9,    // Sweden
            '+47': 8,    // Norway
            '+48': 9,    // Poland
            '+49': 10,   // Germany
            '+51': 9,    // Peru
            '+52': 10,   // Mexico
            '+53': 8,    // Cuba
            '+54': 10,   // Argentina
            '+55': 10,   // Brazil
            '+56': 9,    
            '+57': 10,   
            '+58': 10,   
            '+60': 9,    
            '+61': 9,    
            '+62': 10,   
            '+63': 10,   
            '+64': 9,    
            '+65': 8,    
            '+66': 9,    
            '+81': 10,   
            '+82': 10,   
            '+84': 9,    
            '+86': 11,   
            '+90': 10,   
            '+91': 10,   
            '+92': 10,   
            '+93': 9,    
            '+94': 9,    
            '+95': 9,    
            '+98': 10,   
            '+212': 9,   
            '+213': 9,   
            '+216': 8,   
            '+218': 9,   
            '+220': 7,   
            '+221': 9,   
            '+222': 9,   
            '+223': 8,   
            '+224': 9,   
            '+225': 8,   
            '+226': 8,   
            '+227': 8,   
            '+228': 8,   
            '+229': 8,   
            '+230': 8,   
            '+231': 8,   
            '+232': 8,   
            '+233': 9,   
            '+234': 10,  
            '+235': 8,   
            '+236': 8,   
            '+237': 9,   
            '+238': 7,   
            '+239': 7,   
            '+240': 9,   
            '+241': 9,   
            '+242': 9,   
            '+243': 9,   
            '+244': 9,   
            '+245': 7,   
            '+246': 7,   
            '+248': 7,   
            '+249': 9,   
            '+250': 9,   
            '+251': 9,   
            '+252': 9,   
            '+253': 9,   
            '+254': 10,  
            '+255': 9,   
            '+256': 9,   
            '+257': 8,   
            '+258': 9,   
            '+260': 9,   
            '+261': 9,   
            '+262': 9,   
            '+263': 9,   
            '+264': 9,   
            '+265': 9,   
            '+266': 8,   
            '+267': 8,   
            '+268': 8,   
            '+269': 7,   
            '+290': 5,   
            '+291': 9,   
            '+297': 7,   
            '+298': 6,   
            '+299': 6,   
            
        };

        errorStatus && officialNumbers.forEach((entry, index) => {
            const { countryCode, number } = entry;
            const resolvedCountryCode = countryCode || '+1';
            const numberLength = countryCodeLengths[resolvedCountryCode];
            const numberPattern = new RegExp(`^[0-9]{${numberLength}}$`)

            if (!resolvedCountryCode || !countryCodePattern.test(resolvedCountryCode)) {
                errors[index] = 'Invalid or missing country code.';
            }
            else if (!number || number.length === 0) {
                errors[index] = 'This field is required.';
            }
            else if (number.length !== numberLength) {
                errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
            }
            else if (!numberPattern.test(number)) {
                errors[index] = 'Invalid number. Must contain only digits.';
            }
            else if (
                countryCodeLengths[resolvedCountryCode] &&
                number.length !== countryCodeLengths[resolvedCountryCode]
            ) {
                errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
            }
            else {
                setNumberValidation(true)
            }
        });

        return errors;
    };

    const handleNumberField = (value, id) => {


        const validationNumber = validateOfficialNumbers(value);
        setNumberError(validationNumber);
        setUiData((prevData) =>
            prevData.map((field) => {
                if (field.id === id) {
                    return { ...field, value: value };
                }
                return field;
            })
        );
    }

    const keyValueData = uiData.reduce((acc, field) => {
        acc[field.name] = field.value;
        return acc;
    }, {});






    const handleSubmit = async () => {
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const mobileRegex = /^[0-9]{10,15}$/;
        const zipCodeRegex = /^\d{1,6}$/;
        setErrorStatus(true);
        const updatedUiData = uiData.map((field) => {
            if (field.required === "true" && !field.value) {
                field.error = `${field.name} is required`;
                isValid = false;
            } else if (field.name === "Email id" && !emailRegex.test(field.value)) {
                field.error = "Invalid email address";
                isValid = false;
            }
            //  else if (field.name === "Contact Number" && !mobileRegex.test(field.value)) {
            //     field.error = "Invalid mobile number. It should contain 10-15 digits.";
            //     isValid = false;
            // }
            else if (field.name === "Zip Code" && field.value && !zipCodeRegex.test(field.value)) {
                field.error = "Invalid zip code. It should contain 5-10 digits.";
                isValid = false;
            } else {
                field.error = "";
            }
            return field;
        });
        let ownerData = keyValueData["Ownership"] && keyValueData["Ownership"]
        let OwnerDataArray = [];
        OwnerDataArray.push(ownerData);

        setUiData(updatedUiData);

        if (isValid && numberValidation) {
            const newData = {
                full_name: keyValueData["Full Name"],
                role: keyValueData["Role"],
                company: CompanyID,
                client_type: keyValueData["Client Type"],
                email: [keyValueData["Email id"]],
                mobile: [keyValueData["Contact Number"]],
                country: keyValueData["Country"],
                state: keyValueData["State"],
                city: keyValueData["City"],
                address: keyValueData["Address"],
                urls: [{
                    linkedin_url: keyValueData["Linkedin Profile"],
                    twitter_Profile_Url: keyValueData["Twitter Profile"],
                    facebook_url: keyValueData["facebook Profile"]
                }],
                zipcode: keyValueData["Zip Code"],
                status: keyValueData["Status"],
                notes: keyValueData["Notes"],
                allow_ownership: OwnerDataArray,
            };

            const apiResponse = await dispatch(createContact({ newData }))
                .then((res) => res)
                .catch((err) => console.log(err));

            if (apiResponse?.meta?.requestStatus === "fulfilled") {
                await dispatch(user_JobClientManager_DropDown());
                close();
                return;
            }
        }
    };



    useEffect(() => {
        if (countryName) {
            const country = CountryDropDown.find(
                (item) => item.name === countryName
            );
            if (country) {
                dispatch(state_DropDown({ countryCode: country.isoCode }));
            }
        }
    }, [countryName, dispatch]);

    useEffect(() => {
        if (statesName) {
            const state = stateDropDown.find(
                (item) => item.name === statesName
            );
            const country = CountryDropDown.find(
                (item) => item.name === countryName
            );
            if (state && country) {
                dispatch(
                    city_DropDown({
                        countryCode: country.isoCode,
                        stateCode: state.isoCode,
                    })
                );
            }
        }
    }, [statesName, dispatch])

    const countryData = useMemo(() => {
        return CountryDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [CountryDropDown]);

    const stateData = useMemo(() => {
        return stateDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [stateDropDown]);


    const cityData = useMemo(() => {
        return cityDropDown?.map((item) => ({
            label: item?.name,
            code: item?.isoCode,
        }));
    }, [cityDropDown])



    const handleField = (value, id) => {
        let error = "";


        setUiData((prevData) =>
            prevData.map((field) => {
                if (field.id === id) {

                    if (field.name === "Email id") {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        error = emailRegex.test(value) ? "" : "Invalid email format.";
                    }

                    // else if (field.name === "Contact Number") {
                    //     const mobileRegex = /^[0-9]{10}$/;
                    //     error = mobileRegex.test(value) ? "" : "Invalid mobile number. Must be 10 digits.";
                    // }

                    else if (field.name === "Zip Code") {
                        const zipRegex = /^\d{1,6}$/;
                        error = zipRegex.test(value) ? "" : "Invalid ZIP code. Must be 5-6 digits.";
                    }

                    return { ...field, value: value, error: error };
                }
                return field;
            })
        );
    };


    const handleDropDownChange = (e, id) => {
        setUiData((prevData) =>
            prevData.map((field) =>
                field.id === id ? { ...field, value: e, error: "" } : field
            )
        );
    }

    const Status_Drop_Down = useMemo(() => {
        return statusDropDown?.map((item) => ({
            label: item,
            code: item,
            value: item
        }));
    }, [statusDropDown])

    const getOptions = (fieldName) => {
        switch (fieldName) {
            case "Country":
                return countryData;
            case "State":
                return stateData;
            case "City":
                return cityData;
            case "Client Type":
                return client_TypeDropDwon;
            case "Ownership":
                return jobUserData;
            case "Status":
                return Status_Drop_Down;
            default:
                return [];
        }
    };

    const handleClick = () => {
        setErrorStatus(true);
    }
    // Function to render input fields based on the type
    const renderField = (field) => {
        switch (field.type) {
            case "text":
                return (
                    <div key={field.id} className="flex flex-col">
                        {
                            field?.name === "Contact Number" ? (
                                <>
                                    <CustomSingleNumber
                                        label={field.name}
                                        value={field.value}
                                        onChange={(e) => handleNumberField(e, field.id)}
                                        errors={numberError}
                                        onClick={handleClick}
                                    // error={field.error}
                                    //required={field.required}
                                    />
                                </>
                            ) : (
                                <>
                                    <CustomInput
                                        label={field.name}
                                        value={field.value}
                                        onChange={(e) => handleField(e.target.value, field.id)}
                                        error={field.error}
                                        required={field.required}
                                        readOnly={field?.name === "Company" ? true : false}
                                    />
                                </>
                            )
                        }
                    </div>
                );
            case "dropDown":

                return (
                    <div key={field.id}>
                        {
                            ["Client Type", "Ownership", "Status",].includes(field.name) ? (
                                <>
                                    <CustomAddSelectWithoutAddNew
                                        label={field.name}
                                        value={field.value}
                                        options={getOptions(field.name)}
                                        onChange={(e) => handleDropDownChange(e, field.id)}
                                        error={field.error}
                                        required={field.required}
                                    />
                                </>
                            ) : (
                                <>
                                    <CustomSelect
                                        label={field.name}
                                        value={field.value}
                                        options={getOptions(field.name)}
                                        onChange={(e) => handleDropDownChange(e, field.id)}
                                        error={field.error}
                                        required={field.required}
                                    />
                                </>
                            )
                        }


                    </div>
                );
            case "textArea":
                return (
                    <div key={field.id} className="flex flex-col">
                        <label className="text-md font-medium leading-6 text-gray-600">
                            {field.name}
                        </label>
                        <textarea
                            value={field.value}
                            onChange={(e) => handleField(e.target.value, field.id)}
                            placeholder={field.name}
                            className="w-full p-2 border rounded"
                        />
                        {/* {field.error && <span className="text-red-500 text-sm">{field.error}</span>} */}
                    </div>
                );
            default:
                return null;
        }
    };



    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className="relative bg-white rounded-lg shadow dark:bg-gray-700"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "800px",
                        width: "100%",
                        maxHeight: "80vh",
                        overflowY: "scroll"
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                        borderBottom="1px solid #e0e0e0"
                    >
                        <Typography id="modal-modal-title" variant="h6" component="h2" className="ml-4">
                            {`Update ${label}`}
                        </Typography>
                        <IconButton onClick={close} aria-label="close">
                            {/* <CloseIcon /> */}
                            <CustomIcon name="IoCloseSharp" />
                        </IconButton>

                    </Box>

                    <Box p={3}>
                        <div className="grid grid-cols-2 gap-4">
                            {uiData.map((field) => {
                                if (field.type === "dropDown" && (field.name === "Country" || field.name === "State" || field.name === "City")) {
                                    return (
                                        <CustomSelect
                                            key={field.id}
                                            label={field.name}
                                            value={field.value}
                                            onChange={(e) => {
                                                if (field.name === "Country") {
                                                    setCountryName(e);

                                                }
                                                else if (field.name === "State") {
                                                    setStatesName(e);
                                                }
                                                handleDropDownChange(e, field.id)
                                            }

                                            }
                                            options={getOptions(field.name)}
                                            error={field.error}
                                            required={field.required}
                                        />
                                    );
                                } else {
                                    return renderField(field);
                                }
                            })}
                        </div>
                    </Box>

                    <Box mt={4} pb={4} display="flex" justifyContent="center">
                        <CutomButton text={"Submit"} bgColor={"#3D5B81"} width={"200"} onClick={handleSubmit} />
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default AddNewJobClientManager;
