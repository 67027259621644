import { v4 as uuidv4 } from 'uuid';

export const AccountFormState = {
    fullName: '',
    industry: '',
    officialNumber: [],
    email: [],
    companyheadCount: '',
    clientType: '',
    country: "",
    state: "",
    city: '',
    address: '',
    Url: {},
    zipCode: '',
    status: '',
    image: '',
    allowOwnership: [],
    allowAcess: false,
    notes: '',
}

export const ContactFormState = {
    fullName: '',
    role: '',
    officialNumber: [],
    email: [],
    company: '',
    clientType: '',
    country: "",
    state: "",
    city: '',
    address: '',
    Url: {},
    zipCode: '',
    status: '',
    image: '',
    allowOwnership: [],
    allowAcess: false,
    notes: '',
}

export const CandiDateFromState = {
    fullName: '',
    jobTitle: '',
    date_of_birth:'',
    officialNumber: [],
    email: [],
    rate: '',
    candidateType: '',
    country: "",
    state: "",
    city: '',
    address: '',
    visa:'',
    rateCurrency:'',
    rateMin:'',
    rateMax:'',
    rateWorkingTime:'',
    rateSpentTime:'',
    Url: {},
    zipCode: '',
    status: '',
    image: '',
    allowOwnership: [],
    allowAcess: false,
    notes: '',
}

export const CandiDateDocumentFromState = {
    document: [
        {
            id: uuidv4(),
            doc_type: "",
            title: "",
            expiryDate: "",
            url: "",
            status: true
        },
    ],
}

export const CandidateEductaionDocument = {
    document :[
        {
            id:uuidv4(),
            univerSity:"",
            passingYear:"",
            yearsOfExperience:"",
            degree:"",
            status:true
        }
    ]
}

export const ContactDocumentFromState = {
    document: [
        {
            id: uuidv4(),
            doc_type: "",
            title: "",
            description: "",
            url: "",
            status: true
        },
    ],
}

export const AccountDocumentFromState = {
    document: [
        {
            id: uuidv4(),
            doc_type: "",
            title: "",
            description: "",
            url: "",
            status: true
        },
    ],
}

export const CandiDate_Document_Upload_FromState = {
    document: [
        {
            id: uuidv4(),
            doc_type: "",
            title: "",
            description: "",
            url: "",
            status: true
        },
    ],
}


export const documents = [];