import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  state_DropDown,
  city_DropDown,
  candiDate_DropDown,
  documents_Dropdown,
  user_visa_DropDown
} from "../../stores/Reducers/dropDownApiReducers";
import { JobDegreeDropDown } from "../../stores/Reducers/jobDropDownReducer";
import CustomIcon from "../../components/CustomIcon";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomNumber from "../../components/CustomNumber";
import CustomEmail from "../../components/CustomEmail";
import CustomSocialMedia from "../../components/CustomSocialMedia";
import CutomButton from "../../components/CutomButton";
import { ContextData } from "../../Context/context";
import {
  CandiDateDocumentFromState, CandiDateFromState,
  CandidateEductaionDocument
} from "../../Modules/formState";
import { currencyCodes, TimeHour, SpentTime } from "../../Modules/dropDownData";
import { uploadFile } from "../../Modules/Document_Upload";
import { CandiDateSubmission } from "../../stores/Reducers/newCandiDate";
import CustomAddSelect from "../../components/CustomAddSelect";
import { toast } from "react-toastify";
import CustomSingleNumber from "../../components/CustomSingleNumber";


const JobCandidateSubmission = () => {
  const Navigate = useNavigate();
  const location = useLocation();

  const userData = location && location?.state?.candidateData;

  const job_ID = location && location?.state?.jobId

  console.log(userData, location?.state, "sates");

  const {
    setCandiDateDocument,
    candiDateDocument,
  } = useContext(ContextData);

  const dispatch = useDispatch();

  const [candiDatesFromData, setCandiDatesFromData] = useState(
    {


      fullName: userData?.full_name,
      jobTitle: userData?.job_title,
      date_of_birth: userData?.dob,
      officialNumber: userData?.mobile,
      email: userData?.email,
      candidateType: userData?.source_type,
      country: userData?.country,
      state: userData?.state,
      city: userData?.city,
      address: userData?.address,
      visa: userData?.visa_type,
      rateCurrency: userData?.pay_rate?.currency,
      rateMin: userData?.pay_rate?.minimum,
      rateMax: userData?.pay_rate?.maximum,
      rateWorkingTime: userData?.pay_rate?.field1,
      rateSpentTime: userData?.pay_rate?.field2,
      Url: userData?.pay_rate?.urls,
      zipCode: userData?.zipcode,
      status: userData?.status,
      image: userData?.profile_image,
      // allowOwnership: userData?.full_name,
      allowAcess: false,
      notes: userData?.notes,
    }
  )
  const [candiDateArrayDocument, setcandiDateArrayDocument] = useState([]);

  const [errors, setErrors] = useState({});

  const [accessOwnerShip, setAccessOwnerShip] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorEmployerStatus, setErrEmployerStatus] = useState(false);
  const [errorEmployerOfficialStatus, setErrEmployerOfficialStatus] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [emailErrorStatus, setEmailErrorStatus] = useState(false);
  const [education, setEducation] = useState(CandidateEductaionDocument);
  const [addEducation, setAddEducation] = useState([]);
  const [educationStatus, setEducationStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [fileshow, setFileshow] = useState({})
  const found_Document_Title = candiDateDocument?.document && candiDateDocument?.document.find(doc => doc.title);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [cTwocStatus, setCTwocStatus] = useState("");


  useEffect(() => {
    if (userData) {
      setAddEducation(userData?.educational_details);
      setEducationStatus(true);
      setcandiDateArrayDocument(userData?.documents);
      setDropdownOpen(true);

    }
  }, [])



  const [employerDetails, setEmployerDeatils] = useState([
    { id: "1", name: "Full Name", value: userData?.employer_details?.company_name, error: "" },
    { id: "2", name: "Email id", value: userData?.employer_details?.email, error: "" },
    { id: "3", name: "Employer Company Name", value: userData?.employer_details?.company_name, error: "" }
  ])

  const [employerOfficialNumber, setEmployerOfficialNumber] = useState(userData?.employer_details?.official_number || []);
  const [employerOfficialNumberError, setEmployerOfficialNumberError] = useState(userData?.employer_details?.personal_number || []);

  const [employerPhoneNumberError, setEmployerPhoneNumberError] = useState([])

  const [employerPhoneNumber, setEmployerPhoneNumber] = useState([])

  const navigate = useNavigate();

  const dropDownCall = () => {
    dispatch(candiDate_DropDown());
  };

  useEffect(() => {
    dropDownCall();
  }, []);

  useEffect(() => {
    setCandiDateDocument(CandiDateDocumentFromState);
  }, []);

  const {
    jobDegreeDropDown,
  } = useSelector((data) => data.jobDropDown);

  const {
    CountryDropDown,
    stateDropDown,
    cityDropDown,
    industryDropDown,
    statusDropDown,
    componayDropDown,
    clientDropDown,
    candiDateDropDown,
    documentDropDown,
    visaDropDown
  } = useSelector((data) => data.dropDown);

  useEffect(() => {
    dispatch(JobDegreeDropDown());
    dispatch(user_visa_DropDown());
  }, [])

  useEffect(() => {
    if (candiDatesFromData?.country) {
      const country = CountryDropDown.find(
        (item) => item.name === candiDatesFromData.country
      );
      if (country) {
        dispatch(state_DropDown({ countryCode: country.isoCode }));
      }
    }
  }, [candiDatesFromData.country, CountryDropDown, dispatch]);

  useEffect(() => {
    if (candiDatesFromData?.state) {
      const state = stateDropDown.find(
        (item) => item.name === candiDatesFromData.state
      );
      const country = CountryDropDown.find(
        (item) => item.name === candiDatesFromData.country
      );
      if (state && country) {
        dispatch(
          city_DropDown({
            countryCode: country.isoCode,
            stateCode: state.isoCode,
          })
        );
      }
    }
  }, [candiDatesFromData.state, stateDropDown, CountryDropDown, dispatch]);

  useEffect(() => {
    let endpoints = "candidate";
    dispatch(documents_Dropdown({ endpoints }));
  }, [dispatch])

  const handleChange = useCallback(
    (e, name) => {
      const { type, checked, value } = e?.target || {};
      const fieldName = name || e?.target?.name;
      const zipCodeRegex = /^\d{1,6}$/;
      const nameRoleRegex = /^[A-Za-z\s]+$/;
      const roleRegext = /^[A-Za-z\s]+$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: value?.trim()
          ? fieldName === "zipCode" && !zipCodeRegex.test(value)
            ? "Invalid ZIP Code. Must be numeric and up to 6 digits."
            : fieldName === "fullName" && !nameRoleRegex.test(value) ? "please enter correct name" : fieldName === "jobTitle" && !roleRegext.test(value) ? "please enter correct name" : ""
          : "This field is required",
      }));

      setCandiDatesFromData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [fieldName]: type === "checkbox" ? checked : value,
        };
        return updatedFormData;
      });

      if (type === "checkbox") {
        setAccessOwnerShip(!accessOwnerShip);
      }
    },
    [accessOwnerShip]
  );

  const handleChangeDropDown = useCallback(
    (field, value) => {
      setCandiDatesFromData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: value?.trim() ? "" : "This field is required",
      }));
    },
    [setCandiDatesFromData]
  );

  const validateofficialEmailId = (EmailId, length) => {
    const errors = [];
    const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    emailErrorStatus && EmailId.forEach((email, index) => {
      if (emailErrorStatus) {
        if (!email) {
          errors[index] = `This field is required`;
        }
        else if (!emailRegexPattern.test(email)) {
          errors[index] = `Email is Invalid please write correct email`;
        }
        else if (emailRegexPattern.test(email)) {
          errors[index] = ``;
          setEmailValidation(true);
        }

      } else if (!emailRegexPattern.test(email)) {
        errors[index] = `Email is Invalid please write correct email`;
      } else {
        errors[index] = ``;
        setEmailValidation(true);
      }
    });


    return errors;
  };

  const validateEmployerPersonalNumbers = (officialNumbers) => {
    let errors = [];
    const countryCodePattern = /^\+\d{1,4}$/;
    const countryCodeLengths = {
      '+1': 10,
      '+7': 10,    // Russia, Kazakhstan
      '+20': 10,   // Egypt
      '+27': 9,    // South Africa
      '+30': 10,   // Greece
      '+31': 9,    // Netherlands
      '+32': 9,    // Belgium
      '+33': 9,    // France
      '+34': 9,    // Spain
      '+36': 9,    // Hungary
      '+39': 10,   // Italy
      '+40': 10,   // Romania
      '+41': 9,    // Switzerland
      '+43': 10,   // Austria
      '+44': 10,   // United Kingdom
      '+45': 8,    // Denmark
      '+46': 9,    // Sweden
      '+47': 8,    // Norway
      '+48': 9,    // Poland
      '+49': 10,   // Germany
      '+51': 9,    // Peru
      '+52': 10,   // Mexico
      '+53': 8,    // Cuba
      '+54': 10,   // Argentina
      '+55': 10,   // Brazil
      '+56': 9,    // Chile
      '+57': 10,   // Colombia
      '+58': 10,   // Venezuela
      '+60': 9,    // Malaysia
      '+61': 9,    // Australia
      '+62': 10,   // Indonesia
      '+63': 10,   // Philippines
      '+64': 9,    // New Zealand
      '+65': 8,    // Singapore
      '+66': 9,    // Thailand
      '+81': 10,   // Japan
      '+82': 10,   // South Korea
      '+84': 9,    // Vietnam
      '+86': 11,   // China
      '+90': 10,   // Turkey
      '+91': 10,   // India
      '+92': 10,   // Pakistan
      '+93': 9,    // Afghanistan
      '+94': 9,    // Sri Lanka
      '+95': 9,    // Myanmar
      '+98': 10,   // Iran
      '+212': 9,   // Morocco
      '+213': 9,   // Algeria
      '+216': 8,   // Tunisia
      '+218': 9,   // Libya
      '+220': 7,   // Gambia
      '+221': 9,   // Senegal
      '+222': 9,   // Mauritania
      '+223': 8,   // Mali
      '+224': 9,   // Guinea
      '+225': 8,   // Côte d’Ivoire
      '+226': 8,   // Burkina Faso
      '+227': 8,   // Niger
      '+228': 8,   // Togo
      '+229': 8,   // Benin
      '+230': 8,   // Mauritius
      '+231': 8,   // Liberia
      '+232': 8,   // Sierra Leone
      '+233': 9,   // Ghana
      '+234': 10,  // Nigeria
      '+235': 8,   // Chad
      '+236': 8,   // Central African Republic
      '+237': 9,   // Cameroon
      '+238': 7,   // Cape Verde
      '+239': 7,   // São Tomé and Príncipe
      '+240': 9,   // Equatorial Guinea
      '+241': 9,   // Gabon
      '+242': 9,   // Congo (Brazzaville)
      '+243': 9,   // Congo (Kinshasa)
      '+244': 9,   // Angola
      '+245': 7,   // Guinea-Bissau
      '+246': 7,   // Diego Garcia
      '+248': 7,   // Seychelles
      '+249': 9,   // Sudan
      '+250': 9,   // Rwanda
      '+251': 9,   // Ethiopia
      '+252': 9,   // Somalia
      '+253': 9,   // Djibouti
      '+254': 10,  // Kenya
      '+255': 9,   // Tanzania
      '+256': 9,   // Uganda
      '+257': 8,   // Burundi
      '+258': 9,   // Mozambique
      '+260': 9,   // Zambia
      '+261': 9,   // Madagascar
      '+262': 9,   // Réunion/Mayotte
      '+263': 9,   // Zimbabwe
      '+264': 9,   // Namibia
      '+265': 9,   // Malawi
      '+266': 8,   // Lesotho
      '+267': 8,   // Botswana
      '+268': 8,   // Eswatini
      '+269': 7,   // Comoros
      '+290': 5,   // Saint Helena
      '+291': 9,   // Eritrea
      '+297': 7,   // Aruba
      '+298': 6,   // Faroe Islands
      '+299': 6,   // Greenland
      // Add more if needed
    };

    errorEmployerStatus && officialNumbers.forEach((entry, index) => {
      const { countryCode, number } = entry;
      const resolvedCountryCode = countryCode || '+1';
      const numberLength = countryCodeLengths[resolvedCountryCode];
      const numberPattern = new RegExp(`^[0-9]{${numberLength}}$`)

      if (!resolvedCountryCode || !countryCodePattern.test(resolvedCountryCode)) {
        errors[index] = 'Invalid or missing country code.';
      }
      else if (!number || number.length === 0) {
        errors[index] = 'This field is required.';
      }
      else if (number.length !== numberLength) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else if (!numberPattern.test(number)) {
        errors[index] = 'Invalid number. Must contain only digits.';
      }
      else if (
        countryCodeLengths[resolvedCountryCode] &&
        number.length !== countryCodeLengths[resolvedCountryCode]
      ) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else {
        errors[index] = ``;
      }
    });

    return errors;
  };

  const validateEmployerOfficialNumbers = (officialNumbers) => {
    let errors = [];
    const countryCodePattern = /^\+\d{1,4}$/;
    const countryCodeLengths = {
      '+1': 10,
      '+7': 10,    // Russia, Kazakhstan
      '+20': 10,   // Egypt
      '+27': 9,    // South Africa
      '+30': 10,   // Greece
      '+31': 9,    // Netherlands
      '+32': 9,    // Belgium
      '+33': 9,    // France
      '+34': 9,    // Spain
      '+36': 9,    // Hungary
      '+39': 10,   // Italy
      '+40': 10,   // Romania
      '+41': 9,    // Switzerland
      '+43': 10,   // Austria
      '+44': 10,   // United Kingdom
      '+45': 8,    // Denmark
      '+46': 9,    // Sweden
      '+47': 8,    // Norway
      '+48': 9,    // Poland
      '+49': 10,   // Germany
      '+51': 9,    // Peru
      '+52': 10,   // Mexico
      '+53': 8,    // Cuba
      '+54': 10,   // Argentina
      '+55': 10,   // Brazil
      '+56': 9,    // Chile
      '+57': 10,   // Colombia
      '+58': 10,   // Venezuela
      '+60': 9,    // Malaysia
      '+61': 9,    // Australia
      '+62': 10,   // Indonesia
      '+63': 10,   // Philippines
      '+64': 9,    // New Zealand
      '+65': 8,    // Singapore
      '+66': 9,    // Thailand
      '+81': 10,   // Japan
      '+82': 10,   // South Korea
      '+84': 9,    // Vietnam
      '+86': 11,   // China
      '+90': 10,   // Turkey
      '+91': 10,   // India
      '+92': 10,   // Pakistan
      '+93': 9,    // Afghanistan
      '+94': 9,    // Sri Lanka
      '+95': 9,    // Myanmar
      '+98': 10,   // Iran
      '+212': 9,   // Morocco
      '+213': 9,   // Algeria
      '+216': 8,   // Tunisia
      '+218': 9,   // Libya
      '+220': 7,   // Gambia
      '+221': 9,   // Senegal
      '+222': 9,   // Mauritania
      '+223': 8,   // Mali
      '+224': 9,   // Guinea
      '+225': 8,   // Côte d’Ivoire
      '+226': 8,   // Burkina Faso
      '+227': 8,   // Niger
      '+228': 8,   // Togo
      '+229': 8,   // Benin
      '+230': 8,   // Mauritius
      '+231': 8,   // Liberia
      '+232': 8,   // Sierra Leone
      '+233': 9,   // Ghana
      '+234': 10,  // Nigeria
      '+235': 8,   // Chad
      '+236': 8,   // Central African Republic
      '+237': 9,   // Cameroon
      '+238': 7,   // Cape Verde
      '+239': 7,   // São Tomé and Príncipe
      '+240': 9,   // Equatorial Guinea
      '+241': 9,   // Gabon
      '+242': 9,   // Congo (Brazzaville)
      '+243': 9,   // Congo (Kinshasa)
      '+244': 9,   // Angola
      '+245': 7,   // Guinea-Bissau
      '+246': 7,   // Diego Garcia
      '+248': 7,   // Seychelles
      '+249': 9,   // Sudan
      '+250': 9,   // Rwanda
      '+251': 9,   // Ethiopia
      '+252': 9,   // Somalia
      '+253': 9,   // Djibouti
      '+254': 10,  // Kenya
      '+255': 9,   // Tanzania
      '+256': 9,   // Uganda
      '+257': 8,   // Burundi
      '+258': 9,   // Mozambique
      '+260': 9,   // Zambia
      '+261': 9,   // Madagascar
      '+262': 9,   // Réunion/Mayotte
      '+263': 9,   // Zimbabwe
      '+264': 9,   // Namibia
      '+265': 9,   // Malawi
      '+266': 8,   // Lesotho
      '+267': 8,   // Botswana
      '+268': 8,   // Eswatini
      '+269': 7,   // Comoros
      '+290': 5,   // Saint Helena
      '+291': 9,   // Eritrea
      '+297': 7,   // Aruba
      '+298': 6,   // Faroe Islands
      '+299': 6,   // Greenland
      // Add more if needed
    };

    errorEmployerOfficialStatus && officialNumbers.forEach((entry, index) => {
      const { countryCode, number } = entry;
      const resolvedCountryCode = countryCode || '+1';
      const numberLength = countryCodeLengths[resolvedCountryCode];
      const numberPattern = new RegExp(`^[0-9]{${numberLength}}$`)

      if (!resolvedCountryCode || !countryCodePattern.test(resolvedCountryCode)) {
        errors[index] = 'Invalid or missing country code.';
      }
      else if (!number || number.length === 0) {
        errors[index] = 'This field is required.';
      }
      else if (number.length !== numberLength) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else if (!numberPattern.test(number)) {
        errors[index] = 'Invalid number. Must contain only digits.';
      }
      else if (
        countryCodeLengths[resolvedCountryCode] &&
        number.length !== countryCodeLengths[resolvedCountryCode]
      ) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else {
        errors[index] = ``;
      }
    });

    return errors;
  };

  const validateOfficialNumbers = (officialNumbers) => {
    let errors = [];
    const countryCodePattern = /^\+\d{1,4}$/;
    const countryCodeLengths = {
      '+1': 10,
      '+7': 10,    // Russia, Kazakhstan
      '+20': 10,   // Egypt
      '+27': 9,    // South Africa
      '+30': 10,   // Greece
      '+31': 9,    // Netherlands
      '+32': 9,    // Belgium
      '+33': 9,    // France
      '+34': 9,    // Spain
      '+36': 9,    // Hungary
      '+39': 10,   // Italy
      '+40': 10,   // Romania
      '+41': 9,    // Switzerland
      '+43': 10,   // Austria
      '+44': 10,   // United Kingdom
      '+45': 8,    // Denmark
      '+46': 9,    // Sweden
      '+47': 8,    // Norway
      '+48': 9,    // Poland
      '+49': 10,   // Germany
      '+51': 9,    // Peru
      '+52': 10,   // Mexico
      '+53': 8,    // Cuba
      '+54': 10,   // Argentina
      '+55': 10,   // Brazil
      '+56': 9,    // Chile
      '+57': 10,   // Colombia
      '+58': 10,   // Venezuela
      '+60': 9,    // Malaysia
      '+61': 9,    // Australia
      '+62': 10,   // Indonesia
      '+63': 10,   // Philippines
      '+64': 9,    // New Zealand
      '+65': 8,    // Singapore
      '+66': 9,    // Thailand
      '+81': 10,   // Japan
      '+82': 10,   // South Korea
      '+84': 9,    // Vietnam
      '+86': 11,   // China
      '+90': 10,   // Turkey
      '+91': 10,   // India
      '+92': 10,   // Pakistan
      '+93': 9,    // Afghanistan
      '+94': 9,    // Sri Lanka
      '+95': 9,    // Myanmar
      '+98': 10,   // Iran
      '+212': 9,   // Morocco
      '+213': 9,   // Algeria
      '+216': 8,   // Tunisia
      '+218': 9,   // Libya
      '+220': 7,   // Gambia
      '+221': 9,   // Senegal
      '+222': 9,   // Mauritania
      '+223': 8,   // Mali
      '+224': 9,   // Guinea
      '+225': 8,   // Côte d’Ivoire
      '+226': 8,   // Burkina Faso
      '+227': 8,   // Niger
      '+228': 8,   // Togo
      '+229': 8,   // Benin
      '+230': 8,   // Mauritius
      '+231': 8,   // Liberia
      '+232': 8,   // Sierra Leone
      '+233': 9,   // Ghana
      '+234': 10,  // Nigeria
      '+235': 8,   // Chad
      '+236': 8,   // Central African Republic
      '+237': 9,   // Cameroon
      '+238': 7,   // Cape Verde
      '+239': 7,   // São Tomé and Príncipe
      '+240': 9,   // Equatorial Guinea
      '+241': 9,   // Gabon
      '+242': 9,   // Congo (Brazzaville)
      '+243': 9,   // Congo (Kinshasa)
      '+244': 9,   // Angola
      '+245': 7,   // Guinea-Bissau
      '+246': 7,   // Diego Garcia
      '+248': 7,   // Seychelles
      '+249': 9,   // Sudan
      '+250': 9,   // Rwanda
      '+251': 9,   // Ethiopia
      '+252': 9,   // Somalia
      '+253': 9,   // Djibouti
      '+254': 10,  // Kenya
      '+255': 9,   // Tanzania
      '+256': 9,   // Uganda
      '+257': 8,   // Burundi
      '+258': 9,   // Mozambique
      '+260': 9,   // Zambia
      '+261': 9,   // Madagascar
      '+262': 9,   // Réunion/Mayotte
      '+263': 9,   // Zimbabwe
      '+264': 9,   // Namibia
      '+265': 9,   // Malawi
      '+266': 8,   // Lesotho
      '+267': 8,   // Botswana
      '+268': 8,   // Eswatini
      '+269': 7,   // Comoros
      '+290': 5,   // Saint Helena
      '+291': 9,   // Eritrea
      '+297': 7,   // Aruba
      '+298': 6,   // Faroe Islands
      '+299': 6,   // Greenland
      // Add more if needed
    };

    errorStatus && officialNumbers.forEach((entry, index) => {
      const { countryCode, number } = entry;
      const resolvedCountryCode = countryCode || '+1';
      const numberLength = countryCodeLengths[resolvedCountryCode];
      const numberPattern = new RegExp(`^[0-9]{${numberLength}}$`)

      if (!resolvedCountryCode || !countryCodePattern.test(resolvedCountryCode)) {
        errors[index] = 'Invalid or missing country code.';
      }
      else if (!number || number.length === 0) {
        errors[index] = 'This field is required.';
      }
      else if (number.length !== numberLength) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else if (!numberPattern.test(number)) {
        errors[index] = 'Invalid number. Must contain only digits.';
      }
      else if (
        countryCodeLengths[resolvedCountryCode] &&
        number.length !== countryCodeLengths[resolvedCountryCode]
      ) {
        errors[index] = `Invalid number length. Must be ${countryCodeLengths[resolvedCountryCode]} digits for ${resolvedCountryCode}.`;
      }
      else {
        setNumberValidation(true)
      }
    });

    return errors;
  };

  const validate = (FormData) => {
    let formErrors = {};
    let updatedFormData = FormData || candiDatesFromData;

    if (!updatedFormData.fullName)
      formErrors.fullName = "Full Name is required";

    if (!updatedFormData.jobTitle)
      formErrors.jobTitle = "Job Title is required";
    if (!updatedFormData.candidateType)
      formErrors.candidateType = "Source Type is required";
    if (!updatedFormData.country) formErrors.country = "Country is required";
    if (!updatedFormData.state) formErrors.state = "State is required";
    if (!updatedFormData.city) formErrors.city = "City is required";
    if (!updatedFormData.status) formErrors.status = "Status is required";

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleChangeArray = useCallback(
    (data) => {
      setCandiDatesFromData((prevState) => ({
        ...prevState,
        officialNumber: data,
      }));
      const officialNumberErrors = validateOfficialNumbers(data);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (officialNumberErrors.length > 0) {
          newErrors.officialNumber = officialNumberErrors;
        } else {
          delete newErrors.officialNumber;
        }
        return newErrors;
      });
    },
    [candiDatesFromData.officialNumber]
  );

  const handleChangeEmailArray = useCallback(
    (data) => {
      setCandiDatesFromData((prevState) => ({
        ...prevState,
        email: data,
      }));
      const EmailNumberErrors = validateofficialEmailId(data);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (EmailNumberErrors.length > 0) {
          newErrors.email = EmailNumberErrors;
        } else {
          delete newErrors.email;
        }
        return newErrors;
      });
    },
    [candiDatesFromData.email]
  );

  const handleChangeObject = useCallback(
    (data) => {
      setCandiDatesFromData((prevState) => ({
        ...prevState,
        Url: data,
      }));
    },
    [candiDatesFromData.Url]
  );

  const jobDegreeData = useMemo(() => {
    return jobDegreeDropDown?.map((item) => ({
      label: item,
      value: item,
      code: item
    }));
  }, [jobDegreeDropDown])


  const getEmployerValueByName = (name) => {
    const field = employerDetails.find((detail) => detail.name === name);
    return field ? field.value : null;
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoader(true);
    setErrorStatus(true);
    setEmailErrorStatus(true);
    let educational_Details;
    let documentData;
    if (educationStatus) {

      educational_Details = addEducation && addEducation?.map((item) => {
        return {
          university: item?.university,
          passing_year: item?.passingYear,
          //experience: item?.yearsOfExperience,
          degree: item?.degree
        }
      })

    }
    else {
      educational_Details = education && education?.document.map((item) => {
        return {
          university: item?.univerSity,
          passing_year: item?.passingYear,
         // experience: item?.yearsOfExperience,
          degree: item?.degree
        }
      })
    }

    const validataData = validate();
    try {
      if (dropdownOpen) {
        documentData = candiDateArrayDocument.map((item) => ({
          doc_type: item.type,
          title: item.title,
          expiryDate: item.expiryDate,
          url: item.url,
        }));
      }
      else {
        documentData = candiDateDocument?.document && candiDateDocument?.document?.map((item) => ({
          doc_type: item.type,
          title: item.title,
          description: item.description,
          url: item.url,
        }));
      }
      if (validataData && numberValidation && emailValidation) {
        const newData = {
          status: "New",
          job_id: job_ID,
          candidate_id: userData?._id,
          candidate_copy: {
            _id: userData?._id,
            full_name: candiDatesFromData?.fullName,
            job_title: candiDatesFromData?.jobTitle,
            mobile: candiDatesFromData?.officialNumber,
            email: candiDatesFromData?.email,
            source_type: candiDatesFromData?.candidateType,
            country: candiDatesFromData?.country,
            dob: candiDatesFromData?.date_of_birth,
            visa_type: candiDatesFromData?.visa,
            pay_rate: {
              currency: candiDatesFromData?.rateCurrency,
              minimum: candiDatesFromData?.rateMin,
              maximum: candiDatesFromData?.rateMax,
              field1: candiDatesFromData?.rateWorkingTime,
              field2: candiDatesFromData?.rateSpentTime
            },
            state: candiDatesFromData?.state,
            city: candiDatesFromData?.city,
            address: candiDatesFromData?.address,
            urls: candiDatesFromData?.Url,
            zipcode: candiDatesFromData?.zipCode,
            status: candiDatesFromData?.status,
            notes: candiDatesFromData?.notes,
            profile_image: candiDatesFromData?.image,
            documents: documentData,
            educational_details: educational_Details,
            employer_details: {
              full_name: getEmployerValueByName("Full Name"),
              official_number: employerOfficialNumber,
              personal_number: employerPhoneNumber,
              email: getEmployerValueByName("Email id"),
              company_name: getEmployerValueByName("Employer Company Name")
            }
          }
        };


        const apiResponse = await dispatch(CandiDateSubmission({ newData }))
          .then((res) => res)
          .catch((err) => console.log(err));

        if (apiResponse?.meta?.requestStatus === "fulfilled") {
          navigate("/dashboard/job-list/joblistData"
          );
          toast.success("sucess")
          clearForm();
        }
      }
      else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoader(false);
    }
  };


  const clearForm = () => {
    setCandiDateDocument(CandiDateDocumentFromState);
    setcandiDateArrayDocument([]);
    setErrors({});
    setCandiDatesFromData(CandiDateFromState);
    setLoader(false);
    setErrorStatus(false);

  };

  const handleButtonClick = (e) => {
    setLoader(true);
    e.preventDefault();
    handleSubmit(e);
  };

  const countryData = useMemo(() => {
    return CountryDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [CountryDropDown]);

  const stateData = useMemo(() => {
    return stateDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [stateDropDown]);

  const cityData = useMemo(() => {
    return cityDropDown?.map((item) => ({
      label: item?.name,
      code: item?.isoCode,
    }));
  }, [cityDropDown]);

  const Industry_DropDown = useMemo(() => {
    return industryDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [industryDropDown]);

  const Company_DropDwon = useMemo(() => {
    return componayDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [componayDropDown]);

  const Status_DropDwon = useMemo(() => {
    return statusDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [statusDropDown]);

  const candiDateDropDwon = useMemo(() => {
    return candiDateDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [candiDateDropDown]);

  const document_DropDwon = useMemo(() => {
    return documentDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [documentDropDown]);


  const visa_DropDown = useMemo(() => {
    return visaDropDown?.map((item) => ({
      value: item,
      label: item,
    }));
  }, [visaDropDown]);



  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileLocation = await uploadFile(file);
      setFileshow(file);
      if (fileLocation?.Location) {
        toast.success("file is uploaded Sucess");
        setCandiDateDocument(prevState => {
          const hasEmptyTitleDocument = prevState.document.some(doc => doc.title === '');
          const updatedDocuments = hasEmptyTitleDocument
            ? prevState.document.map(doc =>
              doc.title === '' ? { ...doc, title: file.name, url: fileLocation?.Location, description: "" } : doc
            )
            : [...prevState.document, { title: file.name, url: fileLocation?.Location, description: "" }];

          return {
            ...prevState,
            document: updatedDocuments,
          };
        });
      }
      else {
        toast.error("file is not uploaded")
      }
    }
  };

  const handleDriveOpen = () => {
    const googleDriveUrl = "https://drive.google.com/drive/my-drive";
    window.open(googleDriveUrl, '_blank');
  };
  const handleDropboxSignIn = () => {
    const dropboxSignInUrl = "https://www.dropbox.com/login";
    window.open(dropboxSignInUrl, "_blank");
  };

  const handleSelectChangeDocument = useCallback((index, e) => {
    const newTitle = e;
    setCandiDateDocument(prevState => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, doc_type: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  }, [candiDateDocument.document])


  const handleChangeDocument = useCallback((index, e) => {
    const newTitle = e.target.value;
    setCandiDateDocument(prevState => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, title: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  }, []);

  const handleChangeExpiaryDate = (index, e) => {
    const newTitle = e.target.value;
    setCandiDateDocument(prevState => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index ? { ...doc, expiryDate: newTitle } : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  };

  const handleAddDocument = () => {
    if (candiDateDocument?.document && candiDateDocument?.document.length > 0) {
      const newDocuments = candiDateDocument.document.map((doc, index) => ({
        id: candiDateArrayDocument && candiDateArrayDocument.length + doc.id,
        type: doc.doc_type,
        title: doc.title,
        url: doc.url,
        expiryDate: doc.expiryDate
      }));
      setcandiDateArrayDocument(prevDocuments => [...prevDocuments, ...newDocuments]);
      setCandiDateDocument(CandiDateDocumentFromState)
      setDropdownOpen(true);
      setUploadStatus(false);
    }
  };
  const handleChildItemDelete = (id) => {
    setcandiDateArrayDocument(prevDocuments => prevDocuments.filter(doc => doc.id !== id));
  };

  const handleChangeClick = () => {
    setErrorStatus(true);
  }
  const handleChangeEmailClick = () => {
    setEmailErrorStatus(true);
  }

  const currencyCodesData = useMemo(() => {
    return currencyCodes?.map((item) => ({
      label: item,
      code: item,
      value: item
    }));
  }, [currencyCodes]);

  const TimeHourData = useMemo(() => {
    return TimeHour?.map((item) => ({
      label: item?.name,
      code: item?.name,
    }));
  }, [TimeHour]);

  const SpentTimeData = useMemo(() => {
    return SpentTime?.map((item) => ({
      label: item?.name,
      code: item?.name,
    }));
  }, [SpentTime]);


  const handleChangeEducation = (index, e) => {
    const newValue = e.target.value;
    const name = e?.target?.name;

    setEducation(prevState => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index
          ? { ...doc, [name]: newValue }
          : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  };

  const handleSelecteducationDocument = (index, e) => {
    setEducation(prevState => {
      const updatedDocuments = prevState.document.map((doc, i) =>
        i === index
          ? { ...doc, degree: e }
          : doc
      );

      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  }

  const handleAddEducation = () => {
    const isValid = education.document.every(doc =>
      doc.univerSity.trim() !== "" &&
      doc.passingYear.trim() !== "" &&
      //doc.yearsOfExperience.trim() !== "" &&
      doc.degree.trim() !== ""
    );

    if (education?.document && isValid) {
      const newDocuments = education.document.map((doc, index) => ({
        id: addEducation && addEducation?.length + doc.id,
        university: doc.univerSity,
        passingYear: doc.passingYear,
        //yearsOfExperience: doc.yearsOfExperience,
        degree: doc.degree
      }));
      setAddEducation(prevDocuments => [...prevDocuments, ...newDocuments]);
      setEducation(CandidateEductaionDocument)
      setEducationStatus(true);

    }
    else {
      toast.error("please add college Name, Years of experience,  passing year , degree")
    }
  }
  const handleChildEducationItemDelete = (id) => {
    setAddEducation(prevDocuments => prevDocuments.filter(doc => doc.id !== id));
  }

  const handleEmployerChange = (name, newValue) => {
    setEmployerDeatils(prevState =>
      prevState.map(detail =>
        detail.name === name
          ? { ...detail, value: newValue, error: "" }
          : detail
      )
    );
  };

  const handleChangeEmployerOfficialNumber = (data) => {
    const errorData = validateEmployerOfficialNumbers(data);
    setEmployerOfficialNumber(data);
    setEmployerOfficialNumberError(errorData);
  }

  const handleChangeEmployerPersonalNumber = (data) => {
    const errorData = validateEmployerPersonalNumbers(data);
    setEmployerPhoneNumber(data);
    setEmployerPhoneNumberError(errorData);
  }

  const handleOfficialError = () => {
    setErrEmployerOfficialStatus(true);
  }


  const handleOfficialNumberError = () => {
    setErrEmployerStatus(true);
  }

  const handleCancel = () => {
    Navigate("/dashboard/job-list/joblistData")
  }

  return (
    <div className="shadow-md shadow-slate-700 bg-[#F2F3F3] ring-black ring-opacity-50 w-full h-full rounded-xl p-4">
      <div className="bg-[#F2F3F3] rounded-xl">
        <div className="flex flex-col md:flex-row items-center justify-between pt-3 pl-6 text-2xl text-[#004C95] font-bold rounded-t-lg">
          <div className="flex-1 md:flex-[0.2]">
            <p>Edit Caniddate</p>
          </div>
        </div>

        <div className="pt-6 pl-4 pr-4">
          <div className="text-lg text-[#004C95] font-bold">
            Personal Details :
          </div>
          <div className="mb-4 text-[#0000004D] border"></div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="md:mr-4">
              <CustomInput
                type="text"
                name="fullName"
                placeholder="Enter Full Name"
                label="Full Name"
                icon="BsPersonFill"
                value={candiDatesFromData.fullName}
                onChange={handleChange}
                error={errors.fullName}
                required={"true"}
              />
            </div>
            <div>
              <CustomInput
                type="text"
                name="jobTitle"
                placeholder="Enter Job Title"
                label="Job Title"
                icon="BsPersonFill"
                value={candiDatesFromData.jobTitle}
                onChange={handleChange}
                error={errors.jobTitle}
                required={"true"}
              />
            </div>
            <div className="mt-2 md:mr-4">
              <CustomNumber
                type="text"
                name="officialNumber"
                placeholder="Enter Mobile Number"
                label="Mobile Number"
                value={candiDatesFromData.officialNumber}
                onChange={handleChangeArray}
                onClick={handleChangeClick}
                icon={"MdOutlinePhoneIphone"}
                errors={errors.officialNumber}
                required={true}
              />
            </div>
            <div className="mt-2">
              <CustomEmail
                type="email"
                name="email"
                label="Email ID"
                placeholder="Enter Email ID"
                value={candiDatesFromData.email}
                onChange={handleChangeEmailArray}
                onClick={handleChangeEmailClick}
                icon={"IoIosMail"}
                errors={errors.email}
                required={true}
              />
            </div>
            <div className="mt-2 md:mr-4">
              <CustomInput
                name="date_of_birth"
                label="Date of Birth"
                value={candiDatesFromData.date_of_birth}
                type={"date"}
                onChange={handleChange}
              />
            </div>

            <div className="mt-2 md:mr-4">
              <CustomSelect
                name="country"
                icon="CiLocationOn"
                label="Candidate Location Country"
                value={candiDatesFromData.country }
                onChange={(selectedValues) =>
                  handleChangeDropDown("country", selectedValues)
                }
                error={errors.country}
                options={countryData}
                required={"true"}
              />
            </div>
            <div className="mt-2">
              <CustomSelect
                name="state"
                icon="CiLocationOn"
                label="State"
                value={candiDatesFromData.state}
                onChange={(selectedValues) =>
                  handleChangeDropDown("state", selectedValues)
                }
                error={errors.state}
                options={stateData}
                required={"true"}
              />
            </div>
            <div className="mt-2 md:mr-4">
              <CustomSelect
                name="city"
                icon="IoEarth"
                label="City"
                value={candiDatesFromData.city}
                onChange={(selectedValues) =>
                  handleChangeDropDown("city", selectedValues)
                }
                error={errors.city}
                options={cityData}
                required={"true"}
              />
            </div>
            <div className="mt-2">
              <CustomInput
                type="text"
                name="address"
                placeholder="Enter Address"
                label="Address"
                icon="CiLocationOn"
                value={candiDatesFromData.address}
                onChange={handleChange}
                error={errors.address}
              />
            </div>
            <div className="mt-2 ">
              <CustomInput
                type="text"
                name="zipCode"
                placeholder="Enter Zip Code"
                label="Zip Code"
                icon="CiLocationOn"
                value={candiDatesFromData.zipCode}
                onChange={handleChange}
                error={errors.zipCode}
              />
            </div>
            <div className="mt-2">
              <CustomAddSelect
                name="visa"
                label="Visa"
                options={visa_DropDown || []}
                onChange={(selectedValues) =>
                  handleChangeDropDown("visa", selectedValues)
                }
                name2={"candidate"}
              />
            </div>

            <div className="mt-2">
              <div className="flex flex-col">
                <label

                  className="block text-md font-medium leading-6 text-gray-600"
                >
                  Pay Rate / Salary

                </label>
                <div className="flex flex-row gap-[2px] mb-[2px]">
                  <CustomSelect
                    name="rateCurrency"
                    value={candiDatesFromData?.rateCurrency || "USD"}
                    onChange={(selectedValues) =>
                      handleChangeDropDown("rateCurrency", selectedValues)
                    }
                    options={currencyCodesData}
                  />
                  <div style={{
                    width: "20%"
                  }}>
                    <CustomInput
                      name="rateMin"
                      type="number"
                      value={candiDatesFromData?.rateMin}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    style={{
                      width: "20%"
                    }}
                  >
                    <CustomInput
                      name="rateMax"
                      type="number"
                      value={candiDatesFromData?.rateMax}
                      onChange={handleChange}
                    />
                  </div>



                </div>
                <div className="flex flex-row gap-[5px]">
                  <CustomSelect
                    name={"rateWorkingTime"}
                    value={candiDatesFromData?.rateWorkingTime}
                    onChange={(selectedValues) =>
                      handleChangeDropDown("rateWorkingTime", selectedValues)
                    }
                    options={TimeHourData || []}
                  />
                  <CustomSelect
                    name={"rateSpentTime"}
                    value={candiDatesFromData?.rateSpentTime}
                    onChange={(selectedValues) => {
                      setCTwocStatus(selectedValues);
                      handleChangeDropDown("rateSpentTime", selectedValues);
                    }

                    }
                    options={SpentTimeData || []}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2">
              <CustomAddSelect
                name="candidateType"
                icon="FaClipboardUser"
                label="Source Type"
                placeholder="Enter CandiDate Name"
                value={candiDatesFromData.candidateType}
                options={candiDateDropDwon}
                onChange={(selectedValues) =>
                  handleChangeDropDown("candidateType", selectedValues)
                }
                error={errors.candidateType}
                required={"true"}

              />
            </div>
            <div className="mt-2 md:mr-4">
              <CustomAddSelect
                name="status"
                icon="GrStatusGood"
                label="Status"
                placeholder="Select Status"
                value={candiDatesFromData.status}
                options={Status_DropDwon}
                onChange={(selectedValues) =>
                  handleChangeDropDown("status", selectedValues)
                }
                error={errors.status}
                required={"true"}
              />
            </div>
            <div className="mt-2 lg:pr-4 sm:px-0">
              <CustomSocialMedia
                type="text"
                name="Url"
                placeholder="Enter URL's"
                label="Enter URLs"
                value={candiDatesFromData.Url}
                onChange={handleChangeObject}
                error={errors.Url}
              />
            </div>
            <div className="mt-2">
              <textarea
                name="notes"
                rows={5}
                placeholder="Add Notes"
                className="w-full pl-4 pt-2 bg-white border border-gray-300 rounded-lg shadow-md"
                value={candiDatesFromData.notes}
                onChange={handleChange}
              />

            </div>

          </div>
          <div className="text-lg text-[#004C95] font-bold">
            Education :
          </div>
          <div className="mb-4 text-[#0000004D] border"></div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              {education?.document &&
                education?.document.map((doc, index) => (
                  <div key={index}>
                    {doc?.status && (
                      <CustomInput
                        type="text"
                        name="univerSity"
                        label="University/College"
                        icon="IoDocumentText"
                        value={doc.univerSity || ""}
                        onChange={(e) => handleChangeEducation(index, e)}

                      />
                    )}
                  </div>
                ))}
            </div>
            <div>
              {education?.document &&
                education?.document.map((doc, index) => (
                  <div key={index}>
                    {doc?.status && (
                      <CustomInput
                        type="number"
                        name="passingYear"
                        label="Passing Year"
                        value={doc?.passingYear || ""}
                        onChange={(e) => handleChangeEducation(index, e)}

                      />
                    )}
                  </div>
                ))}
            </div>
            {/* <div>
              {education?.document &&
                education?.document.map((doc, index) => (
                  <div key={index}>
                    {doc?.status && (
                      <CustomInput
                        type="number"
                        name="yearsOfExperience"
                        label="Year of Experience"
                        value={doc?.yearsOfExperience || ""}
                        onChange={(e) => handleChangeEducation(index, e)}

                      />
                    )}
                  </div>
                ))}
            </div> */}
            <div>
              {education?.document &&
                education?.document.map((doc, index) => (
                  <div key={index}>
                    {doc?.status && (
                      <CustomAddSelect
                        label={"Degree"}
                        value={doc?.degree || ""}
                        onChange={(e) => handleSelecteducationDocument(index, e)}
                        options={jobDegreeData}
                        name2={"candidate"}
                      />
                    )}
                  </div>
                ))}
            </div>

          </div>
          <div className="flex justify-end  md:mt-6">
            <button
              className="flex items-center justify-center bg-custom-blue text-white font-normal py-2 px-4 h-9 rounded-xl"
              onClick={handleAddEducation}
            >
              <span className="text-lg sm:text-xl mr-1 h-5">
                <CustomIcon name={"TiPlus"} />
              </span>
              Add
            </button>
          </div>
          {educationStatus && addEducation.length > 0 && (
            <div className="mt-3">
              <table className="w-full border border-gray-300">
                <thead className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      S.No.
                    </th>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      University / College Name
                    </th>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      Passing Year
                    </th>
                    {/* <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      Experience
                    </th> */}
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      Degree
                    </th>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {addEducation &&
                    addEducation.map((doc, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 dark:hover:bg-gray-600 dark:bg-gray-800"
                      >
                        <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                        <td className="border border-gray-300 px-4 py-2">{doc?.university}</td>
                        <td className="border border-gray-300 px-4 py-2">
                          {doc?.passingYear || doc?.passing_year}
                        </td>
                        {/* <td className="border border-gray-300 px-4 py-2">{doc?.yearsOfExperience || doc?.experience}</td> */}
                        <td className="border border-gray-300 px-4 py-2">{doc?.degree}</td>
                        <td className="border border-gray-300 px-4 py-2 text-red-500 cursor-pointer">
                          <div onClick={() => handleChildEducationItemDelete(doc.id)}>
                            <CustomIcon name={"IoCloseCircle"} />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>


            </div>
          )}
          <div className="text-lg text-[#004C95] font-bold mt-2">
            Upload Documents :
          </div>
          <div className="mb-4 text-[#0000004D] border"></div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              {candiDateDocument?.document &&
                candiDateDocument?.document.map((doc, index) => (
                  <div key={index}>
                    {doc?.status && (
                      <CustomAddSelect
                        label={"Select Document"}
                        icon={"BsPersonFill"}
                        value={doc?.doc_type || ""}
                        onChange={(e) =>{
                          setUploadStatus(true);
                          handleSelectChangeDocument(index, e);
                        }}
                        
                        options={document_DropDwon}
                        name2={"candidate"}
                      />
                    )}
                  </div>
                ))}
            </div>
            <div>
              <div className="block text-sm sm:text-md font-medium leading-6 text-gray-600 mb-2">
                Upload File
              </div>
              <div className="flex">
                <div className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl text-blue-600">
                  <label>
                    <CustomIcon name={"CgScreen"} />
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                      disabled={!uploadStatus}
                    />
                  </label>
                </div>
                <div
                  className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                  onClick={handleDriveOpen}
                  style={{ cursor: "pointer" }}
                >
                  <CustomIcon name={"BsCloudsFill"} />
                </div>
                <div
                  className="bg-white border-2 border-gray-400 p-1 text-3xl sm:text-4xl ml-3 text-blue-600"
                  onClick={handleDropboxSignIn}
                  style={{ cursor: "pointer" }}
                >
                  <CustomIcon name={"FaDropbox"} />
                </div>
              </div>
              <div className="text-sm text-red-500 mt-2">
                {found_Document_Title?.title}
              </div>
            </div>


            <div>
              {candiDateDocument?.document &&
                candiDateDocument?.document.map((doc, index) => (
                  <div key={index}>
                    {doc?.status && (
                      <CustomInput
                        type="text"
                        placeholder="Please enter title"
                        label="Document Title"
                        icon="IoDocumentText"
                        value={doc.title || ""}
                        onChange={(e) => handleChangeDocument(index, e)}
                        disabled={!uploadStatus}
                      />
                    )}
                  </div>
                ))}
            </div>

            <div>
              {candiDateDocument?.document &&
                candiDateDocument?.document.map((doc, index) => (
                  <>
                    {
                      doc?.doc_type && doc?.doc_type !== "Resume" && (
                        <>
                          <div key={index}>
                            <CustomInput
                              name="expiry_date"
                              label="Expiry Date"
                              value={doc.expiryDate}
                              type={"date"}
                              disabled={!uploadStatus}
                              onChange={(e) => handleChangeExpiaryDate(index, e)}
                            />
                          </div>
                        </>
                      )

                    }

                  </>
                ))}
            </div>
          </div>
          <div className="flex justify-end  md:mt-6">
            <button
              className="flex items-center justify-center bg-custom-blue text-white font-normal py-2 px-4 h-9 rounded-xl"
              onClick={handleAddDocument}
              disabled={!uploadStatus}
            >
              <span className="text-lg sm:text-xl mr-1 h-5">
                <CustomIcon name={"TiPlus"} />
              </span>
              Add
            </button>
          </div>
          {dropdownOpen && candiDateArrayDocument.length > 0 && (
            <div className="mt-3">
              <table className="w-full border border-gray-300">
                <thead className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      S.No.
                    </th>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      Document Type
                    </th>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      Document Title
                    </th>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      Expiry Date
                    </th>
                    <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {candiDateArrayDocument &&
                    candiDateArrayDocument.map((doc, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 dark:hover:bg-gray-600 dark:bg-gray-800"
                      >
                        <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                        <td className="border border-gray-300 px-4 py-2">{doc?.type || doc?.doc_type}</td>
                        <td className="border border-gray-300 px-4 py-2">
                          {doc?.title.toUpperCase()}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">{doc?.expiryDate || doc?.expiry_date}</td>
                        <td className="border border-gray-300 px-4 py-2 text-red-500 cursor-pointer">
                          <div onClick={() => handleChildItemDelete(doc.id)}>
                            <CustomIcon name={"IoCloseCircle"} />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>


            </div>
          )}

          {
            cTwocStatus === "C2C" && (
              <>
                <div className="text-lg text-[#004C95] font-bold">
                  Employer Details:
                </div>
                <div className="mb-4 text-[#0000004D] border"></div>
                <div className="grid lg:grid-cols-1 md:grid-cols-4 gap-4">

                  <div className="flex flex-row gap-[15px]">
                    {employerDetails && employerDetails.map((item) => (
                      <div key={item.id}>
                        {["Full Name", "Employer Company Name", "Email id"].includes(item.name) && (
                          <CustomInput
                            name={item.name}
                            label={item.name}
                            value={item.value}
                            onChange={(e) => handleEmployerChange(item.name, e.target.value)}
                          />
                        )}
                      </div>
                    ))}
                    <div style={{
                      width: "20%"
                    }}>
                      <CustomSingleNumber
                        name={"officialNumber"}
                        label={"officialNumber"}
                        value={employerOfficialNumber}
                        onChange={(e) => handleChangeEmployerOfficialNumber(e)}
                        onClick={handleOfficialError}
                        errors={employerOfficialNumberError}
                      />
                    </div>

                    <div style={{
                      width: "20%"
                    }}>
                      <CustomSingleNumber
                        name={"Personal Number"}
                        label={"Personal Number"}
                        value={employerPhoneNumber}
                        onChange={(e) => handleChangeEmployerPersonalNumber(e)}
                        onClick={handleOfficialNumberError}
                        errors={employerPhoneNumberError}
                      />
                    </div>

                  </div>



                </div>
              </>
            )
          }


          <div className="mt-10 pb-10 flex justify-center">
            <button onClick={handleCancel}
              style={{
                border: "1px solid lightgray",
                padding: "10px",
                borderRadius: "11px",
                marginRight: "12px"

              }}
            >
              Cancel
            </button>

            <CutomButton
              text={loader ? "" : "Submit"}
              bgColor="#3D5B81"
              width="200"
              onClick={handleButtonClick}
              loader={loader}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCandidateSubmission;