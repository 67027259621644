

export const presentationDropDwon = [
    {id:1, name:'header', key:'header'},
    {id:2, name:'body', key:'body'},
    
]

export const  currencyCodes = [
    "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN", "BAM", "BBD", "BDT", "BGN", "BHD", "BIF",
    "BMD", "BND", "BOB", "BRL", "BSD", "BTN", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY", "COP", "CRC", "CUP",
    "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP", "ERN", "ETB", "EUR", "FJD", "FKP", "GBP", "GEL", "GGP", "GHS", "GIP",
    "GMD", "GNF", "GTQ", "GYD", "HKD", "HNL", "HRK", "HTG", "HUF", "IDR", "ILS", "INR", "IQD", "IRR", "ISK", "JMD", "JOD",
    "JPY", "KES", "KGS", "KHR", "KMF", "KRW", "KWD", "KYD", "KZT", "LAK", "LBP", "LKR", "LRD", "LSL", "LTL", "LVL", "LYD",
    "MAD", "MDL", "MGA", "MKD", "MMK", "MNT", "MOP", "MRO", "MRU", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN", "NAD", "NGN",
    "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN", "PGK", "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF",
    "SAR", "SBD", "SCR", "SDG", "SEK", "SGD", "SHP", "SLL", "SOS", "SPL", "SRD", "SSP", "STN", "SVC", "SYP", "SZL", "THB",
    "TJS", "TMT", "TND", "TOP", "TRY", "TTD", "TWD", "TZS", "UAH", "UGX", "USD", "UYU", "UZS", "VEF", "VES", "VND", "VUV",
    "WST", "XAF", "XCD", "XOF", "XPF", "YER", "ZAR", "ZMK", "ZWD"
];

export const TimeHour = [
    {id:1, name:'Hourly', key:'Hourly'},
    {id:2, name:'Daily', key:'Daily'},
    {id:3, name:'Weekly', key:'Weekly'},
    {id:1, name:'Yearly', key:'Yearly'},
    {id:2, name:'Monthly', key:'Monthly'},
    {id:3, name:'Bi-Weekly', key:'Bi-Weekly'},
]


export const SpentTime = [
    {id:1, name:'1099', key:'1099'},
    {id:2, name:'C2C', key:'C2C'},
    {id:3, name:'C2H', key:'C2H'},
    {id:4, name:'Full Time', key:'Full Time'},
    {id:5, name:'Intern', key:'Intern'},
    {id:6, name:'w-2', key:'w-2'},
    {id:7, name:'Part Time', key:'Part Time'},
    {id:8, name:'Seasonal', key:'Seasonal'},
    {id:9, name:'Other', key:'Other'},
]




