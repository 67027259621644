


export const remove_LocalStorage = () => {
    sessionStorage.removeItem('auth');
    sessionStorage.clear();
    
}


export const remove_LocalStorage_CandiDateApi = () => {
    localStorage.removeItem('candiDateFromData');
    localStorage.removeItem('candiDateDocument');
    localStorage.removeItem('candiDateDocumentData');
    localStorage.removeItem('candiDateArrayDocument');
}