import React, { useState, useEffect } from 'react'
import Buttonsheet from '../../components/Buttonsheet'
import { useDispatch, useSelector } from 'react-redux';
import { candidateSubmissionData } from "../../stores/Reducers/newCandiDate";


const TotalSubmissionData = ({ open, onClose, submissionData }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (submissionData) {
            let jobId = submissionData?._id;
            dispatch(candidateSubmissionData({ jobId }));
        }
    }, []);

    const ressponseData = useSelector((state) => {
        return state?.candiDatelist

    });

    return (
        <Buttonsheet open={open} onClose={onClose} header={"Submission"}>
            <table className="w-full border border-gray-300">
                <thead className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                            S.No.
                        </th>
                        <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                            Job ID
                        </th>
                        <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                            Candidate Name
                        </th>
                        <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                            Source Type
                        </th>
                        <th scope="col" className="border border-gray-300 px-4 py-2 text-left">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {ressponseData?.candidateSubmissionResponse && ressponseData?.candidateSubmissionResponse?.map((doc, index) => (
                            <tr
                                key={index}
                                className="hover:bg-gray-50 dark:hover:bg-gray-600 dark:bg-gray-800"
                            >
                                <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                                <td className="border border-gray-300 px-4 py-2">{doc?.job_id}</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {doc?.candidate_copy?.full_name}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">{doc?.candidate_copy?.source_type}</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {doc?.status}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Buttonsheet>
    )
}

export default TotalSubmissionData
