import React, { useEffect, useState, useCallback } from "react";
import CustomIcon from "./CustomIcon";

const useDebounce = (callback, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};

const CustomEmail = ({
  value,
  name,
  onChange,
  label,
  type,
  placeholder,
  icon,
  errors,
  onClick,
  required,
}) => {
  const [inputFields, setInputFields] = useState(
    value && value.length > 0
      ? value.map((val, index) => ({ id: `field-${index}`, value: val }))
      : [{ id: "field-1", value: "" }]
  );

  useEffect(() => {
    const updatedFields =
      value && value.length > 0
        ? value.map((val, index) => ({ id: `field-${index}`, value: val }))
        : [{ id: "field-1", value: "" }];
    setInputFields(updatedFields);
  }, [value]);

  useDebounce(() => {
    const valuesArray = inputFields.map((field) => field.value);
    onChange(valuesArray);
  }, 300);

  const handleFieldChange = (id, e) => {
    const updatedFields = inputFields.map((field) =>
      field.id === id ? { ...field, value: e.target.value } : field
    );
    setInputFields(updatedFields);
  };

  const handleClick = () => {
    const newId = `field-${inputFields.length + 1}`;
    setInputFields([...inputFields, { id: newId, value: "" }]);
  };
  const handleRemove = (id) => {
    setInputFields(inputFields.filter((input) => input.id !== id));
  };

  return (
    <>
      <div>
        <label
          htmlFor="email"
          className="block text-md font-medium leading-6 text-gray-600"
        >
          {label} 
          {
            required === true  && <span className="text-red-500 font-medium">*</span>
          }
          
        </label>
        {inputFields.map((input, index) => (
          <div key={input.id}>
            <div className="relative ">
              <input
                type={type}
                //placeholder={placeholder}
                required
                value={input?.value}
                className=" py-1.5   w-full mt-1   px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
                onChange={(e) => handleFieldChange(input.id, e)}
                onClick={onClick}
                style={{
                  borderRadius: "5px",
                  border:"1px solid lightgray",
                  fontSize:"14px"

                }}
              />
              {/* <span
                className="absolute left-2 top-1/2 transform -translate-y-1/2 text-xl"
                style={{ color: "#3d5b81", fontSize: "27px" }}
              >
                <CustomIcon name={icon} />
              </span> */}
              {index === inputFields.length - 1 && (
                <span
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-2xl"
                  style={{ color: "#3d5b81", fontSize: "24px" }}
                >
                  <CustomIcon name={"FaPlusCircle"} onClick={handleClick} />
                </span>
              )}
              <span
                className="absolute right-12 top-1/2 transform -translate-y-1/2 text-xl md:text-2xl"
                style={{ color: "#f00", fontSize: "22px", cursor: "pointer" }}
                onClick={() => handleRemove(input.id)}
              >
                {index !== 0 ? <CustomIcon name={"FaMinusCircle"} /> : ""}
              </span>
            </div>
            {errors && errors[index] && (
              <p className="text-red-500 text-sm mt-1">{errors[index]}</p>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomEmail;
