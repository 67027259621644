import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ContectList from "../AllContactList/ContectList";
import SegmentGroup from "../AllContactList/SegmentGroup";
import { Contactlist } from "../../stores/Reducers/newContact";
import { AccountData } from "../../stores/Reducers/newAccount";
import { useDispatch, useSelector } from "react-redux";
import ContactlistHeader from "../AllContactList/ContactlistHeader";
import SegmentAll from "../AllContactList/SegmentAll";
import AccountList from "../AllContactList/Accountlist";

const AccountListData = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [allAccount, setAllAccount] = useState(true);
  const [allContact, setAllContact] = useState(true);
  const [contactSegment, setContactSegment] = useState(false);
  const [accountSegment, setAccountSegment] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [filterColumnData, setFilterColumnData] = useState([]);




  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Contactlist());
    dispatch(AccountData())
  }, [dispatch]);


  const handleContactShow = () => {
    setAllContact(false);
    setAllAccount(true);
    setAccountSegment(false);

  }

  const handleAccountShow = () => {
    setAllContact(true);
    setAllAccount(false);
    setContactSegment(false);
  }

  const handleContactSegment = () => {
    setContactSegment(!contactSegment);
    // setAllContact(false);
    // setAllAccount(true);
  }

  const handleAccountSegment = () => {
    setAccountSegment(!accountSegment);
    // setAllContact(true);
    // setAllAccount(false);
  }

  const onCloseFilterShow = () => {
    setFilterShow(false);
  }


  const toggleDropdown = () => {
    setFilterShow(true);
  }

  return (
    <div
      className="min-w-full px-4 py-6 bg-[#F2F3F3] border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700"
      style={{ height: "87.5vh" }}
    >
      {
        allAccount && (
          <>
            <ContactlistHeader title={"ALL Accounts List"} handleAccount={handleAccountShow} handleAccountSegment={handleAccountSegment} isOpen={filterShow} onCloseColumnFilter={onCloseFilterShow} toggleDropdown={toggleDropdown} filterColumnData={filterColumnData} setFilterColumnData={setFilterColumnData} segmentGroup={accountSegment} />
            <div className="mt-4">
              {
                accountSegment ? (
                  <>
                    <SegmentAll />
                  </>
                ) : (
                  <>
                    <AccountList setFilterColumnData={setFilterColumnData} filterColumnData={filterColumnData} />
                  </>
                )
              }
            </div>
          </>
        )
      }
    </div>

  );
};

export default AccountListData;
