import React, { useCallback, useMemo, useState, useEffect } from "react";
import CustomInput from "../../components/CustomInput";
import { useNavigate, useLocation } from "react-router-dom";
import CustomSelect from "../../components/CustomSelect";
import CustomSocialMedia from "../../components/CustomSocialMedia";
import CutomButton from "../../components/CutomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  state_DropDown,
  city_DropDown,
} from "../../stores/Reducers/dropDownApiReducers";
import { Updatelead } from "../../stores/Reducers/newleads";
import CustomNumber from "../../components/CustomNumber";
import CustomEmail from "../../components/CustomEmail";
// import SearchableDropDown from "../../components/SearchableDropDown";
import SingleSearchableSelect from "../../components/SingleSearchableSelect"

const EditContactLeads = () => {
    const location = useLocation();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: [],
    officialNumber: [],
    country: "",
    state: "",
    city: "",
    address: "",
    zipCode: "",
    source: "",
    url: [],
    company: "",
    leadLabel: "",
    potentialBuys: false,
    rate: "",
    note: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const { leadDataId } = useSelector((data) => data?.leadlist);

  console.log(leadDataId, "editData", location.state.id);
  useEffect(() => {
    if (leadDataId) {
      let data = {
        name: leadDataId?.name,
        email: leadDataId?.email,
        officialNumber: leadDataId?.mobile,
        country: leadDataId?.country,
        state: leadDataId?.state,
        city: leadDataId?.city,
        address: leadDataId?.address,
        zipCode: leadDataId?.zipcode,
        source: leadDataId?.source,
        url: leadDataId?.urls,
        company: leadDataId?.company,
        leadLabel: leadDataId?.lead_lebel,
        potentialBuys: leadDataId?.potential_buyer,
        rate: leadDataId?.budget_amount,
        note: leadDataId?.note,
      };
      setFormData(data);
    }
  }, []);
  const {
    sourceDropdown,
    leadLabelDropDown,
    CountryDropDown,
    stateDropDown,
    cityDropDown,
  } = useSelector((data) => data.dropDown);

  const SourceData = useMemo(() => {
    return sourceDropdown?.map((item) => ({
      label: item,
      value: item,
    }));
  }, [sourceDropdown]);

  const LeadLable = useMemo(() => {
    return leadLabelDropDown?.map((item) => ({
      label: item,
      value: item,
    }));
  }, [leadLabelDropDown]);

  const countryData = useMemo(() => {
    return CountryDropDown?.map((item) => ({
      label: item?.name,
      value: item?.isoCode,
    }));
  }, [CountryDropDown]);

  const stateData = useMemo(() => {
    return stateDropDown?.map((item) => ({
      label: item?.name,
      value: item?.isoCode,
    }));
  }, [stateDropDown]);

  const cityData = useMemo(() => {
    return cityDropDown?.map((item) => ({
      label: item?.name,
      value: item?.isoCode,
    }));
  }, [cityDropDown]);

  useEffect(() => {
    if (formData?.country) {
      const country = CountryDropDown.find(
        (item) => item.name === formData.country
      );
      if (country) {
        dispatch(state_DropDown({ countryCode: country.isoCode }));
      }
    }
  }, [formData.country, CountryDropDown, dispatch]);

  useEffect(() => {
    if (formData?.state) {
      const state = stateDropDown.find((item) => item.name === formData.state);
      const country = CountryDropDown.find(
        (item) => item.name === formData.country
      );
      if (state && country) {
        dispatch(
          city_DropDown({
            countryCode: country.isoCode,
            stateCode: state.isoCode,
          })
        );
      }
    }
  }, [formData.state, stateDropDown, CountryDropDown, dispatch]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target || {};
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setFormErrors((prevFormErrors) => {
      const updatedErrors = { ...prevFormErrors };
      if (type === "text" && value.trim() !== "") {
        delete updatedErrors[name];
      }

      return updatedErrors;
    });
  };

  const handleChangeDropDown = useCallback((Field, value) => {
    setFormData((prevdata) => ({
      ...prevdata,
      [Field]: value,
    }));
    setFormErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value) {
        delete updatedErrors[Field];
      }

      return updatedErrors;
    });
  }, []);

  const handleChangeObject = useCallback(
    (data) => {
      setFormData((prevState) => ({
        ...prevState,
        url: data,
      }));
    },
    [formData.url]
  );


  const validateOfficialNumbers = (officialNumbers) => {
    const errors = [];
    const officialNumberPattern = /^[0-9]{10}$/;
    officialNumbers.forEach((number, index) => {
      if (!number) {
        errors[index] = `Number  is required`;
      } else if (!officialNumberPattern.test(number)) {
        errors[index] = `Number  is invalid`;
      }
    });
    return errors;
  };

  const validateofficialEmailId = (EmailId) => {
    const errors = [];
    const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    EmailId.forEach((email, index) => {
      if (!email) {
        errors[index] = `Email is required`;
      } else if (!emailRegexPattern.test(email)) {
        errors[index] = `Email  is invalid`;
      }
    });

    return errors;
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email[0].length < 0 && formData.email.length === 1) {
      errors.email = [" Email is required"];
    } else {
      const emailErrors = validateofficialEmailId(formData.email);
      if (emailErrors && emailErrors.length > 1) {
        errors.email = emailErrors;
      }
    }

    if (
      !formData.officialNumber[0].length < 0 &&
      formData.officialNumber.length === 1
    ) {
      errors.officialNumber = ["Number is required"];
    } else {
      const officialNumberErrors = validateOfficialNumbers(
        formData.officialNumber
      );
      if (officialNumberErrors && officialNumberErrors.length > 1) {
        errors.officialNumber = officialNumberErrors;
      }
    }
    if (!formData.country.trim()) {
      errors.country = "Country is required";
    }

    if (!formData.state.trim()) {
      errors.state = "State is required";
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
    }
    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }

    const zipCodePattern = /^[0-9]{6}$/;
    if (!formData.zipCode.trim()) {
      errors.zipCode = "ZipCode is required";
    } else if (!zipCodePattern.test(formData.zipCode)) {
      errors.zipCode = "Invalid number (must be 6 digits)";
    }

    if (!formData.source) {
      errors.source = "Source is required";
    }

    if (!formData.company.trim()) {
      errors.company = "Company / Organization is required";
    }

    if (!formData.leadLabel) {
      errors.leadLabel = "Lead Label is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    
    const validatedata = validateForm();
    if (validatedata) {
      try {
        const newData = {
          name: formData.name,
          mobile: formData?.officialNumber,
          email: formData?.email,
          source: formData?.source,
          company: formData?.company,
          country: formData?.country,
          state: formData?.state,
          city: formData?.city,
          address: formData?.address,
          urls: formData?.url,
          zipcode: formData?.zipCode,
          lead_label: formData?.leadLabel,
          potential_buyer: true,
          budget_currency: formData?.rate,
          budget_amount: formData?.rate,
          note: formData?.note,
        };
        let id = leadDataId._id
        const responseCall = await dispatch(Updatelead({ id, newData }))
          .then((res) => {
            return res;
          })
          .catch((err) => console.error(err));

        if (responseCall?.payload?.message) {
          navigate("/dashboard/leadsData", { state: { from: "/dashboard/editleadsFrom" } });
        }
        console.log("Lead created successfully!");
      } catch (error) {
        console.error("Error creating lead:", error);
      } finally {
        setLoader(false);
      }
    } else {
      console.log("Form contains errors.");
      setLoader(false);
    }
  };

  const handleButtonClick = (e) => {
    setLoader(true);
    e.preventDefault();
    handleSubmit(e);
  };

  const handleChangeArray = useCallback(
    (data) => {
      setFormData((prevState) => ({
        ...prevState,
        officialNumber: data,
      }));
      const officialNumberErrors = validateOfficialNumbers(data);

      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };

        if (officialNumberErrors.length > 0) {
          newErrors.officialNumber = officialNumberErrors;
        } else {
          delete newErrors.officialNumber;
        }

        return newErrors;
      });
    },
    [formData.officialNumber]
  );

  const handleChangeEmailArray = useCallback(
    (data) => {
      setFormData((prevState) => ({
        ...prevState,
        email: data,
      }));
      const EmailNumberErrors = validateofficialEmailId(data);
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };

        if (EmailNumberErrors.length > 0) {
          newErrors.email = EmailNumberErrors;
        } else {
          delete newErrors.email;
        }

        return newErrors;
      });
    },
    [formData.email]
  );

  return (
    <>
      <div className="shadow-md shadow-slate-700 bg-[#F2F3F3] ring-black ring-opacity-50 w-full h-full rounded-xl p-4">
        <div className="bg-[#F2F3F3] rounded-xl">
          <div className="flex flex-col md:flex-row items-center justify-between pl-6 text-[#004C95] font-bold rounded-t-lg">
            <div className="flex-1 md:flex-[0.2] mt-2">
              <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-gray-700">
                Edit Lead Form
              </h2>
            </div>
          </div>
          <div className="border-[#0000004D] border mx-4 md:mx-6"></div>

          <form onSubmit={handleSubmit} className="pt-6 px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <CustomInput
                  type={"text"}
                  name="name"
                  label={"Name"}
                  placeholder={"Enter Name"}
                  icon="BsPersonFill"
                  value={formData.name}
                  onChange={handleChange}
                  error={formErrors.name}
                />
              </div>

              <div>
                <CustomEmail
                  type="email"
                  name="email"
                  label="Email ID"
                  placeholder="Enter Email ID"
                  value={formData.email}
                  onChange={handleChangeEmailArray}
                  errors={formErrors.email}
                />
              </div>
              <div>
                <CustomNumber
                  type="text"
                  name="officialNumber"
                  placeholder="Enter Mobile Number"
                  label="Number"
                  value={formData.officialNumber}
                  onChange={handleChangeArray}
                  errors={formErrors.officialNumber}
                />
              </div>
              <div className="">
                <CustomSelect
                  name="country"
                  icon="CiLocationOn"
                  label="Country"
                  value={formData.country}
                  onChange={(selectedValues) =>
                    handleChangeDropDown("country", selectedValues)
                  }
                  error={formErrors.country}
                  options={countryData}
                />
              </div>
              <div className="">
                <CustomSelect
                  name="state"
                  icon="CiLocationOn"
                  label="State"
                  value={formData.state}
                  onChange={(selectedValues) =>
                    handleChangeDropDown("state", selectedValues)
                  }
                  error={formErrors.state}
                  options={stateData}
                />
              </div>
              <div className="">
                <CustomSelect
                  name="city"
                  icon="IoEarth"
                  label="City"
                  value={formData.city}
                  onChange={(selectedValues) =>
                    handleChangeDropDown("city", selectedValues)
                  }
                  error={formErrors.city}
                  options={cityData}
                />
              </div>
              <div className="">
                <CustomInput
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                  label="Address"
                  icon="CiLocationOn"
                  value={formData.address}
                  onChange={handleChange}
                  error={formErrors.address}
                />
              </div>

              <div className=" ">
                <CustomInput
                  type="text"
                  name="zipCode"
                  placeholder="Enter Zip Code"
                  label="Zip Code"
                  icon="CiLocationOn"
                  value={formData.zipCode}
                  onChange={handleChange}
                  error={formErrors.zipCode}
                />
              </div>
              <div>
                <SingleSearchableSelect
                  name="Source"
                  label="Source"
                  value={formData.source}
                  onChange={(selectedValues) =>
                    handleChangeDropDown("source", selectedValues)
                  }
                  options={SourceData}
                  error={formErrors.source}
                  required={true}
                />
              </div>
              <div>
                <CustomInput
                  type="text"
                  name="company"
                  placeholder="Enter Company Organization"
                  label="Company / Organization"
                  value={formData.company}
                  onChange={handleChange}
                  error={formErrors.company}
                />
              </div>
              <div>
                <SingleSearchableSelect
                  name="Leadlable"
                  label="Lead Label"
                  placeholder="Select Lead Label"
                  value={formData.leadLabel}
                  options={LeadLable}
                  onChange={(selectedValues) =>
                    handleChangeDropDown("leadLabel", selectedValues)
                  }
                  error={formErrors.leadLabel}
                  required={true}
                />
              </div>
              <div className="flex flex-col sm:flex-row">
                <div className="w-full">
                  <label
                    htmlFor="Rate"
                    className="block text-md font-medium leading-6 text-gray-600"
                  >
                    Budget
                  </label>
                  <div className="flex">
                    <div className="my-2 w-[32%]">
                      <select
                        id="currency"
                        name="currency"
                        value={formData.currency}
                        onChange={handleChange}
                        className="border w-full p-2 rounded-l-lg pl-2 py-1.5 shadow-custom-top-none leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
                      >
                        <option value="$" label="USD $" />
                        <option value="€" label="EUR €" />
                        <option value="£" label="GBP £" />
                        <option value="¥" label="JPY ¥" />
                        <option value="A$" label="AUD $" />
                        <option value="C$" label="CAD $" />
                        <option value="CHF" label="CHF" />
                        <option value="¥" label="CNY ¥" />
                        <option value="₹" label="INR ₹" />
                      </select>
                    </div>
                    <div className="my-2 w-[70%]">
                      <input
                        type="number"
                        id="Rate"
                        name="rate"
                        value={formData.rate}
                        onChange={handleChange}
                        placeholder="Rate"
                        className="border w-full py-1.5 p-2 rounded-r-lg shadow-custom-top-none leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "-6px" }}>
                <CustomSocialMedia
                  type="text"
                  name="Url"
                  placeholder="Enter URLs"
                  label="Enter URLs"
                  value={formData.url}
                  onChange={handleChangeObject}
                />
              </div>
              <div>
                <label
                  htmlFor="note"
                  className="block text-md font-medium leading-6 text-gray-600"
                >
                  Note
                </label>
                <div className="mt-2">
                  <textarea
                    name="note"
                    id="note"
                    cols="30"
                    rows="3"
                    onChange={handleChange}
                    className="w-full sm:w-72 rounded-lg pl-2 shadow-custom-top-none leading-tight focus:outline-none focus:ring-2 focus:ring-light-blue-50"
                  ></textarea>
                </div>
              </div>

              <div className="mt-2 ml-4">
                <label
                  htmlFor=""
                  className="block text-md font-medium leading-6 text-gray-600"
                >
                  Potential Buyer
                </label>
                <div className="flex space-x-4 mt-2">
                  <div>
                    <input
                      type="radio"
                      name="potentialBuys"
                      value="yes"
                      checked={formData.potentialBuys && true ?  "yes" : "no"}
                      onChange={handleChange}
                    />
                    <span>Yes</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="potentialBuys"
                      value="no"
                      checked={formData.potentialBuys && false ? "no" : "yes"}
                      onChange={handleChange}
                    />
                    <span>No</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 pb-10 flex justify-center">
              <CutomButton
                text={loader ? "" : "Update"}
                bgColor="#3D5B81"
                width="200"
                onClick={handleButtonClick}
                loader={loader}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditContactLeads;
