import React from "react";
import moment from "moment";

const UserDetailsRight = ({ data, title }) => {

  console.log(data, "userDetails")

  function removeKeys(obj) {
    if (title === "Contact") {
      const { __v, profile_image, template, ...cleanedObject } = obj;
      return cleanedObject;
    } else if (title === "Account") {
      const { _v, ...cleanedObject } = obj;
      return cleanedObject;
    } else if (title === "Candidate") {
      const { _v, allow_ownership, documents, ...cleanedObject } = obj;
      return cleanedObject;
    }
    else if (title === "Job") {
      const { _v,  documents, ...cleanedObject } = obj;
      return cleanedObject;
    }
  }

  const contactData = removeKeys(data);
  const AccountData = removeKeys(data);
  const CandiDateData = removeKeys(data);

  const jobData = removeKeys(data);

  const ContactkeyOrder = [
    "_id",
    "full_name",
    "role",
    "mobile",
    "email",
    "company",
    "country",
    "state",
    "city",
    "zipcode",
    "status",
    "notes",
    "updatedAt",
    "createdAt",
    "created_by",
  ];

  const AccountkeyOrder = [
    "company_name",
    "industry",
    "company_headcount",
    "email",
    // "official_number",
    "company",
    "country",
    "state",
    "city",
    "zipcode",
    "status",
    "updatedAt",
    "createdAt",
    "created_by",
  ];

  const CandiDatekeyOrder = [
    "_id",
    "full_name",
    // "mobile",
    "email",
    "job_title",
    "candidate_type",
    "country",
    "state",
    "city",
    "zipcode",
    "status",
    "notes",
    "updatedAt",
    "createdAt",
    "created_by",
  ];


  const JobkeyOrder = [
    "_id",
    "job_title",
    "job_code",
    "job_type",
    "country",
    "state",
    "city",
    "zipcode",
    "degree",
    "duration",
    "account_manager",
    "additional_notification",
    "address",
    "assigned_to",
    "business_unit",
    "clearance",
    "client",
    "client_category",
    "client_manager",
    "comment",
    "end_client",
    "expenses_paid",
    "exprience",
    "industry",
    "interview_mode",
    "languages",
    "notice_period",
    "number_of_position",
    "pay_rate",
    "placement_fee_percentage",
    "primary_skills",
    "priority",
    "recruitment_manager",
    "remote_job",
    "required_document",
    "required_hours",
    "tax_term",
    "turnarount_time",
    "work_authorization",
    "updatedAt",
    "createdAt",
    "created_by",
  ];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatDate = (date) => {
    return moment(date).format("MMMM Do, YYYY, h:mm A");
  };

  const handleObjectField = (obj) => {
    return (
      <ul className="list-disc pl-4">
        { obj && Object.entries(obj).map(([key, value], index) => (
          <li key={index} className="text-[#383838] font-poppins">
            {capitalizeFirstLetter(key)}: {value}
          </li>
        ))}
      </ul>
    );
  };

  const handleArrayField = (obj) => {
    return (
      <ul className="list-disc pl-4">
        {  obj && obj.map((item, index) => (
          <li key={index} className="text-[#383838] font-poppins">
            {item}
          </li>
        ))}
      </ul>
    );
  };

  const handleBooleanField = (value) => {
    return value ? "Yes" : "No";
  };

  return (
    <>
      <div className="flex justify-center ">
        {/* <div className="w-24 h-24 bg-[#4f5d73] rounded-full flex place-items-center p-4">
          <div className="w-16 h-16 bg-white rounded-full"></div>
        </div> */}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 p-4 mt-2">
        {title === "Contact" &&
          contactData &&
          ContactkeyOrder?.map((key, index) => (
            <div key={index} className="flex flex-col items-start">
              <span className="text-[#646464]">{capitalizeFirstLetter(key)}</span>
              <span className="text-[#383838] font-poppins">
                {key === "createdAt" || key === "updatedAt"
                  ? formatDate(contactData[key])
                  : Array.isArray(contactData[key])
                  ? Array.isArray(contactData[key][0]) || typeof contactData[key][0] === "object" 
                    ? contactData[key].map((item, idx) => (
                        <div key={idx} className="flex flex-col">
                          {Object.entries(item).map(([objKey, objValue], objIdx) => (
                            <span key={objIdx}>
                              <strong>{capitalizeFirstLetter(objKey)}:</strong> {objValue}
                            </span>
                          ))}
                        </div>
                      ))
                    : handleArrayField(contactData[key]) 
                  : typeof contactData[key] === "boolean"
                  ? handleBooleanField(contactData[key])
                  : contactData[key]}
              </span>
            </div>
          ))
          
          
          }

        {title === "Account" &&
          AccountData &&
          AccountkeyOrder?.map((key, index) => (
            <div key={index} className="flex flex-col items-start">
              <span className="text-[#646464]">{capitalizeFirstLetter(key)}</span>
              <span className="text-[#383838] font-poppins">
                {key === "createdAt" || key === "updatedAt"
                  ? formatDate(AccountData[key])
                  : Array.isArray(AccountData[key])
                  ? handleArrayField(AccountData[key])
                  :  typeof AccountData[key] === "object"
                  ? handleObjectField(AccountData[key])
                  :  typeof AccountData[key] === "boolean"
                  ? handleBooleanField(AccountData[key])
                  : AccountData[key]
                }
              </span>
            </div>
          ))}

        {title === "Candidate" &&
          CandiDateData &&
          CandiDatekeyOrder?.map((key, index) => (
            <div key={index} className="flex flex-col items-start">
              <span className="text-[#646464]">{capitalizeFirstLetter(key)}</span>
              <span className="text-[#383838] font-poppins">
                {key === "createdAt" || key === "updatedAt"
                  ? formatDate(CandiDateData[key])
                  : Array.isArray(CandiDateData[key])
                  ? handleArrayField(CandiDateData[key])
                  :   typeof CandiDateData[key] === "object"
                  ? handleObjectField(CandiDateData[key])
                  :  typeof CandiDateData[key] === "boolean"
                  ? handleBooleanField(CandiDateData[key])
                  : CandiDateData[key]
                }
              </span>
            </div>
          ))}
          {title === "Job" &&
          jobData &&
          JobkeyOrder?.map((key, index) => (
            <div key={index} className="flex flex-col items-start">
              <span className="text-[#646464]">{capitalizeFirstLetter(key)}</span>
              <span className="text-[#383838] font-poppins">
                {key === "createdAt" || key === "updatedAt"
                  ? formatDate(jobData[key])
                  : Array.isArray(jobData[key])
                  ? jobData[key][0]
                  :   typeof jobData[key] === "object"
                  ? handleObjectField(jobData[key])
                  :  typeof jobData[key] === "boolean"
                  ? handleBooleanField(jobData[key])
                  : jobData[key]
                  }
              </span>
            </div>
          ))}
      </div>

      
    </>
  );
};

export default UserDetailsRight;
