import React from 'react';
import {
    Grid,
    IconButton,
    Typography,
    Box,
    Drawer,
    Tooltip
} from '@mui/material';
import CustomIcon from './CustomIcon';

const Buttonsheet = (props) => {
    return (
        <Drawer anchor="bottom" open={props.open} onClose={props.onClose}>
            <Box
                sx={{
                    height: props.heightvalue || '80vh',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    //backgroundColor: 'orange'
                }}
            >
                <Box
                    px={5}
                    sx={{
                        backgroundColor: '#026AA2',
                        position: 'absolute',
                        zIndex: 1,
                        maxWidth: '100vw',
                        minWidth: '100vw'
                    }}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: '5px' }}
                    >
                        <Grid item>
                            <Typography
                                variant="body1"
                                sx={{ fontWeight: 600, color: 'black' }}
                            >
                                {props.header ? ` View ${props.header} Details` : 'View Details'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Close" placement="bottom">
                                <IconButton onClick={props.onClose}>
                                    <CustomIcon name="IoCloseSharp" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    px={10}
                    py={7}
                    sx={{
                        padding: '5.25rem 0.5rem'
                    }}
                >
                    <Box>{props.children}</Box>
                </Box>
            </Box>
        </Drawer>
    );
};

export default Buttonsheet;
