import React, { useState, useEffect, useRef } from "react";
import AddNewField from "./AddNewField";
import CustomIcon from "./CustomIcon";
import AddNewJobField from "./AddNewJobField";
import AddCompanyInfo from "./AddCompanyInfo";
import AddNewJobClient from "./AddNewJobClient";
import AddNewJobClientManager from "./AddNewJobClientManager";

const SearchableDropDown = ({
  name,
  options,
  error,
  value,
  onChange,
  onSelect,
  placeholder,
  icon,
  label,
  required,
  onAddNew,
  name1,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const dropdownRef = useRef(null); // Reference for the dropdown container

  useEffect(() => {
    setSearchTerm("");
  }, [options]);

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e.target?.value || "";
    setShowOptions(true);
    if (onChange) onChange(newValue, e.target.name);
  };

  const handleInputAnotherChange = (e) => {
    const newValue = e.target?.value || "";
    setSearchTerm(newValue);
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.some((selected) => selected.value === option.value)) {
      const updatedSelections = selectedOptions.filter(
        (selected) => selected.value !== option.value
      );
      setSelectedOptions(updatedSelections);
      if (onChange) onChange(updatedSelections, name);
    } else {
      const updatedSelections = [...selectedOptions, option];
      setSelectedOptions(updatedSelections);
      if (onChange) onChange(updatedSelections, name);
    }
    // setShowOptions(false);
  };

  const handleAddNewClick = () => {
    setIsModalOpen(true);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = options && options?.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCloseDropDown = () => {
    setShowOptions(false);
  }

  return (
    <>
      <div className="relative" ref={dropdownRef}>
        {label && (
          <label className="block text-md font-medium leading-6 text-gray-600">
            {label}
            {required === "true" && <span className="text-red-500">*</span>}
          </label>
        )}
        <div>
          <div className=" flex items-center bg-white  border border-gray-300 rounded-md" style={{ maxHeight: "15rem",  }}>
            <div className="flex flex-col items-center gap-[0.2rem]">
              {selectedOptions.map((option) => (
                <div key={option.value} className="flex  items-center bg-gray-200 text-sm px-2 py-1 rounded-md" style={{ width: option.label.length > 12 ? "11rem" : '6rem' }}>
                  {option.label}
                  <span className="ml-2 text-red-500 cursor-pointer" onClick={() => handleOptionClick(option)}>
                    &times;
                  </span>
                </div>
              ))}
            </div>
            <input
              type="text"
              onChange={handleInputChange}
              onClick={() => setShowOptions(true)}
              placeholder={placeholder}
              className="w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none"
              style={{ border: "none",  }}
              readOnly
            />
            {
              showOptions ? (
                <span onClick={handleCloseDropDown}>
                  <CustomIcon name="IoChevronUpSharp" className="text-gray-500 mr-3 cursor-pointer" style={{ fontSize: "1.2rem" }} />
                </span>
              ) : (
                <>
                  <CustomIcon name="LuChevronDown" className="text-gray-500 mr-3 cursor-pointer" style={{ fontSize: "1.2rem" }} />
                </>
              )
            }
            {/* <CustomIcon name={showOptions ? "IoChevronUpSharp" : "LuChevronDown"} className="text-gray-500 mr-3 cursor-pointer" style={{ fontSize: "1.2rem" }} /> */}
          </div>
          {showOptions && (
            <div className="absolute z-10 w-full border border-gray-300 rounded-lg bg-white shadow-lg max-h-60 overflow-y-auto">
              <ul className="p-[5px]">
                <input
                  type="search"
                  value={searchTerm}
                  onChange={handleInputAnotherChange}
                  placeholder={"search.."}
                  className="w-full py-1.5 px-6 text-gray-700 leading-tight focus:outline-none"
                  style={{ border: "1px solid lightgray", borderRadius: "5px" }}
                />
                {(label === "Assigned To" || label === "Additional Notification") ? null : (
                  <button className="text-blue-500 underline" onClick={handleAddNewClick} style={{ paddingleft: "15px", paddingBottom: "5px" }}>
                    Add New
                  </button>
                )}
                {filteredOptions.length > 0 &&
                  filteredOptions.map((option) => (
                    <li key={option.value} onClick={() => handleOptionClick(option)} className="cursor-pointer px-4 py-2 hover:bg-gray-100">
                      <input type="checkbox" checked={selectedOptions.some((selected) => selected.value === option.value)} onChange={() => handleOptionClick(option)} className="mr-2" />
                      {option.label}
                    </li>
                  ))}

              </ul>
            </div>
          )}
        </div>
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </div>
      {isModalOpen && (
        <>
          {label === "Industry" ? (
            <AddCompanyInfo isModalOpen={isModalOpen} label={label} close={() => setIsModalOpen(false)} />
          ) : name1 === "Job" && label !== "Client" && label !== "Client Manager" ? (
            <AddNewJobField isModalOpen={isModalOpen} label={label} close={() => setIsModalOpen(false)} icon={icon} />
          ) : name1 === "Job" && label === "Client" ? (
            <AddNewJobClient isModalOpen={isModalOpen} label={label} close={() => setIsModalOpen(false)} onClick={onAddNew} icon={icon} />
          ) : name1 === "Job" && label === "Client Manager" ? (
            <AddNewJobClientManager isModalOpen={isModalOpen} label={label} close={() => setIsModalOpen(false)} onClick={onAddNew} icon={icon} />
          ) : (
            <AddNewField isModalOpen={isModalOpen} label={label} close={() => setIsModalOpen(false)} onClick={onAddNew} icon={icon} />
          )}
        </>
      )}
    </>
  );
};

export default SearchableDropDown;
