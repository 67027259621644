import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomIcon from "../../components/CustomIcon";
import CardViews from "./CardViews";
import ColumnView from "./ColumnView";
import { LeadData } from "../../stores/Reducers/newleads";

const AllLeads = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [view, setView] = useState("CardViews");
  const [isOpen, setIsOpen] = useState(false);
  const [shows, setShows] = useState(true);
  const [activeView, setActiveView] = useState("ColumnView");
  const [filterColumnData, setFilterColumnData] = useState([]);

  const [rowData, setRowData] = useState([]);

  const { getLeadData } = useSelector((state) => state?.leadlist);

  useEffect(() => {
    dispatch(LeadData());
  }, [dispatch]);

  useEffect(() => {
    if(getLeadData){
      setRowData(getLeadData);
    }
    
  }, [getLeadData]);

  console.log(getLeadData, rowData, "led")

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const handleViewChange = (view) => {
    setActiveView(view);
  };

  const handleClick = () => {
    navigate("/dashboard/leadsFrom", {
      state: { from: "/dashboard/leadsData" },
    });
  };

  const renderView = () => {
    if (activeView === "CardView") {
      return <CardViews rowData={rowData} />;
    }
    if (activeView === "ColumnView") {
      return (
        <ColumnView
          filterColumnData={filterColumnData}
          setFilterColumnData={setFilterColumnData}
          rowData={rowData}
          setRowData={setRowData}
        />
      );
    }
  };

  return (
    <>
      <div className="min-w-full px-4 py-6 bg-[#F2F3F3] border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700"
        style={{width:'54rem', maxHeight:"500vh" }}
      >
        <div className="flex flex-col md:flex-row justify-between items-center flex-wrap space-y-4 md:space-y-0">
          <div className="text-lg md:text-xl font-bold text-custom-blue-light lg:mr-32" style={{ flex: 0.2 }}>
            Leads
          </div>
          <div style={{ flex: 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '5px', }}>
            {/* <div
              onClick={() => handleViewChange("CardView")}
              className={`cursor-pointer w-full md:w-1/6 lg:w-1/6 border border-[#6b829e] py-1 text-center text-[#3e5c82] rounded-full ${activeView === "CardView"
                  ? "bg-[#3e5c82] text-white"
                  : "bg-white"
                }`}
            >
              Card View
            </div> */}

            {/* <div
              onClick={() => handleViewChange("ColumnView")}
              className={`cursor-pointer w-full md:w-1/4 lg:w-1/6 border border-[#6b829e] py-1 text-center text-[#3e5c82] rounded-full ${activeView === "ColumnView"
                  ? "bg-[#3e5c82] text-white"
                  : "bg-white"
                }`}
            >
              Column View
            </div> */}

            {/* <div className="flex items-center space-x-2 w-full md:w-auto">
                    <div className="text-[#3e5c82]">Filter</div>
                    <div className="relative inline-block text-left">
                        <button
                            type="button"
                            onClick={toggleDropdown}
                            className="inline-flex sm:w-full justify-center gap-x-1.5 rounded-full bg-white px-3 py-2 text-sm font-semibold text-[#aeaeae] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            id="menu-button"
                            aria-expanded={isOpen}
                            aria-haspopup="true"
                        >
                            All Contacts
                            <svg
                                className="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>

                        {isOpen && (
                            <div
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                            >
                                <div className="py-1" role="none">
                                    <a
                                        href="#"
                                        className="block px-4 py-2 text-sm text-gray-700"
                                        role="menuitem"
                                        onClick={handleSelect}
                                    >
                                        Account settings
                                    </a>
                                    <a
                                        href="#"
                                        className="block px-4 py-2 text-sm text-gray-700"
                                        role="menuitem"
                                        onClick={handleSelect}
                                    >
                                        Support
                                    </a>
                                    <a
                                        href="#"
                                        className="block px-4 py-2 text-sm text-gray-700"
                                        role="menuitem"
                                        onClick={handleSelect}
                                    >
                                        License
                                    </a>
                                    <form method="POST" action="#" role="none">
                                        <button
                                            type="submit"
                                            className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                            onClick={handleSelect}
                                        >
                                            Sign out
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                </div> */}

            <div
              className="w-full md:w-1/4 lg:w-1/6 px-2 flex items-center justify-center border border-[#6b829e] py-1 bg-[#3e5c82] rounded-full text-white"
              onClick={handleClick}
            >
              <button className="">
                <CustomIcon name={"TiPlus"} />
              </button>
              <span> New Lead</span>
            </div>
            <button className="mt-1 text-[#3e5c82]">
              <CustomIcon name={"IoFilter"} />
            </button>
            <button className="text-[#3e5c82]">
              <CustomIcon
                name={"FaEdit"}
                size="text-base md:text-xl lg:text-2xl"
              />
            </button>
          </div>
        </div>
        <div className="my-2 text-sm">Total Opportunities: 1500</div>
        <div
          className="bg-[#707070] my-4"
          style={{ height: "1px", width: "100%" }}
        ></div>
        <div className="mt-4" style={{width:'100%'}} >
          {
            renderView()
          }
        </div>
      </div>
    </>
  );
};

export default AllLeads;
