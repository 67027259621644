import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3500";



export const createContact = createAsyncThunk(
    'create/contact',
    async ({newData}, { rejectWithValue }) => {
        try {
            const tokenData = sessionStorage.getItem("auth");
            const token = JSON.parse(tokenData);
            const response = await axios.post(`${apiUrl}/api/createContact`,newData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if(response?.data){
              toast.success("contacts Data added SucessFully")
            }
            return response?.data;
        } catch (error) {
          toast.error(error.response.data.message);
            return rejectWithValue(error.response.data.message);
        }
    }
);


export const Contactlist = createAsyncThunk(
  'contacts/getContactData',
  async () => {
      try {
          const tokenData = sessionStorage.getItem("auth");
          const token = JSON.parse(tokenData);
          const response = await axios.get(`${apiUrl}/api/getContacts`,{
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });
          // if(response?.data){
          //   toast.success("contacts Data added SucessFully")
          // }
          return response?.data;
      } catch (error) {
        toast.error(error.response.data.message);
          
      }
  }
);


const newContact = createSlice({
    name: 'newContact',
    initialState: {
      loading: false,
      message:"",
      getNewData:[],
      contactData:[],
      
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(createContact.pending, (state) => {
          state.loading = true;
        })
        .addCase(createContact.fulfilled, (state, action) => {
          state.loading = false;
          state.message = "Sucessfully Post";
          state.getNewData = action.payload
          
        })
        .addCase(createContact.rejected, (state, action) => {
          state.loading = false;
          state.message = "you have wrongCredatins filled that";
          state.error = action.payload;
        })
        .addCase(Contactlist.pending, (state) => {
          state.loading = true;
        })
        .addCase(Contactlist.fulfilled, (state, action) => {
          state.loading = false;
          state.contactData = action?.payload?.data
          
        })
        .addCase(Contactlist.rejected, (state, action) => {
          state.loading = false;

        })
        
    },
  });
  

  export default newContact.reducer;