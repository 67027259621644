import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ModalUserDetails from "../ModalUserDetails";
import moment from "moment";

const ContectList = ({ setFilterColumnData, filterColumnData }) => {
  const navigate = useNavigate();
  const { contactData } = useSelector((state) => state?.contactlist);
  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [passData, setPassData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setRowData(contactData || []);
    setFilteredData(contactData || []);
  }, [contactData]);

  useEffect(() => {
    if (rowData) {
      setFilterColumnData([
        { field: "full_name", headerName: "Name", visible: true, filter: true },
        { field: "role", headerName: "Role", visible: true, filter: true },
        { field: "mobile", headerName: "Mobile No.", visible: true, filter: true },
        { field: "email", headerName: "Email ID", visible: true, filter: true },
        { field: "company", headerName: "Company Name", visible: true, filter: true },
        { field: "client_type", headerName: "Client Type", visible: true, filter: true },
        { field: "city", headerName: "City", visible: true, filter: true },
        { field: "state", headerName: "States", visible: true, filter: true },
        { field: "country", headerName: "Country", visible: true, filter: true },
        { field: "createdAt", headerName: "Create", visible: true, filter: true },
        { field: "updatedAt", headerName: "Update", visible: true, filter: true },
        { field: "status", headerName: "Status", visible: true, filter: true },

      ]);
    }
  }, [rowData]);

  const onRowClicked = (event) => {
    setModalOpen(true);
    setPassData(event?.data);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = rowData.filter((row) =>
      Object.values(row).some((val) =>
        String(val).toLowerCase().includes(value)
      )
    );

    setFilteredData(filtered);
  };

  const columnDefs = useMemo(() => {
    const checkboxColumn = {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      headerClass: "bg-[#3C9DD1] text-black",
    };

    const visibleColumns = filterColumnData
      .filter((col) => col.visible)
      .map((col) => {
        return {
          field: col.field,
          headerName: col?.headerName,
          filter: col?.filter,
          width: 150,
          headerClass: "bg-[#3C9DD1]",
          tooltipField: col?.field,
          cellRenderer: (params) => {
            if (col.field === "mobile" && Array.isArray(params.value)) {
              return (
                <ul>
                  {params.value.map((item, index) => (
                    <li key={index}>{item.number}</li>
                  ))}
                </ul>
              );
            }
            if (col.field === "createdAt" || col.field === "updatedAt") {
              return (
                <>
                  {
                    moment(params.value).format("DD-MMM-YYYY")
                  }
                </>
              )
            }
            if (col?.field === "status") {
              return (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: "100%",
                  borderRadius: "20px",
                  height:"2rem",
                  backgroundColor: params.value?.toLowerCase() === "pending" ? "yellow" : (params.value?.toLowerCase() === "active" ||  params.value?.toLowerCase() === "working") ? "green" : "red",
                 
                }}>
                  <span
                  >
                    {params?.value}
                  </span>

                </div>
              )
            }
            return params.value;
          },

          valueFormatter:
            col.field === "createdAt" || col.field === "updatedAt"
              ? (params) => moment(params.value).format("DD-MMM-YYYY")
              : undefined,
        };
      });

    return [checkboxColumn, ...visibleColumns];
  }, [filterColumnData]);

  const gridOptions = useMemo(
    () => ({
      //rowSelection: "multiple", 
      rowDragManaged: true,
      animateRows: true,
    }),
    []
  );

  const modalClose = () => {
    setModalOpen(false);
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      //filter: true,
      floatingFilter: false,
      resizable: true,
      enableRowGroup: false,
    };
  }, []);

  return (
    <>
      <div>
        <div className="flex justify-end mb-4">
          <input
            type="text"
            placeholder="Search.."
            value={searchText}
            onChange={handleSearch}
            className="border p-2 w-1/3"
            style={{
              borderRadius: "9px",
            }}
          />
        </div>
        <div
          className="ag-theme-quartz"
          style={{ height: "65vh", width: "100%" }}
        >
          <AgGridReact
            rowData={filteredData}
            columnDefs={columnDefs}
            gridOptions={{
              ...gridOptions,
              suppressHorizontalScroll: true,
            }}
            defaultColDef={defaultColDef}
            rowDragManaged={true}
            rowDragEntireRow={true}
            rowDragMultiRow={true}
            onRowClicked={onRowClicked}
            pagination={true}
            paginationPageSize={10}
            domLayout="normal"
          />
        </div>
      </div>

      {/* Modal */}
      {modalOpen && (
        <ModalUserDetails
          isVisible={modalOpen}
          onClose={modalClose}
          data={passData}
          title={"Contact"}
        />
      )}
    </>
  );
};

export default ContectList;
