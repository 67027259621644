import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ModalUserDetails from "../ModalUserDetails";
import moment from "moment";

const CandiDateTable = ({ rowData, filterColumnData, setFilterColumnData }) => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(rowData || []);
  const [passData, setPassData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (rowData) {
      setFilteredData(rowData);
      setFilterColumnData([
        { field: "full_name", headerName: "Name", visible: true, filter: true },
        { field: "job_title", headerName: "Job Title", visible: true, filter: true },
        {
          field: "candidate_type",
          headerName: "CandiDate Type",
          visible: true,
          filter: true
        },
        { field: "mobile", headerName: "Mobile_No.", visible: true, filter: true },
        { field: "email", headerName: "E_mail_ID", visible: true, filter: true },
        { field: "city", headerName: "City", visible: true, filter: true },
        { field: "state", headerName: "States", visible: true, filter: true },
        { field: "country", headerName: "Country", visible: true, filter: true },
        { field: "status", headerName: "Status", visible: true, filter: true },
      ]);
    }
  }, [rowData]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query) {
      const filtered = rowData.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(query)
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(rowData);
    }
  };

  const onRowClicked = (event) => {
    setModalOpen(true);
    setPassData(event?.data);
  };

  const columnDefs = useMemo(() => {
    const checkboxColumn = {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      headerClass: "bg-[#3C9DD1] text-black",
    };

    const visibleColumns = filterColumnData
      .filter((col) => col.visible)
      .map((col) => {
        return {
          field: col.field,
          headerName: col?.headerName,
          filter: col?.filter,
          width: 150,
          headerClass: "bg-[#3C9DD1]",
          tooltipField: col?.field,
          cellRenderer: (params) => {
            if (col.field === "mobile" && Array.isArray(params.value)) {
              return (
                <ul>
                  {params?.value &&  params?.value?.map((item, index) => (
                    <li key={index}>{item.number}</li>
                  ))}
                </ul>
              );
            }
            if (col.field === "createdAt" || col.field === "updatedAt") {
              return (
                <>
                  {
                    moment(params.value).format("DD-MMM-YYYY")
                  }
                </>
              )
            }
            if (col?.field === "status") {
              return (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: "100%",
                  borderRadius: "20px",
                  height:"2rem",
                  backgroundColor: params.value?.toLowerCase() === "pending" ? "yellow" : (params.value?.toLowerCase() === "active" ||  params.value?.toLowerCase() === "working") ? "green" : "red",

                }}>
                  <span
                  >
                    {params?.value}
                  </span>

                </div>
              )
            }
            return params.value;
          },

          valueFormatter:
            col.field === "createdAt" || col.field === "updatedAt"
              ? (params) => moment(params.value).format("DD-MMM-YYYY")
              : undefined,
        };
      });

    return [checkboxColumn, ...visibleColumns];
  }, [filterColumnData]);

  const gridOptions = useMemo(
    () => ({
      //rowSelection: "multiple", 
      rowDragManaged: true,
      animateRows: true,
    }),
    []
  );

  const modalClose = () => {
    setModalOpen(false);
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: false,
      floatingFilter: false,
      enableRowGroup: true,
    };
  }, []);

  const selection = useMemo(() => {
    return { mode: "multiRow" };
  }, []);

  return (
    <>
      <div
        className="flex justify-end mb-4"
        style={{ width: "100%" }}
      >
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search..."
          className="border p-2"
          style={{
            borderRadius: "9px",
            width: "30%",

          }}
        />
      </div>
      <div
        className="ag-theme-quartz"
        style={{ height: "65vh", width: "100%" }}
      >
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          gridOptions={{
            ...gridOptions,
            suppressHorizontalScroll: true,
          }}
          defaultColDef={defaultColDef}
          rowDragManaged={true}
          rowDragEntireRow={true}
          rowDragMultiRow={true}
          selection={selection}
          onRowClicked={onRowClicked}
          pagination={true}
        />
      </div>

      {modalOpen && (
        <ModalUserDetails
          isVisible={modalOpen}
          onClose={modalClose}
          data={passData}
          title={"Candidate"}
        />
      )}
    </>
  );
};

export default CandiDateTable;
