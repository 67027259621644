import React from "react";

const CustomCartActivity = ({ text1, text2, text3 }) => {
  return (
    <>
      <div>
        <div className="w-40 h-36 shadow-lg rounded-lg relative">
          <div className="my-1 bg-[#EFEFEF] text-center font-poppins text-2xl text-[#3f3f3f] rounded-t-lg">{text1}</div>
          <div className="my-1 p-5 font-poppins  ">{text2}</div>
          <div className="mt-6 font-poppins absolute top-20 w-full text-center">{text3}</div>

        </div>
      </div>
    </>
  );
};

export default CustomCartActivity;
