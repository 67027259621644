import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CandiDateHeader from "./CandiDateHeader";
import { getCandiDateData } from "../../stores/Reducers/newCandiDate";
import CandiDateTable from "./CandiDateTable";


const CandiDateData = () => {
  const navigate = useNavigate();
  const [filterOpen, setFilterOpen] = useState(false)
 const [rowData, setRowData] = useState([]);
 const [filterColumnData, setFilterColumnData ] = useState([]);
  


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCandiDateData())
  }, [dispatch]);

  const {CandiDateData} = useSelector((state) =>  state?.candiDatelist)

  useEffect(() =>{
   if(CandiDateData){
    setRowData(CandiDateData);
   }
  },[CandiDateData])
  
 const closeFilter = () =>{
    setFilterOpen(false);
 }

 const openFilter = () =>{
    setFilterOpen(true);
 }

 

  return (
    <>
      
      <div
        className="min-w-full px-4 py-6 bg-[#F2F3F3] border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700"
        style={{ height: "86.5vh" }}
      >
        <CandiDateHeader title={"All CandiDate list"} isOpen={filterOpen} closeFilter={closeFilter} openFilter={openFilter} setFilterColumnData={setFilterColumnData} />
        <div className="mt-4">
            <CandiDateTable rowData={rowData}  filterColumnData={filterColumnData} setFilterColumnData={setFilterColumnData}  />
        </div>
        
      </div>
    </>
  );
};

export default CandiDateData;
