import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3500";

export const leadCreate = createAsyncThunk(
  "create/lead",
  async ({ newData }, { rejectWithValue }) => {
    try {
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.post(`${apiUrl}/api/createLead`, newData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data) {
        toast.success(`${response?.data?.message}`);
        return response?.data;
      }
      return response?.data;
    } catch (error) {
      toast.error(`${error.message}`);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const Updatelead = createAsyncThunk(
  "update/lead",
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.patch(`${apiUrl}/api/updateLead/${id}`, newData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data) {
        toast.success(`${response?.data?.message}`);
        return response?.data;
      }
      return response?.data;
    } catch (error) {
      toast.error(`${error.message}`);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getLeadDataId = createAsyncThunk(
  'get/leadById',
  async({id}, {rejectWithValue}) => {
    try{
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.get(`${apiUrl}/api/getLeadByID/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data) {
        // toast.success("Leads updated added SucessFully");
        return response?.data;
      }
      return response?.data;
    }catch (error) {
      toast.error("Id is Invalid");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
)

export const DeleteLeads = createAsyncThunk(
  'delete/lead',
  async({id}, {rejectWithValue}) => {
    try{
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.delete(`${apiUrl}/api/deleteLead/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data) {
        toast.success("Leads Deleted SucessFully");
        return response?.data;
      }
      return response?.data;
    }catch (error) {
      toast.error("Id is Invalid");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
)

export const LeadData = createAsyncThunk("get/leadData", async () => {
  try {
    const tokenData = sessionStorage.getItem("auth");
    const token = JSON.parse(tokenData);
    const response = await axios.get(`${apiUrl}/api/getLeads`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data) {
      return response?.data;
    }
    return response?.data;
  } catch (err) {
    return err;
  }
});

const newleads = createSlice({
  name: "leadFromState",
  initialState: {
    loading: false,
    message: "",
    deleteMessage:"",
    updated:"",
    getNewData: [],
    getLeadData:[],
    leadDataId:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(leadCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(leadCreate.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Sucessfully Post";
        state.getNewData = action.payload;
      })
      .addCase(leadCreate.rejected, (state, action) => {
        state.loading = false;
        state.message = "you have wrongCredatins filled that";
        state.error = action.payload;
      })
      .addCase(LeadData.pending, (state) => {
        state.loading = true;
      })
      .addCase(LeadData.fulfilled, (state, action) => {
        state.loading = false;
        
        state.getLeadData = action.payload?.data;
      })
      .addCase(LeadData.rejected, (state, action) => {
        state.loading = false;
        
      })
      .addCase(DeleteLeads.pending, (state) => {
        state.loading = true;
        state.deleteMessage="";
      })
      .addCase(DeleteLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteMessage = "Deleted Successfull";
      })
      .addCase(DeleteLeads.rejected, (state, action) => {
        state.loading = false;
        state.deleteMessage="";
        
      })
      .addCase(getLeadDataId.pending, (state) => {
        state.loading = true;
        
      })
      .addCase(getLeadDataId.fulfilled, (state, action) => {
        state.loading = false;
        state.leadDataId = action?.payload?.data;
      })
      .addCase(getLeadDataId.rejected, (state, action) => {
        state.loading = false;
        
        
      })
      .addCase(Updatelead.pending, (state) => {
        state.loading = true;
        
      })
      .addCase(Updatelead.fulfilled, (state, action) => {
        state.loading = false;
        state.Updated = "Success Updated"
      })
      .addCase(Updatelead.rejected, (state, action) => {
        state.loading = false;
        
        
      });

  },
});

export default newleads.reducer;
