import React, { useState, useMemo, useEffect } from 'react'
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { getCandiDateData } from "../../stores/Reducers/newCandiDate";
import moment from "moment";

const CandiDateSubmission = ({ jobId , close }) => {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const { CandiDateData } = useSelector((state) => state?.candiDatelist)
  const [filterColumnData, setFilterColumnData] = useState
    (
      [
        { field: "full_name", headerName: "Name", visible: true, filter: true },
        { field: "job_title", headerName: "Job Title", visible: true, filter: true },
        {
          field: "candidate_type",
          headerName: "CandiDate Type",
          visible: true,
          filter: true
        },
        { field: "mobile", headerName: "Mobile_No.", visible: true, filter: true },
        { field: "email", headerName: "E_mail_ID", visible: true, filter: true },
        { field: "city", headerName: "City", visible: true, filter: true },
        { field: "state", headerName: "States", visible: true, filter: true },
        { field: "country", headerName: "Country", visible: true, filter: true },
        { field: "status", headerName: "Status", visible: true, filter: true },
      ]
    )


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCandiDateData())
  }, [dispatch]);

  useEffect(() => {
    if (CandiDateData) {
      setRowData(CandiDateData);
    }
  }, [CandiDateData]);

  const selection = useMemo(() => {
    return { mode: "multiRow" };
  }, []);

  const columnDefs = useMemo(() => {
    const checkboxColumn = {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      headerClass: "bg-[#3C9DD1] text-black",
    };

    const visibleColumns = filterColumnData
      .filter((col) => col.visible)
      .map((col) => {
        return {
          field: col.field,
          headerName: col?.headerName,
          filter: col?.filter,
          width: 150,
          headerClass: "bg-[#3C9DD1]",
          tooltipField: col?.field,
          cellRenderer: (params) => {
            if (col.field === "mobile" && Array.isArray(params.value)) {
              return (
                <ul>
                  {params?.value && params?.value?.map((item, index) => (
                    <li key={index}>{item.number}</li>
                  ))}
                </ul>
              );
            }
            if (col.field === "createdAt" || col.field === "updatedAt") {
              return (
                <>
                  {
                    moment(params.value).format("DD-MMM-YYYY")
                  }
                </>
              )
            }
            if (col?.field === "status") {
              return (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: "100%",
                  borderRadius: "20px",
                  height: "2rem",
                  backgroundColor: params.value?.toLowerCase() === "pending" ? "yellow" : (params.value?.toLowerCase() === "active" || params.value?.toLowerCase() === "working") ? "green" : "red",

                }}>
                  <span
                  >
                    {params?.value}
                  </span>

                </div>
              )
            }
            return params.value;
          },

          valueFormatter:
            col.field === "createdAt" || col.field === "updatedAt"
              ? (params) => moment(params.value).format("DD-MMM-YYYY")
              : undefined,
        };
      });

    return [checkboxColumn, ...visibleColumns];
  }, [filterColumnData]);

  const gridOptions = useMemo(
    () => ({
      //rowSelection: "multiple",
      rowDragManaged: true,
      animateRows: true,
    }),
    []
  );
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: false,
      floatingFilter: false,
      //   enableRowGroup: true,
    };
  }, []);

  const onRowClicked = (event) => {
    // setModalOpen(true);
    // setPassData(event?.data);
    close();
    const data = {
      jobId: jobId,
      candidateData: event?.data,
    }
    navigate("/dashboard/job-list/candidateSubmission", { state: data });
  };

  return (
    <div
      className="ag-theme-quartz"
      style={{ height: "65vh", width: "100%" }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        gridOptions={{
          ...gridOptions,
          suppressHorizontalScroll: true,
        }}
        defaultColDef={defaultColDef}
        rowDragManaged={true}
        rowDragEntireRow={true}
        rowDragMultiRow={true}
        selection={selection}
        pagination={true}
        onRowClicked={onRowClicked}
      />
    </div>
  )
}

export default CandiDateSubmission
