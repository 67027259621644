import React, { useState } from 'react';

const InvoiceForm = () => {
    const [formData, setFormData] = useState({
        companyName: '',
        contact: '',
        dueDate: '',
        currency: '',
        productName: 'Black T-Shirt, Half',
        quantity: 10,
        tax: 2,
        price: 25000,
        discount: 12,
        addDiscount: true,
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    return (
        <div className="">
            <div className="bg-white border border-gray-300 p-2 rounded-lg w-full max-w-xl mb-8">
                <div className="text-lg font-bold mb-4">Invoice Details</div>

                <div className=' pr-20'>
                    <div className="mb-4">
                        {/* <label className="block text-sm font-medium text-gray-700">Select Company Name</label> */}
                        <select
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleInputChange}
                            className="mt-1 block w-full p-1 border border-gray-300 rounded-md">
                            <option>Select Company Name</option>
                            <option>Company A</option>
                            <option>Company B</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        {/* <label className="block text-sm font-medium text-gray-700">Select Contact</label> */}
                        <select
                            name="contact"
                            value={formData.contact}
                            onChange={handleInputChange}
                            className="mt-1 block w-full p-1 border border-gray-300 rounded-md">
                            <option>Select Contact</option>
                            <option>Contact A</option>
                            <option>Contact B</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        {/* <label className="block text-sm font-medium text-gray-700">Due Date</label> */}
                        <input
                            type="date"
                            name="dueDate"
                            value={formData.dueDate}
                            onChange={handleInputChange}
                            className="mt-1 block w-full  border border-gray-300 rounded-md"
                        />
                    </div>

                    <div className="mb-4">
                        {/* <label className="block text-sm font-medium text-gray-700">Select Currency</label> */}
                        <select
                            name="currency"
                            value={formData.currency}
                            onChange={handleInputChange}
                            className="mt-1 block w-full p-1 border border-gray-300 rounded-md">
                            <option>Select Currency</option>
                            <option>INR</option>
                            <option>USD</option>
                        </select>
                    </div>
                </div>

                {/* Product Details */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Product Details</label>
                    <table className="w-full border border-gray-300 text-left">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="p-2">Items</th>
                                <th className="p-2 text-sm">Qty</th>
                                <th className="p-2 text-sm">Tax %</th>
                                <th className=" text-sm">per pc. price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-2">
                                    <div className="flex items-center">
                                        <img
                                            src="https://via.placeholder.com/50"
                                            alt="Product"
                                            className="w-10 h-10 rounded mr-2"
                                        />
                                        <span className=' text-sm'>{formData.productName}</span>
                                    </div>
                                </td>
                                <td className="p-2 ">
                                    <input
                                        type="number"
                                        name="quantity"
                                        value={formData.quantity}
                                        onChange={handleInputChange}
                                        className="w-12 pl-3 border border-gray-300 rounded-md"
                                    />
                                </td>
                                <td className="p-2">
                                    <input
                                        type="number"
                                        name="tax"
                                        value={formData.tax}
                                        onChange={handleInputChange}
                                        className="w-12 pl-3 px-1 border border-gray-300 rounded-md"
                                    />
                                </td>
                                <td className="p-2">
                                    <input
                                        type="number"
                                        name="price"
                                        value={formData.price}
                                        onChange={handleInputChange}
                                        className="w-20 pl-4 border border-gray-300 rounded-md"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className=' text-end'>Add New Item</div>
                    <div className=' flex'>
                        <input type="checkbox" className=' w-4 h-4 mt-1' />
                        <div className=' ml-2'>Add Coupon</div>
                    </div>
                </div>

                {/* Discount Section */}
                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            name="addDiscount"
                            checked={formData.addDiscount}
                            onChange={handleInputChange}
                            className="form-checkbox h-4 w-4 text-blue-600"
                        />
                        <span className="ml-2">Add Discount</span>
                    </label>
                    {formData.addDiscount && (
                        <input
                            type="number"
                            name="discount"
                            value={formData.discount} 
                            onChange={handleInputChange}
                            className="ml-4 w-16 p-1 border border-gray-300 rounded-md"
                        />
                    )}
                </div>

                {/* Action Buttons */}
            </div>
            <div className="flex space-x-3 justify-end mt-6">
                <button className="bg-white text-black border border-gray-600 px-4 py-1 rounded-lg">Cancel</button>
                <button className="bg-[#3D5B81] hover:bg-[#3D5B81] text-white px-4 py-1 rounded-lg">Send Invoice</button>
            </div>
        </div>
    );
};

export default InvoiceForm;
