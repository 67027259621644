import AWS from 'aws-sdk';
const keyid = process.env.REACT_APP_AWS_ACCESS_KEY_ID
const secretAcess = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const regionName = process.env.REACT_APP_AWS_REGION;
const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;

const s3 = new AWS.S3({
  accessKeyId: keyid,
  secretAccessKey: secretAcess,
  region: regionName
});

export const uploadFile = async (file) => {
  const params = {
    Bucket: bucketName, 
    Key: `${Date.now()}-${file.name}`, 
    Body: file,
  };
  const result = await s3.upload(params).promise();
  return result
};