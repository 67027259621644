import React, { useState } from "react";
import CustomInput from "./CustomInput";
import CutomButton from "./CutomButton";
import { useDispatch } from "react-redux";
import { patchJob } from "../stores/Reducers/jobPatchDropDownReducer";
import { Job_Client_DropDown, Job_Code_DropDown, Job_User_DropDown, Job_Work_DropDown, JobDegreeDropDown, JobLanguageDropDown, JobNoticeDropDown, JobDocumentDropDown, JobPriorityDropDown, JobStatusDropDown, JobTaxTermDropDown, JobTypeDropDown } from "../stores/Reducers/jobDropDownReducer";

const AddNewJobField = ({ isModalOpen, close, label, onClick, icon }) => {
  const dispatch = useDispatch();
  const [addField, setAddField] = useState("");
  const [error, setError] = useState("");

  // if (!isvisible) return null;

  console.log(isModalOpen, "data1569")





  const handleSubmit = async () => {
    console.log('Submitting new option:', addField);
    try {
      if (!addField) {
        setError(`${label} is Required`)
      }
      else if (addField && label === "job Status") {
        const newData = {
          new_job_status: addField
        };
        const endPoint = "updateJobStatusList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job status list updated") {
          await dispatch(JobStatusDropDown());
          close();
          return;
        }

      }
      else if (addField && label === "Job Type") {
        const newData = {
          new_job_type: addField
        };
        const endPoint = "updateJobTypeList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job type list updated") {
          await dispatch(JobTypeDropDown());
          close();
          return ;
        }

      }
      else if (addField && label === "Priority") {
        const newData = {
          new_job_priority: addField
        };
        const endPoint = "updateJobPriorityList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job priority list updated") {
          await dispatch(JobPriorityDropDown());
          close();
          return ;
        }
      }
      else if (addField && label === "Notice Period") {
        const newData = {
          new_job_noticePeriod: addField
        };
        const endPoint = "updateJobNoticePeriodList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job noticePeriod list updated") {
          await dispatch(JobNoticeDropDown());
          close();
          return;
        }

      }
      else if (addField && label === "Tax Terms") {
        const newData = {
          new_job_taxTerm: addField
        };
        const endPoint = "updateJobTaxTermList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job taxTerm list updated") {
          await dispatch(JobTaxTermDropDown());
          close();
          return ;
        }

      }
      else if (addField && label === "Required Documents for Submission") {
        const newData = {
          new_job_document: addField
        };
        const endPoint = "updateJobDocumentList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job document already exists") {
          await dispatch(JobDocumentDropDown());
          close();
          return ;
        }

      }
      else if (addField && label === "Work Authorization") {
        const newData = {
          new_job_workAuth: addField
        };
        const endPoint = "updateJobWorkAuthList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job workAuth list updated") {
          await dispatch(Job_Work_DropDown());
          close();
          return ;
        }

      }
      else if (addField && label === "Degree") {
        const newData = {
          new_job_degree: addField
        };
        const endPoint = "updateJobDegreeList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job degree list updated") {
          await dispatch(JobDegreeDropDown());
          close();
          return;
        }

      }
      else if (addField && label === "Language") {
        const newData = {
          new_job_language: addField
        };
        const endPoint = "updateJobLanguageList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job language list updated") {
          await dispatch(JobLanguageDropDown());
          close();
          return;
        }

      }

    }
    catch (err) {
      console.error(err)
    }


  };

  const handleField = (e) => {
    setAddField(e.target.value)
  }


  return (
    <>
      {
        isModalOpen &&
        <div
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-7xl" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {`Update ${label}`}
                </h3>
                <button
                  type="button"
                  onClick={() => close()}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>

              <div className="p-4 md:p-5 space-y-4" >
                <div className=" grid grid-cols-2 gap-8 px-4 py-3">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CustomInput
                      label={label}
                      placeholder={`Add New ${label} `}
                      value={addField}
                      icon={icon}
                      onChange={(e) => handleField(e)}
                      error={error}
                    />
                  </div>

                </div>


              </div>
              <div className="mt-20 pb-20 flex justify-center animate-slide-in-left">
                <CutomButton text={"Submit"} bgColor={"#3D5B81"} width={"200"} onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      }


    </>
  );
};

export default AddNewJobField;