import React, { useState } from "react";
import CustomInput from "./CustomInput";
import CutomButton from "./CutomButton";
import { useDispatch } from "react-redux";
import { putJob, putGuidelineJob } from "../stores/Reducers/jobPatchDropDownReducer";
import { documents_Dropdown, candiDate_DropDown, Guideline_documents_Dropdown, Country_DropDown, company_DropDown, client_DropDown, status_DropDown, Industry_DropDown, leadDropDown, sourceDropDown, user_visa_DropDown } from '../stores/Reducers/dropDownApiReducers';
import { patchJob } from "../stores/Reducers/jobPatchDropDownReducer";
import {  JobDegreeDropDown} from "../stores/Reducers/jobDropDownReducer";

const AddNewField = ({ isModalOpen, close, label, onClick, icon, name2 }) => {
  const dispatch = useDispatch();
  const [addField, setAddField] = useState("");
  const [error, setError] = useState("");

  // if (!isvisible) return null;


  const handleSubmit = async () => {
    try {
      if (!addField) {
        setError(`${label} is Required`)
      }
      else if (addField && label === "Client Type" && name2 === "account") {
        const newData = {
          new_client_type: addField
        };
        const endPoint = "updateCTList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint, name2
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "Client Type list updated") {
          let endpoints = "account";
          await dispatch(client_DropDown({ endpoints }));
          close();
          return;
        }
      }
      else if (addField && label === "Client Type" && name2 === "contact") {
        const newData = {
          new_client_type: addField
        };
        const endPoint = "updateCTList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint, name2
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "Client Type list updated") {
          let endpoints = "contact";
          await dispatch(client_DropDown({ endpoints }));
          close();
          return;
        }
      }
      else if (addField && label === "Select Document" && name2 === "contact") {
        const newData = {
          new_document_name: addField
        };
        const endPoint = "updateDocumentList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint, name2
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "Document Name list updated") {
          let endpoints = "contact";
          await dispatch(documents_Dropdown({ endpoints }));
          close();
          return;
        }
      }
      else if (addField && label === "Select Document" && name2 === "account") {
        const newData = {
          new_document_name: addField
        };
        const endPoint = "updateDocumentList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint, name2
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "Document Name list updated") {
          let endpoints = "account";
          await dispatch(documents_Dropdown({ endpoints }));
          close();
          return;
        }
      }
      else if (addField && label === "Select Document" && name2 === "candidate") {
        const newData = {
          new_document_name: addField
        };
        const endPoint = "updateDocumentList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint, name2
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "Document Name list updated") {
          let endpoints = "candidate";
          await dispatch(documents_Dropdown({ endpoints }));
          close();
          return;
        }
      }
      else if (addField && label === "Select Document" && name2 === "jobDocuments") {
        const newData = {
          new_document_name: addField
        };
        const endPoint = "updateDocumentList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint, name2: "job"
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "Document Name list updated") {
          let endpoints = "job";
          await dispatch(documents_Dropdown({ endpoints }));
          close();
          return;
        }
      }
      else if (addField && label === "Select Document" && name2 === "GuidelineAccount") {
        const newData = {
          new_document_name: addField
        };


        const upDateResponse = await dispatch(putGuidelineJob({
          newData, accountName: "account"
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "Document Name list updated") {
          let endpoints = "account";
          await dispatch(Guideline_documents_Dropdown({ endpoints }));
          close();
          return;
        }
      }
      else if (addField && label === "Status") {
        const newData = {
          new_status: addField
        };
        const endPoint = "updateStatusList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint
        }))
        if (upDateResponse?.payload && upDateResponse?.payload?.message === "Status list updated") {
          await dispatch(status_DropDown());
          close();
          return;
        }
      }
      else if (addField && label === "Visa") {
        const newData = {
          new_status: addField
        };
        const endPoint = "updateVisaTypeList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint
        })).then((res) => {
          return res;
        }).catch((err) => console.log(err));
        if (upDateResponse?.payload && upDateResponse?.payload?.message === "Visa Type list updated") {
          await dispatch(user_visa_DropDown()).then((res) => {
            return res;
          }).catch((err) => console.log(err));
          close();
          return;
        }
      }
      else if (addField && label === "Degree" ) {
        const newData = {
          new_job_degree: addField
        };
        const endPoint = "updateJobDegreeList";

        const upDateResponse = await dispatch(patchJob({
          newData, endPoint
        }))
        if (upDateResponse.payload && upDateResponse.payload.message === "job degree list updated") {
          dispatch(JobDegreeDropDown());
          close();
          return;
        }
      }
      else if (addField && label === "Lead Label") {
        const newData = {
          new_lead_lebel: addField
        };
        const endPoint = "updateLeadLebelList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint
        })).then((res) => {
          return res;
        }).catch((err) => console.log(err));
        if (upDateResponse?.payload && upDateResponse?.payload?.message === "Lead Lebel list updated") {
          await dispatch(leadDropDown()).then((res) => {
            return res;
          }).catch((err) => console.log(err));
          close();
          return;
        }
      }
      else if (addField && label === "Source") {
        const newData = {
          new_lead_source: addField
        };
        const endPoint = "updateLeadSourceList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint
        }))
        if (upDateResponse?.payload && upDateResponse?.payload?.message === "Lead Source list updated") {
          await dispatch(sourceDropDown());
          close();
          return;
        }
      }
      else if (addField && label === "Source Type") {
        const newData = {
          candidate_new_type: addField
        };
        const endPoint = "updateCandidateTypeList";

        const upDateResponse = await dispatch(putJob({
          newData, endPoint
        }))
        if (upDateResponse?.payload && upDateResponse?.payload?.message === "Candidate Type list updated") {
          await dispatch(candiDate_DropDown());
          close();
          return;
        }
      }
    }
    catch (err) {
      console.error(err);
    }



  };

  const handleField = (e) => {
    setAddField(e.target.value)
  }


  return (
    <>
      {
        isModalOpen &&
        <div
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-7xl" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {`Update ${label}`}
                </h3>
                <button
                  type="button"
                  onClick={() => close()}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>

              <div className="p-4 md:p-5 space-y-4" >
                <div className=" grid grid-cols-2 gap-8 px-4 py-3">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CustomInput
                      label={label}
                      placeholder={`Add New ${label} `}
                      value={addField}
                      icon={icon}
                      onChange={(e) => handleField(e)}
                      error={error}
                    />
                  </div>

                </div>


              </div>
              <div className="mt-20 pb-20 flex justify-center animate-slide-in-left">
                <CutomButton text={"Submit"} bgColor={"#3D5B81"} width={"200"} onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      }


    </>
  );
};

export default AddNewField;