import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3500";

export const createAccount = createAsyncThunk(
  "create/account",
  async ({ newData }, { rejectWithValue }) => {
    try {
      const tokenData = sessionStorage.getItem("auth");
      const token = JSON.parse(tokenData);
      const response = await axios.post(
        `${apiUrl}/api/createAccount`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        toast.success("Accounts Data added SucessFully");
      }
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const AccountData = createAsyncThunk("get/accountData", async () => {
  try {
    const tokenData = sessionStorage.getItem("auth");
    const token = JSON.parse(tokenData);
    const response = await axios.get(`${apiUrl}/api/getAccounts`,  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data) {
      return response?.data;
    }
    return response?.data;
  } catch (err) {
    return err;
  }
});

const newAccount = createSlice({
  name: "contactFromState",
  initialState: {
    loading: false,
    message: "",
    getNewData: [],
    getAccountData:[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data){
        state.message = action?.payload?.message;
        state.getNewData = action.payload?.data;
        }
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.loading = false;
        state.message = "you have wrongCredatins filled that";
        state.error = action.payload;
      })
      .addCase(AccountData.pending, (state) => {
        state.loading = true;
      })
      .addCase(AccountData.fulfilled, (state, action) => {
        state.loading = false;
        state.getAccountData = action?.payload?.data;
      })
      .addCase(AccountData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default newAccount.reducer;
